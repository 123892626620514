import React from "react";
import {
    Badge,
    Button,
    ButtonGroup, Checkbox,
    Dialog,
    Grid,
    makeStyles,
    Paper,
    Snackbar,
    Typography
} from "@material-ui/core";
import Colors from "../../../../constants/Colors";
import Env from "../../../../constants/Env";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles( () => ( {
    paper: {
        width: "100%",
        height: "100%",
        borderRadius: 10,
        padding: 20,
    },
    titleText: {
        color: Colors.PRIMARY_BLUE,
        fontSize: 28,
        fontFamily: Colors.fontOfProgram,
        paddingBottom: 20,
        fontWeight: "bold",
    },
    input: {
        display: "none",
    },
    img: {
        paddingRight: 10,
        width: 160,
        maxHeight: 328,
        cursor: "pointer",
        display: "inline",
    },
    imgTemp: {
        paddingRight: 10,
        width: 160,
        minHeight: 328,
    },
    imageGrid: {
        padding: 4,
        position: "relative",
        maxWidth: 1400,
        overflowX: "auto",
        whiteSpace: "nowrap",
    },
    button: {
        width: 200,
        padding: 10,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_BLUE,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_BLUE,
            color: Colors.PRIMARY_WHITE,
        }
    },
    buttonText: {
        fontWeight: "bold",
    },
    buttonGrid: {
        display: "flex",
        justifyContent: "center",
        borderRadius: 10,
        paddingTop: 0,
        margin: 0,
    },
    buttonGridTop: {
        display: "flex",
        justifyContent: "center",
        borderRadius: 10,
        paddingTop: 30,
        margin: 0,
    },
    dialogImage: {
        maxHeight: 900,
        maxWidth: 1200
    },
    seeAllGrid: {
        padding: 20,
        display: "flex",
        justifyContent: "center",
    },
    seeAllImg: {
        padding: 4,
        width: 200,
        cursor: "pointer",
        display: "inline",
    },
    buttonLabel: {
        backgroundColor: Colors.PRIMARY_WHITE,
        borderRadius: 10,
        padding: 4,
    },
    dialogBodyText: {
        fontWeight: "bold",
        fontFamily: Colors.fontOfProgram,
        paddingRight: 20,
        fontSize: 16,
        color: Colors.PRIMARY_GREY,
    },

} ) )

export default function ProjectImageSettings( props ) {
    const [ imageDialog, setImageDialog ] = React.useState( null )
    const [ successOpen, setSuccessOpen ] = React.useState( "" )
    const [ errorOpen, setErrorOpen ] = React.useState( "" )
    const [ seeAll, setSeeAll ] = React.useState( false )
    const [ selectedPhotos, setSelectedPhotos ] = React.useState( [] )
    const classes = useStyles()

    function checkImageWasSelected( imageId ) {
        let check = false

        selectedPhotos.forEach( id => {
            if ( id === imageId )
                check = true
        } )

        return check
    }


    return <Paper
        elevation={ 20 }
        className={ classes.paper }>
        <Grid container>
            <Grid container>
                <Grid item>
                    <Typography className={ classes.titleText }>
                        Images
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item>
                    <Typography className={ classes.dialogBodyText }>
                        Click to zoom
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Paper elevation={ 0 } className={ classes.imageGrid }>

                    { props.project.images.map( image => {
                        return <img
                            onClick={ () => setImageDialog( image ) }
                            src={ process.env.REACT_APP_IMAGE_URL + image.url } alt="project's uploaded img"
                            className={ classes.img }/>
                    } ) }
                    { props.project.images.length === 0 ?
                        <Paper
                            elevation={ 0 }
                            className={ classes.imgTemp }
                        />
                        :
                        null }

                </Paper>

            </Grid>
            <Grid container className={ classes.buttonGridTop }>
                <Paper elevation={ 10 } className={ classes.buttonGrid }>
                    <input
                        disabled={ !props.auth }
                        className={ classes.input }
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={ async ( event ) => {
                            /*
                            let tempList = [];
                            const formData = new FormData();

                            Array.from( event.target.files )
                                 .forEach( ( file, index ) => {
                                     formData.append( "files", file, file.name )
                                     tempList.push( {
                                         "listOfLong": []
                                     } )
                                 } )

                            formData.append( "projectId", props.project.id )


                            const headers = {
                                'Authorization': 'Bearer ' + Env.TOKEN
                            }

                            fetch( process.env.REACT_APP_URL + 'projects/files', {
                                method: 'POST', headers: headers, body: formData
                            }, )
                                .then( response => response.json() )
                                .then( ( data ) => {

                                    props.project.images = [ ...props.project.images, ...data ]
                                    setSelectedPhotos( [] )
                                } )
                                .then( () => {
                                    setSuccessOpen( "Images are uploaded successfully" )
                                } )
                                .catch( () => {
                                    setErrorOpen( "Something went wrong!" )
                                } )
                             */

                            let tracer = 0
                            for ( const file of Array.from( event.target.files ) ) {
                                let tempListAlt = [];
                                const formDataAlt = new FormData();
                                formDataAlt.append( "files", file, file.name )
                                tempListAlt.push( {
                                    "listOfLong": []
                                } )
                                formDataAlt.append( "projectId", props.project.id )
                                const headers = {
                                    'Authorization': 'Bearer ' + Env.TOKEN
                                }
                                await fetch( process.env.REACT_APP_URL + 'projects/files', {
                                    method: 'POST', headers: headers, body: formDataAlt
                                }, )
                                    .then( response => response.json() )
                                    .then( ( data ) => {

                                        props.project.images = [ ...props.project.images, ...data ]
                                        setSelectedPhotos( [] )
                                    } )
                                    .then( () => {
                                        tracer++
                                        setSuccessOpen( tracer + "/" + event.target.files.length )

                                    } )
                                    .catch( () => {
                                        setErrorOpen( "Something went wrong!" )
                                    } )
                            }


                        } }
                    />
                    <ButtonGroup>

                        <label htmlFor="contained-button-file" className={ classes.buttonLabel }>
                            <Button disabled={ !props.auth }
                                    component="span"
                                    className={ classes.button }>
                                <Typography className={ classes.buttonText }>
                                    Upload
                                </Typography>
                            </Button>
                        </label>
                        <label className={ classes.buttonLabel }>
                            <Button
                                disabled={ props.project.images.length === 0 }
                                onClick={ () => setSeeAll( true ) } className={ classes.button }>
                                <Typography className={ classes.buttonText }>
                                    See All
                                </Typography>
                            </Button>
                        </label>
                    </ButtonGroup>
                </Paper>
            </Grid>
        </Grid>
        { imageDialog !== null ? <Dialog open={ imageDialog !== null } onClose={ () => setImageDialog( null ) }>
            <img
                src={ process.env.REACT_APP_IMAGE_URL + imageDialog.url } alt="project's uploaded img"
                className={ classes.dialogImage }
            />
        </Dialog> : null }
        <Dialog fullWidth={ true } maxWidth={ "lg" } open={ seeAll } onClose={ () => setSeeAll( false ) }>
            <Grid container style={ {
                display: "flex",
                justifyContent: "center",
                paddingTop: 30,
                paddingBottom: 20
            } }>
                <ButtonGroup style={ {
                    background: Colors.PRIMARY_BLUE,
                    borderColor: Colors.PRIMARY_BLUE,
                    borderWidth: 2,
                    border: "solid",
                    borderRadius: 15,
                } }>
                    <label>
                        <Button
                            onClick={ () => {
                                if ( selectedPhotos.length === 0 ) {
                                    setErrorOpen( "No selection was made!" )
                                } else {
                                    const headers = {
                                        "Content-Type": "application/json",
                                        'Authorization': 'Bearer ' + Env.TOKEN
                                    }

                                    let temp = []
                                    props.project.images.forEach( image => {
                                        let check = true
                                        selectedPhotos.forEach( id => {
                                            if ( image.id === id ) {
                                                check = false
                                            }
                                        } )

                                        if ( check )
                                            temp.push( image )
                                    } )

                                    props.project.images = temp
                                    let returnObj = [ ...selectedPhotos ]
                                    setSelectedPhotos( [] )

                                    fetch( process.env.REACT_APP_URL + 'projects/images', {
                                        method: 'DELETE', headers: headers, body: JSON.stringify( returnObj )
                                    }, )
                                        .then( response => response.json() )
                                        .then( ( data ) => {

                                        } )
                                        .then( () => {
                                            setSuccessOpen( "Images are deleted successfully" )
                                        } )
                                        .catch( () => {
                                            setErrorOpen( "Something went wrong!" )
                                        } )
                                }
                            } }
                            className={ classes.button }>
                            <Typography className={ classes.buttonText }>
                                Delete Images
                            </Typography>
                        </Button>
                    </label>
                </ButtonGroup>
            </Grid>
            <Grid container className={ classes.seeAllGrid }>
                { props.project.images.map( image => {
                    return <Grid item>
                        <Grid container>
                            <Grid item xs={ 12 } style={ {
                                display: "flex",
                                justifyContent: "center",
                            } }>
                                <img
                                    onClick={ () => setImageDialog( image ) }
                                    src={ process.env.REACT_APP_IMAGE_URL + image.url } alt="project's uploaded img"
                                    className={ classes.seeAllImg }/>
                            </Grid>
                            <Grid item xs={ 12 } style={ {
                                display: "flex",
                                justifyContent: "center",
                            } }>
                                <Checkbox checked={ checkImageWasSelected( image.id ) } onChange={ () => {
                                    if ( checkImageWasSelected( image.id ) ) {
                                        let temp = []
                                        selectedPhotos.forEach( id => {
                                            if ( id !== image.id ) {
                                                temp.push( id )
                                            }
                                        } )
                                        setSelectedPhotos( temp )
                                    } else {
                                        let temp = [ ...selectedPhotos, image.id ]
                                        setSelectedPhotos( temp )
                                    }
                                } }/>
                            </Grid>
                        </Grid>
                    </Grid>
                } ) }
            </Grid>
        </Dialog>
        <Snackbar open={ errorOpen !== "" }
                  autoHideDuration={ 6000 }
                  onClose={ () => setErrorOpen( "" ) }
        >
            <Alert onClose={ () => setErrorOpen( "" ) }
                   severity={ "warning" }
            >
                { errorOpen }
            </Alert>
        </Snackbar>
        <Snackbar open={ successOpen !== "" }
                  autoHideDuration={ 1000 }
                  onClose={ () => setSuccessOpen( "" ) }
        >
            <Alert onClose={ () => setSuccessOpen( "" ) }
                   severity={ "success" }
            >
                { successOpen }
            </Alert>
        </Snackbar>
    </Paper>
}