import React from "react";
import { Grid, Snackbar, Tab, Tabs } from "@material-ui/core";
import InnerTabs from "./InnerTabs";
import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../../../constants/Colors";
import Env from "../../../../constants/Env";
import { Alert } from "@material-ui/lab";
import Program from "../../../../constants/Program";

const useStyles = makeStyles( {
    root: {
        flexGrow: 1,
        backgroundColor: Colors.gradientMainLayoutBlueReverse,
        display: 'flex',
    },
    outerTab: {
        paddingTop: 48,
        borderRadius: 10,
        borderTopRightRadius: 0,
        background: Colors.gradientMainLayoutBlueReverse,
        color: Colors.PRIMARY_WHITE,
        "&.Mui-selected": {}
    },
    indicator: {
        background: "transparent",
        width: '100%',
        display: "flex",
        justifyContent: 'center',
    },
    tab: {
        paddingLeft: 30,
        paddingRight: 30,
        "&.Mui-selected": {
            background: Colors.PRIMARY_WHITE,
            color: Colors.PRIMARY_PINK_UP,
        }
    },
    transparent: {
        background: 'transparent',
    }
} );

export default function OuterTabs( props ) {
    const [ outerTab, setOuterTab ] = React.useState( 0 )
    const [ innerTab, setInnerTab ] = React.useState( 0 )
    const classes = useStyles()
    const [ table, setTable ] = React.useState( null )
    const [ allTable, setAllTable ] = React.useState( null )
    const [ projectTrace, setProjectTrace ] = React.useState( null )
    const [ textChanges, setTextChanges ] = React.useState( [] )
    const [ localizationChanges, setLocalizationChanges ] = React.useState( [] )
    const [ submitted, setSubmitted ] = React.useState( false )
    const [ successOpen, setSuccessOpen ] = React.useState( false )
    const [ errorOpen, setErrorOpen ] = React.useState( false )
    const [ infoOpen, setInfoOpen ] = React.useState( "" )
    const [ autoSave, setAutoSave ] = React.useState( false )
    const [ selectedLocalizations, setSelectedLocalizations ] = React.useState( [] )
    const [ allTableAddedTexts, setAllTableAddedTexts ] = React.useState( [] )
    const [ languages, setLanguages ] = React.useState( null )
    const [ allTableLoading, setAllTableLoading ] = React.useState( false )
    const [ ableLoading, setTableLoading ] = React.useState( false )
    const categoryConfirmations = []
    let userRole = null

    if ( languages === null ) {
        setLanguages( [ ...props.project.projectLanguages, props.project.base ] )
    }

    props.project.categories.forEach( cat => {
        categoryConfirmations[ cat.categoryName ] = 0
    } )

    /*
        props.program.user.roles.forEach(role => {
            props.project.categories.forEach(category => {
                let count = 0;
                category.texts.forEach(text => {
                    text.localizations.forEach(localization => {

                            localization.confirmations.forEach(confirmation => {
                                if ((confirmation.statusInfo === "CHANGE")
                                    && confirmation.status !== "APPROVED"
                                    && confirmation.projectRole.id === role.projectRole.id)
                                    count++;
                            })
                        }
                    )
                })
                categoryConfirmations[category.categoryName] = categoryConfirmations[category.categoryName] + count;
            })
        })
     */

    props.project.roles.forEach( role => {
        let categoryCheck = false
        role.roleRestrictionList.forEach( roleRest => {

            if ( roleRest.category.id === props.project.categories[ outerTab ].id )
                categoryCheck = true
        } )

        if ( categoryCheck ) {
            role.roleList.forEach( el => {

                if ( el.user.id === props.program.user.id ) {
                    userRole = role
                }
            } )
        }
    } )

    if ( projectTrace === null || projectTrace.id !== props.project.id ) {
        setLanguages( [ ...props.project.projectLanguages, props.project.base ] )
        setProjectTrace( props.project )
        setLocalizationChanges( [] )
        setTextChanges( [] )
        setOuterTab( 0 )
        setInnerTab( 0 )
        handleTableChange( 0, 0 )
        Program.setCheckList( [] )
        handleAllTableChange( 0 )
    }

    function checkTextInBlackList( text ) {
        let check = false
        props.project.blackListTexts.forEach( blackListText => {
            if ( blackListText.text === text )
                check = true
        } )
        return check
    }

    function calculateTextCount( textModelList ) {
        const regexpEmojiPresentation = /\p{Emoji_Presentation}/gu;
        let count = 0

        textModelList.forEach( textModel => {
            textModel.englishShownWord.split( " " )
                     .forEach( el => {

                         if ( el.match( regexpEmojiPresentation ) === null && el.length > 1 ) {
                             if ( !checkTextInBlackList( el ) ) {
                                 count++
                             }
                         } else {
                             el.split( regexpEmojiPresentation )
                               .forEach( elChild => {
                                   if ( elChild.length > 1 && !checkTextInBlackList( elChild ) ) {
                                       count++
                                   }
                               } )
                         }
                     } )


        } )
        return count
    }

    async function saveAllTableNewTexts() {
        let returnObject = []
        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }

        allTableAddedTexts.forEach( row => {
            returnObject.push( {
                "englishWord": row,
                "projectId": props.project.id,
                "categoryId": props.project.categories[ outerTab ].id
            } )

        } )
        setAllTableAddedTexts( [] )


        await fetch( process.env.REACT_APP_URL + 'projects/texts?userId=' + props.program.user.id, {
            method: 'POST', headers: headers, body: JSON.stringify( returnObject )
        }, )
            .then( response => response.json() )
            .then( data => {

                let tempList = [ ...allTable, ...data ]
                tempList.forEach( text => {
                    text.localizations = text.localizations.sort( ( l1, l2 ) => {
                        if ( l1.base ) {
                            return 1
                        } else if ( l2.base ) {
                            return -1
                        } else if ( l1.projectLanguage.language.language > l2.projectLanguage.language.language ) {
                            return 1
                        } else {
                            return -1
                        }
                    } )
                } )
                setAllTable( tempList )
                props.project.categories[ outerTab ].texts = tempList
            } )
            .catch( e => {
                setErrorOpen( true )
                console.log( e )
            } )

    }

    async function deleteText( text ) {
        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }
        let temp = []
        allTable.forEach( row => {
            if ( row.id !== text.id ) {
                temp.push( row )
            }
        } )
        setAllTable( temp )

        await fetch( process.env.REACT_APP_URL + 'projects/texts?textId=' + text.id, {
            method: 'DELETE', headers: headers
        }, )
            .then( response => response.json() )
            .then( () => {
                setSuccessOpen( true );
            } )
            .catch( e => {
                setAllTable( props.project.categories[ outerTab ].texts )
                setErrorOpen( true )
            } )
    }

    async function deleteTexts( list ) {
        setSubmitted( true )
        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }
        let temp = []

        allTable.forEach( row => {
            let check = true
            list.forEach( el => {
                if ( el === row.id ) {
                    check = false
                }
            } )
            if ( check ) {
                temp.push( row )
            }
        } )
        setAllTable( temp )

        await fetch( process.env.REACT_APP_URL + 'projects/texts/selected', {
            method: 'DELETE', headers: headers, body: JSON.stringify( list )
        }, )
            .then( response => response.json() )
            .then( () => {
                setSuccessOpen( true );
                setSubmitted( false )
            } )
            .catch( e => {
                setAllTable( props.project.categories[ outerTab ].texts )
                setErrorOpen( true )
            } )
    }

    async function autoFillTexts( list ) {
        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }

        await fetch( process.env.REACT_APP_URL + 'projects/texts/autofill?userId=' + props.program.user.id, {
            method: 'POST', headers: headers, body: JSON.stringify( list )
        }, )
            .then( response => response.json() )
            .then( async () => {
                let tempList = []

                setAllTable( [] )

                await handleCategoryGet( props.project.categories[ outerTab ].id )
                    .then( data => {
                        data.texts.forEach( text => {
                            let check = false
                            text.localizations.forEach( loc => {
                                if ( !check && checkConfirmationIsExist( loc ) ) {
                                    check = true
                                }
                            } )
                            text.confirmNeed = check
                            tempList.push( text )
                        } )
                        props.project.categories[ outerTab ].texts = tempList
                        setAllTable( tempList )

                    } )
            } )
            .then( () => {
                setSuccessOpen( true )

            } )
            .catch( e => {
                setAllTable( props.project.categories[ outerTab ].texts )
                setErrorOpen( true )
            } )
    }

    async function handleAllTableImageChange( textId, image ) {
        setAutoSave( true )
        let temp = []

        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }
        let rowTemp
        allTable.forEach( row => {
            if ( row.id === textId ) {
                row.image = image
                rowTemp = row
            }
            temp.push( row )
        } )

        setAllTable( temp )

        if ( rowTemp !== null ) {
            let returnObject = []

            returnObject.push( {
                "textId": rowTemp.id,
                "image": rowTemp.image,
                "explanation": rowTemp.explanation,
                "englishWord": rowTemp.englishWord,
                "androidKey": rowTemp.androidKey
            } )

            await fetch( process.env.REACT_APP_URL + 'projects/texts/update?userId=' + props.program.user.id, {
                method: 'POST', headers: headers, body: JSON.stringify( returnObject )
            }, )
                .then( response => response.json() )
                .then( data => {
                } )
                .then( () => {
                    handleTableChange( outerTab, innerTab )

                    setAutoSave( false )
                } )
                .catch( e => {
                } )
        }
    }

    async function handleAllTableAndroidKeySave( textId, androidKey ) {
        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }
        let returnObject = []

        returnObject.push( {
            "id": textId,
            "androidKey": androidKey
        } )

        allTable.forEach( row => {
            if ( row.id === textId ) {
                row.androidKey = androidKey
            }
        } )


        fetch( process.env.REACT_APP_URL + 'projects/texts/androidKeys', {
            method: 'POST', headers: headers, body: JSON.stringify( returnObject )
        }, )
            .then( response => response.json() )
            .then( data => {
                //setAutoSave(false)

            } )
            .catch( e => {
            } )

        props.project.categories[ outerTab ].texts = allTable

    }

    async function handleAllTableExplanationSave( textId, explanation ) {
        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }
        let rowTemp
        let temp = []
        allTable.forEach( row => {
            if ( row.id === textId ) {
                row.explanation = {
                    id: 0,
                    content: explanation
                }
                rowTemp = row
            }
            temp.push( row )
        } )

        setAllTable( temp )
        if ( rowTemp !== null ) {
            let returnObject = []
            returnObject.push( {
                "content": explanation,
                "textIdList": [ textId ]
            } )

            await fetch( process.env.REACT_APP_URL + 'projects/explanations?projectId=' + props.project.id, {
                method: 'POST', headers: headers, body: JSON.stringify( returnObject )
            }, )
                .then( response => {
                    if ( response.ok ) {
                        return response.json()
                    } else {
                        throw Error( "Something went wrong" )
                    }
                } )
                .then( ( data ) => {
                    let temp = []
                    allTable.forEach( row => {
                        if ( row.id === textId ) {
                            row.explanation = {
                                id: data[ 0 ].id,
                                content: explanation
                            }
                        }
                        temp.push( row )
                    } )
                    setAllTable( temp )
                    props.project.categories[ outerTab ].texts = temp

                    let check = true

                    props.project.explanations.forEach( exp => {
                        if ( exp.id === data[ 0 ].id )
                            check = false
                    } )

                    if ( check )
                        props.project.explanations.push( {
                            id: data[ 0 ].id,
                            content: explanation
                        } )

                    handleTableChange( outerTab, innerTab )

                } )
                .catch( e => {
                } )
        }
    }

    function handleAllTableExplanationChange( textId, explanation ) {

        let temp = []
        allTable.forEach( row => {
            if ( row.id === textId ) {
                row.explanation = {
                    id: 0,
                    content: explanation
                }
            }
            temp.push( row )
        } )

        setAllTable( temp )
    }

    function allTableAddNewText( text ) {
        let check = true;
        allTableAddedTexts.forEach( el => {
            if ( el.trim() === text.trim() )
                check = false
        } )

        allTable.forEach( row => {
            if ( row.englishWord.trim() === text.trim() )
                check = false
        } )

        if ( check ) {
            let temp = [ ...allTableAddedTexts ]
            temp.push( text.trim() )
            setAllTableAddedTexts( temp )
        } else {
            setInfoOpen( "Identical line already in the list." )
        }

    }

    function allTableDeleteNewText( text ) {
        let temp = []
        allTableAddedTexts.forEach( el => {
            if ( el !== text ) {
                temp.push( el )
            }
        } )

        setAllTableAddedTexts( temp )
    }

    function allTableNewTextChange( index, text ) {
        let temp = [ ...allTableAddedTexts ]
        temp[ index ] = text
        setAllTableAddedTexts( temp )
    }

    function allTableBaseChange( baseId, text ) {
        let tempTable = [ ...allTable ]

        tempTable.forEach( row => {
            if ( row.id === baseId )
                row.englishWord = text
        } )
        setAllTable( tempTable )
    }

    async function saveTableLocalizationChange( localization ) {
        //setAutoSave(true)

        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }
        let returnObject = []


        returnObject.push( {
            "id": localization.id,
            "text": localization.text
        } )


        fetch( process.env.REACT_APP_URL + 'projects/textLocalizations?id=' + props.program.user.id, {
            method: 'POST', headers: headers, body: JSON.stringify( returnObject )
        }, )
            .then( response => response.json() )
            .then( data => {
                //console.log(data)
                //setAutoSave(false)

            } )
            .catch( e => {
            } )


    }

    async function saveTableTextChange( base ) {
        //setAutoSave(true)

        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }
        let returnObject = []

        returnObject.push( {
            "textId": base.id,
            "image": base.image,
            "explanation": base.explanation,
            "englishWord": base.englishWord,
            "androidKey": base.androidKey
        } )


        fetch( process.env.REACT_APP_URL + 'projects/texts/update?userId=' + props.program.user.id, {
            method: 'POST', headers: headers, body: JSON.stringify( returnObject )
        }, )
            .then( response => response.json() )
            .then( data => {
                //setAutoSave(false)

            } )
            .catch( e => {
            } )

        props.project.categories[ outerTab ].texts = allTable
    }

    function allTableLocalizationChange( localizationId, text ) {
        let tempTable = [ ...allTable ]
        tempTable.forEach( row => {
            row.localizations.forEach( rowEl => {
                if ( rowEl.id === localizationId )
                    rowEl.text = text
            } )
        } )
        setAllTable( tempTable )

    }

    function checkSelected( id ) {
        let check = false
        selectedLocalizations.forEach( loc => {
            if ( loc === id )
                check = true
        } )
        return check
    }

    function handleCheck( id ) {
        let temp = []
        let check = false
        selectedLocalizations.forEach( loc => {
            if ( loc === id ) {
                check = true
            } else {
                temp.push( loc )
            }
        } )
        if ( !check )
            temp.push( id )
        setSelectedLocalizations( temp )
    }

    function selectAll( status, isAndroid ) {
        let temp = []
        if ( status ) {
            table.forEach( row => {
                if ( !( row.localization.text === null
                    || row.localization.text.length === 0
                    ||
                    ( isAndroid && ( row.androidKey === null || row.androidKey.length === 0 ) ) )
                )
                    temp.push( row.localization.id )
            } )
        }
        setSelectedLocalizations( temp )
    }

    function selectUnconfirmed( isAndroid ) {
        let temp = [ ...selectedLocalizations ]
        table.forEach( row => {
            if ( row.localization.text !== null && row.localization.text.length > 0
                && row.confirmationAvailable
                &&
                ( !isAndroid || ( isAndroid && row.androidKey !== null && row.androidKey.length > 0 ) )
                && !checkSelected( row.localization.id )
            )
                temp.push( row.localization.id )
        } )
        setSelectedLocalizations( temp )
    }

    async function confirmChangesWithIdList() {
        let hashmap = {}
        let temp = []
        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }

        table.forEach( row => {
            let tempRow = { ...row }
            selectedLocalizations.forEach( id => {
                if ( tempRow.localization.id === id ) {
                    tempRow.localization.confirmations.forEach( ( confirmation, index ) => {
                        if ( confirmation.statusInfo === "CHANGE"
                            && confirmation.status !== "APPROVED"
                            && confirmation.projectRole.id === userRole.id ) {
                            hashmap[ confirmation.id ] = "APPROVED"
                            tempRow.localization.confirmations[ index ].status = "APPROVED"
                        }
                    } )
                }
            } )
            temp.push( tempRow )
        } )

        selectedLocalizations.forEach( id => {
            props.project.categories[ outerTab ].texts.forEach( text => {
                text.localizations.forEach( loc => {

                    if ( loc.id === id &&
                        (
                            ( loc.base && props.project.base.language === languages[ innerTab - 1 ].language )
                            || ( loc.projectLanguage !== null && languages[ innerTab - 1 ].language.language ===
                                loc.projectLanguage.language.language )
                        )
                    ) {
                        loc.confirmations.forEach( confirmation => {
                            //console.log(confirmation)
                            confirmation.status = "APPROVED"
                        } )
                    }
                } )
            } )
        } )

        await fetch( process.env.REACT_APP_URL + 'projects/confirmations', {
            method: 'POST', headers: headers, body: JSON.stringify( hashmap )
        }, )
            .then( response => response.json() )
            .then( data => {
                //console.log(data)
            } )
            .then( async () => {
                setSuccessOpen( true )
            } )
            .then( () => {
                handleTableChange( outerTab, innerTab )

            } )
            .catch( e => {
                setErrorOpen( true )
            } )
    }

    function confirmChangesAllTable( idList ) {
        let hashmap = {}
        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }

        idList.forEach( id => {
            props.project.categories[ outerTab ].texts.forEach( row => {
                row.localizations.forEach( loc => {
                    if ( loc.id === id ) {
                        loc.confirmations.forEach( ( confirmation, index ) => {
                            if ( confirmation.statusInfo === "CHANGE"
                                && confirmation.status !== "APPROVED"
                                && confirmation.projectRole.id === userRole.id ) {
                                hashmap[ confirmation.id ] = "APPROVED"
                                loc.confirmations[ index ].status = "APPROVED"
                            }
                        } )
                    }
                } )
            } )
        } )

        handleTableChange( outerTab, innerTab )
        handleAllTableChange( outerTab )

        fetch( process.env.REACT_APP_URL + 'projects/confirmations', {
            method: 'POST', headers: headers, body: JSON.stringify( hashmap )
        }, )
            .then( response => response.json() )
            .then( data => {
                //console.log(data)
            } )
            .then( () => {

                setSuccessOpen( true )
            } )
            .then( () => {


            } )
            .catch( e => {
                setErrorOpen( true )
            } )

    }

    function checkConfirmationIsExist( loc ) {
        if ( userRole === null )
            return false
        else {
            let check = false
            loc.confirmations.forEach( confirmation => {
                if ( confirmation.status !== "APPROVED" && confirmation.statusInfo === "CHANGE" &&
                    confirmation.projectRole.id === userRole.id ) {
                    check = true
                }
            } )
            return check;
        }
    }

    function handleTableChange( outer, inner ) {
        if ( inner !== 0 ) {
            let temp = []
            //console.log(props.project)
            props.project.categories[ outer ].texts.forEach( text => {
                text.localizations.forEach( localization => {

                    if (
                        ( localization.base && props.project.base.language ===
                            languages[ inner - 1 ].language ) ||
                        ( localization.projectLanguage !== null && localization.projectLanguage.language.language ===
                            languages[ inner - 1 ].language.language )
                    ) {
                        temp.push( {
                            "id": text.id,
                            "englishWord": text.englishWord,
                            "image": text.image,
                            "androidKey": text.androidKey,
                            "explanation": text.explanation,
                            "localization": localization,
                            "confirmationAvailable": checkConfirmationIsExist( localization )
                        } )
                    }
                } )
            } )
            setTable( temp )
        }
    }

    function handleAllTableChange( outer ) {


        let tempList = []
        setAllTableLoading( true )
        setAllTable( [] )

        if ( props.project.categories.length > 0 ) {
            handleCategoryGet( props.project.categories[ outer ].id )
                .then( data => {
                    data.texts.forEach( text => {
                        let check = false
                        text.localizations.forEach( loc => {
                            if ( !check && checkConfirmationIsExist( loc ) ) {
                                check = true
                            }
                        } )
                        text.confirmNeed = check
                        tempList.push( text )
                    } )
                    props.project.categories[ outer ].texts = tempList
                    setAllTable( tempList )
                    setAllTableLoading( false )
                } )
        }

    }

    function handleTextLocalizationTrace( localization ) {
        let temp = [ ...localizationChanges ]
        let exist = false

        localizationChanges.forEach( loc => {
            if ( loc === localization )
                exist = true
        } )
        if ( !exist )
            temp.push( localization )
        setLocalizationChanges( temp )
    }

    function handleTextTrace( text ) {
        let temp = [ ...textChanges ]
        let exist = false
        textChanges.forEach( el => {
            if ( el === text )
                exist = true
        } )
        if ( !exist )
            temp.push( text )

        setTextChanges( temp )
    }

    function handleTextLocalizationChange( localization, newLocalization ) {
        let temp = []

        table.forEach( row => {
            if ( row.localization.id === localization.id ) {
                let tempLoc = { ...row.localization }
                tempLoc.text = newLocalization
                temp.push( {
                    "id": row.id,
                    "englishWord": row.englishWord,
                    "image": row.image,
                    "androidKey": row.androidKey,
                    "explanation": row.explanation,
                    "localization": tempLoc,
                    "confirmationAvailable": row.confirmationAvailable,
                } )
                handleTextLocalizationTrace( tempLoc.id )
            } else {
                temp.push( {
                    "id": row.id,
                    "englishWord": row.englishWord,
                    "image": row.image,
                    "androidKey": row.androidKey,
                    "explanation": row.explanation,
                    "localization": row.localization,
                    "confirmationAvailable": row.confirmationAvailable,
                } )
            }
        } )
        setTable( temp )
    }

    function handleTextChange( text, newText ) {
        let temp = []

        table.forEach( row => {
            if ( row.id === text.id ) {
                temp.push( {
                    "id": row.id,
                    "englishWord": newText,
                    "androidKey": row.androidKey,
                    "image": row.image,
                    "explanation": row.explanation,
                    "localization": row.localization,
                    "confirmationAvailable": row.confirmationAvailable,
                } )
                handleTextTrace( row.id )
            } else {
                temp.push( {
                    "id": row.id,
                    "englishWord": row.englishWord,
                    "androidKey": row.androidKey,
                    "image": row.image,
                    "explanation": row.explanation,
                    "localization": row.localization,
                    "confirmationAvailable": row.confirmationAvailable,
                } )
            }
        } )
        setTable( temp )

    }

    function handleImageChange( text, newImage ) {
        let temp = []

        table.forEach( row => {
            if ( row.id === text.id ) {
                temp.push( {
                    "id": row.id,
                    "englishWord": row.englishWord,
                    "androidKey": row.androidKey,
                    "image": newImage,
                    "explanation": row.explanation,
                    "localization": row.localization,
                    "confirmationAvailable": row.confirmationAvailable,
                } )
                handleTextTrace( row.id )
            } else {
                temp.push( {
                    "id": row.id,
                    "englishWord": row.englishWord,
                    "androidKey": row.androidKey,
                    "image": row.image,
                    "explanation": row.explanation,
                    "localization": row.localization,
                    "confirmationAvailable": row.confirmationAvailable,
                } )
            }
        } )
        setTable( temp )
    }

    function handleExplanationChange( text, newExplanation ) {
        let temp = []

        table.forEach( row => {
            if ( row.id === text.id ) {
                temp.push( {
                    "id": row.id,
                    "englishWord": row.englishWord,
                    "androidKey": row.androidKey,
                    "image": row.image,
                    "explanation": newExplanation,
                    "localization": row.localization,
                    "confirmationAvailable": row.confirmationAvailable,
                } )
                handleTextTrace( row.id )
            } else {
                temp.push( {
                    "id": row.id,
                    "englishWord": row.englishWord,
                    "androidKey": row.androidKey,
                    "image": row.image,
                    "explanation": row.explanation,
                    "localization": row.localization,
                    "confirmationAvailable": row.confirmationAvailable,
                } )
            }
        } )
        setTable( temp )
    }

    function handleAndroidKeyChange( text, newAndroidKey ) {
        let temp = []
        console.log( text )
        console.log( newAndroidKey )
        table.forEach( row => {
            if ( row.id === text.id ) {
                temp.push( {
                    "id": row.id,
                    "englishWord": row.englishWord,
                    "androidKey": newAndroidKey,
                    "image": row.image,
                    "explanation": row.explanation,
                    "localization": row.localization,
                    "confirmationAvailable": row.confirmationAvailable,
                } )
                handleTextTrace( row.id )
            } else {
                temp.push( {
                    "id": row.id,
                    "englishWord": row.englishWord,
                    "androidKey": row.androidKey,
                    "image": row.image,
                    "explanation": row.explanation,
                    "localization": row.localization,
                    "confirmationAvailable": row.confirmationAvailable,
                } )
            }
        } )
        setTable( temp )
    }

    function handleReplaceText( source, target ) {

        allTable.map( ( row, index ) => {
            if ( row.englishWord.includes( source ) ) {
                let temp = { ...row }
                temp.englishWord = temp.englishWord.replaceAll( source, target )

                let tempList = [ ...allTable ]
                tempList[ index ].englishWord = temp.englishWord

                props.categories[ outerTab ].texts[ index ].englishWord = temp.englishWord
                saveTableTextChange( temp )
            }

            row.localizations.map( ( localization, locIndex ) => {
                if ( localization.text != null && localization.text.includes( source ) ) {
                    let temp = { ...localization }
                    temp.text = temp.text.replaceAll( source, target )

                    let tempList = [ ...allTable ]
                    props.categories[ outerTab ].texts[ index ].localizations[ locIndex ].text = temp.text

                    tempList[ index ].localizations[ locIndex ].text = temp.text
                    saveTableLocalizationChange( temp )
                }
            } )
        } )

        handleAllTableChange( outerTab )
    }

    async function confirmChanges() {
        setSubmitted( true )
        let hashmap = {}
        let temp = []
        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }

        table.forEach( row => {
            let tempRow = { ...row }
            tempRow.localization.confirmations.forEach( ( confirmation, index ) => {
                if ( confirmation.statusInfo === "CHANGE"
                    && confirmation.status !== "APPROVED"
                    && confirmation.projectRole.id === userRole.id ) {
                    tempRow.localization.confirmations[ index ].status = "APPROVED"
                    hashmap[ confirmation.id ] = "APPROVED"
                }
            } )


            props.project.categories[ outerTab ].texts.forEach( text => {
                text.localizations.forEach( loc => {
                    if ( loc.id === row.localization.id && (
                        ( loc.base && innerTab === props.project.projectLanguages.length )
                        || ( loc.projectLanguage !== null && props.project.projectLanguages[ innerTab ].language ===
                            loc.projectLanguage.language.language )
                    ) ) {
                        loc.confirmations.forEach( confirmation => {
                            confirmation.status = "APPROVED"
                        } )
                    }
                } )
            } )
            temp.push( tempRow )
        } )

        await fetch( process.env.REACT_APP_URL + 'projects/confirmations', {
            method: 'POST', headers: headers, body: JSON.stringify( hashmap )
        }, )
            .then( response => response.json() )
            .then( data => {

            } )
            .then( () => {
                props.functionList.getProgram()
                setSuccessOpen( true )
            } )
            .then( () => {
                handleTableChange( outerTab, innerTab )
            } )
            .catch( e => {
                setErrorOpen( true )
            } )
        setSubmitted( false )
    }

    async function handleSaveChanges() {
        setSubmitted( true )
        let successLocalization = false
        let successTextUpdate = false

        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }

        if ( localizationChanges.length > 0 ) {
            let returnObject = []

            localizationChanges.forEach( el => {
                let tempString = ''


                table.forEach( row => {
                    if ( row.localization.id === el ) {
                        returnObject.push( {
                            "id": el,
                            "text": row.localization.text
                        } )
                        tempString = row.localization.text
                    }
                } )

                props.project.categories[ outerTab ].texts.forEach( text => {
                    text.localizations.forEach( loc => {
                        if ( loc.id === el && (
                            ( loc.base && props.project.base.language === languages[ innerTab ].language ) ||
                            ( loc.projectLanguage !== null && languages[ innerTab ].language ===
                                loc.projectLanguage.language.language )
                        ) ) {

                            loc.text = tempString
                            loc.confirmations.forEach( confirmation => {
                                confirmation.status = "WAITING"
                                confirmation.statusInfo = "CHANGE"
                            } )
                        }
                    } )
                } )

            } )


            await fetch( process.env.REACT_APP_URL + 'projects/textLocalizations?id=' + props.program.user.id, {
                method: 'POST', headers: headers, body: JSON.stringify( returnObject )
            }, )
                .then( response => response.json() )
                .then( data => {
                    //console.log(data)
                } )
                .then( async () => {
                    successLocalization = true
                    setLocalizationChanges( [] )
                } )
                .then( () => {
                    handleTableChange( outerTab, innerTab )
                } )
                .catch( e => {
                    successLocalization = false
                } )

        } else {
            successLocalization = true
        }


        if ( textChanges.length > 0 ) {
            let returnObject = []
            let image = null
            let explanation = null
            let englishWord = null
            let androidKey = null

            textChanges.forEach( el => {
                table.forEach( row => {
                    if ( row.id === el ) {
                        returnObject.push( {
                            "textId": el,
                            "image": row.image,
                            "explanation": row.explanation,
                            "englishWord": row.englishWord,
                            "androidKey": row.androidKey
                        } )
                        image = row.image
                        explanation = row.explanation
                        englishWord = row.englishWord
                        androidKey = row.androidKey
                    }
                } )

                props.project.categories[ outerTab ].texts.forEach( text => {
                    if ( text.id === el ) {
                        text.image = image
                        text.explanation = explanation
                        text.englishWord = englishWord
                        text.androidKey = androidKey
                        text.localizations.forEach( loc => {
                            if ( loc.base ) {
                                loc.text = englishWord
                                loc.confirmations.forEach( confirmation => {
                                    confirmation.status = "WAITING"
                                } )
                            }
                        } )
                    }
                } )
            } )

            await fetch( process.env.REACT_APP_URL + 'projects/texts/update?userId=' + props.program.user.id, {
                method: 'POST', headers: headers, body: JSON.stringify( returnObject )
            }, )
                .then( response => response.json() )
                .then( data => {
                } )
                .then( async () => {
                    successTextUpdate = true
                    setTextChanges( [] )
                } )
                .then( () => {
                    handleTableChange( outerTab, innerTab )
                } )
                .catch( e => {
                    successTextUpdate = false
                } )

        } else {
            successTextUpdate = true
        }

        if ( !successLocalization || !successTextUpdate ) {
            setErrorOpen( true )
        } else {
            setSuccessOpen( true )
        }
        setSubmitted( false )
    }

    async function handleCategoryGet( categoryId ) {
        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }
        const data = fetch( process.env.REACT_APP_URL + 'projects/categories/single?categoryId=' + categoryId, {
            method: 'GET', headers: headers
        }, )
            .then( response => {
                if ( response.ok ) return response.json()
                else throw new Error();
            } )
            .then( data => {
                //console.log(data)
                data.texts.forEach( text => {
                    text.localizations = text.localizations.sort( ( l1, l2 ) => {
                        if ( l1.base ) {
                            return 1
                        } else if ( l2.base ) {
                            return -1
                        } else if ( l1.projectLanguage.language.language > l2.projectLanguage.language.language ) {
                            return 1
                        } else {
                            return -1
                        }
                    } )
                } )
                console.log( data )
                return data
            } )
        return data
    }


    return <div className={ classes.root }>
        <Tabs
            id={ 'outer' + outerTab }
            key={ 'outer' + outerTab }
            classes={ {
                indicator: classes.indicator
            } }
            orientation={ "vertical" }
            className={ classes.outerTab }
            value={ outerTab }
            onChange={ ( event, index ) => {
                setOuterTab( index )
                setLocalizationChanges( [] )
                setTextChanges( [] )
                handleTableChange( index, innerTab )
                handleAllTableChange( index )
            } }
            variant={ "scrollable" }
            scrollButtons={ "auto" }
            aria-label={ "scrollable auto outer tabs" }
        >
            { props.categories.map( ( category, index ) => {
                return <Tab
                    key={ "outer-tab-" + index }
                    className={ classes.tab }
                    label={ category.categoryName
                    + ( categoryConfirmations[ category.categoryName ] === 0 ? "" :
                        " (" + categoryConfirmations[ category.categoryName ] + ") " ) }
                    aria-controls={ `scrollable-auto-tabpanel-${ index }` }
                    id={ `scrollable-auto-tab-${ index }` }/>
            } ) }
        </Tabs>
        <Grid container>
            <Grid item xs={ 12 } className={ classes.transparent }>
                <InnerTabs
                    setProjectTrace={ ( val ) => setProjectTrace( val ) }
                    open={ props.open }
                    auth={ props.auth }
                    program={ props.program }
                    functionList={ props.functionList }
                    project={ props.project }
                    categories={ props.categories }
                    table={ table }
                    allTableLoading={ allTableLoading }
                    allTable={ allTable }
                    allTableAddedTexts={ allTableAddedTexts }
                    autoSave={ autoSave }
                    handleAllTableAndroidKeySave={ ( textId, androidKey ) => handleAllTableAndroidKeySave( textId,
                        androidKey ) }
                    handleAllTableAddedTextChange={ ( index, text ) => allTableNewTextChange( index, text ) }
                    handleAllTableSetProjectAllTable={ ( val ) => setAllTable( val ) }
                    handleAllTableTextAutoFill={ ( list ) => autoFillTexts( list ) }
                    handleAllTableTextListDeletion={ ( list ) => deleteTexts( list ) }
                    handleAllTableTextChange={ ( index, text ) => allTableNewTextChange( index, text ) }
                    handleAllTableTextAddition={ ( text ) => allTableAddNewText( text ) }
                    handleAllTableTextDeletion={ ( text ) => allTableDeleteNewText( text ) }
                    handleAllTableTextRemove={ ( text ) => deleteText( text ) }
                    handleAllTableImageChange={ ( textId, image ) => handleAllTableImageChange( textId, image ) }
                    handleAllTableConfirmation={ ( idList ) => confirmChangesAllTable( idList ) }
                    handleAllTableExplanationSave={ ( textId, explanation ) => handleAllTableExplanationSave( textId,
                        explanation ) }
                    handleAllTableExplanationChange={ ( textId, explanation ) => handleAllTableExplanationChange(
                        textId,
                        explanation ) }
                    handleAllTableTextSave={ () => saveAllTableNewTexts() }
                    categoryIndex={ outerTab }
                    languageIndex={ innerTab }
                    handleAllTableBaseChange={ ( baseId, text ) => allTableBaseChange( baseId, text ) }
                    handleAllTableLocalizationChange={ ( localizationId, text ) => allTableLocalizationChange(
                        localizationId, text ) }
                    handleAllTableLocalizationSave={ ( localization ) => saveTableLocalizationChange( localization ) }
                    handleAllTableBaseSave={ ( base ) => saveTableTextChange( base ) }
                    handleLanguageIndex={ ( val ) => {
                        setInnerTab( val )
                        setLocalizationChanges( [] )
                        setTextChanges( [] )
                        handleTableChange( outerTab, val )
                    } }
                    handleLocalizationChange={
                        ( localization, newLocalization ) => handleTextLocalizationChange(
                            localization, newLocalization )
                    }
                    handleTextChange={
                        ( textId, newText ) => handleTextChange( textId, newText )
                    }
                    handleExplanationChange={
                        ( textId, newExplanation ) => handleExplanationChange( textId, newExplanation )
                    }
                    handleImageChange={
                        ( textId, newImage ) => handleImageChange( textId, newImage )
                    }
                    handleAndroidKeyChange={ ( textId, newAndroidKey ) => handleAndroidKeyChange( textId,
                        newAndroidKey ) }
                    handleSaveChanges={ () => handleSaveChanges() }
                    submitted={ submitted }
                    textChanges={ textChanges }
                    localizationChanges={ localizationChanges }
                    confirmChanges={ () => confirmChanges() }
                    checkSelected={ ( id ) => checkSelected( id ) }
                    handleCheck={ ( id ) => handleCheck( id ) }
                    selectUnconfirmed={ ( isAndroid ) => selectUnconfirmed( isAndroid ) }
                    selectAll={ ( status, isAndroid ) => selectAll( status, isAndroid ) }
                    confirmChangesWithIdList={ () => confirmChangesWithIdList() }
                    selectedLocalizations={ selectedLocalizations }
                    setSelectedLocalizationsEmpty={ () => setSelectedLocalizations( [] ) }
                    allTableReplaceText={ ( source, target ) => handleReplaceText( source, target ) }
                />
            </Grid>

        </Grid>
        <Snackbar open={ errorOpen }
                  autoHideDuration={ 6000 }
                  onClose={ () => setErrorOpen( false ) }
        >
            <Alert onClose={ () => setErrorOpen( false ) }
                   severity={ "warning" }
            >
                Something went wrong partially or completely, try it later.
            </Alert>
        </Snackbar>
        <Snackbar open={ successOpen }
                  autoHideDuration={ 1000 }
                  onClose={ () => setSuccessOpen( false ) }
        >
            <Alert onClose={ () => setSuccessOpen( false ) }
                   severity={ "success" }
            >
                Successfully completed
            </Alert>
        </Snackbar>
        <Snackbar open={ infoOpen.length > 0 }
                  autoHideDuration={ 1000 }
                  onClose={ () => setInfoOpen( "" ) }
        >
            <Alert onClose={ () => setInfoOpen( "" ) }
                   severity={ "info" }
            >
                { infoOpen }
            </Alert>
        </Snackbar>

    </div>
}