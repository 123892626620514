import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Colors from "../constants/Colors";
import { CloudUpload, ExitToApp, Home, Save } from "@material-ui/icons";
import TranslationTaskCard from "./translationTaskScreens/TranslationTaskCard";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Paper,
    Snackbar,
    Tooltip
} from "@material-ui/core";
import TranslationTaskPage from "./translationTaskScreens/TranslationTaskPage";
import { Alert } from "@material-ui/lab";
import Env from "../constants/Env";

const useStyles = makeStyles( ( theme ) => ( {
    grow: {
        flexGrow: 1,
    },
    appBar: {
        background: Colors.gradient,
    },
    menuButton: {
        color: Colors.PRIMARY_WHITE,
        paddingRight: 16,
    },
    title: {
        fontWeight: "bold",
        display: 'none',
        [ theme.breakpoints.up( 'sm' ) ]: {
            display: 'block',
        },
    },
    searchIcon: {
        padding: theme.spacing( 0, 2 ),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing( 1, 1, 1, 0 ),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${ theme.spacing( 4 ) }px)`,
        transition: theme.transitions.create( 'width' ),
        width: '100%',
        [ theme.breakpoints.up( 'md' ) ]: {
            width: '20ch',
        },
    },
    sectionDesktop: {

        display: 'none',
        [ theme.breakpoints.up( 'md' ) ]: {
            display: 'flex',
        },
    },
    sectionMobile: {

        display: 'flex',
        [ theme.breakpoints.up( 'md' ) ]: {
            display: 'none',
        },
    },
    icon: {
        paddingRight: 8,
        fontSize: 28,
        fontFamily: Colors.fontOfProgram,
    },
    div: {
        paddingTop: 100,
        padding: 40,
    },
    tableHead: {
        backgroundColor: Colors.PRIMARY_PINK_UP,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
    },
    expiration: {
        fontWeight: "bold",
        paddingRight: 28,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    paper: {
        background: Colors.PRIMARY_BACKGROUND,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        alignItems: "center",
        alignContent: "center",
        display: "flex",
        justifyItems: "center",
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 16,
        paddingBottom: 16,
        justifyContent: "center"
    },
    bodyTitle: {
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        fontSize: 28,
        fontFamily: Colors.fontOfProgram,
        color: Colors.PRIMARY_PINK_UP,
        fontWeight: "bold",
    },
    text: {
        color: Colors.PRIMARY_PINK_ALT,
        fontWeight: "bold",
    },
    button: {
        padding: 0,
        color: Colors.PRIMARY_WHITE
    },
    grid: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    verticalDivider: {
        marginRight: 12,
        width: 2,
        height: '100%',
        backgroundColor: Colors.PRIMARY_WHITE,
    },
    dialog: {
        padding: 32,
        backgroundColor: Colors.PRIMARY_BACKGROUND
    },
    dialogButtons: {
        display: "flex",
        justifyContent: "center",
    },
    dialogButtonConfirm: {
        paddingLeft: 60,
        paddingRight: 60,
        padding: 10,

        backgroundColor: Colors.PRIMARY_GREEN_LIGHT,
        color: Colors.PRIMARY_WHITE,
        fontWeight: "bold",
    },
    dialogButtonReject: {
        paddingLeft: 60,
        paddingRight: 60,
        padding: 10,
        backgroundColor: Colors.PRIMARY_PINK,
        color: Colors.PRIMARY_WHITE,
        fontWeight: "bold",
    }

} ) );

export default function TranslationMainLayoutPage( props ) {
    const classes = useStyles();
    const [ translationOrder, setTranslationOrder ] = React.useState( null )
    const [ changedOnes, setChanged ] = React.useState( new Map() )
    const [ errorOpen, setErrorOpen ] = React.useState( false )
    const [ successOpen, setSuccessOpen ] = React.useState( false )
    const [ submitDialog, setSubmitDialog ] = React.useState( null )
    const [ saveProgress, setSaveProgress ] = React.useState( false )
    const [ submitProgress, setSubmitProgress ] = React.useState( false )

    console.log( props.program )

    window.onbeforeunload = ( event ) => {
        const e = event || window.event;
        // Cancel the event
        e.preventDefault();
        if ( e ) {
            e.returnValue = ''; // Legacy method for cross browser support
        }
        return ''; // Legacy method for cross browser support
    };

    const handleErrorOpen = () => {
        setErrorOpen( true )
    }

    const handleErrorClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }

        setErrorOpen( false );
    };

    const handleSuccessClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }
        setSuccessOpen( false );
    };

    const handleSuccessOpen = () => {
        setSuccessOpen( true )
    }

    const handleChangesOnes = ( id, text ) => {
        setChanged( changedOnes.set( id, text ) )
    }

    const handleTranslationOrder = ( order ) => {
        setTranslationOrder( order )
        if ( order !== null && order.type === "WRITE" &&
            !( order.status !== "COMPLETED" || order.status !== "PENDING" || order.status !== "CHECKED" ||
                order.status !== "PAID" ) ) {
            changeStatus( order.id, "IN_PROGRESS", order.user.userExpirationTime, order.user.id )
        }
    }

    const saveChanges = async () => {
        if ( changedOnes.size > 0 ) {
            const requestList = []
            changedOnes.forEach( ( value, id ) => {

                requestList.push( {
                    "id": id,
                    "equivalent": value
                } )
            } )

            const headers = {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + Env.TOKEN
            }
            await fetch( process.env.REACT_APP_URL + 'projects/translationTexts/update', {
                method: 'POST', headers: headers, body: JSON.stringify( requestList )
            }, )
                .then( response => {
                    if ( response.ok ) return response.json()
                    else throw new Error();
                } )
                .then( data => {
                    setChanged( new Map() )
                    handleSuccessOpen();
                } )
                .then( async () => await props.functionList.getProgram() )
                .catch( e => {
                    handleErrorOpen();
                } )
        }
    }

    const changeStatus = async ( id, status, date, userId ) => {
        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }
        await fetch(
            process.env.REACT_APP_URL + 'projects/translationOrders/update?id=' + id +
            "&status=" + status +
            "&userId=" + userId +
            "&date=" + date,
            {
                method: 'POST', headers: headers
            }, )
            .then( response => {
                if ( response.ok ) return response.json()
                else throw new Error();
            } )
            .then( data => {
            } )
            .then( async () => await props.functionList.getProgram() )
            .catch( e => {
            } )

    }

    return ( <div className={ classes.grow }>
        <AppBar className={ classes.appBar } position="fixed">
            <Toolbar>
                <Tooltip title={ "Home" } arrow>
                    <IconButton
                        onClick={ () => {
                            if ( translationOrder != null ) {
                                if ( translationOrder.status === "NOT_STARTED" || translationOrder.status ===
                                    "IN_PROGRESS" || translationOrder.status === "SAVED" )
                                    changeStatus( translationOrder.id, "SAVED",
                                        translationOrder.user.userExpirationTime,
                                        translationOrder.user.id )
                                setTranslationOrder( null )
                            }
                            if ( changedOnes.size > 0 ) {
                                saveChanges()
                            } else {
                                setChanged( new Map() )
                            }

                        } }
                        edge="start"
                        className={ classes.menuButton }
                        color="inherit"
                        aria-label="open drawer"
                    >
                        <Home className={ classes.icon }/>
                    </IconButton>
                </Tooltip>
                { translationOrder === null
                    ?
                    <Typography className={ classes.title } variant="h6" noWrap>
                        Localify
                    </Typography>
                    : <div>
                        <Grid container>
                            <Grid item xs>
                                <div className={ classes.verticalDivider }/>
                            </Grid>
                            <Grid item xs>
                                <Tooltip title={ "Save Changes you made. You can edit after saving." }>
                                    { saveProgress ?
                                        <CircularProgress/>
                                        :
                                        <Button
                                            disabled={ translationOrder.type === "READ" ||
                                            ( translationOrder.status === "COMPLETED" || translationOrder.status ===
                                                "CHECKED" || translationOrder.status === "PAID" ||
                                                translationOrder.status === "PENDING" ) }
                                            onClick={ async () => {
                                                setSaveProgress( true )
                                                if ( translationOrder.status === "NOT_STARTED" ||
                                                    translationOrder.status ===
                                                    "IN_PROGRESS" || translationOrder.status === "SAVED" )
                                                    await saveChanges()
                                                setSaveProgress( false )

                                            } } className={ classes.menuButton }>
                                            <Save className={ classes.icon }/>
                                            <Typography className={ classes.title } variant="p" noWrap>
                                                Save
                                            </Typography>
                                        </Button>
                                    }
                                </Tooltip>
                            </Grid>
                            <Grid item xs>
                                <Tooltip title={ "Submit Changes. You can NOT edit anymore after you submitted." }>
                                    <Button
                                        disabled={ translationOrder.type === "READ" ||
                                        ( translationOrder.status === "COMPLETED" || translationOrder.status ===
                                            "CHECKED" || translationOrder.status === "PAID" ||
                                            translationOrder.status === "PENDING" ) }
                                        onClick={ () => {
                                            setSubmitDialog( translationOrder.id )
                                        } } className={ classes.menuButton }>
                                        <CloudUpload className={ classes.icon }/>
                                        <Typography className={ classes.title } variant="p" noWrap>
                                            Submit
                                        </Typography>
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </div>
                }
                <div className={ classes.grow }/>

                <Typography className={ classes.expiration }>
                    { "Deadline " }
                </Typography>
                <Typography className={ classes.expiration }>
                    { new Date( ( props.program.user.userExpiration ) ).toLocaleTimeString() + " " }
                    { new Date( ( props.program.user.userExpiration ) ).toLocaleDateString() + " " }
                </Typography>
                <Tooltip title={ "Logout" }>
                    <IconButton
                        onClick={ () => props.functionList.signOut() }
                        edge="end"
                        aria-label="account of current user"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <ExitToApp className={ classes.icon }/>
                    </IconButton>
                </Tooltip>
            </Toolbar>
        </AppBar>
        { translationOrder === null
            ?
            <div className={ classes.div }>
                <Typography className={ classes.bodyTitle }>
                    Translation Tasks
                </Typography>
                <Paper className={ classes.paper }>
                    <Grid container className={ classes.grid }>
                        <Grid item xs={ 1 }>
                            <Typography className={ classes.text }>
                                Permission
                            </Typography>
                        </Grid>
                        <Grid item xs={ 1 }>
                            <Typography className={ classes.text }>
                                Status
                            </Typography>

                        </Grid>

                        <Grid item xs={ 2 }>
                            <Typography
                                className={ classes.text }>
                                Target Language
                            </Typography>

                        </Grid>
                        <Grid item xs={ 1 }>
                            <Typography
                                className={ classes.text }>
                                Editable
                            </Typography>

                        </Grid>
                        <Grid item xs={ 1 }>
                            <Typography
                                className={ classes.text }>

                            </Typography>

                        </Grid>
                    </Grid>
                </Paper>
                { props.program.user.translationOrders.map( ( order, index ) =>
                    <TranslationTaskCard
                        key={ props.program.user.translationOrders[ props.program.user.translationOrders.length -
                        index - 1 ].id }
                        goToTask={ handleTranslationOrder }
                        account={ props.program.user }
                        order={ props.program.user.translationOrders[ props.program.user.translationOrders.length -
                        index - 1 ] }/>
                ) }
            </div>
            :
            <div className={ classes.div }>
                <TranslationTaskPage isPreview={ false } order={ translationOrder } onChange={ handleChangesOnes }/>
            </div>
        }
        <Snackbar open={ errorOpen }
                  autoHideDuration={ 6000 }
                  onClose={ handleErrorClose }
        >
            <Alert onClose={ handleErrorClose }
                   severity={ "warning" }
            >
                Something went wrong, try it later.
            </Alert>
        </Snackbar>
        <Snackbar open={ successOpen }
                  autoHideDuration={ 1000 }
                  onClose={ handleSuccessClose }
        >
            <Alert onClose={ handleSuccessClose }
                   severity={ "success" }
            >
                Successfully saved.
            </Alert>
        </Snackbar>
        <Dialog fullWidth={ true } maxWidth={ 'sm' } open={ submitDialog != null }>
            <DialogContent className={ classes.dialog }>
                <Typography className={ classes.text }>
                    Do you want to submit ? You can not make any changes after confirmation of this step!
                </Typography>
            </DialogContent>
            <DialogActions className={ classes.dialog }>
                <Grid container className={ classes.grid }>
                    { submitProgress ?
                        <CircularProgress/> :
                        <Button
                            className={ classes.dialogButtonConfirm }
                            onClick={
                                async () => {
                                    saveChanges()
                                    setSubmitProgress( true )
                                    await changeStatus( translationOrder.id, "COMPLETED",
                                        translationOrder.user.userExpirationTime, translationOrder.user.id )
                                    setSubmitProgress( false )
                                    setTranslationOrder( null )
                                    setSubmitDialog( null )
                                }
                            }
                        >
                            Confirm
                        </Button>
                    }

                    <Button
                        className={ classes.dialogButtonReject }
                        onClick={
                            () => {
                                setSubmitDialog( null )
                            }
                        }
                    >
                        Cancel
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    </div> );
}