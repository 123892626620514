import React from "react";
import {
    ButtonGroup, Chip, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid,
    makeStyles,
    Select,
    Typography
} from "@material-ui/core";
import Colors from "../../../../constants/Colors";
import Button from "@material-ui/core/Button";
import Env from "../../../../constants/Env";

const useStyles = makeStyles( () => ( {
    dialog: {
        background: Colors.gradient,
    },
    title: {
        fontSize: 32,
        fontWeight: "bold",
        color: Colors.PRIMARY_BLUE,
        fontFamily: Colors.fontOfProgram,

    },
    dialogContent: {
        paddingLeft: 28,
        paddingRight: 28,
    },
    dialogAction: {
        justifyContent: "center",
        display: "flex",
        paddingTop: 20,
        paddingBottom: 20,
    },
    buttonCreate: {
        width: 200,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_GREEN_LIGHT,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_YELLOW_LIGHT,
        }
    },
    buttonCancel: {
        width: 200,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_PINK,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_BLUE,
        }
    },
    buttonText: {
        fontWeight: "bold",
        color: Colors.PRIMARY_WHITE,
    },
    grid: {
        display: "flex",
        justifyContent: "center",
        padding: 20,
    },
    textField: {
        width: "60%"
    },
    gridChild: {
        display: "flex",
        justifyContent: "center",
        paddingTop: 4,
    }
} ) )


export default function RoleRestrictionAdditionDialog( props ) {
    const classes = useStyles()
    const [ chips, setChips ] = React.useState( [] )


    return <Dialog
        className={ classes.dialog }
        fullWidth
        maxWidth={ "sm" }
        open={ props.isOpen }
        onClose={
            () => props.onClose()
        }>
        <DialogTitle className={ classes.grid }>
            <Typography className={ classes.title }>
                NEW ROLE ALLOWANCE
            </Typography>
        </DialogTitle>
        <DialogContent className={ classes.grid }>
            <Grid container className={ classes.grid }>
                <Grid container className={ classes.gridChild }>
                    <Grid item xs={ 12 } className={ classes.gridChild }>
                        <Select
                            className={ classes.textField }
                            native
                            value={ "" }
                            onChange={ ( val ) => {
                                // console.log(val.target.value)
                                setChips( [ ...chips, val.target.value ] )
                            } }
                            inputProps={ {
                                name: 'Translation Subject',
                                id: 'cat-native-simple',
                            } }
                        >
                            <option value={ "" }>{ "" }</option>
                            { props.project.categories.map( category => {
                                let check = true

                                props.role.roleRestrictionList.forEach( roleRestriction => {
                                    if ( roleRestriction.category.id === category.id )
                                        check = false
                                } )

                                chips.forEach( chip => {
                                    if ( chip === category.categoryName )
                                        check = false
                                } )

                                if ( check ) {
                                    return <option key={ category.id }
                                                   value={ category.categoryName }>{ category.categoryName }</option>

                                } else {
                                    return null
                                }
                            } ) }
                        </Select>
                    </Grid>
                </Grid>
                <Grid container className={ classes.gridChild }>
                    <Grid item xs={ 12 } className={ classes.gridChild }>
                        { chips.map( chip => {
                            return <Chip
                                key={ "productDetail-dialog-chip5-" + chip }
                                label={ chip }
                                onClick={ () => {
                                    let temp = []
                                    chips.forEach( ch => {
                                        if ( ch !== chip )
                                            temp.push( chip )
                                    } )
                                    setChips( temp )

                                } }>{ chip.toString() }</Chip>
                        } ) }
                    </Grid>
                </Grid>
            </Grid>


        </DialogContent>
        <DialogActions className={ classes.grid }>
            { props.progress ? <CircularProgress/> : <ButtonGroup>
                <Button
                    disabled={ chips.length === 0 }
                    onClick={
                        () => {
                            const headers = {
                                "Content-Type": "application/json",
                                'Authorization': 'Bearer ' + Env.TOKEN
                            }
                            props.setProgress( true )
                            let returnObj = []
                            let categoryIds = []
                            chips.forEach( chip => {
                                props.project.categories.forEach( category => {
                                    if ( category.categoryName === chip ) {
                                        categoryIds.push(
                                            category.id
                                        )
                                    }
                                } )
                            } )

                            returnObj.push( {
                                "projectRoleId": props.role.id,
                                "categoryIds": categoryIds,
                            } )


                            fetch( process.env.REACT_APP_URL + 'projects/roleRestrictions', {
                                method: 'POST', headers: headers, body: JSON.stringify( returnObj )
                            }, )
                                .then( response => response.json() )
                                .then( ( data ) => {
                                    props.handleRoleRestrictionAddition( data )
                                    setChips( [] )
                                    props.onClose()
                                } )
                                .then( () => props.setProgress( false )
                                )
                                .catch( e => console.log( e ) )

                        }
                    } className={ classes.buttonCreate }>
                    <Typography className={ classes.buttonText }>
                        ALLOW
                    </Typography>
                </Button>
                <Button onClick={ () => {
                    props.onClose()
                } } className={ classes.buttonCancel }>
                    <Typography className={ classes.buttonText }>
                        CANCEL
                    </Typography>
                </Button>
            </ButtonGroup> }
        </DialogActions>
    </Dialog>

}