import { Button, ButtonGroup, Dialog, Grid, Tooltip, Typography } from "@material-ui/core";
import Env from "../../../../../../constants/Env";
import Colors from "../../../../../../constants/Colors";
import React from "react";

let tracer = 0
let total = 0

export default function ImageUploadDialogInTranslationCreation( props ) {
    const [ submitted, setSubmitted ] = React.useState( false )
    return <Dialog fullWidth={ true }
                   maxWidth={ "lg" }
                   open={ props.dialogOpen > -1 }
                   onClose={ () => props.setDialogOpen( -1 ) }>
        <Grid container spacing={ 1 } className={ props.classes.gridAlternative }>
            <Grid container spacing={ 1 } className={ props.classes.dialogButtonGroup }>
                <Grid item>
                    <input
                        className={ props.classes.input }
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={ async ( event ) => {
                            setSubmitted( true )

                            total = event.target.files.length
                            tracer = 0
                            for ( const file of Array.from( event.target.files ) ) {

                                const formDataAlt = new FormData();
                                let tempListAlt = [];
                                formDataAlt.append( "files", file, file.name )
                                tempListAlt.push( {
                                    "listOfLong": []
                                } )
                                formDataAlt.append( "projectId", props.project.id )
                                const headers = {
                                    'Authorization': 'Bearer ' + Env.TOKEN
                                }
                                await fetch( process.env.REACT_APP_URL + 'projects/files', {
                                    method: 'POST', headers: headers, body: formDataAlt
                                }, )
                                    .then( response => response.json() )
                                    .then( ( data ) => {
                                        props.handleImageAddition( data )

                                    } )
                                    .then( () => {
                                        tracer = tracer + 1

                                        if ( tracer === total ) {
                                            setSubmitted( false )
                                        }

                                    } )
                                    .catch( () => {
                                        tracer = tracer + 1

                                        if ( tracer === total ) {
                                            setSubmitted( false )
                                        }

                                    } )
                            }

                            /*
                                     let tempList = [];
                                     const formData = new FormData();

                                     Array.from( event.target.files )
                                          .forEach( ( file ) => {
                                              formData.append( "files", file, file.name )
                                              tempList.push( {
                                                  "listOfLong": []
                                              } )
                                          } )

                                     formData.append( "projectId", props.project.id )

                                     //console.log(returnObj)
                                     const headers = {
                                         'Authorization': 'Bearer ' + Env.TOKEN
                                     }


                                     await fetch( process.env.REACT_APP_URL + 'projects/files', {
                                         method: 'POST', headers: headers, body: formData
                                     }, )
                                         .then( response => response.json() )
                                         .then( ( data ) => {
                                             //console.log(data.message)
                                         } )

                             */

                        } }
                    />
                    <ButtonGroup>
                        <label className={ props.classes.buttonLabel }>
                            <Tooltip title={ "Make guide image empty." }>
                                <Button onClick={
                                    () => {
                                        props.handleImageChange( null, props.currentText )
                                        props.setDialogOpen( -1 )
                                    }
                                }
                                        className={ props.classes.dialogButton }>
                                    <Typography className={ props.classes.dialogButtonText }>
                                        Empty
                                    </Typography>
                                </Button>
                            </Tooltip>
                        </label>
                        <label htmlFor="contained-button-file" className={ props.classes.buttonLabel }>
                            <Tooltip title={ "Upload new images to project." }>
                                <Button component="span" className={ props.classes.dialogButton }>
                                    <Typography className={ props.classes.dialogButtonText }>
                                        UPLOAD
                                    </Typography>
                                </Button>
                            </Tooltip>
                        </label>
                    </ButtonGroup>
                </Grid>
                <Grid item>
                    <ButtonGroup>
                        <label className={ props.classes.buttonLabel }>
                            <Tooltip title={ "Cancel" }>
                                <Button onClick={ () => {
                                    props.setDialogOpen( -1 )
                                } }
                                        className={ props.classes.dialogButton }>
                                    <Typography className={ props.classes.dialogButtonText }>
                                        Cancel
                                    </Typography>
                                </Button>
                            </Tooltip>
                        </label>
                    </ButtonGroup>

                </Grid>
            </Grid>

            <Grid container className={ props.classes.gridAlternative }>
                {
                    submitted ?
                        <Typography style={ {
                            color: Colors.PRIMARY_BLUE,
                            fontSize: 72,
                            paddingTop: 28,
                            paddingBottom: 28
                        } }>
                            { tracer + "/" + total }
                        </Typography>
                        :
                        props.images.length > 0 ?
                            props.images.map( image => {
                                return <Grid item xs className={ props.classes.gridBody }>
                                    <Tooltip title={ "Click to Select" }>
                                        <img className={ props.dialogOpen === image.id ? props.classes.divSelected :
                                            props.classes.divNotSelected }
                                             alt={ "text's img" }
                                             onClick={ () => {
                                                 props.setDialogOpen( -1 )
                                                 props.handleImageChange( image, props.currentText )
                                             } }
                                             src={ process.env.REACT_APP_IMAGE_URL + image.url }
                                             height={ 400 }
                                        />
                                    </Tooltip>
                                </Grid>
                            } ) : <Grid container style={ { display: "flex", justifyContent: "center", } }>
                                <Grid item>
                                    <Typography style={ {
                                        color: Colors.PRIMARY_PINK_ALT,
                                        fontSize: 24,
                                        marginTop: 20,
                                        marginBottom: 20
                                    } }>
                                        There is no image uploaded before in this project!
                                    </Typography>
                                </Grid>
                            </Grid>
                }
            </Grid>

        </Grid>

    </Dialog>
}