import React from 'react';
import ProjectPreviewConsole from './projectScreensComponents/ProjectPreviewConsole';

export default function ProjectsPage(props) {
    return (
        <ProjectPreviewConsole
            functionList={props.functionList}
            goToProjectPage={props.goToProjectPage}
            userProjects={props.userProjects}
            projects={props.projects}/>
    );
}