import React from 'react';

import {TextField, Paper, Typography, Button, Snackbar, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Colors from "../../constants/Colors";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles({
    textField: {
        width: '90%',
        fontSize: 48,
        fontWeight: 'bold',
        fontFamily: 'poppins',
        outline: 0,
        borderWidth: '0 0 2px',
        borderColor: '#496B66',
        '&:focus': {
            backgroundColor: 'red',
            borderColor: '#496B66',

        },
    },
    grid: {
        width: '100%'
    },
    button: {
        marginTop: 24,
        paddingBottom: 8,
        paddingTop: 8,
        paddingLeft: 50,
        paddingRight: 50,
        borderRadius: 16,
        borderWidth: 16,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        '&:hover': {
            background: Colors.PRIMARY_BLUE,
        },
    },
    buttonText: {
        color: 'white',
        fontSize: 12,
        fontWeight: 'bold',
        fontFamily: 'poppins',
    },
    forgetPasswordText: {
        color: '#949191',
        fontSize: 10,
        fontFamily: 'poppins',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: 'transparant',
            color: '#496B66',
        },
    },
    paper: {
        marginTop: 24,
    },
    text: {
        fontFamily: 'poppins',
        fontSize: '200%',
        fontWeight: 'bold',
        color: Colors.PRIMARY_BLUE
    }
});

const SignIn = (props) => {
    const [forgetPassword, setForgetPassword] = React.useState(false)
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [error, setError] = React.useState('')
    const [success, setSuccess] = React.useState('')
    const [submitted, setSubmitted] = React.useState(false)
    const classes = useStyles();

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    return <Paper elevation={0}>
        <Paper elevation={0} className={classes.paper}>
            <Typography className={classes.text}>{forgetPassword ? "RESET" : "WELCOME"}</Typography>
        </Paper>
        <br/>
        <Paper elevation={0}>
            <TextField
                value={email}
                onChange={(val) => setEmail(val.target.value)}
                type="text" placeholder="Email"
                className={classes.textField}/>
        </Paper>
        <br/>
        {forgetPassword ? null : <Paper elevation={0}>
            <TextField
                value={password}
                onChange={(val) => setPassword(val.target.value)}
                type="password"
                placeholder="Password"
                className={classes.textField}/>
        </Paper>}
        <br/>
        {submitted ?
            <CircularProgress size={40}/>
            :
            <Button
                onClick={async () => {
                    if (forgetPassword) {
                        if (!validateEmail(email)) {
                            setError('Please fill email correctly')
                        } else {
                            const headers = {
                                "Content-Type": "application/json"
                            }
                            const returnObject = {
                                "email": email
                            }

                            fetch(process.env.REACT_APP_URL + "auth/resetPasswordRequest",
                                {method: "POST", headers: headers, body: JSON.stringify(returnObject)})
                                .then(response => {
                                    if (response.ok && response.status === 200) {
                                        response.json().then((data) => {
                                            if (data.operationResult !== "ERROR") {
                                                setSuccess("Reset link is sent to your email")
                                                setEmail("")
                                                setPassword("")
                                            } else {
                                                setError(
                                                    'Email is not exist OR you do not have a permission to change this account password')
                                            }
                                        })
                                    } else {
                                        setError(
                                            'Email is not exist OR you do not have a permission to change this account password')
                                    }

                                }).catch((e) => setError('Something went wrong ' + e))

                        }
                    } else {
                        if (password.length < 6 || !validateEmail(email)) {
                            setError('Please fill empty fields correctly')
                        } else {
                            setSubmitted(true)
                            const headers = {
                                "Content-Type": "application/json"
                            }

                            const returnObject = {
                                "email": email,
                                "password": password
                            }

                            await fetch(process.env.REACT_APP_URL + "auth/signIn",
                                {method: "POST",  headers: headers, body: JSON.stringify(returnObject)})
                                .then(response => {
                                    if (response.ok && response.status === 200) {
                                        response.json().then(res => {
                                            props.functionList.handleSignIn(res.token, res.publicId)
                                                .then(() => {
                                                    setPassword("")
                                                    setEmail("")
                                                    setSubmitted(false)
                                                }).catch((e) => {
                                                setSubmitted(false)
                                                setError('Something went wrong ' + e)
                                            })
                                        })
                                    } else {
                                        setError('Email or/and password are wrong')
                                        setSubmitted(false)
                                    }

                                }).catch(e => {
                                    setError('Something went wrong ' + e)
                                    setSubmitted(false)
                                })


                        }
                    }
                }}
                className={classes.button}>
                <Typography className={classes.buttonText}>{forgetPassword ? "SEND" : "SIGN IN"}</Typography>
            </Button>
        }
        <br/>
        <Button onClick={() => setForgetPassword(!forgetPassword)}>
            <Typography className={classes.forgetPasswordText}>{forgetPassword ? "LOGIN" :
                "forget/reset password"}</Typography>
        </Button>
        <Snackbar open={error !== ''}
                  autoHideDuration={5000}
                  onClose={() => setError('')}
        >
            <Alert onClose={() => setError('')}
                   severity={"warning"}
            >
                {error}
            </Alert>
        </Snackbar>
        <Snackbar open={success !== ''}
                  autoHideDuration={2000}
                  onClose={() => setSuccess('')}
        >
            <Alert onClose={() => setSuccess('')}
                   severity={"success"}
            >
                {success}
            </Alert>
        </Snackbar>
    </Paper>;
}

export default SignIn;