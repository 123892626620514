import React from "react";
import {Button, Grid, TableCell, TextField, Typography} from "@material-ui/core";
import Colors from "../../../../constants/Colors";
import {AddOutlined} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    buttonGrid: {
        display: "flex",
        justifyContent: "center",
        borderRadius: 10,
        paddingTop: 0,
        margin: 0,
    },
    buttonGridTop: {
        display: "flex",
        justifyContent: "center",
        borderRadius: 10,
        padding: 16,
        margin: 0,
    },
    input: {
        display: "none",
    },
    tableCellDescription: {
        width: 200,
        minWidth: 200,
        maxWidth: 200,
    },
    tableCellImages: {
        width: 100,
        minWidth: 100,
        maxWidth: 100,
    },
    tableCellHeadOpen: {
        width: 200,
        minWidth: 200,
        maxWidth: 200,
    },
    tableCellHeadOpenAlternative: {
        width: 300,
        minWidth: 300,
        maxWidth: 300,
    },
    tableCellDescriptionOpen: {
        width: 236,
        minWidth: 236,
        maxWidth: 236,
    },
    tableCellImagesOpen: {
        width: 118,
        minWidth: 118,
        maxWidth: 118,
    },
    seeAllGrid: {
        padding: 20,
        display: "flex",
        justifyContent: "center",
    },
    addButtonText: {
        minWidth: 160,
        padding: 16,
        marginLeft: 8,
        border: "groove",
        borderColor: Colors.PRIMARY_GREEN_SECONDARY,
        color: Colors.PRIMARY_WHITE,
        backgroundColor: Colors.PRIMARY_GREEN_LIGHT,
        borderRadius: 10,
        "&:hover": {
            color: Colors.PRIMARY_GREEN_LIGHT,
            borderColor: "transparent",
            backgroundColor: Colors.PRIMARY_WHITE,
        }
    },
    addButton: {
        width: 160,
        padding: 16,
        border: "groove",
        borderColor: Colors.PRIMARY_GREEN_SECONDARY,
        color: Colors.PRIMARY_WHITE,
        backgroundColor: Colors.PRIMARY_GREEN_LIGHT,
        borderRadius: 10,
        "&:hover": {
            color: Colors.PRIMARY_GREEN_LIGHT,
            borderColor: "transparent",
            backgroundColor: Colors.PRIMARY_WHITE,
        }
    },
    buttonLabel: {
        backgroundColor: Colors.PRIMARY_WHITE,
        borderRadius: 10,
    },
    bodyGrid: {
        alignContent: "center",
        alignItems: "center",
        paddingBottom: 20,
    },
    topButton: {
        borderRadius: 10,
        margin: 4,
        border: "solid",
        borderColor: "transparent",
        width: 250,
        backgroundColor: Colors.PRIMARY_BLUE,
        color: Colors.PRIMARY_WHITE,
        '&:hover': {
            border: "solid",
            borderColor: Colors.PRIMARY_BLUE,
            backgroundColor: Colors.PRIMARY_WHITE,
            color: Colors.PRIMARY_BLUE,
        }
    },
    seeAllImg: {
        padding: 4,
        width: 200,
        cursor: "pointer",
        display: "inline",
    },
    seeAllImgActive: {
        border: "solid",
        borderColor: Colors.PRIMARY_BLUE,
        padding: 4,
        width: 200,
        cursor: "pointer",
        display: "inline",
    },
    seeAllExp: {
        margin: 4,
        padding: 8,
        width: 300,
        cursor: "pointer",
        display: "inline",
    },
    seeAllExpActive: {
        border: "solid",
        borderColor: Colors.PRIMARY_BLUE,
        margin: 4,
        padding: 8,
        width: 300,
        cursor: "pointer",
        display: "inline",
    },
    topButtonText: {
        fontSize: 14,
        fontWeight: "bold",
    },
    gridText: {
        fontWeight: "bold",
        fontSize: 12,
        cursor: "pointer",
    },
    buttonGroup: {
        marginTop: 8,
        borderRadius: 10,
        display: "flex",
        justifyContent: "center",
    },
    img: {
        cursor: "pointer",
    },
    innerButtonGroup: {
        padding: 4,
        backgroundColor: Colors.PRIMARY_WHITE,
        borderColor: Colors.PRIMARY_BLUE,
        borderRadius: 10,
    },
    gridBody: {
        padding: 10,
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
    },
    gridAlternative: {
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        color: Colors.PRIMARY_WHITE,
        background: Colors.PRIMARY_WHITE,
    },
    divSelected: {
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        backgroundColor: Colors.PRIMARY_PINK_UP,
        fontWeight: "bold",
        borderRadius: 10,
        padding: 5,
        cursor: "pointer",
        color: Colors.PRIMARY_WHITE,
        maxHeight: 500,
        overflowY: "scroll",
        maxWidth: 400,
    },
    dialogTitleText: {
        textAlign: "start",
        justifyContent: "start",
        alignContent: 'start',
        color: Colors.PRIMARY_BLUE,
        alignItems: 'start',
        display: 'flex',
        fontFamily: Colors.fontOfProgram,
        fontSize: 32,
        fontWeight: "bold"
    },

    divNotSelected: {
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        borderRadius: 10,
        alignContent: "center",
        padding: 5,
        cursor: "pointer",
        fontWeight: "bold",
        color: Colors.PRIMARY_PINK_ALT,
        maxHeight: 500,
        overflowY: "scroll",
        maxWidth: 400,
    },
    scrollable: {
        overflowX: "scroll",
        scrollbarGutter: "stable",
    },
    explanationGrid: {
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
    },
    buttonLabelAlternative: {
        border: "solid",
        backgroundColor: Colors.PRIMARY_WHITE,
        borderRadius: 10,
    },
    buttonCancel: {
        width: 140,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_BLUE,
        }
    },
    buttonDialog: {
        width: 200,
        padding: 10,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_BLUE,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_BLUE,
            color: Colors.PRIMARY_WHITE,
        }
    },
    buttonText: {
        fontWeight: "bold",
    },
    dialogButton: {
        borderRadius: 10,
        height: 35,
        width: 200,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_BLUE,
        '&:hover': {
            color: Colors.PRIMARY_WHITE,
            backgroundColor: Colors.PRIMARY_PINK_UP,
        }
    },
    dialogButtonText: {
        fontWeight: "bold",
        fontSize: 16,
    },
    dialogButtonGroup: {
        backgroundColor: Colors.PRIMARY_BLUE,
        padding: 20,
        display: "flex",
        justifyContent: "space-between",
    },
    topText: {
        color: Colors.PRIMARY_GREY,
        fontWeight: "bold",
        fontFamily: Colors.fontOfProgram,
        fontSize: 16,
    },
    textField: {
        fontSize: 12,
    },
    buttonDialogDelete: {
        width: 200,
        padding: 10,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_PINK_ALT,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_PINK_ALT,
            color: Colors.PRIMARY_WHITE,
        }
    },
});


export default function NewTextTextField(props) {
    const [newText, setNewText] = React.useState("")
    const [keyMap, setKeyMap] = React.useState({})
    const classes = useStyles()

    return <Grid container style={{marginTop: 16}}>
        <Grid item xs={12}>
            <Grid container style={{backgroundColor: Colors.PRIMARY_WHITE, borderRadius: 10}}>
                <Grid item xs={props.allTableAddedTexts.length > 0 ? 8 : 10}>
                    <TextField
                        value={newText}
                        multiline
                        aria-multiline={true}
                        onKeyUp={(e) => {
                            keyMap[e.key] = false;
                        }}
                        onKeyDown={async (e) => {
                            keyMap[e.key] = true

                            if (keyMap['Alt'] && keyMap['Enter']) {
                                setNewText(newText + "\n")
                            }

                            if (e.key === 'Enter' && !keyMap['Alt']) {
                                e.preventDefault()
                                if (newText.trim().length === 0) {
                                    props.setErrorOpen("Text should be entered!")
                                } else {
                                    await props.handleAllTableTextAddition(newText.trim())
                                    setNewText("")
                                    props.scrollStarter()

                                }
                            }
                        }}
                        onChange={(val) => {
                            setNewText(val.target.value)
                        }}
                        fullWidth
                        style={{border: "none", paddingTop: 16, marginLeft: 16,}}
                    />

                </Grid>
                <Grid item xs={props.allTableAddedTexts.length > 0 ? 3 : 1}>

                </Grid>
                <Grid item xs={1} style={{
                    justifyContent: "flex-end",
                    display: "flex",
                    alignItems: "center",
                }}>
                    <TableCell style={{border: "none", padding: 0, alignItems: "center"}}>
                        <Button
                            disabled={!(props.auth)}
                            onClick={async () => {
                                if (newText.trim().length === 0) {
                                    props.setErrorOpen("Text should be entered!")
                                } else {
                                    await props.handleAllTableTextAddition(newText.trim())
                                    setNewText("")
                                    props.scrollStarter()
                                }
                            }}
                            className={classes.addButton}>
                            <AddOutlined/>
                        </Button>
                    </TableCell>
                    {
                        props.allTableAddedTexts.length > 0 ?
                            <Button
                                disabled={!(props.auth)}
                                onClick={async () => {
                                    props.setSubmitted(true)
                                    await props.handleAllTableTextSave()
                                    props.setSubmitted(false)
                                }}
                                className={classes.addButtonText}>
                                <Typography>
                                    Save
                                </Typography>
                            </Button>
                            :
                            null
                    }
                </Grid>
            </Grid>

        </Grid>
    </Grid>
}