import React from "react";
import {
    Paper,
    Typography,
    Divider,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions, ButtonGroup, TextField, Snackbar
} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import Colors from "../../constants/Colors";
import Env from "../../constants/Env";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    card: {
        padding: 20,
        alignItems: "flex-start",

        display: 'flex',
        borderRadius: 10,
        height: '100%',
        color: Colors.PRIMARY_PINK_ALT,
        backgroundColor: Colors.PRIMARY_WHITE,
        '&:hover': {
            color: Colors.PRIMARY_PINK_UP,
            opacity: 1,
        },
    },
    cardBody: {
        paddingLeft: 16,
        paddingRight: 16,
        justifyContent: 'space-between',
        display: 'flex',
        padding: 10,
        color: Colors.PRIMARY_BLUE,
    },
    title: {
        paddingLeft: 12,
        display: 'flex',
        fontSize: 20,
        fontFamily: Colors.fontOfProgram,
        fontWeight: "bold"

    },
    forwardIcon: {
        color: Colors.PRIMARY_BLUE,
        fontSize: 24,
        fontFamily: Colors.fontOfProgram,
    },
    button: {
        padding: 0,
        margin: 0,
    },
    cardBodyChild: {
        paddingRight: 60,
        justifyContent: 'flex-end',
        display: 'flex',
        color: Colors.PRIMARY_BLUE,
    },
    text: {
        color: Colors.PRIMARY_BLUE,
        display: 'flex',
        fontSize: 16,
        fontFamily: Colors.fontOfProgram,
        fontWeight: "bold"
    },
    divider: {
        height: 2,
        borderRadius: 50,
        minWidth: 200,
        marginTop: 4,
        marginBottom: 4,
        background: Colors.gradient,
        width: '100%'
    },
    dialogTitle: {
        color: Colors.PRIMARY_PINK_UP,
        fontWeight: "bold",
        fontFamily: Colors.fontOfProgram,
        fontSize: 32,
    },
    dialogTextField: {},
    dialogButtonGroup: {
        display: "flex",
        justifyContent: "center",
        paddingBottom: 10,
    },
    dialogButton: {
        paddingLeft: 40,
        paddingRight: 40,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_PINK_UP,
        }
    },
    dialogButtonText: {
        fontWeight: "bold",
        color: Colors.PRIMARY_WHITE,
    }
}));

export default function CompanyShortCut(props) {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const projectCount = (props.company != null ? props.company.projects.length : "0 ");
    const [companyName, setCompanyName] = React.useState("")
    const [companyDialog, setCompanyDialog] = React.useState(false)
    const [successOpen, setSuccessOpen] = React.useState(false)
    const [errorOpen, setErrorOpen] = React.useState(false)
    let languageList = [];
    let roleCount = 0;
    let permissionCount = 0;
    let categoryCount = 0;
    let textCount = 0;

    const handleSuccessOpen = () => {
        setSuccessOpen(true)
    }

    const handleErrorOpen = () => {
        setErrorOpen(true)
    }

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorOpen(false);
    };

    const handleSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessOpen(false);
    };


    if (props.company != null) {
        props.company.projects.forEach((project) => {
            roleCount = roleCount + project.roles.length;
            permissionCount = permissionCount + project.permissions.length;
            categoryCount = categoryCount + project.categories.length;
            project.categories.forEach(category => {
                textCount = textCount + category.texts.length;
            })
            project.projectLanguages.forEach((language) => {
                let check = true;
                languageList.forEach((l) => {
                    if (l === language.language.language.toLowerCase())
                        check = false;
                })
                if (check)
                    languageList.push(language.language.language.toLowerCase())
            })
        });

    }

    return <Paper onMouseOver={() => {
        setOpen(true)
    }} onMouseOut={() => {
        setOpen(false)
    }} elevation={open ? 2 : 0} className={classes.card}>
        {props.company != null ? <Grid container spacing={1}>
            <Grid container>
                <Grid item xs={8}>
                    <Typography className={classes.title}>
                        {props.company.name.charAt(0).toUpperCase() + props.company.name.slice(1).toLowerCase()}
                    </Typography>
                </Grid>
                <Grid item xs={4} className={classes.cardBodyChild}>
                    <Grid item xs={2}>
                        <Button disabled={true} className={classes.button}>

                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}>
                </Divider>
            </Grid>
            <Grid container className={classes.cardBody}>
                <Grid item xs={5}>
                    <Typography className={classes.text}>
                        {"Projects "}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography className={classes.text}>
                        {projectCount}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.cardBody}>
                <Grid item xs={5}>
                    <Typography className={classes.text}>
                        {"Languages "}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography className={classes.text}>
                        {languageList.length}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.cardBody}>
                <Grid item xs={5}>
                    <Typography className={classes.text}>
                        {"Work Titles "}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography className={classes.text}>
                        {roleCount}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.cardBody}>
                <Grid item xs={5}>
                    <Typography className={classes.text}>
                        {"Permissions "}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography className={classes.text}>
                        {permissionCount}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.cardBody}>
                <Grid item xs={5}>
                    <Typography className={classes.text}>
                        {"Translation Subjects"}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography className={classes.text}>
                        {categoryCount}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.cardBody}>
                <Grid item xs={5}>
                    <Typography className={classes.text}>
                        {"Lines "}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography className={classes.text}>
                        {textCount}
                    </Typography>
                </Grid>
            </Grid>

        </Grid> : <Grid container>
            <Grid item>
                <Button
                    onClick={() => setCompanyDialog(true)}>
                    <Typography className={classes.text}>
                        Create Company
                    </Typography>
                </Button>
            </Grid>
        </Grid>
        }
        <Dialog fullWidth maxWidth={"xs"} open={companyDialog} onClose={() => setCompanyDialog(false)}>
            <DialogTitle>
                <Typography className={classes.dialogTitle}>
                    Create Company
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.dialogButtonGroup}>
                <TextField
                    fullWidth
                    value={companyName}
                    onChange={(value) => {
                        setCompanyName(value.target.value)
                    }}
                />
            </DialogContent>
            <DialogActions className={classes.dialogButtonGroup}>
                <ButtonGroup className={classes.dialogButtonGroup}>
                    <Button onClick={() => {
                        const returnObject = {
                            name: companyName,
                            userId: props.program.user.id
                        }

                        const headers = {
                            "Content-Type": "application/json",
                            'Authorization': 'Bearer ' + Env.TOKEN
                        }

                        fetch(process.env.REACT_APP_URL + 'company', {
                            method: 'POST', headers: headers, body: JSON.stringify(returnObject)
                        },)
                            .then(response => {
                                if (response.ok) return response.json()
                                else throw new Error();
                            })
                            .then(data => {
                                handleSuccessOpen()
                            })
                            .then(() => props.functionList.getProgram())
                            .catch(e => {
                                //console.log(e)
                                handleErrorOpen()
                            })
                        setCompanyDialog(false)
                    }}
                            disabled={companyName.length === 0}
                            className={classes.dialogButton}>
                        <Typography className={classes.dialogButtonText}>
                            Create
                        </Typography>
                    </Button>
                    <Button onClick={() => setCompanyDialog(false)} className={classes.dialogButton}>
                        <Typography className={classes.dialogButtonText}>
                            Cancel
                        </Typography>
                    </Button>
                </ButtonGroup>
            </DialogActions>
        </Dialog>
        <Snackbar open={errorOpen}
                  autoHideDuration={6000}
                  onClose={handleErrorClose}
        >
            <Alert onClose={handleErrorClose}
                   severity={"warning"}
            >
                Something went wrong, try it later.
            </Alert>
        </Snackbar>
        <Snackbar open={successOpen}
                  autoHideDuration={1000}
                  onClose={handleSuccessClose}
        >
            <Alert onClose={handleSuccessClose}
                   severity={"success"}
            >
                Company is created.
            </Alert>
        </Snackbar>
    </Paper>
}
