import React from "react";
import {Grid, makeStyles, Paper, Typography} from "@material-ui/core";
import Colors from "../../../constants/Colors";
import ProjectLanguageSettings from "./projectSettingsScreens/ProjectLanguageSettings";

import ProjectImageSettings from "./projectSettingsScreens/ProjectImageSettings";
import ProjectExplanationSettings from "./projectSettingsScreens/ProjectExplanationSettings";
import ProjectBlackListSettings from "./projectSettingsScreens/ProjectBlackListSettings";

const useStyles = makeStyles(() => ({
    topPaper: {
        borderRadius: 10,
        padding: 20,
        margin: 10,
        backgroundColor: "transparent",
    },
    paper: {
        borderRadius: 10,
        padding: 8,
        margin: 8,
        cursor: "pointer",
    },
    paperWider: {
        borderWidth: 1,
        borderRadius: 10,
        height: "100%",
    },
    title: {
        color: Colors.PRIMARY_BLUE,
        paddingLeft: 20,
        paddingBottom: 20,
        fontSize: 40,
        fontWeight: "bold",
    },
    subTitle: {
        fontWeight: "bold",
        fontSize: 14,
    },
    settingContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    paperSelected: {
        borderRadius: 10,
        padding: 8,
        margin: 8,
        cursor: "pointer",
        backgroundColor: Colors.PRIMARY_BLUE,
        color: Colors.PRIMARY_WHITE,
    },
    menuPaper: {
        borderRadius: 10,
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
    },
    menuPaperChild: {
        paddingTop: 10,
        paddingBottom: 400,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
    },
    transparent: {
        borderRadius: 10,
        margin: 0,
        padding: 0,
        backgroundColor: "transparent",
    }
}))

export default function ProjectSettings(props) {
    let auth = false;


    if ((props.isCompanyProject && props.project.company.user.userName === props.user.userName) || checkUserRoleAuth())
        auth = true;

    function checkUserRoleAuth() {
        let check = false
        props.user.roles.forEach(roleDesp => {
            if (props.project.id === roleDesp.projectRole.project.id) {
                roleDesp.projectRole.project.roles.forEach(role => {
                    if (role.admin) {
                        role.roleList.forEach(p => {
                            if (p.user.userName === props.user.userName)
                                check = true
                        })
                    }
                })
            }

        })

        return check
    }

    const classes = useStyles()
    const [open, setOpen] = React.useState("languageSetting")


    return <Paper elevation={0} className={classes.topPaper}>
        <Typography className={classes.title}>
            Settings
        </Typography>

        <Grid container className={classes.paperWider} spacing={2}>
            <Grid item xs={2} className={classes.transparent}>
                <Paper elevation={20} className={classes.menuPaperChild}>
                    <Grid item xs={12}>
                        <Paper elevation={0}
                               onClick={() => {
                                   setOpen("languageSetting")
                               }
                               }
                               className={open === "languageSetting" ? classes.paperSelected : classes.paper}>
                            <Typography className={classes.subTitle}>
                                Project Language Settings
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={0}
                               onClick={() => {
                                   setOpen("imageSetting")
                               }
                               } className={open === "imageSetting" ? classes.paperSelected : classes.paper}>
                            <Typography className={classes.subTitle}>
                                Images
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={0}
                               onClick={() => {
                                   setOpen("explanationSetting")
                               }
                               } className={open === "explanationSetting" ? classes.paperSelected : classes.paper}>
                            <Typography className={classes.subTitle}>
                                Descriptions
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={0}
                               onClick={() => {
                                   setOpen("blackList")
                               }
                               } className={open === "blackList" ? classes.paperSelected : classes.paper}>
                            <Typography className={classes.subTitle}>
                                Block List
                            </Typography>
                        </Paper>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={10} className={classes.transparent}>
                <Paper elevation={0} className={classes.paperWider}>
                    {open === "languageSetting" ?
                        <ProjectLanguageSettings
                            program={props.program}
                            user={props.user}
                            functionList={props.functionList}
                            project={props.project}
                            auth={auth}
                        />
                        :
                        open === "imageSetting" ?
                            <ProjectImageSettings
                                program={props.program}
                                user={props.user}
                                functionList={props.functionList}
                                project={props.project}
                                auth={auth}
                            />
                            :
                            open === "explanationSetting" ?
                                <ProjectExplanationSettings
                                    program={props.program}
                                    user={props.user}
                                    functionList={props.functionList}
                                    project={props.project}
                                    auth={auth}
                                />
                                : <ProjectBlackListSettings
                                    program={props.program}
                                    user={props.user}
                                    functionList={props.functionList}
                                    project={props.project}
                                    auth={auth}
                                />
                    }
                </Paper>
            </Grid>
        </Grid>
    </Paper>
}