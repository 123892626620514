import React from 'react';

import {TextField, Paper, Typography, Button, Snackbar, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Colors from "../../constants/Colors";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles({
    textField: {
        width: '90%',
        color: Colors.PRIMARY_PINK_ALT,
        fontSize: 48,
        fontFamily: Colors.fontOfProgram,
        fontWeight: 'bold',
    },
    grid: {
        width: '100%'
    },
    button: {
        marginTop: 24,
        paddingBottom: 8,
        paddingTop: 8,
        paddingLeft: 50,
        paddingRight: 50,
        borderRadius: 16,
        borderWidth: 16,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        '&:hover': {
            background: Colors.PRIMARY_BLUE,
        },

    },
    buttonText: {
        color: 'white',
        fontSize: 12,
        fontWeight: 'bold',
        fontFamily: 'Poppins',
    },
    paper: {
        marginTop: 24,
    },
    text: {
        fontFamily: 'Poppins',
        fontSize: '200%',
        fontWeight: 'bold',
        color: Colors.PRIMARY_BLUE
    }
});

const SignUp = () => {
    const classes = useStyles();
    const [name, setName] = React.useState("")
    const [surname, setSurname] = React.useState("")
    const [username, setUsername] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [errorOpen, setErrorOpen] = React.useState("")
    const [successOpen, setSuccessOpen] = React.useState(false)
    const [submitted, setSubmitted] = React.useState(false)


    const handleSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessOpen(false);
    };

    const handleSuccessOpen = () => {
        setSuccessOpen(true)
    }


    const handleErrorOpen = (val) => {
        if(val !== null){
            setErrorOpen(val)

        }else{
            setErrorOpen("Something went wrong")
        }
    }

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorOpen("");
    };

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    return <Paper elevation={0}>
        <Paper elevation={0} className={classes.paper}>
            <Typography className={classes.text}>JOIN US</Typography>
        </Paper>
        <br/>
        <Paper elevation={0}>
            <TextField
                value={name}
                onChange={(value) => {
                    setName(value.target.value)
                }}
                type="text"
                placeholder="Name"
                className={classes.textField}/>
        </Paper>
        <br/>
        <Paper elevation={0}>
            <TextField
                value={surname}
                onChange={(value) => {
                    setSurname(value.target.value)
                }}
                type="text"
                placeholder="Surname"
                className={classes.textField}/>
        </Paper>
        <br/>
        <Paper elevation={0}>
            <TextField
                value={username}
                onChange={(value) => {
                    setUsername(value.target.value)
                }}
                type="text"
                placeholder="Username"
                className={classes.textField}/>
        </Paper>
        <br/>
        <Paper elevation={0}>
            <TextField
                error={email.length > 0 ? !validateEmail(email) : false}
                value={email}
                onChange={(value) => {
                    setEmail(value.target.value)
                }}
                type="text"
                placeholder="Email"
                className={classes.textField}/>
        </Paper>
        <br/>
        <Paper elevation={0}>
            <TextField
                value={password}
                onChange={(value) => {
                    setPassword(value.target.value)
                }}
                type="password"
                placeholder="Password"
                className={classes.textField}/>
        </Paper>
        <br/>
        {submitted ?
            <CircularProgress size={40}/>
            :
            <Button onClick={async () => {
                if (name.length === 0) {
                    handleErrorOpen("Name can not be empty")
                } else if (surname.length === 0) {
                    handleErrorOpen("Surname can not be empty")
                } else if (username.length === 0) {
                    handleErrorOpen("Username can not be empty")
                } else if (email.length === 0 || !validateEmail(email)) {
                    handleErrorOpen("email can not be empty or not valid email")
                } else if (password.length < 8) {
                    handleErrorOpen("Password can not be shorter than 8 char")
                } else {
                    const headers = {
                        "Content-Type": "application/json"
                    }
                    setSubmitted(true)
                    const returnObject = {
                        "name": name,
                        "surname": surname,
                        "userName": username,
                        "email": email,
                        "password": password
                    }

                    await fetch(process.env.REACT_APP_URL + "auth/signUp",
                        {method: "POST", headers: headers, body: JSON.stringify(returnObject)})
                        .then(response => {
                            if (response.ok) {
                                setPassword("")
                                setEmail("")
                                setSurname("")
                                setName("")
                                setUsername("")
                                return handleSuccessOpen()
                            } else response.json().then(data => handleErrorOpen(data.message));
                        }).catch((e) => {
                            handleErrorOpen(e)
                        })
                    setSubmitted(false)
                }
            }} className={classes.button}>
                <Typography className={classes.buttonText}>SIGN UP</Typography>
            </Button>
        }
        <Snackbar open={errorOpen.length > 0}
                  autoHideDuration={2000}
                  onClose={handleErrorClose}
        >
            <Alert onClose={handleErrorClose}
                   severity={"warning"}
            >
                {errorOpen}
            </Alert>
        </Snackbar>
        <Snackbar open={successOpen}
                  autoHideDuration={3000}
                  onClose={handleSuccessClose}
        >
            <Alert onClose={handleSuccessClose}
                   severity={"success"}
            >
                Account is created.Check your email.
            </Alert>
        </Snackbar>
    </Paper>;
}

export default SignUp;