import React, {useEffect, useRef} from "react";
import {InputBase, TableCell} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Colors from "../../../../constants/Colors";

const useStyles = makeStyles({
    progressBar: {
        background: Colors.gradient,
        borderRadius: 10,
        height: 5,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
    },
    input: {
        display: "none",
    },
    tableCellDescription: {
        width: 200,
        minWidth: 200,
        maxWidth: 200,
    },
    tableCellImages: {
        width: 100,
        minWidth: 100,
        maxWidth: 100,
    },
    tableCellHeadOpen: {
        width: 200,
        minWidth: 200,
        maxWidth: 200,
    },
    tableCellHeadOpenAlternative: {
        width: 300,
        minWidth: 300,
        maxWidth: 300,
    },
    tableCellDescriptionOpen: {
        width: 236,
        minWidth: 236,
        maxWidth: 236,
    },
    tableCellImagesOpen: {
        width: 118,
        minWidth: 118,
        maxWidth: 118,
    },
    seeAllGrid: {
        padding: 20,
        display: "flex",
        justifyContent: "center",
    },
    bodyGrid: {
        alignContent: "center",
        alignItems: "center",
        paddingBottom: 20,
    },
    topButton: {
        borderRadius: 10,
        margin: 4,
        border: "solid",
        borderColor: "transparent",
        width: 250,
        backgroundColor: Colors.PRIMARY_BLUE,
        color: Colors.PRIMARY_WHITE,
        '&:hover': {
            border: "solid",
            borderColor: Colors.PRIMARY_BLUE,
            backgroundColor: Colors.PRIMARY_WHITE,
            color: Colors.PRIMARY_BLUE,
        }
    },
    seeAllImg: {
        padding: 4,
        width: 200,
        cursor: "pointer",
        display: "inline",
    },
    seeAllImgActive: {
        border: "solid",
        borderColor: Colors.PRIMARY_BLUE,
        padding: 4,
        width: 200,
        cursor: "pointer",
        display: "inline",
    },
    seeAllExp: {
        margin: 4,
        padding: 8,
        width: 300,
        cursor: "pointer",
        display: "inline",
    },
    seeAllExpActive: {
        border: "solid",
        borderColor: Colors.PRIMARY_BLUE,
        margin: 4,
        padding: 8,
        width: 300,
        cursor: "pointer",
        display: "inline",
    },
    topButtonText: {
        fontSize: 14,
        fontWeight: "bold",
    },
    gridText: {
        fontWeight: "bold",
        fontSize: 12,
        cursor: "pointer",
    },
    buttonGroup: {
        marginTop: 8,
        borderRadius: 10,
        display: "flex",
        justifyContent: "center",
    },
    img: {
        cursor: "pointer",
    },
    scrollable: {
        overflowX: "scroll",
        scrollbarGutter: "stable",
    },
    explanationGrid: {
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
    },
    topText: {
        color: Colors.PRIMARY_GREY,
        fontWeight: "bold",
        fontFamily: Colors.fontOfProgram,
        fontSize: 16,
    },
    textField: {
        fontSize: 12,
        height: "inherit"
    },
});


export default function AllLanguagesPanelCell(props) {
    const classes = useStyles()
    const [textValue, setTextValue] = React.useState(null)
    const [categoryId, setCategoryId] = React.useState(null)
    const [allTable, setAllTable] = React.useState(null)
    const [keyMap, setKeyMap] = React.useState({})


    if (!props.isNew &&
        (textValue === null || categoryId === null || categoryId !== props.category.id || allTable === null ||
            allTable !== props.allTable)) {
        setTextValue(props.text !== null ? props.text : "")
        setCategoryId(props.category.id)
        setAllTable(props.allTable)
    }


    if (props.isNew) {
        return <TableCell
            style={{
                padding: 4,
                width: 200,
                minWidth: 200,
                borderRightStyle: "groove",

            }}>
            <InputBase
                disabled={!props.auth}
                onBlur={() => {
                    if (props.isBase) {
                        let temp = [...props.allTableAddedTexts]
                        temp[props.rowIndex] = textValue
                        props.handleAllTableAddedTextChange(temp)
                    }
                }}
                className={classes.textField}
                fullWidth
                onKeyUp={(e) => {
                    //keyMap[e.key] = false;
                }}
                onKeyDown={(e) => {
                    /*
                    keyMap[e.key] = true

                    if (keyMap['Alt'] && keyMap['Enter']) {
                        props.handleAllTableLocalizationChange(rowEl.id,
                            (rowEl.text !== null ? rowEl.text : "") + "\n")
                    }
                      disabled={!(checkPermission(rowEl.projectLanguage !== null ?
                    rowEl.projectLanguage.language.language : "") || props.auth) ||
                props.languageIndex ===
                languages.length - 1}
                     */
                }}
                multiline
                onChange={(val) => {
                    props.handleAllTableAddedTextChange(props.rowIndex, val.target.value)
                }}
                value={props.text}
            />
        </TableCell>
    } else {
        return <TableCell
            style={{
                padding: 4,
                width: 200,
                minWidth: 200,
                borderRightStyle: "groove",
            }}>
            <InputBase
                disabled={!props.auth}

                onBlur={() => {

                    if (props.isBase) {
                        let temp = {...props.base}
                        temp.englishWord = textValue

                        let tempList = [...props.allTable]
                        tempList[props.rowIndex].englishWord = textValue

                        //props.handleAllTableSetProjectAllTable(tempList)

                       // props.category.texts[props.rowIndex].englishWord = textValue
                        props.handleAllTableBaseSave(temp)
                    } else {
                        let temp = {...props.localization}
                        temp.text = textValue
                        let tempList = [...props.allTable]
                       // props.category.texts[props.rowIndex].localizations[props.localizationIndex].text = textValue

                        //props.handleAllTableSetProjectAllTable(tempList)
                        tempList[props.rowIndex].localizations[props.localizationIndex].text = textValue
                        props.handleAllTableLocalizationSave(temp)
                    }

                }}
                className={classes.textField}
                fullWidth
                onKeyUp={(e) => {
                    //keyMap[e.key] = false;
                }}
                onKeyDown={(e) => {
                    /*
                    keyMap[e.key] = true

                    if (keyMap['Alt'] && keyMap['Enter']) {
                        props.handleAllTableLocalizationChange(rowEl.id,
                            (rowEl.text !== null ? rowEl.text : "") + "\n")
                    }
                      disabled={!(checkPermission(rowEl.projectLanguage !== null ?
                    rowEl.projectLanguage.language.language : "") || props.auth) ||
                props.languageIndex ===
                languages.length - 1}
                     */
                }}
                multiline
                onChange={(val) => {
                    setTextValue(val.target.value)
                }}
                value={textValue}
            />
        </TableCell>
    }

}
