import React from "react";
import {
    Button,
    ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle,
    Grid,
    IconButton,
    makeStyles,
    Paper, Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, TextField, Tooltip,
    Typography
} from "@material-ui/core";
import Colors from "../../../../constants/Colors";
import Env from "../../../../constants/Env";
import { Alert } from "@material-ui/lab";
import { AddOutlined, CancelOutlined, RemoveOutlined } from "@material-ui/icons";

const useStyles = makeStyles( () => ( {
    paper: {
        width: "100%",
        height: "100%",
        borderRadius: 10,
        padding: 20,
    },
    titleText: {
        color: Colors.PRIMARY_BLUE,
        fontSize: 28,
        fontFamily: Colors.fontOfProgram,
        paddingBottom: 20,
        fontWeight: "bold",
    },
    img: {
        paddingRight: 10,
        width: 160,
        maxHeight: 328,
        cursor: "pointer",
        display: "inline",
    },
    imgTemp: {
        paddingRight: 10,
        width: 160,
        minHeight: 328,
    },
    imageGrid: {
        padding: 4,
        position: "relative",
        maxWidth: 1400,
        overflowX: "auto",
        whiteSpace: "nowrap",
    },
    button: {
        width: 200,
        padding: 10,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_GREEN_LIGHT,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_GREEN_LIGHT,
            color: Colors.PRIMARY_WHITE,
        }
    },
    deleteButton: {
        width: 200,
        padding: 10,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: 'darkred',
        "&:hover": {
            backgroundColor: 'darkred',
            color: Colors.PRIMARY_WHITE,
        }
    },
    buttonText: {
        fontWeight: "bold",
    },
    buttonGrid: {
        display: "flex",
        justifyContent: "center",
        borderRadius: 10,
        paddingTop: 1,
        margin: 0,
    },
    buttonGridTop: {
        display: "flex",
        justifyContent: "center",
        borderRadius: 10,
        paddingTop: 30,
        margin: 0,
    },
    dialogImage: {
        maxHeight: 900,
        maxWidth: 1200
    },
    seeAllGrid: {
        padding: 20,
        display: "flex",
        justifyContent: "center",
    },
    buttonLabel: {
        backgroundColor: Colors.PRIMARY_WHITE,
        borderRadius: 10,
        padding: 4,
    },
    table: {
        minHeight: 300,
        maxHeight: 300,
        overflowY: "scroll",
    },
    tableHead: {
        background: Colors.PRIMARY_BLUE,
        color: Colors.PRIMARY_WHITE,
    },
    tableHeadText: {
        color: Colors.PRIMARY_WHITE,
        fontWeight: "bold",
    },
    tableBodyText: {
        colors: Colors.PRIMARY_BLACK,
        fontWeight: "bold",
    },
    tableBodyIcon: {
        color: Colors.PRIMARY_BLUE,
        '&:hover': {
            color: 'red'
        }
    },
    dialogTitleText: {
        fontWeight: "bold",
        paddingLeft: 20,
        paddingRight: 20,
        fontFamily: Colors.fontOfProgram,
        fontSize: 28,
        color: Colors.PRIMARY_BLUE,
    },
    dialogBodyText: {
        fontWeight: "bold",
        paddingLeft: 20,
        paddingRight: 20,
        fontSize: 16,
        fontFamily: Colors.fontOfProgram,
        color: Colors.PRIMARY_GREY,
    },
    selection: {
        paddingRight: 20,
        marginLeft: 20,
    },
    chip: {
        margin: 4,
        background: Colors.PRIMARY_BLUE,
        color: Colors.PRIMARY_WHITE
    },
    grid: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
    },
    buttonCreate: {
        width: 40,
        marginBottom: 20,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_GREEN_LIGHT,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_GREEN_LIGHT,
            color: Colors.PRIMARY_WHITE,
        }
    },
    buttonDelete: {
        width: 40,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        color: Colors.PRIMARY_WHITE,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_WHITE,
            color: Colors.PRIMARY_PINK_ALT,
        }
    },
    gridRow: {
        alignItems: "center",
        background: Colors.PRIMARY_BACKGROUND,
        paddingLeft: 16,
        borderRadius: 10,
        marginBottom: 4,
    },


} ) )

export default function ProjectBlackListSettings( props ) {

    const classes = useStyles()
    const [ successOpen, setSuccessOpen ] = React.useState( false )
    const [ errorOpen, setErrorOpen ] = React.useState( false )
    const [ addNew, setAddNew ] = React.useState( false )
    const [ deleteConfirmation, setDeleteConfirmation ] = React.useState( null )
    const [ blackList, setBlackList ] = React.useState( [] )
    const [ newBlackListTexts, setNewBlackListTexts ] = React.useState( [] )
    const [ newText, setNewText ] = React.useState( "" )

    if ( blackList.length !== props.project.blackListTexts.length ) {
        setBlackList( [ ...props.project.blackListTexts ] )
    }

    function handleBlackListAddition( blackListResponse ) {
        let tempList = [ ...blackList, ...blackListResponse ]
        props.project.blackListTexts = tempList
        setBlackList( tempList )
    }

    function handleBlackListDeletion( elementId ) {
        let tempList = []

        blackList.forEach( bl => {
            if ( bl.id !== elementId ) {
                tempList.push( bl )
            }
        } )

        props.project.blackListTexts = tempList
        setBlackList( tempList )
    }

    return <Paper
        elevation={ 20 }
        className={ classes.paper }>
        <Grid container>
            <Grid item xs={ 12 }>
                <Typography className={ classes.titleText }>
                    Block List
                </Typography>
            </Grid>
            <Grid item xs={ 12 } className={ classes.table }>
                <Table>
                    <TableHead className={ classes.tableHead }>
                        <TableRow>
                            <TableCell>
                                <Typography className={ classes.tableHeadText }>
                                    Word
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography className={ classes.tableHeadText }>
                                    Delete
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { blackList.map( el => {
                            return <TableRow>
                                <TableCell>
                                    <Typography className={ classes.tableBodyText }>
                                        { el.text }
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title={ "Remove \"" + el.text + "\" from the blacklist of this project." }>
                                        <IconButton
                                            disabled={ !props.auth }
                                            onClick={ () => setDeleteConfirmation( el ) }
                                            className={ classes.tableBodyIcon }
                                        >
                                            <CancelOutlined/>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        } ) }
                    </TableBody>
                </Table>

            </Grid>
            <Grid container className={ classes.buttonGridTop }>
                <Paper elevation={ 10 } className={ classes.buttonGrid }>
                    <ButtonGroup>
                        <label className={ classes.buttonLabel }>
                            <Tooltip title={ "Add new BlackList texts." }>
                                <Button
                                    disabled={ !props.auth }
                                    onClick={ () => setAddNew( true ) }
                                    className={ classes.button }>
                                    <Typography className={ classes.buttonText }>
                                        Add
                                    </Typography>
                                </Button>
                            </Tooltip>
                        </label>
                    </ButtonGroup>
                </Paper>
            </Grid>
        </Grid>
        <Dialog open={ addNew }
                fullWidth={ true }
                maxWidth={ "sm" }
                onClose={ () => setAddNew( false ) }>
            <DialogTitle>
                <Typography className={ classes.dialogTitleText }>
                    Add Texts to Black List
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography className={ classes.dialogBodyText }>
                    You can add one or more words. You also can delete by clicking on the chips.
                </Typography>
            </DialogContent>
            <DialogContent className={ classes.grid }>
                <Grid container className={ classes.grid }>
                    <Grid container>
                        <Grid item xs={ 11 }>
                            <TextField
                                fullWidth
                                key={ "textFieldOfExplanationUpload" }
                                value={ newText }
                                margin="dense"
                                onChange={
                                    event => {
                                        setNewText( event.target.value )
                                    }
                                }
                                onKeyDown={ ( e ) => {
                                    if ( e.key === 'Enter' )
                                        e.preventDefault()
                                    if ( e.key === 'Enter' && newText.trim().length > 0 ) {
                                        let check = true

                                        newBlackListTexts.forEach( el => {
                                            if ( el.trim() === newText.trim() ) {
                                                check = false
                                                setNewText( "" )
                                            }
                                        } )

                                        blackList.forEach( el => {
                                            if ( el.text.trim() === newText.trim() ) {
                                                check = false
                                                setNewText( "" )
                                            }
                                        } )

                                        if ( check ) {
                                            let temp = [ ...newBlackListTexts ]
                                            temp.push( newText.trim() )
                                            setNewBlackListTexts( temp )
                                            setNewText( "" )
                                        }
                                    }
                                } }
                            />
                        </Grid>
                        <Grid item xs={ 1 }>
                            <Button onClick={ () => {
                                if ( newText.trim().length > 0 ) {
                                    let check = true

                                    newBlackListTexts.forEach( el => {
                                        if ( el.trim() === newText.trim() ) {
                                            check = false
                                            setNewText( "" )
                                        }
                                    } )

                                    blackList.forEach( el => {
                                        if ( el.text.trim() === newText.trim() ) {
                                            check = false
                                            setNewText( "" )
                                        }
                                    } )

                                    if ( check ) {
                                        let temp = [ ...newBlackListTexts ]
                                        temp.push( newText.trim() )
                                        setNewBlackListTexts( temp )
                                        setNewText( "" )
                                    }
                                }

                                setNewText( "" )
                            } } className={ classes.buttonCreate } color="primary">
                                <AddOutlined/>
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container>
                        { newBlackListTexts.map( el => {
                            return <Grid container className={ classes.gridRow }>
                                <Grid item xs={ 11 }>
                                    <Typography aria-multiline={ true }>
                                        { el }
                                    </Typography>
                                </Grid>
                                <Grid item xs={ 1 }>
                                    <Button onClick={ () => {
                                        let temp = []
                                        newBlackListTexts.forEach( exp => {
                                            if ( exp !== el )
                                                temp.push( exp )
                                        } )

                                        setNewBlackListTexts( temp )
                                    } } className={ classes.buttonDelete } color="primary">
                                        <RemoveOutlined/>
                                    </Button>
                                </Grid>
                            </Grid>
                        } ) }
                    </Grid>
                    {/*
                    <Grid container className={classes.grid}>
                        <Grid item xs={6}>
                            {newBlackListTexts.map(el => {
                                return <Chip
                                    className={classes.chip}
                                    label={el}
                                    key={el}
                                    onClick={
                                        () => {
                                            let temp = []
                                            newBlackListTexts.forEach(exp => {
                                                if (exp !== el)
                                                    temp.push(exp)
                                            })

                                            setNewBlackListTexts(temp)
                                        }
                                    }/>
                            })}
                        </Grid>

                    </Grid>
                    */ }
                </Grid>
            </DialogContent>
            <DialogActions className={ classes.seeAllGrid }>
                <Paper elevation={ 10 } className={ classes.buttonGrid }>
                    <ButtonGroup>
                        <label className={ classes.buttonLabel }>
                            <Button
                                disabled={ !props.auth || newBlackListTexts.length === 0 }
                                style={ newBlackListTexts.length === 0 ? {
                                    color: Colors.PRIMARY_GREY
                                } : {} }
                                onClick={ () => {
                                    if ( newBlackListTexts.length > 0 ) {
                                        let requestBody = []
                                        newBlackListTexts.forEach( el => {
                                            requestBody.push( el )
                                        } )


                                        const headers = {
                                            "Content-Type": "application/json",
                                            'Authorization': 'Bearer ' + Env.TOKEN
                                        }

                                        fetch( process.env.REACT_APP_URL + 'projects/blacklists?projectId=' +
                                            props.project.id, {
                                            method: 'POST', headers: headers, body: JSON.stringify( requestBody )
                                        }, )
                                            .then( response => {
                                                if ( response.ok ) return response.json()
                                                else throw new Error();
                                            } )
                                            .then( data => {
                                                console.log( data )
                                                handleBlackListAddition( data )

                                            } )
                                            .then( () => setSuccessOpen( true ) )
                                            .then( () => setAddNew( false ) )
                                            .then( () => setNewBlackListTexts( [] ) )
                                            .catch( e => {
                                                //console.log(e)
                                                setErrorOpen( true )
                                            } )


                                    }
                                } }
                                className={ classes.button }>
                                <Typography className={ classes.buttonText }>
                                    Save
                                </Typography>
                            </Button>
                        </label>
                        <label className={ classes.buttonLabel }>
                            <Button onClick={ () => setAddNew( false ) } className={ classes.deleteButton }>
                                <Typography className={ classes.buttonText }>
                                    Cancel
                                </Typography>
                            </Button>
                        </label>
                    </ButtonGroup>
                </Paper>
            </DialogActions>

        </Dialog>
        { deleteConfirmation !== null ?
            <Dialog open={ true }
                    fullWidth={ true }
                    maxWidth={ "md" }
                    onClose={ () => setDeleteConfirmation( null ) }
            >
                <DialogTitle>
                    <Typography className={ classes.dialogTitleText }>
                        Delete BlackList Text
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography className={ classes.dialogBodyText }>
                        Are you sure to delete { deleteConfirmation.text } from your project ?
                    </Typography>
                </DialogContent>
                <DialogActions className={ classes.seeAllGrid }>
                    <Paper elevation={ 10 } className={ classes.buttonGrid }>
                        <ButtonGroup>
                            <label className={ classes.buttonLabel }>
                                <Button
                                    disabled={ !props.auth }
                                    onClick={
                                        () => {
                                            const headers = {
                                                "Content-Type": "application/json",
                                                'Authorization': 'Bearer ' + Env.TOKEN
                                            }

                                            fetch( process.env.REACT_APP_URL + 'projects/blacklists?blackTextId=' +
                                                deleteConfirmation.id,
                                                {
                                                    method: 'DELETE', headers: headers
                                                }, )
                                                .then( response => {
                                                    if ( response.ok ) {
                                                        return response.json()
                                                    } else throw new Error();
                                                } )
                                                .then( ( data ) => {
                                                    if ( data.operationResult === "ERROR" ) {
                                                        throw new Error();
                                                    } else {
                                                        handleBlackListDeletion( deleteConfirmation.id )
                                                    }
                                                } )

                                                .then( () => setSuccessOpen( true ) )
                                                .then( () => setDeleteConfirmation( null ) )
                                                .catch( () => {
                                                    setErrorOpen( true )
                                                } )

                                        }
                                    }
                                    className={ classes.deleteButton }>
                                    <Typography className={ classes.buttonText }>
                                        Delete
                                    </Typography>
                                </Button>
                            </label>
                            <label className={ classes.buttonLabel }>
                                <Button onClick={ () => setDeleteConfirmation( null ) } className={ classes.button }>
                                    <Typography className={ classes.buttonText }>
                                        Cancel
                                    </Typography>
                                </Button>
                            </label>
                        </ButtonGroup>
                    </Paper>
                </DialogActions>
            </Dialog> : null }
        <Snackbar open={ errorOpen }
                  autoHideDuration={ 6000 }
                  onClose={ () => setErrorOpen( false ) }
        >
            <Alert onClose={ () => setErrorOpen( false ) }
                   severity={ "warning" }
            >
                Something went wrong, try it later.
            </Alert>
        </Snackbar>
        <Snackbar open={ successOpen }
                  autoHideDuration={ 1000 }
                  onClose={ () => setSuccessOpen( false ) }
        >
            <Alert onClose={ () => setSuccessOpen( false ) }
                   severity={ "success" }
            >
                Operations are completed successfully.
            </Alert>
        </Snackbar>
    </Paper>

}