import React from "react";
import {Grid, makeStyles, Paper, Tooltip, Typography} from "@material-ui/core";
import Colors from "../../../../../../constants/Colors";

const useStyles = makeStyles((theme) => ({
    button: {
        width: 400,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        '&:hover': {
            backgroundColor: Colors.PRIMARY_PINK_UP,

        }
    },
    buttonText: {
        fontWeight: "bold",
        fontSize: 16,
        color: Colors.PRIMARY_WHITE,
        fontFamily: Colors.fontOfProgram,

    },
    buttonGroup: {
        display: "flex",
        justifyContent: "space-evenly",
    },
    grid: {
        justifyContent: "space-between",
        display: "flex",
    },
    subTitle: {
        color: Colors.PRIMARY_PINK_ALT,
        fontSize: 16,
        fontFamily: Colors.fontOfProgram,
        fontWeight: "bold",
    },
    subTitleChild: {
        color: Colors.PRIMARY_BLUE,
        fontWeight: "bold",
        fontSize: 16,
        fontFamily: Colors.fontOfProgram,
    },
    paper: {
        justifyContent: "space-between",
        alignItems: "center",
        justifyItems: "center",
        padding: 20,
        marginTop: 20,
        cursor: "pointer",
    }
}))


export default function TranslationOrderCard(props) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)


    return <Tooltip title={"Click to Select"}>
        <Paper
            className={classes.paper}
            onMouseOver={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            elevation={open ? 2 : 0}
            onClick={() => {
                props.selectOrder()
            }}
        >
            <Grid container className={classes.grid}>
                <Grid item xs>
                    <Typography className={classes.subTitleChild}>
                        {props.order.type.charAt(0) + props.order.type.slice(1).toLowerCase()}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography className={classes.subTitle}>
                        {props.order.status.split("_")[0].charAt(0) +
                        props.order.status.split("_")[0].slice(1).toLowerCase() + " " +
                        ((props.order.status.split("_").length > 1) ?
                            props.order.status.split("_")[1].charAt(0) +
                            props.order.status.split("_")[1].slice(1).toLowerCase() : "")}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography className={classes.subTitleChild}>
                        {props.order.texts.length}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography className={classes.subTitle}>
                        {props.order.user.name + " " + props.order.user.surname}

                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography className={classes.subTitleChild}>
                        {props.order.user.email}

                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    </Tooltip>
}