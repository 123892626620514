import React from "react";
import {
    Button,
    Checkbox, Dialog, DialogActions,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip, Typography
} from "@material-ui/core";
import Colors from "../../../../../../constants/Colors";

const useStyles = makeStyles(() => ({
    dialogAction: {
        display: "flex",
        justifyContent: "center",
    },
    button: {
        width: 400,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        '&:hover': {
            backgroundColor: Colors.PRIMARY_PINK_UP,

        }
    },
    buttonText: {
        fontWeight: "bold",
        fontSize: 16,
        fontFamily: Colors.fontOfProgram,
        color: Colors.PRIMARY_WHITE,
    },
    buttonGroup: {
        display: "flex",
        justifyContent: "space-evenly",
    },
    grid: {
        justifyContent: "space-between",
        display: "flex",
    },
    subTitle: {
        color: Colors.PRIMARY_PINK_ALT,
        fontSize: 12,
        fontFamily: Colors.fontOfProgram,
        fontWeight: "bold",
    },
    subTitleChild: {
        color: Colors.PRIMARY_BLUE,
        fontWeight: "bold",
        fontFamily: Colors.fontOfProgram,
        fontSize: 16,
    },
    hr: {
        borderColor: Colors.PRIMARY_PINK_UP,
    },
    tableHead: {
        background: Colors.gradient,
        color: Colors.PRIMARY_WHITE
    },
    headText: {
        color: Colors.PRIMARY_WHITE,
        fontWeight: "bold",
        '&.Mui-checked': {
            color: Colors.PRIMARY_WHITE,
        }
    },
    image: {
        cursor: "pointer",
    },
    scrollableDiv: {
        maxHeight: 500,
        overflowY: "scroll",
    }
}))

export default function TranslationOrderTextPreviewer(props) {
    const classes = useStyles()
    const [dialogOpen, setDialogOpen] = React.useState(null)
    const [selectedTexts, setSelectedTexts] = React.useState([])

    if (props.texts.length > 0 && selectedTexts.length === 0) {
        setSelectedTexts(props.texts)
    }

    function controlChecked(value) {
        let check = false;
        selectedTexts.forEach(id => {
            if (id === value) {
                check = true
            }
        })
        return check
    }


    return <div>
        <DialogActions className={classes.buttonGroup}>
            <Button
                disabled={selectedTexts.length < 1}
                onClick={() => {
                    let textTempList = []
                    selectedTexts.forEach(textId => {
                        props.order.texts.forEach(text => {
                            if (text.id === textId) {
                                textTempList.push({...text})
                            }
                        })
                    })
                    props.setTextObjects([...textTempList])
                    props.completeSelection(selectedTexts)
                    props.setPage("view_as_list_older")
                }}
                className={classes.button}>
                <Typography className={classes.buttonText}>
                    Continue
                </Typography>
            </Button>

        </DialogActions>
        <DialogActions className={classes.buttonGroup}>
            <Tooltip
                title={selectedTexts.length === props.order.texts.length ? "Unselect All Lines." : "Select All Lines."}>
                <Button onClick={() => {
                    if (props.texts.length > 0) {
                        props.completeSelection([])
                    }
                    if (selectedTexts.length === props.order.texts.length) {
                        setSelectedTexts([])
                    } else {
                        let list = []
                        props.order.texts.forEach((text) => {
                            list.push(text.id)
                        })
                        setSelectedTexts(list)
                    }
                }}
                        className={classes.button}>
                    <Typography className={classes.buttonText}>
                        {selectedTexts.length === props.order.texts.length ? "UnSelect All" : "Select All"}
                    </Typography>
                </Button>
            </Tooltip>
            <Tooltip title={"Select untranslated lines."}>
                <Button onClick={() => {
                    let list = []
                    props.order.texts.forEach(text => {
                        if (text.equivalent === null || text.equivalent.length === 0) {
                            list.push(text.id)
                        }
                    })
                    setSelectedTexts(list)
                }}
                        className={classes.button}>
                    <Typography className={classes.buttonText}>
                        Select Untranslated
                    </Typography>
                </Button>
            </Tooltip>
        </DialogActions>
        <TableContainer className={classes.scrollableDiv}>
            <Table>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        <TableCell padding="checkbox">
                            <Tooltip title={selectedTexts.length === props.order.texts.length ? "Unselect All Lines." :
                                "Select All Lines."}>
                                <Checkbox
                                    checked={selectedTexts.length === props.order.texts.length}
                                    onClick={() => {
                                        if (props.texts.length > 0) {
                                            props.completeSelection([])
                                        }
                                        if (selectedTexts.length === props.order.texts.length) {
                                            setSelectedTexts([])
                                        } else {
                                            let list = []
                                            props.order.texts.forEach((text) => {
                                                list.push(text.id)
                                            })
                                            setSelectedTexts(list)
                                        }
                                    }}
                                    className={classes.headText}/>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            <Typography className={classes.headText}>
                                Localization
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography className={classes.headText}>
                                Shown to Translator
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography className={classes.headText}>
                                Submitted by Translator
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography className={classes.headText}>
                                Guide Image
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography className={classes.headText}>
                                Description
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.order.texts.map(text => {
                        return <TableRow
                            key={"translation-order-text-addition-body-row" + text.id}
                        >
                            <TableCell padding="checkbox">
                                <Tooltip title={controlChecked(text.id) ? "Unselect" : "Select"}>
                                    <Checkbox checked={controlChecked(text.id)}
                                              onClick={() => {
                                                  if (props.texts.length > 0) {
                                                      props.completeSelection([])
                                                  }
                                                  if (controlChecked(text.id)) {
                                                      let temp = []
                                                      selectedTexts.forEach(textId => {
                                                          if (textId !== text.id) {
                                                              //console.log(textId + "   -   " + text.id)
                                                              temp.push(textId)
                                                          }
                                                      })
                                                      setSelectedTexts(temp)
                                                  } else {
                                                      setSelectedTexts([...selectedTexts, text.id])
                                                  }
                                              }}
                                    />
                                </Tooltip>

                            </TableCell>
                            <TableCell>
                                <Typography>
                                    {text.text.baseText.englishWord}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    {text.englishShownWord}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    {text.equivalent}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                {text.image !== null ?
                                    <Tooltip title={"Click to Zoom"}>
                                        <img className={classes.image}
                                             alt={"text's img"}
                                             onClick={() => setDialogOpen(
                                                 process.env.REACT_APP_IMAGE_URL + text.image.url)}
                                             src={process.env.REACT_APP_IMAGE_URL + text.image.url}
                                             height={50}
                                        />
                                    </Tooltip>
                                    :
                                    null}
                            </TableCell>
                            <TableCell>
                                <Typography aria-multiline={true}>
                                    {text.explanation !== null ? text.explanation.content : ""}
                                </Typography>
                            </TableCell>
                        </TableRow>

                    })}
                </TableBody>
            </Table>
        </TableContainer>
        <Dialog open={dialogOpen != null} onClose={() => setDialogOpen(null)}>
            <img
                key={dialogOpen}
                src={dialogOpen}
                width={400}
                alt={"explanation"}/>
        </Dialog>
    </div>
}