import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { CloseOutlined } from "@material-ui/icons";
import React from "react";
import Env from "../../../../constants/Env";

export default function HistoryDialog( props ) {
    const [ historyList, setHistoryList ] = React.useState( [] )

    const [ loading, setLoading ] = React.useState( true )
    const [ isStarted, setStarted ] = React.useState( false )
    const [ error, setError ] = React.useState( false )

    function getHistory() {
        setError( false )

        let headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }
        fetch(
            process.env.REACT_APP_URL + 'projects/textLocalizations/histories?id=' +
            props.history.id, {
                method: 'get', headers: headers,
            }, )
            .then( response => {
                if ( response.ok ) {
                    return response.json()
                } else {
                    response.json()
                            .then( e => {
                                console.log( e )
                            } )
                    throw new Error( 'Something went wrong' );
                }
            } )
            .then( data => {
                setHistoryList( [ ...data.reverse() ] )

            } )
            .catch( ( error ) => {
                setError( true )
            } )
            .then( () => setLoading( false ) )
    }

    if ( !isStarted ) {
        getHistory()
        setStarted( true )
    }

    return <Dialog open={ props.isOpen }
                   fullWidth
                   maxWidth={ "xl" }
                   onClose={ () => props.setOpen( null ) }>
        { loading ? <Grid container style={ { alignItems: "center", display: "flex", justifyContent: "center", } }>
                <CircularProgress/> </Grid> :
            error ? <Grid container style={ { alignItems: "center", display: "flex", justifyContent: "center", } }>
                    <Grid item xs={ 12 } style={ { alignItems: "center", display: "flex", justifyContent: "center", } }>
                        <Typography>Something went wrong! Data can not be downloaded!</Typography>
                    </Grid>
                    <Grid item xs={ 12 } style={ { alignItems: "center", display: "flex", justifyContent: "center", } }>
                        <Button onClick={ () => getHistory() }>
                            <Typography>Retry</Typography>
                        </Button>
                    </Grid>
                </Grid> :
                <Grid container style={ {
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    justifyItems: "center",
                    alignItems: "center"
                } }>
                    <Grid container style={ {
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        justifyItems: "center",
                        alignItems: "center"
                    } }>
                        <Grid item xs style={ {
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            justifyItems: "center",
                            alignItems: "center"
                        } }>
                            <Typography className={ props.classes.dialogTitle }>
                                History
                                of { props.history.englishWord } in { props.languages[ props.languageIndex ].language.language }
                            </Typography>

                        </Grid>
                        <Grid item xs={ 1 }>
                            <Button onClick={
                                () => {
                                    props.setOpen( null )
                                }
                            }>
                                <CloseOutlined/>
                            </Button>
                        </Grid>
                    </Grid>
                    <DialogContent style={ {
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        justifyItems: "center",
                        alignItems: "center"
                    } }>
                        <Grid item xs={ 6 } style={ {
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            justifyItems: "center",
                            alignItems: "center",
                            maxHeight: "50vh"
                        } }>
                            <TableContainer>
                                <TableHead>
                                    <TableCell>
                                        Name-Surname
                                    </TableCell>
                                    <TableCell>
                                        Username
                                    </TableCell>
                                    <TableCell>
                                        Word Equivalent
                                    </TableCell>
                                    <TableCell>
                                        Version Number
                                    </TableCell>
                                    <TableCell>
                                        Date
                                    </TableCell>

                                </TableHead>
                                <TableBody>
                                    { historyList.map( el => <TableRow key={ 'tabpanel-history-' + el.versionNumber }>
                                        <TableCell>
                                            <Typography>
                                                { el.user.name + " " +
                                                el.user.surname }
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                { el.user.userName }
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                { el.equivalent }
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                { el.versionNumber }
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                { new Date(
                                                    ( el.updateDate ) ).toLocaleDateString() + " " }
                                                { new Date(
                                                    ( el.updateDate ) ).toLocaleTimeString() }
                                            </Typography>
                                        </TableCell>
                                    </TableRow> ) }
                                </TableBody>
                            </TableContainer>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                    </DialogActions>
                </Grid> }
    </Dialog>
}