class Env {
    /*
    static IMAGE_URL = "http://localhost:8080/localization-app/v1/images/"
         */


    static URL = "https://localifyserver.com/localization-app/v1/"
    //static URL = "http://localhost:8080/localization-app/v1/"
    static PUBLIC_ID = ""
    static TOKEN = ""
}

export default Env;