import React from "react";
import {Grid, Typography} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import ProjectShortcut from "../shortcutComponents/ProjectShortcut";
import PermissionShortCut from "../shortcutComponents/PermissionShortCut";
import InvitationShortCut from "../shortcutComponents/InvitationShortCut";
import TranslationOrderShortCut from "../shortcutComponents/TranslationOrderShortCut";

const useStyles = makeStyles(() => ({
    grid: {

        justifyContent: "space-between",
        borderRadius: 10,
    },
    gridChild: {
        borderWidth: 0,
        borderRadius: 10,
        margin: 4,
    },
    title: {
        fontWeight: "bold",
        fontSize: 32,
        color: "#808080"
    },
    centerGrid:{
        display:"flex",
        justifyContent:"center",
        paddingTop:300,
    }
}));

export default function UserShortCutsPage(props) {
    const classes = useStyles();
    const projects = [];

    /*
    props.user.permissions.forEach(permission => {
        let isExist = false;
        projects.forEach(project => {
            if (project.id === permission.project.id)
                isExist = true
        })
        if (!isExist && !checkItIsAlreadyInCompany(permission.project))
            projects.push(permission.project)
    })
*/
    props.user.roles.forEach(role => {
        let isExist = false;
        projects.forEach(project => {
            if (project.id === role.projectRole.project.id)
                isExist = true
        })
        if (!isExist && !checkItIsAlreadyInCompany(role.projectRole.project))
            projects.push(role.projectRole.project)
    })

    function checkItIsAlreadyInCompany(project) {
        let check = false
        if (props.company != null) {
            props.company.projects.forEach(pro => {
                if (pro.id === project.id)
                    check = true
            })
        }

        return check
    }

    return <div>
        {
            projects.length === 0
            && props.user.receivedInvitations.length === 0
            && props.user.translationOrders.length === 0
            && props.user.permissions.length === 0 ?
                <Grid item xs={12} className={classes.centerGrid}>
                    <Typography className={classes.title}>
                        {props.user.premium === "premium" ?
                            "There is nothing to show here..." :
                            "No invitations or projects right now"}
                    </Typography>
                </Grid>
                : null
        }
        <Grid className={classes.grid} container spacing={0}>
            {
                projects.length > 0 ?
                    <Grid item className={classes.gridChild} xs={12}>
                        <ProjectShortcut languages={props.languages}
                                         isCompanyPage={false}
                                         goToProjectsPage={props.goToProjectsPage}
                                         user={props.user}
                                         projects={projects}
                                         goToSpecificProjectPage={props.goToSpecificProjectPage}
                        />
                    </Grid>
                    : null
            }
            {props.user.receivedInvitations.length > 0 ?
                <Grid item className={classes.gridChild} xs={12}>
                    <InvitationShortCut isProject={false}
                                        functionList={props.functionList}
                                        isCompanyPage={false}
                                        user={props.user}
                                        invitations={props.user.receivedInvitations}
                    />
                </Grid>
                : null
            }
            {props.user.translationOrders.length > 0 ?
                <Grid item className={classes.gridChild} xs={12}>
                    <TranslationOrderShortCut
                        user={props.user}
                        isCompanyPage={false}
                        isProjects={false}
                        functionList={props.functionList}
                        translationOrders={props.user.translationOrders}
                    />
                </Grid>
                : null
            }
            {props.user.permissions.length > 0 ?
                <Grid item className={classes.gridChild} xs={12}>
                    <PermissionShortCut
                        auth={false}
                        user={props.user}
                        functionList={props.functionList}
                        goToSpecificProjectPage={props.goToSpecificProjectPage}
                        goToSpecificProjectTextPage={props.goToSpecificProjectTextPage}
                        isProjectPage={false}
                        isCompanyPage={false}
                        permissions={props.user.permissions}
                    />
                </Grid>
                : null
            }
        </Grid>
    </div>
}