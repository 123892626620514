import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {Stepper, Typography, Step, StepLabel, StepConnector} from '@material-ui/core';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import ThumbUpRoundedIcon from '@material-ui/icons/ThumbUpRounded';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import Colors from "../../../constants/Colors";

const ColorlibConnector = withStyles({
    alternativeLabel: {

        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
            Colors.gradientSecond,
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
            Colors.gradientSecond,
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
        Colors.gradientSecond,
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
        Colors.gradientSecond,
    },
});


function ColorLibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const {active, completed} = props;

    const icons = {
        1: <SendRoundedIcon/>,
        2: <GetAppRoundedIcon/>,
        3: <ThumbUpRoundedIcon/>,
        4: <AttachMoneyRoundedIcon/>,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}


const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: 'transparent',
    },
    text: {
        color: Colors.PRIMARY_BLUE,
        fontWeight: "bold",
        fontSize: 12,
        fontFamily: Colors.fontOfProgram,
    }

}));

function getSteps() {
    return ['Sent', 'Received', 'Checked', 'Paid'];
}


export default function ProjectPreviewStatusStepper(props) {
    const classes = useStyles();
    const activeOne = props.projectStatusInfo.paid ? 3 : props.projectStatusInfo.checked ? 2 :
        props.projectStatusInfo.received ? 1 : props.projectStatusInfo.sent ? 0 : -1;

    const steps = getSteps();

    return (
        <center className={classes.root}>
            <Stepper className={classes.root} alternativeLabel activeStep={activeOne} connector={<ColorlibConnector/>}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel
                            disabled={true}
                            /*
                            onClick={() => {
                                if (label === "Paid") {
                                    let url = ''
                                    if (props.projectStatusInfo.checked && !props.projectStatusInfo.paid) {
                                        url = process.env.REACT_APP_URL + 'projects/translationStatus/paid?id='
                                            + props.projectStatusInfo.id +
                                            "&paid=true"
                                    } else if (props.projectStatusInfo.paid) {
                                        url = process.env.REACT_APP_URL + 'projects/translationStatus/paid?id='
                                            + props.projectStatusInfo.id +
                                            "&paid=false"
                                    }

                                    if (props.projectStatusInfo.checked || props.projectStatusInfo.paid) {

                                        const headers = {
                                            "Content-Type": "application/json",
                                            'Authorization': 'Bearer ' + Env.TOKEN
                                        }

                                        fetch(url, {
                                            method: 'POST', headers: headers,
                                        },)
                                            .then(response => {
                                                if (response.ok) return response.json()
                                                else throw new Error();
                                            })
                                            .then(data => {
                                            })
                                            .then(() => props.functionList.getProgram())
                                            .catch(e => {
                                                console.log(e)
                                            })
                                    }

                                }
                            }}
                             */
                            StepIconComponent={ColorLibStepIcon}><Typography className={classes.text}>
                            {label}
                        </Typography>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </center>
    );
}
