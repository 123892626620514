import React from "react";
import {
    Link,
    useParams
} from "react-router-dom";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Colors from "../constants/Colors";
import {
    Button,
    Card,
    CircularProgress,
    Grid,
    Snackbar,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

const StyledTabs = withStyles({
    indicator: {
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: 'transparent',
        '& > span': {
            maxWidth: 50,
            width: '100%',
            backgroundColor: 'white',
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{children: <span/>}}/>);

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: 'white',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        fontFamily: 'poppins',
        '&:focus': {
            opacity: 1,
        },
    },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles({
    div: {
        background: Colors.PRIMARY_WHITE,
        display: "flex",
        minHeight: '100%',
        minWidth: '100%',
        position: "fixed",
        justifyContent: "center",
    },
    circularProgress: {
        padding: 200,
    },
    gridCenter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    text: {
        fontWeight: "bold",
        color: Colors.PRIMARY_BLUE,
        fontSize: 32,
    },
    paper: {
        display: "flex",
        justifyContent: "center",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 32,
    },
    textTitle: {
        fontFamily: 'poppins',
        fontSize: '200%',
        fontWeight: 'bold',
        color: Colors.PRIMARY_BLUE
    },
    button: {
        marginTop: 24,
        paddingBottom: 8,
        paddingTop: 8,
        paddingLeft: 50,
        paddingRight: 50,
        borderRadius: 16,
        borderWidth: 16,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        '&:hover': {
            background: Colors.PRIMARY_BLUE,
        },
    },
    buttonText: {
        color: 'white',
        fontSize: 12,
        fontWeight: 'bold',
        fontFamily: 'poppins',
    },
    textField: {
        width: '90%',
        fontSize: 48,
        fontWeight: 'bold',
        fontFamily: 'poppins',
        outline: 0,
        borderWidth: '0 0 2px',
        borderColor: '#496B66',
        '&:focus': {
            backgroundColor: 'red',
            borderColor: '#496B66',

        },
    },
    innerCard: {
        border: 0,
        maxWidth: 400,
        minHeight: 600,
        backgroundColor: Colors.PRIMARY_WHITE,
        borderRadius: 10,
    },
    tabView: {
        display: "flex",
        justifyContent: "center",
        background: Colors.gradient,
        fontFamily: 'poppins',
        fontWeight: 'bold',
        borderRadius: 10,
        paddingLeft: 40,
        paddingRight: 40,
    },

});

export default function ResetPasswordPage() {
    const classes = useStyles()
    const [submitted, setSubmitted] = React.useState(false)
    const [successful, setSuccessful] = React.useState(false)
    const [newPassword, setNewPassword] = React.useState("")
    const [newPasswordCopy, setNewPasswordCopy] = React.useState("")
    const [errorOpen, setErrorOpen] = React.useState('')
    const [successOpen, setSuccessOpen] = React.useState(false)

    let {token} = useParams()

    return <div className={classes.div}>
        <Grid container className={classes.gridCenter}>
            <Card className={classes.innerCard} variant="outlined">
                <div className={classes.tabView}>
                    <StyledTabs value={0} aria-label="styled tabs example">
                        <StyledTab label="RESET PASSWORD"/>
                    </StyledTabs>
                </div>
                <Grid container className={classes.paper}>
                    <Grid container className={classes.paper}>
                        <Grid container className={classes.paper}>
                            <TextField
                                disabled={successful}
                                value={newPassword}
                                onChange={(val) => setNewPassword(val.target.value)}
                                type="password"
                                placeholder="New Password"
                                className={classes.textField}/>
                        </Grid>

                        <Grid container className={classes.paper}>
                            <TextField
                                disabled={successful}
                                value={newPasswordCopy}
                                onChange={(val) => setNewPasswordCopy(val.target.value)}
                                type="password"
                                placeholder="Password Confirm"
                                className={classes.textField}/>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.paper}>
                        {submitted ?
                            <CircularProgress size={20}/>
                            :
                            !successful ? <Button
                                    onClick={() => {
                                        if (newPassword.length === 0 || newPasswordCopy.length === 0)
                                            setErrorOpen('Please fill all empty fields')
                                        else if (newPassword !== newPasswordCopy)
                                            setErrorOpen('Passwords are not same')
                                        else if (newPassword.length < 8)
                                            setErrorOpen('Password is too short(8 char)')
                                        else {
                                            setSubmitted(true)
                                            const headers = {
                                                "Content-Type": "application/json"
                                            }
                                            const returnObject = {
                                                "token": token,
                                                "newPassword": newPassword
                                            }

                                            fetch(process.env.REACT_APP_URL + "auth/resetPassword",
                                                {method: "POST", headers: headers, body: JSON.stringify(returnObject)})
                                                .then(response => {
                                                    if (response.ok && response.status === 200) {
                                                        response.json()
                                                            .then((data) => {
                                                                if (data.operationResult !== "ERROR") {
                                                                    setNewPassword("")
                                                                    setNewPasswordCopy("")
                                                                    setSuccessOpen(true)
                                                                    setSuccessful(true)
                                                                } else {
                                                                    setErrorOpen('Token is not valid')
                                                                }
                                                            })
                                                    } else {
                                                        setErrorOpen('Token is not valid')
                                                    }

                                                }).catch(e => setErrorOpen('Something went wrong ' + e))
                                            setSubmitted(false)

                                        }
                                    }}
                                    className={classes.button}>
                                    <Typography className={classes.buttonText}>
                                        Confirm
                                    </Typography>
                                </Button>
                                :
                                <Button
                                    className={classes.button}
                                >
                                    <Link to={"/"}>
                                        <Typography className={classes.buttonText}>
                                            Go To Login
                                        </Typography>
                                    </Link>
                                </Button>
                        }
                    </Grid>
                </Grid>
            </Card>
        </Grid>
        <Snackbar open={errorOpen.length > 0}
                  autoHideDuration={2000}
                  onClose={() => setErrorOpen('')}
        >
            <Alert onClose={() => setErrorOpen('')}
                   severity={"warning"}
            >
                {errorOpen}
            </Alert>
        </Snackbar>
        <Snackbar open={successOpen}
                  autoHideDuration={2000}
                  onClose={() => setSuccessOpen(false)}
        >
            <Alert onClose={() => setSuccessOpen(false)}
                   severity={"success"}
            >
                Password is changed.
            </Alert>
        </Snackbar>
    </div>
}