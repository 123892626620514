import React from "react";
import {
    Dialog,
    Grid,
    LinearProgress,
    makeStyles,
    Typography
} from "@material-ui/core";
import Colors from "../../constants/Colors";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {Close} from "@material-ui/icons";
import Env from "../../constants/Env";

const useStyles = makeStyles((theme) => ({
    dialog: {
        background: Colors.gradient,
    },
    formControl: {
        width: "100%",
        color: Colors.PRIMARY_PINK_UP,
        paddingBottom: 4,
    },
    title: {
        fontSize: 32,
        fontWeight: "bold",
        color: Colors.PRIMARY_BLUE,
    },
    grid: {
        alignItems: 'center',
        display: "flex",
        justifyContent: "space-between",
    },
    bodyText: {
        fontWeight: "bold",
        color: Colors.PRIMARY_BLUE,
        fontSize: 16,
    },
    bodyBottomText: {
        color: Colors.PRIMARY_PINK,
        fontWeight: "bold",
        fontSize: 20,
    },
    dialogContent: {
        padding: 28,
    },
    dialogAction: {
        display: "flex",
        justifyContent: "center",
        paddingBottom: 20,
    },
    buttonCreate: {
        width: 200,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_GREEN_LIGHT,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_YELLOW_LIGHT,
        }
    },
    icon: {
        fontSize: 32,
        color: Colors.PRIMARY_PINK_UP
    },
    buttonCancel: {
        width: 200,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_PINK,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_BLUE,
        }
    },
    buttonText: {
        fontWeight: "bold",
        color: Colors.PRIMARY_WHITE,
    },
    progressBar: {
        background: Colors.gradient,
        borderRadius: 10,
        height: 5,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
    }
}))


export default function InvitationResponseDialog(props) {
    const classes = useStyles();
    const [submitted, setSubmitted] = React.useState(false);

    return <Dialog fullWidth={true} maxWidth={'md'} open={props.isOpen} className={classes.dialog}
                   aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
            <Grid className={classes.grid} container>
                <Grid item xs={5}>
                    <Typography className={classes.title}>
                        Answer Invitation
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Button onClick={props.handleClose}>
                        <Close className={classes.icon}/>
                    </Button>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                <Grid className={classes.grid} container>
                    <Grid item>
                        <Typography className={classes.bodyText}>
                            From
                        </Typography>
                        <Typography className={classes.bodyBottomText}>
                            {props.invitation.sender.name + " " + props.invitation.sender.surname}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.bodyText}>
                            Project
                        </Typography>
                        <Typography className={classes.bodyBottomText}>
                            {props.invitation.projectRole.project.name}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.bodyText}>
                            Work Title
                        </Typography>
                        <Typography className={classes.bodyBottomText}>
                            {props.invitation.projectRole.role}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContentText>

        </DialogContent>
        <DialogActions className={classes.dialogAction}>
            <Button onClick={() => {
                setSubmitted(true)
                const headers = {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + Env.TOKEN
                }
                fetch(process.env.REACT_APP_URL + 'projects/roles?invitationId=' + props.invitation.id, {
                    method: 'POST', headers: headers,
                },)
                    .then(response => response.json()).then(data => {
                    console.log(data);
                    setSubmitted(false)

                    props.handleClose();
                }).then(() => props.functionList.getProgram()).catch(e=>console.log(e))

            }} className={classes.buttonCreate} color="primary">
                <Typography
                    className={classes.buttonText}>
                    Accept
                </Typography>
            </Button>
            <Button
                onClick={() => {
                    setSubmitted(true)
                    const headers = {
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + Env.TOKEN
                    }
                    fetch(process.env.REACT_APP_URL + 'projects/invitations?invitationId=' + props.invitation.id, {
                        method: 'DELETE', headers: headers,
                    },)
                        .then(response => response.json()).then(data => {
                        console.log(data.message);
                        setSubmitted(false)
                        props.handleClose();
                    }).then(() => props.functionList.getProgram()).catch(e=>console.log(e))
                }}
                className={classes.buttonCancel}
                color="primary">
                <Typography
                    className={classes.buttonText}>
                    Decline
                </Typography>
            </Button>
        </DialogActions>
        {submitted ? <LinearProgress className={classes.progressBar} color={'secondary'}/> : <div/>}
    </Dialog>
}