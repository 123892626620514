import React from "react";
import {
    Paper,
    Typography,
    Divider,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions, ButtonGroup, TextField, Snackbar, Tooltip
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../constants/Colors";
import {
    AddAlarm,
} from "@material-ui/icons";
import Env from "../../constants/Env";
import { Alert } from "@material-ui/lab";
import PermissionLine from "./PermissionLine";

const useStyles = makeStyles( () => ( {
    card: {
        padding: 20,
        display: 'flex',
        height: '100%',
        borderRadius: 10,
        alignItems: "flex-start",
        color: Colors.PRIMARY_PINK_ALT,
        backgroundColor: Colors.PRIMARY_WHITE,
        '&:hover': {
            color: Colors.PRIMARY_PINK_UP,
            opacity: 1,
        },
    },
    buttonDialog: {
        width: 200,
        padding: 10,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_BLUE,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_BLUE,
            color: Colors.PRIMARY_WHITE,
        }
    },
    deleteButton: {
        width: 200,
        padding: 10,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: 'darkred',
        "&:hover": {
            backgroundColor: 'darkred',
            color: Colors.PRIMARY_WHITE,
        }
    },
    buttonText: {
        fontWeight: "bold",
    },
    buttonGrid: {
        display: "flex",
        justifyContent: "center",
        borderRadius: 10,
        paddingTop: 1,
        margin: 0,
    },
    title: {
        paddingLeft: 12,
        display: 'flex',
        fontSize: 20,
        fontWeight: "bold"
    },
    divider: {
        height: 2,
        borderRadius: 50,
        minWidth: 200,
        marginTop: 4,
        marginBottom: 4,
        background: Colors.gradient,
        width: '101%'
    },
    text: {
        color: Colors.PRIMARY_GREEN_LIGHT,
        display: 'flex',
        fontSize: 16,
        fontWeight: "bold"
    },
    textPassive: {
        color: Colors.PRIMARY_PINK_ALT,
        display: 'flex',
        fontSize: 16,
        fontWeight: "bold"
    },
    cardBody: {
        paddingLeft: 16,
        paddingRight: 16,
        justifyContent: 'space-between',
        display: 'flex',
        padding: 10,
        color: Colors.PRIMARY_BLUE,
    },
    lineContainer: {
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        paddingTop: 12,
        color: Colors.PRIMARY_BLUE,
    },
    cardBodyChild: {
        paddingRight: 40,
        justifyContent: 'flex-end',
        display: 'flex',
        color: Colors.PRIMARY_BLUE,
    },
    notificationIcon: {
        color: Colors.PRIMARY_GREEN_LIGHT,
        fontSize: 24,
    },
    notificationIconActive: {
        color: Colors.PRIMARY_PINK_UP,
        fontSize: 24,
    },
    button: {
        padding: 16,
    },
    topButton: {
        padding: 0,
    },
    topButtonIcon: {
        color: Colors.PRIMARY_BLUE,
        fontSize: 28,
    },
    iconPassive: {
        color: Colors.PRIMARY_PINK_ALT,
    },
    buttonLabel: {
        backgroundColor: Colors.PRIMARY_WHITE,
        borderRadius: 10,
        padding: 4,
    },
    icon: {
        color: Colors.PRIMARY_GREEN_LIGHT,
    },
    textName: {
        marginLeft: 24,
        display: "flex",
        fontSize: 20,
        fontWeight: "bold",
    },
    titleText: {
        color: Colors.PRIMARY_WHITE,
        display: 'flex',
        fontSize: 16,
        fontWeight: "bold"
    },
    titleCard: {
        background: Colors.gradientSecond,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: Colors.PRIMARY_BLUE,
        display: "flex",
        alignItems: "center",
        alignContent: 'center',
        paddingLeft: 16,
        paddingRight: 16,
        height: 60,
        color: Colors.PRIMARY_WHITE,
    },
    grid: {
        display: "flex",
        justifyContent: "space-between",
    },
    dialogCenter: {
        display: "flex",
        justifyContent: "center",
        paddingBottom: 20,

    },
    dialogTitle: {
        fontSize: 36,
        color: Colors.PRIMARY_BLUE,
        fontWeight: "bold",
        paddingTop: 20,
    }

} ) );

export default function PermissionShortCut( props ) {
    const classes = useStyles();
    const [ open, setOpen ] = React.useState( false );
    const [ permissionDateDialog, setPermissionDateDialog ] = React.useState( null )
    const [ constantPermissionList, setConstantPermissionList ] = React.useState( [] )
    const [ date, setDate ] = React.useState( null )
    const [ successOpen, setSuccessOpen ] = React.useState( false )
    const [ errorOpen, setErrorOpen ] = React.useState( false )
    const permissionList = [];

    if ( props.isCompanyPage ) {
        props.projects.forEach( project => {
            project.permissions.forEach( permission => {
                permission.projectId = project.id;
                permission.projectName = project.name;
                permissionList.push( permission )

            } )
        } )

    } else {
        permissionList.push( ...props.permissions )
    }

    if ( permissionList.length !== constantPermissionList.length ) {
        setConstantPermissionList( [ ...permissionList ] )
    }
    return <Paper onMouseOver={ () => {
        setOpen( true )
    } } onMouseOut={ () => {
        setOpen( false )
    } } elevation={ open ? 2 : 0 } className={ classes.card }>
        <Grid container spacing={ 1 }>
            <Grid container className={ classes.grid }>
                <Grid key={ "permissionTitle" } item xs={ 8 }>
                    <Typography className={ classes.title }>
                        Permissions
                    </Typography>
                </Grid>
            </Grid>
            <Grid key={ "permissionDivider" } item xs={ 12 }>
                <Divider className={ classes.divider }>
                </Divider>
            </Grid>
            <Grid container className={ classes.titleCard }>
                <Grid key={ "permissionUser" } item xs>
                    <Typography className={ classes.titleText }>
                        User
                    </Typography>
                </Grid>
                <Grid key={ "permissionProjectName" } item xs>
                    <Typography className={ classes.titleText }>
                        Project Name
                    </Typography>
                </Grid>
                <Grid key={ "permissionRole" } item xs>
                    <Typography className={ classes.titleText }>
                        Category
                    </Typography>
                </Grid>
                <Grid key={ "permissionLanguage" } item xs>
                    <Typography className={ classes.titleText }>
                        Language
                    </Typography>
                </Grid>
                <Grid key={ "permissionExpirationDateTime" } item xs={ 2 }>
                    <Typography className={ classes.titleText }>
                        Deadline
                    </Typography>
                </Grid>
                <Grid key={ "permissionActiveness" } item xs>
                    <Typography className={ classes.titleText }>
                        Status
                    </Typography>
                </Grid>
                <Grid key={ "permissionButton" } item xs>
                    <Button disabled={ true } className={ classes.button }>

                    </Button>
                </Grid>

            </Grid>
            <Grid container className={ classes.cardBody }>
                { constantPermissionList.map( permission => {

                    return <PermissionLine
                        setDate={ ( val ) => setDate( val ) }
                        user={ props.user }
                        permission={ permission }
                        classes={ classes }
                        isProjectPage={ props.isProjectPage }
                        setPermissionDateDialog={ ( val ) => setPermissionDateDialog( val ) }
                    />
                } ) }
            </Grid>
        </Grid>
        { permissionDateDialog != null ?
            <Dialog
                fullWidth
                maxWidth={ "sm" }
                open={ true }
                onClose={ () => setPermissionDateDialog( null ) }>
                <DialogTitle>
                    <Typography className={ classes.dialogTitle }>
                        Permission Settings
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth={ true }
                        id="datetime-local1"
                        label="Deadline Date-Time"

                        type="datetime-local"
                        value={ date }
                        InputLabelProps={ {
                            shrink: true,
                        } }
                        onChange={ ( value ) => {
                            setDate( value.target.value.toString() )
                        } }
                    />
                </DialogContent>
                <DialogActions className={ classes.dialogCenter }>
                    <Paper elevation={ 10 } className={ classes.buttonGrid }>
                        <ButtonGroup>
                            <label className={ classes.buttonLabel }>
                                <Button
                                    onClick={ () => {
                                        let requestBody = date.toString() + ":00.000+00:00"

                                        const headers = {
                                            "Content-Type": "application/json",
                                            'Authorization': 'Bearer ' + Env.TOKEN
                                        }

                                        fetch( process.env.REACT_APP_URL + 'projects/permissions/date?permissionId=' +
                                            permissionDateDialog.id, {
                                            method: 'POST', headers: headers, body: JSON.stringify( requestBody )
                                        }, )
                                            .then( response => {
                                                if ( response.ok ) return response.json()
                                                else throw new Error();
                                            } )
                                            .then( data => {
                                                let tempList = []

                                                constantPermissionList.forEach( perm => {
                                                    if ( perm.id !== permissionDateDialog.id ) {
                                                        tempList.push( perm )
                                                    } else {
                                                        perm.expiration = requestBody
                                                        tempList.push( perm )
                                                    }
                                                } )

                                                setConstantPermissionList( tempList )
                                            } )
                                            .then( () => setSuccessOpen( true ) )
                                            .then( () => setPermissionDateDialog( null ) )
                                            .catch( () => {
                                                setErrorOpen( true )
                                            } )
                                    } }


                                    disabled={ !props.auth }
                                    className={ classes.buttonDialog }>
                                    <Typography className={ classes.buttonText }>
                                        Update
                                    </Typography>
                                </Button>
                            </label>
                            <label className={ classes.buttonLabel }>
                                <Button onClick={ () => setPermissionDateDialog( null ) }
                                        className={ classes.buttonDialog }>
                                    <Typography className={ classes.buttonText }>
                                        Cancel
                                    </Typography>
                                </Button>
                            </label>
                        </ButtonGroup>
                    </Paper>
                </DialogActions>
            </Dialog>
            : null }
        <Snackbar open={ errorOpen }
                  autoHideDuration={ 6000 }
                  onClose={ () => setErrorOpen( false ) }
        >
            <Alert onClose={ () => setErrorOpen( false ) }
                   severity={ "warning" }
            >
                Something went wrong, try it later.
            </Alert>
        </Snackbar>
        <Snackbar open={ successOpen }
                  autoHideDuration={ 1000 }
                  onClose={ () => setSuccessOpen( false ) }
        >
            <Alert onClose={ () => setSuccessOpen( false ) }
                   severity={ "success" }
            >
                Operations are completed successfully.
            </Alert>
        </Snackbar>
    </Paper>;
}