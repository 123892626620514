import React from "react";
import {Dialog} from "@material-ui/core";
import TranslationTaskPage from "../translationTaskScreens/TranslationTaskPage";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: 100,
        marginBottom: 100,
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
    },
}));

export default function TranslationOrderPreviewDialog(props) {
    const classes = useStyles();

    return <Dialog open={props.isOpen}
                   className={classes.paper}
                   fullWidth maxWidth={"xl"}
                   onClose={() => props.onClose()}>
        <div style={{paddingTop:40}}>
            {props.order != null ? <TranslationTaskPage isPreview={true} order={props.order}/> : null}
        </div>
    </Dialog>
}