import { Button, Grid, Switch, Tooltip, Typography } from "@material-ui/core";
import Env from "../../constants/Env";
import { LockOpen, RemoveCircleOutline } from "@material-ui/icons";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../constants/Colors";

const useStyles = makeStyles( () => ( {
    text: {
        color: Colors.PRIMARY_BLUE,
        display: 'flex',
        fontSize: 16,
        justifyContent: 'start',
        fontWeight: "bold"
    },
    lineContainer: {
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        paddingLeft: 24,
        paddingTop: 4,
        color: Colors.PRIMARY_BLUE,
    }, icon: {
        fontSize: 32,
        color: Colors.PRIMARY_GREEN_LIGHT,
    },
    iconRemove: {
        fontSize: 32,
        color: Colors.PRIMARY_PINK_ALT,
    },
} ) )

export default function CategoryLine( props ) {
    const classes = useStyles();
    const [ checkedStatus, setCheckedStatus ] = React.useState( false )

    if ( checkedStatus !== props.category.hasAndroidKey ) {
        setCheckedStatus( props.category.hasAndroidKey )
    }

    return <Grid className={ classes.lineContainer } container>
        <Grid item xs={ 5 }>
            <Typography
                className={ classes.text }
            >
                { props.category.categoryName.charAt( 0 )
                       .toUpperCase() +
                props.category.categoryName.toLowerCase()
                     .slice( 1 ) }
            </Typography>
        </Grid>
        {
            /*
        props.auth ?
            <Grid className={classes.text} item xs={2}>
                <Button onClick={() => onHandleTextAdditionOpen(category)}>
                    <Tooltip title={"Add new lines to translation subjects"}>
                        <AddCircleOutlineIcon
                            className={classes.icon}
                        />
                    </Tooltip>
                </Button>
            </Grid>
            :
            null
        */
        }
        { props.auth ?
            <Grid className={ classes.text } item xs={ 4 }>
                <Tooltip title={ "Open/Close whether category has an android key or not" }>
                    <Switch
                        checked={ checkedStatus }
                        onChange={ ( event, checked ) => {
                            props.category.hasAndroidKey = checked;
                            setCheckedStatus( checked )
                            fetch(
                                process.env.REACT_APP_URL + 'projects/categories/android?categoryId=' +
                                props.category.id +
                                "&status=" + checked.toString(), {
                                    method: 'POST', headers: {
                                        "Content-Type": "application/json",
                                        'Authorization': 'Bearer ' + Env.TOKEN
                                    }
                                }, )
                                .then( response => {
                                    if ( response.ok ) return response.json()
                                    else response.json()
                                                 .then( e => {
                                                     //console.log(e)
                                                 } );
                                } )
                                .catch( e => {
                                    props.category.hasAndroidKey = !checked;
                                    setCheckedStatus( !checked )
                                } )
                        } }
                    />
                </Tooltip>
            </Grid>
            :
            null
        }
        {
            /*
            props.auth ?
            <Grid item xs={ 3 }>
                <Tooltip title={ "Give editor permission to user in projects" }>
                    <Button onClick={ () => props.setPermissionCreationDialog( props.category ) }>
                        <LockOpen className={ classes.icon }/>
                    </Button>
                </Tooltip>
            </Grid>
            : null
             */
        }
        { props.auth ?
            <Grid item xs={ 3 }>
                <Tooltip title={ "Remove this subject from the project." }>
                    <Button onClick={ () => {
                        props.setRemoveSubjectDialog( props.category )
                    } }>
                        <RemoveCircleOutline className={ classes.iconRemove }/>
                    </Button>
                </Tooltip>
            </Grid>
            : null
        }
    </Grid>
}