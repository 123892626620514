import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {Card, Tabs, Tab} from '@material-ui/core';
import SignIn from './signIn/SignIn';
import SingUp from './singUp/signUp';
import Colors from "../constants/Colors";

const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            maxWidth: 50,
            width: '100%',
            backgroundColor: 'white',
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{children: <span/>}}/>);

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: 'white',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        fontFamily: 'poppins',
        '&:focus': {
            opacity: 1,
        },
    },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles({
    innerCard: {
        border: 0,
        margin: 100,
        padding: 40,
        maxWidth: 400,
        minHeight: 600,
        backgroundColor: Colors.PRIMARY_WHITE,
        borderRadius: 10,
    },
    root: {
        flexGrow: 1,
    },
    padding: {
        padding: 3,
    },
    tabView: {
        display: "flex",
        justifyContent: "center",
        background: Colors.gradient,
        fontFamily: 'poppins',
        fontWeight: 'bold',
        borderRadius: 10,
    },
});

const InitialPage = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return <Card className={classes.innerCard} variant="outlined">
            <div className={classes.tabView}>
                <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example">
                    <StyledTab label="SIGN IN"/>
                    <StyledTab label="SIGN UP"/>
                </StyledTabs>
            </div>
            <br/>
            {value === 0 ?
                <SignIn
                    functionList={props.functionList}
                />
                : <SingUp
                    functionList={props.functionList}
                />
            }
        </Card>

}

export default InitialPage;