import React from "react";
import {
    Button,
    ButtonGroup, Card,
    Dialog,
    Grid, InputBase,
    makeStyles,
    Paper,
    Snackbar,
    Typography
} from "@material-ui/core";
import Colors from "../../../../constants/Colors";
import { Alert } from "@material-ui/lab";
import ExplanationUploadDialog from "../productDetailScreensDialogComponents/ExplanationUploadDialog";

const useStyles = makeStyles( () => ( {
    paper: {
        width: "100%",
        height: "100%",
        borderRadius: 10,
        padding: 20,
    },
    titleText: {
        color: Colors.PRIMARY_BLUE,
        fontSize: 28,
        fontFamily: Colors.fontOfProgram,
        paddingBottom: 20,
        fontWeight: "bold",
    },
    input: {
        display: "none",
    },
    explanationPaper: {
        marginRight: 12,
        alignItems: "start",
        alignContent: "center",
        padding: 8,
        width: 328,
        maxWidth: 328,
        height: 328,
        maxHeight: 328,
        display: "inline-flex",
    },
    explanationGrid: {
        alignContent: "center",
        alignItems: "center",
        padding: 4,
        position: "relative",
        maxWidth: 1400,
        overflowX: "auto",
        whiteSpace: "nowrap",
    },
    button: {
        width: 200,
        padding: 10,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_BLUE,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_BLUE,
            color: Colors.PRIMARY_WHITE,
        }
    },
    buttonText: {
        fontWeight: "bold",
    },
    buttonGrid: {
        display: "flex",
        justifyContent: "center",
        borderRadius: 10,
        paddingTop: 0,
        margin: 0,
    },
    buttonGridTop: {
        display: "flex",
        justifyContent: "center",
        borderRadius: 10,
        paddingTop: 30,
        margin: 0,
    },
    dialogImage: {
        maxHeight: 900,
        maxWidth: 1200
    },
    seeAllGrid: {
        padding: 20,
        display: "flex",
        justifyContent: "center",
    },
    seeAllExp: {
        margin: 4,
        padding: 8,
        width: 300,
        cursor: "pointer",
        display: "inline",
    },
    buttonLabel: {
        backgroundColor: Colors.PRIMARY_WHITE,
        borderRadius: 10,
        padding: 4,
    }

} ) )

export default function ProjectExplanationSettings( props ) {
    const [ imageDialog, setImageDialog ] = React.useState( null )
    const [ successOpen, setSuccessOpen ] = React.useState( false )
    const [ errorOpen, setErrorOpen ] = React.useState( false )
    const [ explanationUploadDialog, setExplanationUploadDialog ] = React.useState( false )
    const [ seeAll, setSeeAll ] = React.useState( false )
    const [ explanationList, setExplanationList ] = React.useState( [] )
    const classes = useStyles()

    if ( props.project.explanations.length !== explanationList.length ) {
        setExplanationList( [ ...props.project.explanations ] )
    }

    function handleExplanationAddition( responseList ) {
        let tempList = [ ...explanationList, ...responseList ]
        props.project.explanations = tempList
        setExplanationList( tempList )
    }

    return <Paper
        elevation={ 20 }
        className={ classes.paper }>
        <Grid container>
            <Grid item>
                <Typography className={ classes.titleText }>
                    Descriptions
                </Typography>
            </Grid>
            <Grid container>
                <Paper elevation={ 0 } className={ classes.explanationGrid }>
                    { explanationList.map( explanation => {
                        return <Paper className={ classes.explanationPaper }>
                            <InputBase
                                fullWidth={ true }
                                multiline={ true }
                                value={ explanation.content }
                                contentEditable={ false }
                            />
                        </Paper>
                    } ) }
                    { explanationList.length === 0 ?
                        <Paper elevation={ 0 } className={ classes.explanationPaper }>
                            <InputBase
                                fullWidth={ true }
                                multiline={ true }
                                value={ "" }
                                contentEditable={ false }
                            />
                        </Paper> : null }
                </Paper>
            </Grid>
            <Grid container className={ classes.buttonGridTop }>
                <Paper elevation={ 10 } className={ classes.buttonGrid }>
                    <ButtonGroup>

                        <label className={ classes.buttonLabel }>
                            <Button
                                disabled={ !props.auth }
                                onClick={ () => setExplanationUploadDialog( true ) }
                                className={ classes.button }>
                                <Typography className={ classes.buttonText }>
                                    Add
                                </Typography>
                            </Button>
                        </label>
                        <label className={ classes.buttonLabel }>
                            <Button
                                disabled={ props.project.explanations.length === 0 }
                                onClick={ () => setSeeAll( true ) } className={ classes.button }>
                                <Typography className={ classes.buttonText }>
                                    See All
                                </Typography>
                            </Button>
                        </label>
                    </ButtonGroup>
                </Paper>
            </Grid>
        </Grid>
        { imageDialog !== null ? <Dialog open={ true } onClose={ () => setImageDialog( null ) }>
            <img
                src={ process.env.REACT_APP_IMAGE_URL + imageDialog.url } alt="project's uploaded img"
                className={ classes.dialogImage }
            />
        </Dialog> : null }
        <Dialog fullWidth={ true }
                maxWidth={ "lg" }
                open={ seeAll }
                onClose={ () => setSeeAll( false ) }>
            <Grid container className={ classes.seeAllGrid }>
                { props.project.explanations.map( explanation => {
                    return <Card className={ classes.seeAllExp }>
                        { explanation.content }
                    </Card>
                } ) }
            </Grid>
        </Dialog>
        <ExplanationUploadDialog
            handleExplanationAddition={(val)=>handleExplanationAddition(val)}
            isOpen={ explanationUploadDialog }
            onClose={ () => setExplanationUploadDialog( false ) }
            project={ props.project }
            functionList={ props.functionList }
        />
        <Snackbar open={ errorOpen }
                  autoHideDuration={ 6000 }
                  onClose={ () => setErrorOpen( false ) }
        >
            <Alert onClose={ () => setErrorOpen( false ) }
                   severity={ "warning" }
            >
                Something went wrong, try it later.
            </Alert>
        </Snackbar>
        <Snackbar open={ successOpen }
                  autoHideDuration={ 1000 }
                  onClose={ () => setSuccessOpen( false ) }
        >
            <Alert onClose={ () => setSuccessOpen( false ) }
                   severity={ "success" }
            >
                Images are uploaded successfully.
            </Alert>
        </Snackbar>
    </Paper>
}