import React from "react";
import ProjectPreviewCard from "../projectScreensComponents/ProjectPreviewCard";
import { Grid } from "@material-ui/core";
import RoleShortCut from "../../shortcutComponents/RoleShortCut";
import CategoryShortCut from "../../shortcutComponents/CategoryShortCut";
import InvitationShortCut from "../../shortcutComponents/InvitationShortCut";
import TranslationOrderShortCut from "../../shortcutComponents/TranslationOrderShortCut";
import PermissionShortCut from "../../shortcutComponents/PermissionShortCut";

export default function ProjectStatus( props ) {
    let auth = false;
    // console.log(props.project)
    const [ projectId, setProjectId ] = React.useState( null )
    const [ invitations, setInvitations ] = React.useState( [] )

    if ( projectId === null || projectId !== props.project.id ) {
        setProjectId( props.project.id )
        setInvitations( [ ...props.project.invitations ] )
    }

    if ( ( props.isCompanyProject && props.project.company.user.userName === props.user.userName ) ||
        checkUserRoleAuth() )
        auth = true;


    function checkUserRoleAuth() {
        let check = false
        props.user.roles.forEach( roleDesp => {
            if ( props.project.id === roleDesp.projectRole.project.id ) {
                roleDesp.projectRole.project.roles.forEach( role => {
                    if ( role.admin ) {
                        role.roleList.forEach( p => {
                            if ( p.user.userName === props.user.userName )
                                check = true
                        } )
                    }
                } )
            }

        } )

        return check
    }

    function handleInvitationAddition( invitation ) {
        let temp = [ ...invitations ]
        temp.push( invitation )
        setInvitations( temp )
    }

    return <div>
        <br/>

        <Grid spacing={ 1 } container>
            { auth ? <Grid item xs={ 12 }>
                <TranslationOrderShortCut
                    user={ props.user }
                    auth={ auth }
                    functionList={ props.functionList }
                    isCompanyPage={ false }
                    isProjects={ true }
                    translationOrders={ props.project.translationOrders }
                    project={ props.project }
                    program={ props.program }
                />
            </Grid> : null }
            {
                /*
                <Grid item xs={ 12 }>
                <ProjectPreviewCard
                    functionList={ props.functionList }
                    projectInfo={ props.project }
                    index={ 0 }
                    auth={ auth }
                />
            </Grid>
                 */
            }
            <Grid item xs={ auth ? 4 : 6 }>
                <CategoryShortCut
                    auth={ auth }
                    program={ props.program }
                    user={ props.user }
                    functionList={ props.functionList }
                    project={ props.project }
                />
            </Grid>
            <Grid item xs={ auth ? 4 : 6 }>
                <RoleShortCut
                    handleInvitationAddition={ ( val ) => handleInvitationAddition( val ) }
                    program={ props.program }
                    user={ props.user }
                    functionList={ props.functionList }
                    project={ props.project }
                    auth={ auth }
                />
            </Grid>
            { auth ? <Grid item xs>
                <InvitationShortCut
                    auth={ auth }
                    functionList={ props.functionList }
                    program={ props.program }
                    isProject={ true }
                    isCompanyPage={ false }
                    invitations={ invitations }
                    setInvitations={ ( val ) => setInvitations( val ) }
                />
            </Grid> : null }

            { /*
            auth ? <Grid item xs={ 12 }>
                <PermissionShortCut
                    functionList={ props.functionList }
                    auth={ auth }
                    user={ props.user }
                    project={ props.project }
                    isProjectPage={ true }
                    isCompanyPage={ false }
                    permissions={ props.project.permissions }
                />
            </Grid> : null
             */}
        </Grid>
    </div>
}