import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../constants/Colors";
import {
    Button,
    Divider,
    Grid,
    Paper,
    Snackbar, Tooltip,
    Typography
} from "@material-ui/core";
import RoleAdditionDialog
    from "../projectScreens/projectDetailScreens/productDetailScreensDialogComponents/RoleAdditionDialog";
import PersonAdditionDialog
    from "../projectScreens/projectDetailScreens/productDetailScreensDialogComponents/PersonAdditionDialog";
import RoleSettingDialog
    from "../projectScreens/projectDetailScreens/productDetailScreensDialogComponents/RoleSettingDialog";
import { AddBoxOutlined, PersonAdd, Settings } from "@material-ui/icons";
import Env from "../../constants/Env";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles( () => ( {
    card: {
        padding: 20,
        display: 'flex',
        height: '100%',
        borderRadius: 10,
        alignItems: "flex-start",
        color: Colors.PRIMARY_PINK_ALT,
        backgroundColor: Colors.PRIMARY_WHITE,
        '&:hover': {
            color: Colors.PRIMARY_PINK_UP,
            opacity: 1,
        },
    },
    title: {
        paddingLeft: 12,
        display: 'flex',
        justifyContent: 'start',
        fontSize: 20,
        fontWeight: "bold"
    },
    divider: {
        height: 2,
        borderRadius: 50,
        minWidth: 201,
        marginTop: 4,
        marginBottom: 4,
        background: Colors.gradient,
        width: '100%'
    },
    text: {
        color: Colors.PRIMARY_BLUE,
        display: 'flex',
        fontSize: 16,
        justifyContent: 'start',
        fontWeight: "bold"
    },
    textSubmitted: {
        color: Colors.PRIMARY_GREEN_LIGHT,
        display: 'flex',
        fontSize: 16,
        fontWeight: "bold"
    },
    textPassive: {
        color: Colors.PRIMARY_PINK_ALT,
        display: 'flex',
        fontSize: 16,
        fontWeight: "bold"
    },
    cardBody: {
        paddingLeft: 16,
        paddingRight: 16,
        justifyContent: 'space-between',
        display: 'flex',
        padding: 10,
        color: Colors.PRIMARY_BLUE,
    },
    lineContainer: {
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        paddingLeft: 24,
        paddingTop: 4,
        color: Colors.PRIMARY_BLUE,
    },
    cardBodyChild: {
        paddingRight: 40,
        justifyContent: 'flex-end',
        display: 'flex',
        color: Colors.PRIMARY_BLUE,
    },
    roleAdditionIcon: {
        color: Colors.PRIMARY_GREEN_LIGHT,
        fontSize: 32,
    },
    button: {
        justifyContent: 'center',

        padding: 0,
    },
    icon: {
        fontSize: 32,
        color: Colors.PRIMARY_GREEN_LIGHT,
    },
    textName: {
        marginLeft: 24,
        display: "flex",
        fontSize: 20,
        fontWeight: "bold",
    },
    titleText: {
        justifyContent: 'start',
        color: Colors.PRIMARY_WHITE,
        display: 'flex',
        fontSize: 16,
        fontWeight: "bold"
    },
    titleCard: {
        background: Colors.gradientSecond,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: Colors.PRIMARY_BLUE,
        alignContent: 'center',
        alignItems: 'center',
        paddingLeft: 32,
        paddingRight: 16,
        justifyContent: 'space-between',
        display: 'flex',
        height: 60,
        color: Colors.PRIMARY_WHITE,
    },
} ) );


export default function RoleShortCut( props ) {
    const classes = useStyles();
    const [ open, setOpen ] = React.useState( false );
    const [ roleAdditionOpen, setRoleAdditionOpen ] = React.useState( false )
    const [ workTitles, setWorkTitles ] = React.useState( [] )
    const [ personAdditionOpen, setPersonAdditionOpen ] = React.useState( false )
    const [ currentRole, setCurrentRole ] = React.useState( {} )
    const [ roleSettingOpen, setRoleSettingOpen ] = React.useState( -1 )
    const [ successOpen, setSuccessOpen ] = React.useState( false )
    const [ errorOpen, setErrorOpen ] = React.useState( false )

    if ( props.project.roles.length !== workTitles.length ) {
        setWorkTitles( props.project.roles )
    }

    const handleErrorClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }

        setErrorOpen( false );
    };

    const handleSuccessClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }
        setSuccessOpen( false );
    };

    function onHandleRoleAdditionClose() {
        setRoleAdditionOpen( false )
    }

    function onHandleRoleAdditionOpen() {
        setRoleAdditionOpen( true )
    }

    function onHandlePersonAdditionClose() {
        setPersonAdditionOpen( false )
    }

    function onHandlePersonAdditionOpen() {
        setPersonAdditionOpen( true )
    }

    function handleTitleAdminChange( titleId, status ) {
        let tempList = []

        props.project.roles.forEach( tit => {
            if ( tit.id === titleId ) {
                tit.admin = status
                tempList.push( tit )
            } else {
                tempList.push( tit )
            }
        } )

        props.project.roles = tempList
        setWorkTitles( tempList )
    }

    function handleAndroidKeyChange( titleId, status ) {
        let tempList = []

        props.project.roles.forEach( tit => {
            if ( tit.id === titleId ) {
                tit.authAndroid = status
                tempList.push( tit )
            } else {
                tempList.push( tit )
            }
        } )

        props.project.roles = tempList
        setWorkTitles( tempList )
    }

    function handlePersonInvitation( personResponse ) {
        let tempList = [ ...props.project.invitations, personResponse ]
        props.project.invitations = tempList
        props.handleInvitationAddition( personResponse )
    }


    function handleTitleDeletion( titleId ) {
        let tempList = []
        let tempTitle

        workTitles.forEach( tit => {
            if ( tit.id === titleId ) {
                tempTitle = tit
            } else {
                tempList.push( tit )
            }
        } )

        props.project.roles = tempList
        setWorkTitles( tempList )

        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }

        fetch( process.env.REACT_APP_URL + 'projects/projectRoles?projectRoleId=' + titleId, {
            method: 'DELETE', headers: headers,
        }, )
            .then( response => {
                if ( response.ok ) {
                    return response.json()
                } else {
                    throw new Error()
                }
            } )
            .catch( ( error ) => {
                props.project.roles = [ ...tempList, tempTitle ]
                setWorkTitles( [ ...tempList, tempTitle ] )
                setErrorOpen( true )
                //console.log(error)
            } )
    }

    function handleUserDeletionFromTitle( userId ) {
        let currentWorkTitle

        workTitles.forEach( title => {
            console.log( title )
            title.roleList.forEach( role => {
                if ( role.id === userId ) {
                    currentWorkTitle = { ...title }

                    let tempCurrent = []
                    currentWorkTitle.roleList.forEach( rol => {
                        if ( rol.id !== userId ) {
                            tempCurrent.push( rol )
                        }
                    } )

                    currentWorkTitle.roleList = tempCurrent
                }
            } )
        } )

        let tempList = []
        console.log( currentWorkTitle )
        workTitles.forEach( title => {
            if ( title.id !== currentWorkTitle.id ) {
                tempList.push( title )
            } else {
                tempList.push( currentWorkTitle )
            }
        } )
        props.project.roles = tempList
        setWorkTitles( tempList )
    }

    function handlePersonDeletion( roleId ) {

        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }

        fetch( process.env.REACT_APP_URL + 'projects/roles?roleId=' + roleId, {
            method: 'DELETE', headers: headers,
        }, )
            .then( response => {
                if ( response.ok ) {
                    return response.json()
                } else {
                    return response.json()
                                   .then( e => {
                                       //console.log(e)
                                   } );
                }
            } )
            .then(
                () => {
                    handleUserDeletionFromTitle( roleId )
                    setSuccessOpen( true )
                }
            )
            .catch( ( error ) => {
                console.log( error )
                setErrorOpen( true )
                //console.log(error)
            } )


    }

    function handleTitleAddition( workTitleList ) {

        let tempList = [ ...workTitles, ...workTitleList ]
        props.project.roles = tempList
        setWorkTitles( tempList )
    }

    return <Paper onMouseOver={ () => {
        setOpen( true )
    } } onMouseOut={ () => {
        setOpen( false )
    } } elevation={ open ? 2 : 0 } className={ classes.card }>
        <Grid container spacing={ 1 }>
            <Grid container>
                <Grid item xs={ 11 }>
                    <Typography className={ classes.title }>
                        Work Titles
                    </Typography>
                </Grid>
                { props.auth ?
                    <Grid item xs={ 1 }>
                        <Tooltip title={ "Add new work titles to project." }>
                            <Button
                                onClick={
                                    () => onHandleRoleAdditionOpen()
                                }
                                className={ classes.button }>
                                <AddBoxOutlined className={ classes.roleAdditionIcon }/>
                            </Button>
                        </Tooltip>
                    </Grid>
                    : null
                }
            </Grid>
            <Grid item xs={ 12 }>
                <Divider className={ classes.divider }>
                </Divider>
            </Grid>
            <Grid container className={ classes.titleCard }>
                <Grid item xs={ 3 }>
                    <Typography className={ classes.titleText }>
                        Title
                    </Typography>
                </Grid>
                <Grid item xs={ 3 }>
                    <Typography className={ classes.titleText }>
                        User Count
                    </Typography>
                </Grid>
                { props.auth ? <Grid item xs={ 3 }>
                    <Typography className={ classes.titleText }>
                        Add User
                    </Typography>
                </Grid> : null
                }
                { props.auth ?
                    <Grid item xs={ 3 }>
                        <Typography className={ classes.titleText }>
                            Title Settings
                        </Typography>
                    </Grid>
                    : null
                }

            </Grid>
            { props.project.roles.map( ( role ) => {
                return <Grid className={ classes.lineContainer } container>
                    <Grid item xs={ 3 }>
                        <Typography className={ classes.text }>
                            { role.role.charAt( 0 )
                                  .toUpperCase() + role.role.toLowerCase()
                                                       .slice( 1 ) }
                        </Typography>
                    </Grid>
                    <Grid item xs={ 3 }>
                        <Typography className={ classes.text }>
                            { role.roleList.length }
                        </Typography>
                    </Grid>
                    { props.auth ?
                        <Tooltip title={ "Add a new User under this work title." }>
                            <Grid
                                className={ classes.text }
                                onClick={
                                    () => {
                                        setCurrentRole( role )
                                        onHandlePersonAdditionOpen()
                                    }
                                }
                                item xs={ 3 }
                            >
                                <Button>
                                    <PersonAdd className={ classes.icon }/>
                                </Button>
                            </Grid>
                        </Tooltip>
                        : null
                    }
                    { props.auth
                        ?
                        <Tooltip title={ "Title Settings" }>
                            <Grid
                                item
                                xs={ 3 }
                                onClick={
                                    () => {
                                        setRoleSettingOpen( role.id )
                                    }
                                }>
                                <Button>
                                    <Settings className={ classes.icon }/>
                                </Button>
                            </Grid>
                        </Tooltip>
                        : null
                    }
                </Grid>
            } ) }
        </Grid>
        <RoleAdditionDialog
            handleTitleAddition={ ( list ) => handleTitleAddition( list ) }
            functionList={ props.functionList }
            project={ props.project }
            isOpen={ roleAdditionOpen }
            handle
            handleClose={ () => onHandleRoleAdditionClose() }
        />
        <PersonAdditionDialog
            handlePersonAddition={ ( val ) => handlePersonInvitation( val ) }
            user={ props.user }
            role={ currentRole }
            functionList={ props.functionList }
            project={ props.project }
            isOpen={ personAdditionOpen }
            handleClose={ () => onHandlePersonAdditionClose() }
        />
        {
            roleSettingOpen === -1 ? null :
                <RoleSettingDialog
                    handlePersonAddition={ ( val ) => handlePersonInvitation( val ) }
                    handleTitleAdminChange={ ( val ) => handleTitleAdminChange( roleSettingOpen, val ) }
                    handleAndroidKeyChange={ ( val ) => handleAndroidKeyChange( roleSettingOpen, val ) }
                    user={ props.user }
                    auth={ props.auth }
                    role={ roleSettingOpen }
                    setRole={ ( value ) => setRoleSettingOpen( value ) }
                    onDeletion={ ( id ) => handlePersonDeletion( id ) }
                    onTitleDeletion={ ( id ) => handleTitleDeletion( id ) }
                    functionList={ props.functionList }
                    project={ props.project }
                    onClose={ () => setRoleSettingOpen( -1 ) }
                    isOpen={ roleSettingOpen != null }
                />
        }
        <Snackbar open={ errorOpen }
                  autoHideDuration={ 6000 }
                  onClose={ handleErrorClose }
        >
            <Alert onClose={ handleErrorClose }
                   severity={ "warning" }
            >
                Something went wrong, try it later.
            </Alert>
        </Snackbar>
        <Snackbar open={ successOpen }
                  autoHideDuration={ 1000 }
                  onClose={ handleSuccessClose }
        >
            <Alert onClose={ handleSuccessClose }
                   severity={ "success" }
            >
                Successful
            </Alert>
        </Snackbar>
    </Paper>
}