import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    makeStyles,
    useTheme,
    Typography,
    InputLabel,
    FormControl,
    Select,
    Chip,
    Input,
    MenuItem, LinearProgress, Paper, Tooltip
} from "@material-ui/core";
import Colors from "../../constants/Colors";
import Env from "../../constants/Env";
import { InfoOutlined } from "@material-ui/icons";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null,
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles( name, personName, theme ) {
    return {
        fontWeight:
            personName.indexOf( name ) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const useStyles = makeStyles( ( theme ) => ( {
    dialog: {
        background: Colors.gradient,
    },
    formControl: {
        width: "100%",
        color: Colors.PRIMARY_PINK_UP,
        paddingBottom: 4,
    },
    title: {
        fontSize: 32,
        fontWeight: "bold",
        color: Colors.PRIMARY_PINK_UP,
    },
    chips: {
        backgroundColor: 'transparent',
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        color: Colors.PRIMARY_WHITE,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        margin: 2,
    },
    bodyText: {
        fontWeight: "bold",
        fontSize: 16,
    },
    dialogContent: {
        paddingBottom: 20,
    },
    dialogAction: {
        paddingBottom: 20,
    },
    textField: {
        paddingBottom: 4,
    },
    buttonCreate: {
        width: 100,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_GREEN_LIGHT,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_YELLOW_LIGHT,
        }
    },
    buttonCancel: {
        width: 100,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_PINK,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_BLUE,
        }
    },
    buttonText: {
        fontSize: 12,
        fontWeight: "bold",
        color: Colors.PRIMARY_WHITE,
    },
    progressBar: {
        background: Colors.gradient,
        borderRadius: 10,
        height: 5,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
    },
    select: {
        maxHeight: 1000,
    },
    paper: {
        maxHeight: 800,
    },
    infoIcon: {
        color: Colors.PRIMARY_PINK_ALT
    }
} ) )

export default function ProjectCreationDialog( props ) {
    const classes = useStyles();
    const theme = useTheme();
    const [ roleText, setRoleText ] = React.useState( "" );
    const [ roles, setRoles ] = React.useState( [] );
    const [ categoryText, setCategoryText ] = React.useState( "" );
    const [ categories, setCategories ] = React.useState( [] );
    const [ languagesAsString, setLanguagesAsString ] = React.useState( [] );
    const [ submitted, setSubmitted ] = React.useState( false )
    const [ projectName, setProjectName ] = React.useState( "" );
    const [ language, setLanguage ] = React.useState( [] );
    const [ baseLanguage, setBaseLanguage ] = React.useState( '' )
    let languages = [];

    const handleChange = ( event ) => {
        setLanguage( event.target.value );
    };
    const handleRoleAddition = ( value ) => {
        if ( roles.indexOf( value ) !== -1 )
            return
        setRoles( [ ...roles, value ] )
    }
    const handleRoleDeletion = ( value ) => {
        let temp = []
        roles.forEach( role => {
            if ( role !== value )
                temp.push( role )
        } )
        setRoles( temp )
    }
    const handleCategoryAddition = ( value ) => {
        if ( categories.indexOf( value ) !== -1 )
            return
        setCategories( [ ...categories, value ] )
    }
    const handleCategoryDeletion = ( value ) => {
        let temp = []
        categories.forEach( category => {
            if ( category !== value )
                temp.push( category )
        } )
        setCategories( temp )
    }
    const handleSelectedLanguages = ( list ) => {
        languages = []
        list.forEach( el => {
            props.languages.forEach( lan => {
                if ( lan.language === el )
                    languages.push( lan.id )
            } )
        } )
    }


    return <Dialog open={ props.isOpen } className={ classes.dialog } aria-labelledby="form-dialog-title">
        <Paper className={ classes.paper }>
            <DialogTitle id="form-dialog-title">
                <Typography className={ classes.title }>
                    Create New Project
                </Typography>
            </DialogTitle>
            <DialogContent className={ classes.dialogContent }>
                <DialogContentText>
                    <Typography className={ classes.bodyText }>
                        To create new project, please fill all the required fields. Note that you can add new categories
                        and
                        languages after creating this.
                    </Typography>
                </DialogContentText>
                <TextField
                    disabled={ submitted }
                    required={ true }
                    value={ projectName }
                    onChange={ ( value ) => setProjectName( value.target.value ) }
                    className={ classes.textField }
                    autoFocus
                    margin="dense"
                    id="project name id"
                    label="Project Name"
                    type="text"
                    fullWidth
                />
                <FormControl
                    className={ classes.formControl }>
                    <InputLabel id="language-label">
                        Source Language
                    </InputLabel>
                    <Select
                        autoFocus
                        disabled={ submitted }
                        className={ classes.select }
                        labelId="base-language-chip-label"
                        id="base-language-chip"
                        value={ baseLanguage }
                        onChange={ ( val ) => {
                            setBaseLanguage( val.target.value )
                            let temp = []
                            languagesAsString.forEach( lan => {
                                if ( lan !== val.target.value )
                                    temp.push( lan )
                            } )
                            setLanguage( temp )
                            setLanguagesAsString( temp )
                        } }
                        input={ <Input/> }
                        MenuProps={ MenuProps }
                    >
                        { props.languages.map( ( lan ) => {
                            return <MenuItem
                                key={ "companyShortCutPage-" + lan.id } value={ lan.language }
                                style={ getStyles( lan.language, language, theme ) }>
                                { lan.language }
                            </MenuItem>

                        } ) }
                    </Select>
                </FormControl>
                <FormControl className={ classes.formControl }>
                    <InputLabel id="language-chip-label">
                        Target Languages
                    </InputLabel>
                    <Select
                        className={ classes.select }
                        autoFocus
                        disabled={ submitted }
                        labelId="language-chip-label"
                        id="language-chip"
                        multiple
                        value={ language }
                        onChange={ handleChange }
                        input={ <Input/> }
                        renderValue={ ( selected ) => {
                            setLanguagesAsString( selected )
                            return (
                                <div className={ classes.chips }>
                                    { languagesAsString.map( ( value ) => (
                                        <Chip disabled={ submitted } key={ "companyShortcutPage-chip" + value }
                                              label={ value }
                                              className={ classes.chip }/>
                                    ) ) }
                                </div>
                            )
                        } }
                        MenuProps={ MenuProps }
                    >
                        { props.languages.map( ( lan ) => {
                            if ( lan.language !== baseLanguage ) {
                                return <MenuItem key={ "companyShprtcutpage-lan-menuItem" + lan.id }
                                                 value={ lan.language }
                                                 style={ getStyles( lan.language, language, theme ) }>
                                    { lan.language }
                                </MenuItem>
                            } else {
                                return null
                            }
                        } ) }
                    </Select>
                </FormControl>
                <TextField
                    disabled={ submitted }
                    className={ classes.textField }
                    autoFocus
                    value={ roleText }
                    onChange={ ( value ) => {
                        setRoleText( value.target.value )
                    } }
                    onKeyDown={ ( e ) => {
                        if ( e.key === 'Enter' ) {
                            handleRoleAddition( roleText )
                            setRoleText( "" )
                        }
                    } }
                    InputProps={ {
                        startAdornment: (
                            <Tooltip
                                title={ "Determine your project work titles. Ex. ios developer, translation manager etc." }>
                                <InfoOutlined className={ classes.infoIcon }/>
                            </Tooltip>
                        )
                    } }
                    margin="dense"
                    id="project role id"
                    label="Project Work Titles (Press Enter to add)"
                    type="text"
                    fullWidth
                />
                <div className={ classes.chips }>
                    { roles.map( ( value ) => (
                        <Chip
                            key={ "companyShprtcutpage-lan-chip2-" + value }
                            disabled={ submitted } onClick={ () => handleRoleDeletion( value ) }
                            label={ value }
                            className={ classes.chip }/>
                    ) ) }
                </div>
                <TextField
                    className={ classes.textField }
                    autoFocus
                    disabled={ submitted }
                    value={ categoryText }
                    onChange={ ( value ) => {
                        setCategoryText( value.target.value )
                    } }
                    onKeyDown={ ( e ) => {
                        if ( e.key === 'Enter' ) {
                            handleCategoryAddition( categoryText )
                            setCategoryText( "" )
                        }
                    } }
                    InputProps={ {
                        startAdornment: (
                            <Tooltip
                                title={ "Determine your project translation subjects. Ex. app, store front, review etc." }>
                                <InfoOutlined className={ classes.infoIcon }/>
                            </Tooltip>
                        )
                    } }
                    margin="dense"
                    id="Project Categories name"
                    label="Translation Subjects (Press Enter to add)"
                    type="text"
                    fullWidth
                />
                <div className={ classes.chips }>
                    { categories.map( ( value ) => (
                        <Chip
                            key={ "companyShortcutpage-lan-chip3-" + value }
                            disabled={ submitted } onClick={ () => handleCategoryDeletion( value ) }
                            label={ value }
                            className={ classes.chip }/>
                    ) ) }
                </div>
            </DialogContent>
            <DialogActions className={ classes.dialogAction }>
                <Button disabled={ submitted } onClick={ () => {

                    if ( projectName.length > 0 &&
                        baseLanguage.length > 0 ) {
                        setSubmitted( true )
                        handleSelectedLanguages( languagesAsString )
                        let baseId
                        props.languages.forEach( lan => {
                            if ( lan.language === baseLanguage )
                                baseId = lan.id
                        } )
                        const headers = {
                            "Content-Type": "application/json",
                            'Authorization': 'Bearer ' + Env.TOKEN
                        }

                        fetch( process.env.REACT_APP_URL + 'projects', {
                            method: 'POST', headers: headers, body: JSON.stringify( {
                                "name": projectName,
                                "companyId": props.company.id,
                                "baseId": baseId,
                                'languagesAsLong': [ ...languages ],
                                'projectRolesAsString': [ ...roles ],
                                'categoriesAsString': [ ...categories ],
                            } )
                        }, )
                            .then( response => response.json() )
                            .then( data => {
                                props.handleProjectAddition( data )
                                setSubmitted( false )
                                setCategories( [] )
                                setRoles( [] )
                                setProjectName( "" )
                                props.handleClose()
                                return data
                            } )
                    }
                } } className={ classes.buttonCreate } color="primary">
                    <Typography className={ classes.buttonText }>
                        Create
                    </Typography>
                </Button>
                <Button onClick={ () => {
                    props.handleClose()
                    setSubmitted( false )
                }
                } className={ classes.buttonCancel } color="primary">
                    <Typography className={ classes.buttonText }>
                        Close
                    </Typography>
                </Button>
            </DialogActions>
            { submitted ? <LinearProgress className={ classes.progressBar } color={ 'secondary' }/> : <div/> }
        </Paper>
    </Dialog>
}