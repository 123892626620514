import React from "react";
import { Button, ButtonGroup, Checkbox, CircularProgress, Dialog, Grid, Snackbar, Typography } from "@material-ui/core";
import Colors from "../../../../../constants/Colors";
import OrderCreationRow from "./OrderCreationRow";
import { makeStyles } from "@material-ui/core/styles";
import Program from "../../../../../constants/Program";
import Env from "../../../../../constants/Env";
import { Alert } from "@material-ui/lab";
import TranslatorAdditionDialog from "./TranslatorAdditionDialog";

const useStyles = makeStyles( {
    grid: {
        maxHeight: 800,
        display: "flex",
        justifyContent: "center",
    },
    button: {
        width: 250,
        borderRadius: 15,
        color: Colors.PRIMARY_WHITE,
        "&:hover": {
            color: Colors.PRIMARY_BLUE,
            background: Colors.PRIMARY_WHITE,
        }
    }
} );


export default function OrderCreationDialog( props ) {
    const classes = useStyles()
    const [ translators, setTranslators ] = React.useState( null )
    const [ data, setData ] = React.useState( [] )
    let textCount = calculateTextCount( props.allTable )
    const [ submitted, setSubmitted ] = React.useState( false )
    const [ error, setError ] = React.useState( "" )
    const [ totalCount, setTotalCount ] = React.useState( 1 )
    const [ doneCount, setDoneCount ] = React.useState( 0 )
    const [ translatorAdditionDialog, setTranslatorAdditionDialog ] = React.useState( false )
    const [ translatorName, setTranslatorName ] = React.useState( "" )
    const [ translatorSurname, setTranslatorSurname ] = React.useState( "" )
    const [ translatorExpire, setTranslatorExpire ] = React.useState( "" )
    const [ translatorEmail, setTranslatorEmail ] = React.useState( "" )

    if ( props.allTranslatorsInfo === null ) {
        props.loadAllTranslatorsInfo()
    }

    let tempTranslator = []

    function checkTranslatorExist( order ) {
        let check = false
        tempTranslator.forEach( translator => {
            if ( translator.id === order.user.id )
                check = true
        } )
        return check
    }

    function checkInCheckList( id ) {
        let check = false

        Program.getCheckList()
               .forEach( e => {
                   if ( id === e ) {
                       check = true
                   }
               } )

        return check
    }

    if ( translators == null ) {
        tempTranslator = []

        props.program.user.roles.forEach( role => {
            role.projectRole.project.translationOrders.forEach(
                translationOrder => {
                    if ( !checkTranslatorExist( translationOrder ) )
                        tempTranslator.push( translationOrder.user )
                }
            )
        } )

        if ( props.program.company != null ) props.program.company.projects.forEach( project => {
            project.translationOrders.forEach(
                translationOrder => {
                    if ( !checkTranslatorExist( translationOrder ) )
                        tempTranslator.push( translationOrder.user )
                }
            )
        } )

        setTranslators( tempTranslator )
    }

    function calculateTextCount( allTable ) {
        let count = 0
        const regexpEmojiPresentation = /\p{Emoji_Presentation}/gu;

        function checkTextInBlackList( text ) {
            let check = false
            props.project.blackListTexts.forEach( blackListText => {
                if ( blackListText.text === text )
                    check = true
            } )
            return check
        }

        allTable.forEach( textModel => {
            if ( checkInCheckList( textModel.id ) ) {
                textModel.englishWord.split( " " )
                         .forEach( el => {
                             if ( el.match( regexpEmojiPresentation ) === null && el.length > 1 ) {
                                 if ( !checkTextInBlackList( el ) ) {
                                     count++
                                 }
                             } else {
                                 el.split( regexpEmojiPresentation )
                                   .forEach( elChild => {
                                       if ( elChild.length > 1 && !checkTextInBlackList( elChild ) ) {
                                           count++
                                       }
                                   } )
                             }
                         } )
            }
        } )
        return count
    }

    if ( data.length === 0 ) {
        let tempList = []
        props.allTable[ 0 ].localizations.forEach( loc => {
            if ( !loc.base ) {
                tempList.push( {
                    language: loc.projectLanguage,
                    selected: false,
                    textLocalizations: [],
                    type: "WRITE",
                    userId: null,
                    userExpire: new Date(new Date().getTime() + 86400000 * 3).toISOString().split( '.' )[ 0 ],
                    name: "",
                    surname: "",
                    orderTitle: new Date( Date.now() ).toLocaleString(),
                    email: "",
                    textCount: textCount,
                    translationTextModelList: [],
                } )
            }
        } )

        props.allTable.forEach( row => {
            if ( checkInCheckList( row.id ) ) {
                row.localizations.forEach( loc => {
                    if ( !loc.base ) {
                        tempList.forEach( tempRow => {
                            if ( tempRow.language.id === loc.projectLanguage.id ) {
                                let tempLoc = { ...loc }
                                tempLoc.baseText = row.englishWord
                                tempLoc.image = row.image
                                tempLoc.explanation = row.explanation
                                tempRow.textLocalizations.push( tempLoc )
                            }
                        } )
                    }
                } )
            }
        } )

        setData( tempList )
    }

    function handleCheckChange( projectLanguageId ) {
        let tempData = []

        data.forEach( row => {
            if ( row.language.id === projectLanguageId ) {
                let tempObj = { ...row }
                tempObj.selected = !tempObj.selected
                tempData.push( tempObj )
            } else {
                tempData.push( row )
            }
        } )

        setData( tempData )
    }

    function handleTypeChange( projectLanguageId, type ) {
        let tempData = []

        data.forEach( row => {
            if ( row.language.id === projectLanguageId ) {
                let tempObj = { ...row }
                tempObj.type = type
                tempData.push( tempObj )
            } else {
                tempData.push( row )
            }
        } )

        setData( tempData )
    }

    function handleTitleChange( projectLanguageId, title ) {
        let tempData = []

        data.forEach( row => {
            if ( row.language.id === projectLanguageId ) {
                let tempObj = { ...row }
                tempObj.orderTitle = title
                tempData.push( tempObj )
            } else {
                tempData.push( row )
            }
        } )

        setData( tempData )
    }

    function handleExpirationChange( projectLanguageId, expireDate ) {
        let tempData = []

        data.forEach( row => {
            if ( row.language.id === projectLanguageId ) {
                let tempObj = { ...row }
                tempObj.userExpire = expireDate
                tempData.push( tempObj )
            } else {
                tempData.push( row )
            }
        } )

        setData( tempData )
    }

    function selectTranslator( projectLanguageId, email, name, surname, userId ) {
        let tempData = []

        data.forEach( row => {
            if ( row.language.id === projectLanguageId ) {
                let tempObj = { ...row }
                tempObj.email = email
                tempObj.name = name
                tempObj.surname = surname
                tempObj.userId = userId
                tempData.push( tempObj )
            } else {
                tempData.push( row )
            }
        } )

        setData( tempData )
    }

    async function sendJobs() {
        let check = true
        let atLeastOneChecked = false
        let totalTemp = 0
        let doneTemp = 0
        data.forEach( row => {

            if ( row.selected ) {
                totalTemp++
                atLeastOneChecked = true

                if ( row.orderTitle.trim().length === 0 ) {
                    check = false
                }

                if ( row.userId === null ) {
                    check = false
                }
            }
        } )

        if ( check && atLeastOneChecked ) {
            setTotalCount( totalTemp )
            setDoneCount( 0 )
            for ( const row of data ) {
                if ( row.selected ) {
                    setSubmitted( true )
                    let returnObject = {
                        "projectId": props.project.id,
                        "type": row.type,
                        "userId": row.userId,
                        "userExpire": row.userExpire,
                        "language": row.language.language.language,
                        "name": row.name,
                        "orderTitle": row.orderTitle,
                        "surname": row.surname,
                        "email": row.name,
                        textCount: row.textCount,
                        translationTextModelList: []
                    }
                    row.textLocalizations.forEach( localization => {
                        returnObject.translationTextModelList.push( {
                            "textId": localization.id,
                            "englishShownWord": localization.baseText,
                            "image": {
                                "id": localization.image == null ? -1 : localization.image.id,
                            },
                            "explanation": {
                                "id": localization.explanation === null ? -1 : localization.explanation.id,
                                "content": localization.explanation === null ? null :
                                    localization.explanation.content,
                            }
                        } )
                    } )


                    const headers = {
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + Env.TOKEN
                    }


                    await fetch( process.env.REACT_APP_URL + 'projects/translationOrders', {
                        method: 'POST', headers: headers, body: JSON.stringify( returnObject )
                    }, )
                        .then( response => {
                            if ( response.ok ) return response.json()
                            else response.json()
                                         .then( data => console.log( data.message ) )
                        } )
                        .then( ( data ) => {

                            doneTemp++
                            setDoneCount( doneTemp )

                            let tempProject = { ...props.project }
                            tempProject.translationOrders.push( data )
                            props.project = tempProject

                            if ( doneTemp === totalTemp ) {
                                props.onClose()
                            }
                        } )
                        .catch( () => {
                            doneTemp++
                            setDoneCount( doneTemp )
                            setDoneCount( doneCount + 1 )

                        } )

                    setSubmitted( false )

                }
            }
        } else {
            setError( "Please fill all the selected fields!" )
        }

    }

    return <Dialog open={ props.isOpen }
                   maxWidth={ "xl" }
                   fullWidth
                   onClose={ props.onClose }
    >
        { props.allTranslatorsInfo === null ?
            <Grid container style={ { display: "flex", justifyContent: "center", padding: 40 } }>
                <CircularProgress style={ { display: "flex", justifyContent: "center" } }/>
            </Grid> : submitted ? <Grid container color={ Colors.PRIMARY_WHITE } style={ {
                display: "flex",
                justifyContent: "center",
                paddingTop: 40,
                paddingBottom: 40,
                paddingRight: 40,
                paddingLeft: 40
            } }>
                <Grid item xs={ 12 } style={ {
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: 40,

                } }>
                    <Typography>
                        Please wait this might take time!
                    </Typography>
                </Grid>
                <Grid item xs={ 12 } style={ {
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: 40,

                } }>
                    <Typography>
                        { ( doneCount / totalCount * 100 ).toPrecision( 3 ) + "%" }
                    </Typography>
                </Grid>
                <CircularProgress/>
            </Grid> : <Grid container color={ Colors.PRIMARY_WHITE } style={ {
                paddingTop: 40,
                paddingBottom: 40,
                paddingRight: 40,
                paddingLeft: 40
            } }>
                <Grid container style={ {
                    display: "flex",
                    justifyContent: "center",
                    padding: 10,
                } }>
                    <Grid item xs={ 12 } style={ {
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: 20,
                    } }>
                        <Typography style={ { fontWeight: "bold" } }>
                            { "Text Count: " + textCount.toString() + "         Line Count: " + Program.getCheckList()
                                                                                                       .length
                                                                                                       .toString() }
                        </Typography>
                    </Grid>
                    <Grid item xs={ 12 } style={ {
                        display: "flex",
                        justifyContent: "center",
                    } }>
                        <ButtonGroup style={ { background: Colors.PRIMARY_BLUE, padding: 4, borderRadius: 15 } }>
                            <label>
                                <Button className={ classes.button }
                                        onClick={ () => setTranslatorAdditionDialog( true ) }>
                                    <Typography>
                                        Add a new translator
                                    </Typography>
                                </Button>
                            </label>
                            <label>
                                <Button onClick={ () => sendJobs() } className={ classes.button }>
                                    <Typography>
                                        Send Jobs
                                    </Typography>
                                </Button>
                            </label>
                            <label>
                                <Button onClick={ props.onClose } className={ classes.button }>
                                    <Typography>
                                        Close
                                    </Typography>
                                </Button>
                            </label>
                        </ButtonGroup>
                    </Grid>

                </Grid>
                <Grid container style={ {
                    display: "flex",
                    justifyContent: "space-between",
                    background: Colors.gradient,
                    borderRadius: 10,
                    paddingTop: 10,
                    paddingBottom: 10,
                } }>
                    <Grid item xs style={ { display: "flex", justifyContent: "center" } }>

                    </Grid>
                    <Grid item xs style={ { display: "flex", justifyContent: "center", alignItems: "center" } }>
                        <Typography style={ { color: Colors.PRIMARY_WHITE } }>
                            Title
                        </Typography>
                    </Grid>
                    <Grid item xs style={ { display: "flex", justifyContent: "center", alignItems: "center" } }>
                        <Typography style={ { color: Colors.PRIMARY_WHITE } }>
                            Language
                        </Typography>
                    </Grid>
                    <Grid item xs style={ { display: "flex", justifyContent: "center", alignItems: "center" } }>
                        <Typography style={ { color: Colors.PRIMARY_WHITE } }>
                            Translator
                        </Typography>
                    </Grid>
                    <Grid item xs style={ { display: "flex", justifyContent: "center", alignItems: "center" } }>
                        <Typography style={ { color: Colors.PRIMARY_WHITE } }>
                            Job Type
                        </Typography>
                    </Grid>
                    <Grid item xs style={ { display: "flex", justifyContent: "center", alignItems: "center" } }>
                        <Typography style={ { color: Colors.PRIMARY_WHITE } }>
                            Deadline
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    { data.map( row => {
                        return <OrderCreationRow handleCheckChange={ () => handleCheckChange( row.language.id ) }
                                                 handleTitleChange={ ( title ) => handleTitleChange( row.language.id,
                                                     title ) }
                                                 handleTypeChange={ ( type ) => handleTypeChange( row.language.id,
                                                     type ) }
                                                 handleExpirationChange={ ( newDate ) => handleExpirationChange(
                                                     row.language.id, newDate ) }
                                                 selectTranslator={ ( email, name, surname,
                                                                      userId ) => selectTranslator(
                                                     row.language.id, email, name, surname, userId ) }
                                                 row={ row }
                                                 translators={ props.allTranslatorsInfo }/>
                    } ) }
                </Grid>
            </Grid> }
        { translatorAdditionDialog && <TranslatorAdditionDialog
            createTranslator={ ( name, surname, userExpirationTime, email ) => props.createTranslator( name, surname,
                userExpirationTime, email ) }
            name={ translatorName }
            surname={ translatorSurname }
            expire={ translatorExpire }
            email={ translatorEmail }
            setName={ ( val ) => setTranslatorName( val ) }
            setSurname={ ( val ) => setTranslatorSurname( val ) }
            setExpire={ ( val ) => setTranslatorExpire( val ) }
            setEmail={ ( val ) => setTranslatorEmail( val ) }
            open={ translatorAdditionDialog }
            setError={ ( val ) => setError( val ) }
            onClose={ () => setTranslatorAdditionDialog(
                false ) }/> }
        <Snackbar open={ error.trim().length > 0 }
                  autoHideDuration={ 3000 }
                  onClose={ () => setError( "" ) }
        >
            <Alert onClose={ () => setError( "" ) }
                   severity={ "info" }
            >
                { error }
            </Alert>
        </Snackbar>
    </Dialog>
}