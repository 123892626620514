import React from "react";
import {useParams} from "react-router-dom";
import {
    Button,
    CircularProgress,
    Dialog, DialogActions,
    DialogContent,
    Grid,
    LinearProgress,
    Snackbar,
    Tooltip
} from "@material-ui/core";
import Env from "../../../constants/Env";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import {CloudUpload, ExitToApp, Home, Save} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Colors from "../../../constants/Colors";
import {Alert} from "@material-ui/lab";
import TranslationTaskPage from "../TranslationTaskPage";

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    appBar: {
        background: Colors.gradient,
    },
    menuButton: {
        color: Colors.PRIMARY_WHITE,
        paddingRight: 16,
    },
    title: {
        fontWeight: "bold",
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {

        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {

        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    icon: {
        paddingRight: 8,
        fontSize: 28,
    },
    div: {
        paddingTop: 100,
        padding: 40,
    },
    tableHead: {
        backgroundColor: Colors.PRIMARY_PINK_UP,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
    },
    expiration: {
        fontWeight: "bold",
        paddingRight: 28,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    paper: {
        background: Colors.PRIMARY_BACKGROUND,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        alignItems: "center",
        alignContent: "center",
        display: "flex",
        justifyItems: "center",
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 16,
        paddingBottom: 16,
        justifyContent: "center"
    },
    bodyTitle: {
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        fontSize: 28,
        color: Colors.PRIMARY_PINK_UP,
        fontWeight: "bold",
    },
    text: {
        color: Colors.PRIMARY_PINK_ALT,
        fontWeight: "bold",
    },
    button: {
        padding: 0,
        color: Colors.PRIMARY_WHITE
    },
    grid: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    verticalDivider: {
        marginRight: 12,
        width: 2,
        height: '100%',
        backgroundColor: Colors.PRIMARY_WHITE,
    },
    dialog: {
        padding: 32,
        backgroundColor: Colors.PRIMARY_BACKGROUND
    },
    dialogButtons: {
        display: "flex",
        justifyContent: "center",
    },
    dialogButtonConfirm: {
        paddingLeft: 60,
        paddingRight: 60,
        padding: 10,

        backgroundColor: Colors.PRIMARY_GREEN_LIGHT,
        color: Colors.PRIMARY_WHITE,
        fontWeight: "bold",
    },
    dialogButtonReject: {
        paddingLeft: 60,
        paddingRight: 60,
        padding: 10,
        backgroundColor: Colors.PRIMARY_PINK,
        color: Colors.PRIMARY_WHITE,
        fontWeight: "bold",
    },
    errorBox: {
        background: Colors.PRIMARY_WHITE,
        width: 800,
        height: 200,
        borderRadius: 10,

    },
    errorBoxOuter: {
        background: Colors.gradient,
        padding: 20,
        borderRadius: 10,
        alignItems: "center",
        justifyContent: "center",
    },
    errorGrid: {
        paddingTop: 300,
        justifyContent: "center",
        display: "flex",
    },
    errorText: {
        textAlign: "center",
        fontSize: 32,
        fontWeight: "bold",
        paddingTop: 20,
        color: Colors.PRIMARY_PINK_ALT
    },
    errorTextBody: {
        textAlign: "center",
        fontSize: 24,
        fontWeight: "bold",
        color: Colors.PRIMARY_BLUE
    }

}));


export default function TranslationTaskPublicMainLayoutPage(props) {
    const classes = useStyles();
    let {publicLink} = useParams()
    const [loading, setLoading] = React.useState(true)
    const [failed, setFailed] = React.useState(false)
    const [order, setOrder] = React.useState(null)
    const [fetched, setFetched] = React.useState(false)
    const [changedOnes, setChanged] = React.useState(new Map())
    const [errorOpen, setErrorOpen] = React.useState(false)
    const [successOpen, setSuccessOpen] = React.useState(false)
    const [submitDialog, setSubmitDialog] = React.useState(null)
    const [saveProgress, setSaveProgress] = React.useState(false)
    const [submitProgress, setSubmitProgress] = React.useState(false)

    window.onbeforeunload = (event) => {
        const e = event || window.event;
        // Cancel the event
        e.preventDefault();
        if (e) {
            e.returnValue = ''; // Legacy method for cross browser support
        }
        return ''; // Legacy method for cross browser support
    };

    const handleErrorOpen = () => {
        setErrorOpen(true)
    }

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorOpen(false);
    };

    const handleSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessOpen(false);
    };

    const handleSuccessOpen = () => {
        setSuccessOpen(true)
    }

    const handleChangesOnes = (id, text) => {
        setChanged(changedOnes.set(id, text))
    }

    function getOrder() {
        let headers = {
            "Content-Type": "application/json"
        }
        fetch(process.env.REACT_APP_URL + 'projects/translationOrders/publicLink?link=' + publicLink, {
            method: 'get', headers: headers,
        },).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                response.json().then(e => {

                })
                throw new Error('Something went wrong');
            }
        }).then(data => {
            setOrder(data)
            setFailed(false)
            setLoading(false)

        }).catch((error) => {
            setFailed(true)
            setLoading(false)
        })
    }

    const saveChanges = async () => {
        if (changedOnes.size > 0) {
            const requestList = []
            changedOnes.forEach((value, id) => {

                requestList.push({
                    "id": id,
                    "equivalent": value
                })
            })

            const headers = {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + Env.TOKEN
            }
            await fetch(process.env.REACT_APP_URL + 'projects/translationTexts/update', {
                method: 'POST', headers: headers, body: JSON.stringify(requestList)
            },)
                .then(response => {
                    if (response.ok) return response.json()
                    else throw new Error();
                })
                .then(data => {
                    setChanged(new Map())
                    handleSuccessOpen();
                })
                .then(async () => {
                    await getOrder()
                })
                .catch(e => {
                    handleErrorOpen();
                })
        }
    }

    const changeStatus = async (id, status, date, userId) => {
        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }
        await fetch(
            process.env.REACT_APP_URL + 'projects/translationOrders/update?id=' + id +
            "&status=" + status +
            "&userId=" + userId +
            "&date=" + date,
            {
                method: 'POST', headers: headers
            },)
            .then(response => {
                if (response.ok) return response.json()
                else throw new Error();
            })
            .then(data => {
            })
            .then(async () => {
                await getOrder()
            })
            .catch(e => {
            })

    }

    if (!fetched) {
        getOrder()
        setFetched(true)
    }

    return loading ?
        <LinearProgress/>
        :
        failed || order.status === "PENDING"
            ?
            <Grid container className={classes.errorGrid}>
                <Grid item className={classes.errorBoxOuter}>
                    <Grid container className={classes.errorBox}>
                        <Grid item xs={12}>
                            <Typography className={classes.errorText}>
                                FAILED!
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.errorTextBody}>
                                Link is invalid. Please contact with the link provider!
                            </Typography>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            :
            new Date(order.user.userExpirationTime) >
            new Date(Date.now()) ?
                <div className={classes.grow}>
                    <AppBar className={classes.appBar} position="fixed">
                        <Toolbar>
                            <Tooltip title={"Home"} arrow>
                                <IconButton
                                    onClick={() => {
                                    }}
                                    disabled={true}
                                    edge="start"
                                    className={classes.menuButton}
                                    color="inherit"
                                    aria-label="open drawer"
                                >
                                    <Home className={classes.icon}/>
                                </IconButton>
                            </Tooltip>
                            {order === null
                                ?
                                <Typography className={classes.title} variant="h6" noWrap>
                                    Localify
                                </Typography>
                                : <div>
                                    <Grid container>
                                        <Grid item xs>
                                            <div className={classes.verticalDivider}/>
                                        </Grid>
                                        <Grid item xs>
                                            <Tooltip title={"Save Changes you made. You can edit after saving."}>
                                                {saveProgress ?
                                                    <CircularProgress/>
                                                    :
                                                    <Button
                                                        disabled={order.type === "READ" ||
                                                        (order.status === "COMPLETED" || order.status ===
                                                            "CHECKED" || order.status === "PAID" ||
                                                            order.status === "PENDING")}
                                                        onClick={async () => {
                                                            setSaveProgress(true)
                                                            if (order.status === "NOT_STARTED" ||
                                                                order.status ===
                                                                "IN_PROGRESS" || order.status === "SAVED")
                                                                await saveChanges()
                                                            setSaveProgress(false)

                                                        }} className={classes.menuButton}>
                                                        <Save className={classes.icon}/>
                                                        <Typography className={classes.title} variant="p" noWrap>
                                                            Save
                                                        </Typography>
                                                    </Button>
                                                }
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs>
                                            <Tooltip
                                                title={"Submit Changes. You can NOT edit anymore after you submitted."}>
                                                <Button
                                                    disabled={order.type === "READ" ||
                                                    (order.status === "COMPLETED" || order.status ===
                                                        "CHECKED" || order.status === "PAID" ||
                                                        order.status === "PENDING")}
                                                    onClick={() => {
                                                        setSubmitDialog(order.id)
                                                    }} className={classes.menuButton}>
                                                    <CloudUpload className={classes.icon}/>
                                                    <Typography className={classes.title} variant="p" noWrap>
                                                        Submit
                                                    </Typography>
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </div>
                            }
                            <div className={classes.grow}/>
                            <Typography className={classes.expiration}>
                                {"Deadline "}
                            </Typography>
                            <Typography className={classes.expiration}>
                                {new Date(order.user.userExpirationTime).toLocaleTimeString() + " "}
                                {new Date(order.user.userExpirationTime).toLocaleDateString() + " "}
                            </Typography>
                            <Tooltip title={"Logout"}>
                                <IconButton
                                    disabled={true}
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-haspopup="true"
                                    color="inherit"
                                >
                                    <ExitToApp className={classes.icon}/>
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.div}>
                        <TranslationTaskPage isPreview={false} order={order} onChange={handleChangesOnes}/>
                    </div>
                    <Snackbar open={errorOpen}
                              autoHideDuration={6000}
                              onClose={handleErrorClose}
                    >
                        <Alert onClose={handleErrorClose}
                               severity={"warning"}
                        >
                            Something went wrong, try it later.
                        </Alert>
                    </Snackbar>
                    <Snackbar open={successOpen}
                              autoHideDuration={1000}
                              onClose={handleSuccessClose}
                    >
                        <Alert onClose={handleSuccessClose}
                               severity={"success"}
                        >
                            Successfully saved.
                        </Alert>
                    </Snackbar>
                    <Dialog fullWidth={true} maxWidth={'sm'} open={submitDialog != null}>
                        <DialogContent className={classes.dialog}>
                            <Typography className={classes.text}>
                                Do you want to submit ? You can not make any changes after confirmation of this step!
                            </Typography>
                        </DialogContent>
                        <DialogActions className={classes.dialog}>
                            <Grid container className={classes.grid}>
                                {submitProgress ?
                                    <CircularProgress/> :
                                    <Button
                                        className={classes.dialogButtonConfirm}
                                        onClick={
                                            async () => {
                                                await saveChanges()
                                                setSubmitProgress(true)
                                                await changeStatus(order.id, "COMPLETED",
                                                    order.user.userExpirationTime, order.user.id)
                                                setSubmitProgress(false)
                                                setSubmitDialog(null)
                                            }
                                        }
                                    >
                                        Confirm
                                    </Button>
                                }

                                <Button
                                    className={classes.dialogButtonReject}
                                    onClick={
                                        () => {
                                            setSubmitDialog(null)
                                        }
                                    }
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                </div>
                :
                <Grid container className={classes.errorGrid}>
                    <Grid item className={classes.errorBoxOuter}>
                        <Grid container className={classes.errorBox}>
                            <Grid item xs={12}>
                                <Typography className={classes.errorText}>
                                    FAILED!
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.errorTextBody}>
                                    This account is expired. Please contact with your link provider for extensions!
                                </Typography>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

}