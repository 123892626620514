import React from "react";
import { Button, Checkbox, InputBase, Snackbar, TableCell, TableRow, Tooltip, Typography } from "@material-ui/core";
import AllLanguagesPanelCell from "./AllLanguagesPanelCell";
import { RemoveCircleOutline, FileCopy } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../../../constants/Colors";
import { Alert } from "@material-ui/lab";
import Program from "../../../../constants/Program";


const useStyles = makeStyles( {
    grid: {
        maxHeight: 800,
        display: "flex",
        justifyContent: "center",
    },
    gridText: {
        fontWeight: "bold",
        fontSize: 12,
        cursor: "pointer",
    },
    textField: {
        fontSize: 12,
    },
} );


export default function AllLanguagesPanelRow( props ) {
    const classes = useStyles()
    const [ explanationText, setExplanationText ] = React.useState( null )
    const [ androidKey, setAndroidKey ] = React.useState( null )
    const [ categoryId, setCategoryId ] = React.useState( null )
    const [ keyMap, setKeyMap ] = React.useState( {} )
    const [ copyWarning, setCopyWarning ] = React.useState( "" )
    const [ successOpen, setSuccessOpen ] = React.useState( false )
    const [ selected, setSelected ] = React.useState( false )
    const [ textId, setTextId ] = React.useState( -1 )
    const [ allSelection, setAllSelection ] = React.useState( false )

    if ( !props.isNew && ( explanationText === null || categoryId === null || categoryId !== props.category.id ) ) {
        setExplanationText( props.row.explanation !== null ? props.row.explanation.content : "" )
        setAndroidKey( props.row.androidKey !== null ? props.row.androidKey : "" )
        setCategoryId( props.category.id )
    }

    if ( props.allSelected !== allSelection ) {
        setAllSelection( props.allSelected )
        setSelected( checkChecked() )
    }

    if ( textId !== props.row.id && !props.isNew ) {
        setTextId( props.row.id )
        setSelected( checkChecked() )
        setExplanationText( props.row.explanation !== null ? props.row.explanation.content : "" )
        setAndroidKey( props.row.androidKey !== null ? props.row.androidKey : "" )
        setCategoryId( props.category.id )
    }

    function checkChecked() {
        let check = false
        props.checkList.forEach( el => {
            if ( el === props.row.id )
                check = true
        } )
        return check
    }

    if ( props.isNew ) {
        return <TableRow key={ "new-text-" + props.index }>
            <TableCell style={ { width: 20, maxWidth: 20 } }>

            </TableCell>
            <TableCell style={ { width: 50, maxWidth: 50 } }>

            </TableCell>
            <TableCell style={ { width: 50, maxWidth: 50 } }>
                <RemoveCircleOutline
                    style={ { cursor: "pointer" } }
                    onClick={ () => {
                        props.handleAllTableTextDeletion( props.row )
                    } }
                />
            </TableCell>
            {
                props.showImage ?
                    <TableCell style={ {
                        minWidth: 100,
                        borderRightStyle: "groove",
                        maxWidth: 100
                    } }>

                    </TableCell>
                    :
                    null
            }
            {
                props.showExplanation ?
                    <TableCell style={ {
                        minWidth: 200,
                        borderRightStyle: "groove",
                        maxWidth: 200
                    } }>
                    </TableCell>
                    :
                    null
            }
            <AllLanguagesPanelCell
                isNew={ props.isNew }
                allTableAddedTexts={ props.allTableAddedTexts }
                handleAllTableAddedTextChange={ props.handleAllTableAddedTextChange }
                category={ props.category }
                handleAllTableBaseSave={ props.handleAllTableBaseSave }
                handleAllTableLocalizationSave={ props.handleAllTableLocalizationSave }
                handleAllTableSetProjectAllTable={ props.handleAllTableSetProjectAllTable }
                allTable={ props.allTable }
                base={ props.row }
                isBase={ true }
                auth={ props.auth }
                text={ props.row }
                rowIndex={ props.index }
            />
        </TableRow>
    } else {
        if ( !props.showUnconfirmed || ( props.showUnconfirmed && props.row.confirmNeed ) )
            return <TableRow key={ "old-text-" + props.row.id }>
                <TableCell style={ { width: 30, maxWidth: 30 } }>
                    <Typography style={ {
                        fontSize: 14,
                        color: Colors.PRIMARY_BLUE
                    } }>
                        { props.index + 1 }
                    </Typography>
                </TableCell>
                <TableCell style={ { width: 50, maxWidth: 50 } }>
                    <FileCopy
                        onClick={ () => {
                            let check = true
                            props.row.localizations.forEach( loc => {
                                if ( loc.text === null || loc.text.length === 0 )
                                    check = false
                            } )

                            if ( check ) {
                                let temp = ""

                                let orderTemp = [ ...props.row.localizations ]
                                orderTemp = orderTemp.sort( function ( a, b ) {
                                    if ( a.base ) {
                                        return -1
                                    } else if ( b.base ) {
                                        return 1
                                    } else {
                                        if ( a.projectLanguage.language.language >
                                            b.projectLanguage.language.language ) {
                                            return 1
                                        }
                                        if ( a.projectLanguage.language.language <
                                            b.projectLanguage.language.language ) {
                                            return -1
                                        }
                                        return 0
                                    }
                                } )

                                orderTemp.forEach( loc => {
                                    let locTemp = loc.text
                                    locTemp = locTemp.replaceAll("\n", " ")
                                    if ( loc === orderTemp.first ) {
                                        temp = locTemp.trim();
                                    } else {
                                        temp = temp + "\t" + locTemp.trim();
                                    }
                                } )

                                temp = temp.replaceAll( "%", "%%" )
                                temp = temp.replaceAll( "&", "&amp;" )
                                temp = temp.replaceAll( ":STR:", "%s" )
                                temp = temp.replaceAll( ":INT:", "%d" )
                                temp = temp.replaceAll( "'", "\\'" )
                                temp = temp.trim()
                                navigator.clipboard.writeText( temp )
                                         .then( r => {
                                             setSuccessOpen( true )
                                         } )

                            } else {
                                setCopyWarning( "There are empty localizations!" )
                            }
                        } }
                        style={ { fontSize: 16, color: Colors.PRIMARY_BLUE, cursor: "pointer", } }/>
                </TableCell>
                <TableCell style={ { width: 50, maxWidth: 50 } }>
                    <Checkbox checked={ selected || props.allSelected }
                              style={ { padding: 0, margin: 0 } }
                              onChange={ () => {
                                  if ( selected || props.allSelected ) {
                                      setSelected( false )

                                      let temp = []

                                      Program.getCheckList()
                                             .forEach( el => {
                                                 if ( el !== props.row.id )
                                                     temp.push( el )
                                             } )
                                      props.setCheckList( temp )
                                      props.setAllSelected( false )

                                  } else {
                                      setSelected( true )


                                      let temp = [ ...Program.getCheckList() ]
                                      temp.push( props.row.id )
                                      props.setCheckList( temp )
                                      props.setAllSelected( props.allTable.length === temp.length )

                                  }
                              } }/>
                </TableCell>
                {
                    props.showImage ? <TableCell style={ {
                        minWidth: 100,
                        borderRightStyle: "groove",
                        maxWidth: 100
                    } }>
                        { props.row.image != null ?
                            <Tooltip title={ "Click to Zoom or Change" }>
                                <Button
                                    onClick={ () => {
                                        props.setSelectedElement( props.row )
                                        props.setDialogOpen( props.row.image.id )
                                    } }>
                                    <Typography className={ classes.gridText }>
                                        Show
                                    </Typography>
                                </Button>
                            </Tooltip>
                            :
                            <Tooltip title={ "Click to Select" }>
                                <Button
                                    disabled={ !( props.auth ) }
                                    onClick={ () => {
                                        props.setSelectedElement( props.row )
                                        props.setDialogOpen( 0 )
                                    } }>
                                    <Typography className={ classes.gridText }>
                                        Select
                                    </Typography>
                                </Button>
                            </Tooltip>
                        }
                    </TableCell> : null
                }
                {
                    props.showExplanation ?
                        <TableCell style={ {
                            minWidth: 200,
                            borderRightStyle: "groove",
                            maxWidth: 200
                        } }>
                            <InputBase
                                onBlur={ () => {
                                    if ( props.row.explanation !== null ) {
                                        props.handleAllTableExplanationSave( props.row.id,
                                            explanationText )
                                    } else {
                                        props.handleAllTableExplanationSave( props.row.id,
                                            "" )
                                    }

                                } }
                                onKeyUp={ ( e ) => {
                                    keyMap[ e.key ] = false;
                                } }
                                onKeyDown={ ( e ) => {
                                    keyMap[ e.key ] = true
                                    if ( keyMap[ 'Alt' ] && keyMap[ 'Enter' ] ) {
                                        setExplanationText( explanationText + "\n" )
                                    }
                                } }
                                className={ classes.textField }
                                fullWidth
                                multiline
                                onChange={ ( val ) => {
                                    setExplanationText( val.target.value )
                                } }
                                value={ explanationText }
                            />
                        </TableCell>
                        :
                        null
                }
                {
                    props.showAndroidKey ?
                        <TableCell style={ {
                            minWidth: 200,
                            borderRightStyle: "groove",
                            maxWidth: 200
                        } }>
                            <InputBase
                                onBlur={ () => {
                                    if ( props.row.androidKey !== null ) {
                                        props.handleAllTableAndroidKeySave( props.row.id, androidKey )
                                    } else {
                                        props.handleAllTableAndroidKeySave( props.row.id, "" )
                                    }

                                } }
                                onKeyUp={ ( e ) => {
                                    keyMap[ e.key ] = false;
                                } }
                                onKeyDown={ ( e ) => {
                                    keyMap[ e.key ] = true
                                    if ( keyMap[ 'Alt' ] && keyMap[ 'Enter' ] ) {
                                        // setExplanationText( explanationText + "\n" )
                                    }
                                } }
                                className={ classes.textField }
                                fullWidth
                                disabled={ false }
                                multiline
                                disabled={ !( props.userRole !== null && props.userRole.authAndroid ) }
                                onChange={ ( val ) => {
                                    setAndroidKey( val.target.value )
                                } }
                                value={ androidKey }
                            />
                        </TableCell>
                        :
                        null
                }
                <AllLanguagesPanelCell
                    category={ props.category }
                    handleAllTableBaseSave={ props.handleAllTableBaseSave }
                    handleAllTableLocalizationSave={ props.handleAllTableLocalizationSave }
                    handleAllTableSetProjectAllTable={ props.handleAllTableSetProjectAllTable }
                    allTable={ props.allTable }
                    base={ props.row }
                    auth={ props.auth }
                    isBase={ true }
                    text={ props.row.englishWord }
                    id={ props.row.id }
                    rowIndex={ props.index }/>
                { props.row.localizations.map( ( rowEl, index ) => {
                    if ( !rowEl.base ) {
                        return <AllLanguagesPanelCell
                            category={ props.category }
                            handleAllTableBaseSave={ props.handleAllTableBaseSave }
                            handleAllTableLocalizationSave={ props.handleAllTableLocalizationSave }
                            handleAllTableSetProjectAllTable={ props.handleAllTableSetProjectAllTable }
                            allTable={ props.allTable }
                            isBase={ false }
                            auth={ props.auth }
                            localization={ rowEl }
                            text={ rowEl.text }
                            rowIndex={ props.index }
                            id={ rowEl.id }
                            localizationIndex={ index }/>
                    } else {
                        return null
                    }
                } ) }
                <Snackbar open={ copyWarning.length > 0 }
                          autoHideDuration={ 6000 }
                          onClose={ () => setCopyWarning( "" ) }
                >
                    <Alert onClose={ () => setCopyWarning( "" ) }
                           severity={ "warning" }
                    >
                        { copyWarning }
                    </Alert>
                </Snackbar>
                <Snackbar open={ successOpen }
                          autoHideDuration={ 1000 }
                          onClose={ () => setSuccessOpen( false ) }
                >
                    <Alert onClose={ () => setSuccessOpen( false ) }
                           severity={ "success" }
                    >
                        Copied
                    </Alert>
                </Snackbar>
            </TableRow>
        else
            return <div></div>
    }

}