import React from "react";
import {
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    TextField
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Colors from "../../../../constants/Colors";
import Env from "../../../../constants/Env";
import { AddOutlined, RemoveOutlined } from "@material-ui/icons";

const useStyles = makeStyles( () => ( {
    button: {
        borderRadius: 10,
        height: 35,
        width: 200,
        backgroundColor: Colors.PRIMARY_BLUE,
        '&:hover': {
            backgroundColor: Colors.PRIMARY_PINK_UP,
        }
    },
    dialog: {
        background: Colors.gradientMainLayoutBlue,
    },
    buttonText: {
        fontWeight: "bold",
        fontSize: 16,
        fontFamily: Colors.fontOfProgram,
        color: Colors.PRIMARY_WHITE,
    },
    buttonGroup: {
        padding: 10,
        display: "flex",
        justifyContent: "space-evenly",
    },
    title: {
        color: Colors.PRIMARY_BLUE,
        fontWeight: "bold",
        fontSize: 32,
        fontFamily: Colors.fontOfProgram,
        paddingBottom: 10,
        paddingTop: 10,

    },
    grid: {
        display: "flex",
        justifyContent: "center",
    },

    chip: {
        color: Colors.PRIMARY_WHITE,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        marginTop: 8,
        maxWidth: "100%"
    },
    popover: {
        pointerEvents: 'none',
    },
    popoverText: {
        width: 300,
        padding: 20,
        color: Colors.PRIMARY_BLACK,
        border: "solid",
    },
    dialogBodyText: {
        fontWeight: "bold",
        paddingRight: 20,
        fontSize: 16,
        fontFamily: Colors.fontOfProgram,
        color: Colors.PRIMARY_GREY,
    },
    buttonCreate: {
        width: 40,
        marginBottom: 20,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_GREEN_LIGHT,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_GREEN_LIGHT,
            color: Colors.PRIMARY_WHITE,
        }
    },
    buttonDelete: {
        width: 40,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        color: Colors.PRIMARY_WHITE,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_WHITE,
            color: Colors.PRIMARY_PINK_ALT,
        }
    },
    gridRow: {
        alignItems: "center",
        background: Colors.PRIMARY_BACKGROUND,
        paddingLeft: 16,
        borderRadius: 10,
        marginBottom: 4,
    },
} ) )


export default function ExplanationUploadDialog( props ) {
    const classes = useStyles()
    const [ explanations, setExplanations ] = React.useState( [] )
    const [ currentExplanation, setCurrentExplanation ] = React.useState( "" )

    return <Dialog
        className={ classes.dialog }
        open={ props.isOpen }
        fullWidth
        onClose={ () => props.onClose() }>
        <DialogTitle>
            <Typography className={ classes.title }>
                Add Description Texts
            </Typography>
            <Typography className={ classes.dialogBodyText }>
                Press Enter to add multiple descriptions.
            </Typography>
        </DialogTitle>
        <DialogContent className={ classes.grid }>
            <Grid container className={ classes.grid }>
                <Grid container>
                    <Grid item xs={ 11 }>
                        <TextField
                            multiline={ true }
                            fullWidth
                            key={ "textFieldOfExplanationUpload" }
                            value={ currentExplanation }
                            onChange={
                                event => {
                                    setCurrentExplanation( event.target.value )
                                }
                            }
                            onKeyDown={ ( e ) => {
                                if ( e.key === 'Enter' )
                                    e.preventDefault()
                                if ( e.key === 'Enter' && currentExplanation.trim().length > 0 ) {
                                    let check = true

                                    explanations.forEach( explanation => {
                                        if ( explanation.trim()
                                                        .toLowerCase() ===
                                            currentExplanation.trim()
                                                              .toLowerCase() ) {
                                            check = false
                                        }
                                    } )

                                    if ( check ) {
                                        let temp = [ ...explanations ]
                                        temp.push( currentExplanation.trim() )
                                        setExplanations( temp )
                                        setCurrentExplanation( "" )
                                    }
                                }
                            } }

                        />
                    </Grid>
                    <Grid item xs={ 1 }>
                        <Button onClick={ () => {
                            if ( currentExplanation.trim().length > 0 ) {
                                let check = true

                                explanations.forEach( explanation => {
                                    if ( explanation.trim()
                                                    .toLowerCase() === currentExplanation.trim()
                                                                                         .toLowerCase() ) {
                                        check = false
                                    }
                                } )

                                if ( check ) {
                                    let temp = [ ...explanations ]
                                    temp.push( currentExplanation.trim() )
                                    setExplanations( temp )
                                    setCurrentExplanation( "" )
                                }
                            }
                        } } className={ classes.buttonCreate } color="primary">
                            <AddOutlined/>
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    { explanations.map( el => {
                        return <Grid container className={ classes.gridRow }>
                            <Grid item xs={ 11 }>
                                <Typography aria-multiline={ true } noWrap={ false }>
                                    { el }
                                </Typography>
                            </Grid>
                            <Grid item xs={ 1 }>
                                <Button onClick={ () => {
                                    let temp = []
                                    explanations.forEach( exp => {
                                        if ( exp !== el )
                                            temp.push( exp )
                                    } )

                                    setExplanations( temp )
                                } } className={ classes.buttonDelete } color="primary">
                                    <RemoveOutlined/>
                                </Button>
                            </Grid>
                        </Grid>
                    } ) }
                </Grid>
                {
                    /*
                                    <Grid container className={classes.grid}>
                    <Grid item xs={6}>
                        {explanations.map(explanation => {
                            return <div
                                key={"productDetail-dialog-explanation-" + explanation.id}
                            >
                                <Chip
                                    onMouseEnter={(event) => {
                                        setAnchorEl(event.currentTarget)
                                        setPopover(explanation)
                                    }}
                                    onMouseLeave={() => {
                                        setAnchorEl(null)
                                        setPopover("")
                                    }}
                                    className={classes.chip}
                                    label={explanation}
                                    key={explanation}
                                    onClick={
                                        () => {
                                            let temp = []
                                            explanations.forEach(exp => {
                                                if (exp !== explanation)
                                                    temp.push(exp)
                                            })

                                            setExplanations(temp)
                                            setAnchorEl(null)
                                            setPopover("")
                                        }
                                    }/>
                                <Popover
                                    onClose={() => setPopover("")}
                                    open={popover !== ""}
                                    className={classes.popover}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    disableRestoreFocus
                                >
                                    <Typography className={classes.popoverText}>
                                        {popover}
                                    </Typography>
                                </Popover>
                            </div>
                        })}
                    </Grid>

                </Grid>

                     */
                }
            </Grid>
        </DialogContent>
        <DialogActions className={ classes.grid }>
            <ButtonGroup className={ classes.buttonGroup }>
                <Button
                    disabled={ explanations.length === 0 }
                    className={ classes.button }
                    onClick={
                        () => {
                            if ( explanations.length > 0 ) {
                                const returnObject = []

                                explanations.forEach( explanation => {
                                    returnObject.push( {
                                        "content": explanation,
                                        "textIdList": []
                                    } )
                                } )

                                const headers = {
                                    "Content-Type": "application/json",
                                    'Authorization': 'Bearer ' + Env.TOKEN
                                }

                                fetch(
                                    process.env.REACT_APP_URL + 'projects/explanations?projectId=' + props.project.id,
                                    {
                                        method: 'POST', headers: headers, body: JSON.stringify( returnObject )
                                    }, )
                                    .then( response => {
                                        if ( response.ok ) return response.json()
                                        else throw new Error();
                                    } )
                                    .then( data => {
                                        props.handleExplanationAddition( data )
                                    } )
                                    .then( () => props.onClose() )
                                    .catch( e => {
                                        //console.log(e)
                                    } )

                                setExplanations( [] )
                            }
                        }
                    }
                >
                    <Typography className={ classes.buttonText }>
                        Save
                    </Typography>
                </Button>
                <Button
                    className={ classes.button }
                    onClick={ () => props.onClose() }
                >
                    <Typography className={ classes.buttonText }>
                        Cancel
                    </Typography>
                </Button>
            </ButtonGroup>
        </DialogActions>
    </Dialog>
}