import React from "react";
import { Chip, Dialog, Grid, LinearProgress, makeStyles, Snackbar, Typography } from "@material-ui/core";
import Colors from "../../../../constants/Colors";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { Close } from "@material-ui/icons";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Alert } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import Env from "../../../../constants/Env";

const useStyles = makeStyles( () => ( {
    dialog: {
        background: Colors.gradient,
    },
    formControl: {
        width: "100%",
        color: Colors.PRIMARY_PINK_UP,
        paddingBottom: 4,
    },
    title: {
        fontSize: 32,
        fontWeight: "bold",
        color: Colors.PRIMARY_BLUE,
        fontFamily: Colors.fontOfProgram,
    },
    grid: {
        alignItems: 'center',
        display: "flex",
        justifyContent: "space-between",
    },
    bodyText: {
        fontWeight: "bold",
        color: Colors.PRIMARY_BLUE,
        fontSize: 16,
        fontFamily: Colors.fontOfProgram,
    },
    bodyBottomText: {
        color: Colors.PRIMARY_PINK,
        fontWeight: "bold",
        fontSize: 20,
        fontFamily: Colors.fontOfProgram,
    },
    dialogContent: {
        padding: 28,
    },
    dialogAction: {
        justifyContent: "center",
        display: "flex",
        paddingBottom: 20,
    },
    buttonCreate: {
        width: 200,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_GREEN_LIGHT,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_YELLOW_LIGHT,
        }
    },
    icon: {
        fontSize: 32,
        color: Colors.PRIMARY_PINK_UP,
    },
    buttonCancel: {
        width: 200,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_PINK,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_BLUE,
        }
    },
    buttonText: {
        fontWeight: "bold",
        color: Colors.PRIMARY_WHITE,
    },
    progressBar: {
        background: Colors.gradient,
        borderRadius: 10,
        marginBottom: 10,
        height: 5,
        marginLeft: 10,
        marginRight: 10,
    }
} ) )

export default function RoleAdditionDialog( props ) {
    const classes = useStyles();
    const [ roleText, setRoleText ] = React.useState( "" );
    const [ roles, setRoles ] = React.useState( [] );
    const [ submitted, setSubmitted ] = React.useState( false )
    const [ successOpen, setSuccessOpen ] = React.useState( false )
    const [ errorOpen, setErrorOpen ] = React.useState( false )
    const [ infoOpen, setInfoOpen ] = React.useState( false )

    const handleInfoOpen = () => {
        setInfoOpen( true )
    }

    const handleInfoClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }
        setInfoOpen( false );
    };

    const handleErrorOpen = () => {
        setErrorOpen( true )
    }

    const handleErrorClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }

        setErrorOpen( false );
    };

    const handleSuccessClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }
        props.handleClose();
        setSuccessOpen( false );
    };

    const handleSuccessOpen = () => {
        setSuccessOpen( true )
    }

    function checkAlreadyExist( value ) {
        let check = false;
        props.project.roles.forEach( role => {
            if ( role.role.toLowerCase()
                     .trim() === value.toLowerCase()
                                      .trim() ) {
                check = true
            }
        } )
        return check
    }

    const handleRoleAddition = ( value ) => {
        if ( roles.indexOf( value.toLowerCase() ) !== -1 || checkAlreadyExist( value ) || value.length < 1 ) {
            handleInfoOpen()
        } else {
            setRoles( [ ...roles, value.toLowerCase() ] )
        }

    }

    const handleRoleDeletion = ( value ) => {
        let temp = []
        roles.forEach( role => {
            if ( role !== value )
                temp.push( role )
        } )
        setRoles( temp )
    }

    return <Dialog fullWidth={ true } maxWidth={ 'md' } className={ classes.dialog } open={ props.isOpen }>
        <DialogTitle id="form-dialog-title">
            <Grid className={ classes.grid } container>
                <Grid item xs={ 5 }>
                    <Typography className={ classes.title }>
                        Add a Work Title
                    </Typography>
                </Grid>
                <Grid item xs={ 1 }>
                    <Button onClick={ props.handleClose }>
                        <Close className={ classes.icon }/>
                    </Button>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent className={ classes.dialogContent }>
            <TextField
                className={ classes.textField }
                autoFocus
                disabled={ submitted }
                value={ roleText }
                onChange={ ( value ) => {
                    setRoleText( value.target.value )
                } }
                onKeyDown={ ( e ) => {
                    if ( e.key === 'Enter' ) {
                        if ( roleText.trim().length > 200 ) handleInfoOpen()
                        else {
                            handleRoleAddition( roleText )
                            setRoleText( "" )
                        }
                    }
                } }
                margin="dense"
                id="name"
                label="Press Enter to add multiple titles..."
                type="text"
                fullWidth
            />
            <div>
                { roles.map( ( value ) => (
                    <Chip
                        key={ "productDetail-dialog-Project-Roles-" + value }
                        disabled={ submitted } onClick={ () => handleRoleDeletion( value ) }
                        label={ value }
                    />
                ) ) }
            </div>

        </DialogContent>
        <DialogActions className={ classes.dialogAction }>
            <Button onClick={ () => {
                if ( roles.length > 0 ) {
                    let requestList = [];
                    roles.map( ( role ) => {
                        return requestList.push( {
                            "projectId": props.project.id,
                            "role": role
                        } )
                    } )
                    setSubmitted( true )
                    const headers = {
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + Env.TOKEN
                    }
                    fetch( process.env.REACT_APP_URL + 'projects/projectRoles', {
                        method: 'POST', headers: headers, body: JSON.stringify( requestList )
                    }, )
                        .then( response => {
                            if ( response.ok ) return response.json()
                            else throw new Error();
                        } )
                        .then( data => {
                            props.handleTitleAddition( data )
                            setSubmitted( false );
                            setRoles( [] );
                            handleSuccessOpen();
                            return data
                        } )
                        .catch( () => {
                            setSubmitted( false );
                            handleErrorOpen()
                        } )
                }
            } } className={ classes.buttonCreate } color="primary">
                <Typography
                    className={ classes.buttonText }>
                    Add
                </Typography>
            </Button>

        </DialogActions>
        <Snackbar open={ errorOpen }
                  autoHideDuration={ 6000 }
                  onClose={ handleErrorClose }
        >
            <Alert onClose={ handleErrorClose }
                   severity={ "warning" }
            >
                Something went wrong, try it later.
            </Alert>
        </Snackbar>
        <Snackbar open={ successOpen }
                  autoHideDuration={ 1000 }
                  onClose={ handleSuccessClose }
        >
            <Alert onClose={ handleSuccessClose }
                   severity={ "success" }
            >
                Successfully added
            </Alert>
        </Snackbar>
        <Snackbar open={ infoOpen }
                  autoHideDuration={ 1000 }
                  elevation={ 6 }
                  onClose={ handleInfoClose }
        >
            <Alert onClose={ handleInfoClose }
                   severity={ "info" }
            >
                Duplicated category or length of it exceeds the bounds(200 characters)
            </Alert>
        </Snackbar>
        { submitted ? <LinearProgress className={ classes.progressBar } color={ 'secondary' }/> : <div/> }
    </Dialog>
}

/*
 <Button
                onClick={() => {
                    const headers = {
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJlbXJlQGdtYWlsLmNvbSIsImV4cCI6MTYyNTg5NjU1OX0.b5h176OdkBRCDauqkQ_rJhfg6I9YdDkYMEp_ywcsi_FpSUqMIdS90XqZo3KUhWpI77uSNF_ZcGQQxt-KEQPVmQ'
                    }
                    fetch('http://localhost:8080/projects/invitations?invitationId=' + props.invitation.id, {
                        method: 'DELETE', headers: headers,
                    },)
                        .then(response => response.json()).then(data => {
                        console.log(data);
                        props.handleClose();
                    })
                }}
                className={classes.buttonCancel}
                color="primary">
                <Typography
                    className={classes.buttonText}>
                    Reject
                </Typography>
            </Button>
 */