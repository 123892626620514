import React from "react";
import {
    Button, ButtonGroup,
    Dialog,
    FormControlLabel,
    Grid,
    makeStyles,
    Radio,
    RadioGroup, Tab,
    Tabs, Tooltip,
    Typography
} from "@material-ui/core";
import Colors from "../../../../../../constants/Colors";
import TranslationOrderCategoryCard from "./TranslationOrderCategoryCard";
import TranslationOrderTextViewerAsList from "./TranslationOrderTextViewerAsList";
import {SystemUpdateAlt} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
    button: {
        height: 40,
        width: 400,
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        justifyItems: "center",
        justifyContent: "center",
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        '&:hover': {
            backgroundColor: Colors.PRIMARY_PINK_UP,

        }
    },
    icon: {
        fontSize: 30,
        color: Colors.PRIMARY_WHITE
    },
    iconButton: {
        height: 35,
        width: 50,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        '&:hover': {
            backgroundColor: Colors.PRIMARY_PINK_UP,

        }
    },
    buttonText: {
        paddingTop: 4,
        fontWeight: "bold",
        fontSize: 16,
        fontFamily: Colors.fontOfProgram,
        color: Colors.PRIMARY_WHITE,
    },
    buttonGroup: {
        padding: 10,
        display: "flex",
        justifyContent: "space-evenly",
    },
    grid: {
        padding: 20,
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        color: Colors.PRIMARY_WHITE,
        background: Colors.gradient,
    },
    gridAlternative: {
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        color: Colors.PRIMARY_WHITE,
        background: Colors.PRIMARY_BACKGROUND,
    },
    subGrid: {
        borderColor: Colors.PRIMARY_PINK_ALT,
        borderWidth: 1,
        borderTopWidth: 0,
        border: "solid",
        padding: 20,
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
    },
    title: {
        display: "flex",
        justifyContent: "center",
        color: Colors.PRIMARY_PINK_UP,
        fontWeight: "bold",
        fontFamily: Colors.fontOfProgram,
        fontSize: 24,
        paddingTop: 20,
    },
    titleSubChild: {
        display: "flex",
        justifyContent: "center",
        color: Colors.PRIMARY_GREY,
        fontWeight: "bold",
        fontFamily: Colors.fontOfProgram,
        fontSize: 16,
        paddingBottom: 20,
    },
    subTitleChild: {
        color: Colors.PRIMARY_WHITE,
        fontWeight: "bold",
        fontFamily: Colors.fontOfProgram,
        fontSize: 16,
    },
    paper: {
        background: Colors.gradient,
        justifyContent: "space-between",
        alignItems: "center",
        justifyItems: "center",
        padding: 20,
    },
    scrollableDiv: {
        maxHeight: 800,
        overflowY: "scroll",
    },
    text: {
        color: Colors.PRIMARY_WHITE,
        fontSize: 20,
        fontFamily: Colors.fontOfProgram,
        fontWeight: "bold",
    },
    divSelected: {
        justifyContent: "center", display: "flex", alignItems: "center", alignContent: "center",
        backgroundColor: Colors.PRIMARY_PINK_UP,
        fontWeight: "bold",
        borderRadius: 10,
        padding: 5,
        cursor: "pointer",
        color: Colors.PRIMARY_WHITE,
        maxHeight: 500,
        overflowY: "scroll",
        maxWidth: 400,
    },
    divNotSelected: {
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        borderRadius: 10,
        alignContent: "center",
        padding: 5,
        cursor: "pointer",
        fontWeight: "bold",
        color: Colors.PRIMARY_PINK_ALT,
        maxHeight: 500,
        overflowY: "scroll",
        maxWidth: 400,
    },
    subButtonText: {
        color: Colors.PRIMARY_WHITE,
        fontWeight: "bold",
        fontFamily: Colors.fontOfProgram,
        fontSize: 12,
    },
    explanationGrid: {
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
    },
    bodyGrid: {
        paddingBottom: 20,
    },
    dialog: {
        justifyContent: "center",
        display: "flex",
        padding: 20,
    },
    radioGroup: {
        display: "flex",
        justifyContent: "center",
    },
    divider: {
        borderRadius: 50,
        marginLeft: 80,
        marginRight: 80,
        borderColor: Colors.PRIMARY_BLUE
    },
    buttonImportGrid: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingRight: 40,
        paddingTop: 20,
        paddingBottom: 20,
    },
    gridButton: {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        justifyItems: "center",
    },
    gridButtonIcon: {
        marginTop: 10,
        color: Colors.PRIMARY_WHITE,
        fontSize: 24,
    },
    indicator: {
        height: 8,
        borderTopLeftRadius: 50,
        borderTopRightRadius: 50,
        background: Colors.PRIMARY_WHITE,
        width: '100%',
        display: "flex",
        justifyContent: 'center',
    },
    tab: {
        color: Colors.PRIMARY_WHITE,
        "&.Mui-selected": {
            color: Colors.PRIMARY_WHITE,
        }
    },
    innerTab: {
        color: Colors.PRIMARY_WHITE,
        "&.Mui-selected": {
            color: Colors.PRIMARY_WHITE,
        }
    },
    gridHeadCell: {
        color: Colors.PRIMARY_BLUE,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
    },
    tabsGrid: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        background: Colors.PRIMARY_BLUE,
        marginLeft: 80,
        marginRight: 80,
        borderRadius: 10,
    },
    groupButtonLabel: {
        backgroundColor: Colors.PRIMARY_WHITE,
        borderRadius: 10,
        padding: 2,
    },
    groupButtonText: {
        fontWeight: "bold",
        alignItems: "center",
        fontFamily: Colors.fontOfProgram,
        fontSize: 16,
    },
    groupButton: {
        width: 360,
        height: 32,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_BLUE,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_BLUE,
            color: Colors.PRIMARY_WHITE,
        }
    },
    buttonGroupButtons: {
        borderRadius: 10,
        background: Colors.PRIMARY_BLUE,
        padding: 4,
    }
}))

export default function TranslationOrderTextCreator(props) {
    const classes = useStyles()
    const [importDialog, setImportDialog] = React.useState(false)
    const [selectedLocalizations, setSelectedLocalizations] = React.useState([])
    const [language, setLanguage] = React.useState("")
    const [texts, setTexts] = React.useState([])
    const [currentTab, setCurrentTab] = React.useState(0)
    let images = []

    props.project.images.forEach(image => {
        images.push(image)
    })

    function handleTextLocalizationSelection(localization) {

        let temp = [...selectedLocalizations, localization]
        setSelectedLocalizations(temp)
    }

    function importSelectedTextToOrderText() {
        let temp = []
        selectedLocalizations.forEach(loc => {
            if ((loc.projectLanguage !== null && loc.projectLanguage.language.language === language) ||
                (loc.base && props.project.base.language === language)
            ) {
                props.project.categories.forEach(category => {
                    category.texts.forEach(text => {
                        text.localizations.forEach(localization => {
                            if (localization.id === loc.id) {
                                temp.push({
                                    "id": text.id,
                                    "text": {
                                        "text": loc.text,
                                        "id": loc.id,
                                        "projectLanguage": loc.projectLanguage,
                                        "baseText": {
                                            "id": text.id,
                                            "englishWord": text.englishWord
                                        }
                                    },
                                    "equivalent": null,
                                    "englishShownWord": text.englishWord,
                                    "image": text.image,
                                    "explanation": text.explanation,
                                })
                            }
                        })
                    })
                })
            }
        })
        setTexts(temp)
    }

    function checkLocalizationChecked(id) {
        let check = false

        selectedLocalizations.forEach(loc => {
            if (loc.id === id)
                check = true
        })

        return check;
    }

    function handleTextLocalizationUnSelection(id) {
        let temp = []

        selectedLocalizations.forEach(loc => {
            if (loc.id !== id) {
                temp.push(loc)
            }
        })

        setSelectedLocalizations(temp)
    }

    function handleAddAll(texts) {
        let temp = []
        texts.forEach(text => {
            text.localizations.forEach(localization => {
                if (!checkLocalizationChecked(localization.id)) {
                    temp.push(localization)
                }
            })
        })

        setSelectedLocalizations([...selectedLocalizations, ...temp])
    }

    function handleEmptiesAddition(texts) {
        let temp = []

        texts.forEach(text => {
            text.localizations.forEach(localization => {
                if ((localization.text === null || localization.text.trim().length === 0) &&
                    !checkLocalizationChecked(localization.id)) {
                    temp.push(localization)
                }
            })
        })

        setSelectedLocalizations([...selectedLocalizations, ...temp])
    }

    function handleDeleteAll(texts) {
        let temp = []

        selectedLocalizations.forEach(loc => {
            let check = true
            texts.forEach(text => {
                text.localizations.forEach(localization => {
                    if (loc.id === localization.id) {
                        check = false
                    }
                })
            })
            if (check)
                temp.push(loc)
        })

        setSelectedLocalizations(temp)
    }

    function selectAll() {
        let temp = []
        props.project.categories.forEach(category => {
            category.texts.forEach(text => {
                text.localizations.forEach(loc => {
                    temp.push(loc)
                })
            })
        })
        setSelectedLocalizations(temp)
    }

    function checkAllChecked() {
        let check = true;

        props.project.categories.forEach(category => {
            category.texts.forEach(text => {
                text.localizations.forEach(loc => {
                    if (!checkLocalizationChecked(loc.id)) {
                        check = false
                    }
                })
            })
        })

        return check;
    }

    function selectAllEmpties() {
        let temp = []
        props.project.categories.forEach(category => {
            category.texts.forEach(text => {
                text.localizations.forEach(localization => {
                    if ((localization.text === null || localization.text.trim().length === 0) &&
                        !checkLocalizationChecked(localization.id)) {
                        temp.push(localization)
                    }
                })
            })
        })

        setSelectedLocalizations([...selectedLocalizations, ...temp])

    }

    function handleEnglishShownWordChange(value, textId) {
        let textTemp = [...texts]
        textTemp.forEach(text => {
            if (text.id === textId) {
                text.englishShownWord = value
            }
        })
        setTexts(textTemp)
    }

    function handleImageChange(img, textId) {
        let textTemp = [...texts]
        textTemp.forEach(text => {
            if (text.id === textId) {
                text.image = img
            }
        })
        setTexts(textTemp)
    }

    function handleExplanationChange(value, textId, explanationId) {
        let textTemp = [...texts]

        textTemp.forEach(text => {
            if (text.id === textId) {
                text.explanation = {
                    "id": explanationId,
                    "content": value
                }
            }
        })
        setTexts(textTemp)
    }

    return <Grid container>
        <Grid container className={classes.buttonGroup}>
            <Button onClick={() => setImportDialog(true)} className={classes.button}>
                <Typography className={classes.buttonText}>
                    Choose Texts
                </Typography>
            </Button>
        </Grid>
        {texts.length !== 0 ? <TranslationOrderTextViewerAsList
            language={language}
            handleClose={() => props.handleClose()}
            functionList={props.functionList}
            orderTitle={props.orderTitle}
            texts={texts}
            handleEnglishShownWordChange={(val, textId) => handleEnglishShownWordChange(val, textId)}
            handleExplanationChange={(val, textId, explanationId) => handleExplanationChange(val, textId,
                explanationId)}
            handleImageChange={(val, textId) => handleImageChange(val, textId)}
            textIdList={props.selectedTexts}
            project={props.project}
            order={props.oldOrder}
            program={props.program}
            name={props.name}
            surname={props.surname}
            setPage={props.setPage}
            email={props.email}
            date={props.date}
            translator={props.translator}
        /> : null
        }
        <Dialog open={importDialog}
                fullWidth={true}
                maxWidth={"lg"} onClose={() => {
            setImportDialog(false)
        }}>
            <div>

                <Typography className={classes.title}>
                    Choose Language
                </Typography>
                <Typography className={classes.titleSubChild}>
                    Choose the target language for the job.
                </Typography>
                <RadioGroup aria-label={"language"}
                            value={language}
                            onChange={(val) => setLanguage(val.target.value)}>
                    <div className={classes.radioGroup}>
                        {props.project.projectLanguages.map(lan => {
                            return <FormControlLabel
                                labelPlacement={"top"}
                                value={lan.language.language}
                                control={<Radio/>}
                                label={lan.language.language}/>
                        })}
                    </div>
                </RadioGroup>
                <hr className={classes.divider}/>
                {language === '' ?
                    null
                    :
                    <Grid container>
                        <Grid container className={classes.buttonGroup}>
                            <Grid item className={classes.buttonGroupButtons}>
                                <ButtonGroup>
                                    <label className={classes.groupButtonLabel}>
                                        <Tooltip title={"Select All Untranslated Lines"}>
                                            <Button onClick={() => selectAllEmpties()}
                                                    className={classes.groupButton}>
                                                <Typography className={classes.groupButtonText}>
                                                    Select All Untranslated
                                                </Typography>
                                            </Button>
                                        </Tooltip>
                                    </label>
                                    <label className={classes.groupButtonLabel}>
                                        <Tooltip title={checkAllChecked() ? "Unselect All Lines" :
                                            "Select All Lines"}>
                                            <Button
                                                onClick={() => {
                                                    if (checkAllChecked()) {
                                                        setSelectedLocalizations([])
                                                    } else {
                                                        selectAll()
                                                    }
                                                }}
                                                className={classes.groupButton}>
                                                <Typography className={classes.groupButtonText}>
                                                    {checkAllChecked() ? "Unselect All" :
                                                        "Select All"}
                                                </Typography>
                                            </Button>
                                        </Tooltip>
                                    </label>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.tabsGrid}>
                            <Tabs
                                id={'text-creator'}
                                key={'text-creator'}
                                classes={{
                                    indicator: classes.indicator
                                }}
                                className={classes.innerTab}
                                value={currentTab}
                                onChange={(event, index) => setCurrentTab(index)}
                                variant={"scrollable"}
                                scrollButtons={"auto"}
                                aria-label={"scrollable auto outer tabs"}
                            >
                                {props.project.categories.map((category, index) => {
                                    return <Tooltip title={"Click to View " + category.categoryName + " category."}>
                                        <Tab
                                            key={"creator-tab-" + index}
                                            className={classes.tab}
                                            label={category.categoryName}
                                            aria-controls={`scrollable-creator-tab-auto-tabpanel-${index}`}
                                            id={`scrollable-creator-tab-auto-tab-${index}`}
                                        />
                                    </Tooltip>

                                })}
                            </Tabs>
                        </Grid>
                        <TranslationOrderCategoryCard
                            project={props.project}
                            selectedLocalizations={selectedLocalizations}
                            handleAddition={(val) => {
                                handleTextLocalizationSelection(val)
                            }}
                            handleDeletion={(id) => {
                                handleTextLocalizationUnSelection(id)
                            }}
                            handleAllAddition={(texts) => {
                                handleAddAll(texts)
                            }}
                            handleDeleteAll={(texts) => {
                                handleDeleteAll(texts)
                            }}
                            handleEmptiesAddition={(texts) => {
                                handleEmptiesAddition(texts)
                            }}
                            language={language}
                            category={props.project.categories[currentTab]}/>

                        <Grid container className={classes.buttonImportGrid}>
                            <Grid item>
                                <Tooltip title={"Import Selected Lines to the Job"}>
                                    <Button
                                        disabled={language === ""}
                                        onClick={() => {
                                            importSelectedTextToOrderText()
                                            setImportDialog(false)
                                        }}
                                        className={classes.button}
                                        style={{maxWidth: 200}}
                                    >
                                        <Grid spacing={2} container className={classes.gridButton}>
                                            <Grid item>
                                                <Typography className={classes.buttonText}>
                                                    Import
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <SystemUpdateAlt className={classes.gridButtonIcon}/>
                                            </Grid>
                                        </Grid>
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </div>
        </Dialog>
    </Grid>
}