import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Drawer,
    AppBar,
    Toolbar,
    List,
    CssBaseline,
    Typography,
    Divider,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText, Collapse, Button, CircularProgress, Snackbar, Tooltip, Popover, Grid, Paper
} from '@material-ui/core';

import {
    Menu,
    ChevronLeft,
    ChevronRight,
    ExitToApp,
    Refresh,
    Person, NotificationImportant, People, SearchTwoTone, Textsms, TextFieldsRounded
} from '@material-ui/icons';
import ProjectsPage from "./projectScreens/ProjectsPage";
import Colors from "./../constants/Colors";
import UserShortCutsPage from "./userShortcutScreens/UserShortCutsPage";
import ProjectListItem from "./ProjectListItem";
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import EmailIcon from '@material-ui/icons/Email';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import CompanyShortCutsPage from "./companyShortcutScreens/CompanyShortCutsPage";
import ProjectStatus from "./projectScreens/projectDetailScreens/ProjectStatus";
import ProjectTexts from "./projectScreens/projectDetailScreens/ProjectTexts";
import ProjectSettings from "./projectScreens/projectDetailScreens/ProjectSettings";
import { Alert } from "@material-ui/lab";
import TextSearchDialog from "./TextSearchDialog";

const drawerWidth = 260;

const useStyles = makeStyles( ( theme ) => ( {
    root: {
        display: 'flex',
    },
    grow: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create( [ 'width', 'margin' ], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        } ),
        background: Colors.gradientMainLayoutGreen,
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${ drawerWidth }px)`,
        transition: theme.transitions.create( [ 'width', 'margin' ], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        } ),
    },
    menuButton: {
        color: Colors.PRIMARY_WHITE,
        marginRight: 36,
    },
    hide: {
        color: Colors.PRIMARY_WHITE,
        display: 'none',
    },
    drawer: {
        elevation: 0,
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        color: Colors.PRIMARY_WHITE,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create( 'width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        } ),
        background: Colors.gradientMainLayoutBlue,
        color: Colors.PRIMARY_WHITE,
    },
    drawerClose: {
        transition: theme.transitions.create( 'width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        } ),
        background: Colors.gradientMainLayoutBlueReverse,
        overflowX: 'hidden',
        color: Colors.PRIMARY_WHITE,
        width: theme.spacing( 9 ) + 1,
        [ theme.breakpoints.up( 'sm' ) ]: {
            width: theme.spacing( 9 ) + 1,
        },
    },
    notificationGrid: {
        paddingTop: 12,
        paddingBottom: 12,
        borderBottom: "groove",
        cursor: "pointer",
    },
    toolbar: {
        color: Colors.PRIMARY_WHITE,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing( 0, 1 ),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
        padding: theme.spacing( 3 ),
    },
    iconActive: {
        color: Colors.PRIMARY_WHITE,
    },
    iconClosed: {
        color: "#F5D26E",
    },
    signOutButton: {
        margin: 0,
        paddingLeft: 20,
        paddingRight: 20,
    },
    signOutIcon: {
        color: Colors.PRIMARY_WHITE,
        fontSize: 32,
        fontFamily: Colors.fontOfProgram,
    },
    circularProgress: {
        margin: 20
    },
    notificationContainer: {
        width: 400,
        color: Colors.PRIMARY_BLUE,
        padding: 20,
    },
    titleGrid: {
        alignItems: "start",

    },
    bodyGrid: {
        alignItems: "center",

    },
    notificationTitleText: {
        fontWeight: "bold",
        alignItems: "start",
        color: Colors.PRIMARY_PINK_ALT,
        fontSize: 24,
        fontFamily: Colors.fontOfProgram,
        paddingBottom: 4,
    },
    notificationBodyText: {
        alignItems: "start",
        fontWeight: "bold",
        color: Colors.PRIMARY_BLACK,
        display: "flex",
        justifyContent: "flex-start",
        textAlign: "start",
        fontSize: 16,
        fontFamily: Colors.fontOfProgram,
    },
    divider: {
        background: Colors.gradient,
        height: 8,
        borderRadius: 40,
    },
    notificationHead: {
        alignItems: "center",
        paddingBottom: 8,
        paddingTop: 8,
        borderRadius: 10,
        background: Colors.PRIMARY_BLUE,
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
    },
    notificationHeadText: {
        alignItems: "center",
        fontWeight: "bold",
        color: Colors.PRIMARY_WHITE,
        fontSize: 16,
        fontFamily: Colors.fontOfProgram,
    },
    notificationEnd: {
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-end",
    },
    notificationIcon: {
        fontWeight: "bold",
        color: Colors.PRIMARY_BLUE,
        fontSize: 32,
        fontFamily: Colors.fontOfProgram,
    },
    dashboard: {
        fontFamily: Colors.fontOfProgram
    }

} ) );


export default function MainLayoutPage( props ) {
    const classes = useStyles()
    const theme = useTheme()
    const [ open, setOpen ] = React.useState( true )
    const [ currentIndex, setCurrentIndex ] = React.useState( 0 )
    const [ projectCollapse, setProjectCollapse ] = React.useState( false )
    const [ updated, setUpdated ] = React.useState( false )
    const [ errorOpen, setErrorOpen ] = React.useState( false )
    const [ successOpen, setSuccessOpen ] = React.useState( false )
    const [ orderNotificationOpen, setOrderNotificationOpen ] = React.useState( null )
    const [ textSearchDialog, setTextSearchDialog ] = React.useState( false )
    let submittedOrders = []
    const items = [];
    const [ innerIndex, setInnerIndex ] = React.useState( 0 )
    const userProjects = [];
    let num = props.program.company != null ? props.program.company.projects.length : 0;

    /*
    props.program.user.permissions.forEach(permission => {
        let isExist = false;
        userProjects.forEach(project => {
            if (project.id === permission.project.id)
                isExist = true
        })
        if (props.program.company != null) {
            props.program.company.projects.forEach(project => {
                if (project.id === permission.project.id)
                    isExist = true
            })
        }
        if (!isExist)
            userProjects.push(permission.project)
    })
     */

    props.program.user.roles.forEach( role => {
        let isExist = false;

        userProjects.forEach( project => {
            if ( project.id === role.projectRole.project.id ) {
                isExist = true
            }
        } )

        if ( props.program.company != null ) {
            props.program.company.projects.forEach( project => {
                if ( project.id === role.projectRole.project.id ) {
                    isExist = true
                }
            } )
        }

        if ( !isExist ) {
            userProjects.push( role.projectRole.project )
        }

        let trace = -1
        for ( let a = 0; a < userProjects.length; a++ ) {
            if ( userProjects[ a ].id === role.projectRole.project.id ) {
                trace = a;
            }
        }

        if ( role.projectRole.admin ) {
            role.projectRole.project.translationOrders.forEach( order => {
                if ( order.type === "WRITE" && order.status === "COMPLETED" ) {
                    let check = true
                    submittedOrders.forEach( or => {
                        if ( or.id === order.id )
                            check = false
                    } )
                    if ( check ) {
                        let tempOrder = { ...order }
                        tempOrder.projectName = role.projectRole.project.name
                        tempOrder.projectId = role.projectRole.project.id
                        tempOrder.goToProject = () => {
                            handleDrawerButtonActions( trace + num + 3 )
                            handleInnerIndex( ( num + trace ) * 3 + 0 )
                            handleProjectListClick()

                        }
                        submittedOrders.push( tempOrder )
                    }

                }
            } )
        }

    } )

    if ( props.program.company != null ) {
        props.program.company.projects.forEach( ( project, index ) => {
            project.translationOrders.forEach( order => {
                if ( order.type === "WRITE" && order.status === "COMPLETED" ) {
                    let check = true
                    submittedOrders.forEach( or => {
                        if ( or.id === order.id )
                            check = false
                    } )
                    if ( check ) {
                        let tempOrder = { ...order }
                        tempOrder.projectName = project.name
                        tempOrder.projectId = project.id
                        tempOrder.goToProject = () => {
                            handleDrawerButtonActions( index + 3 )
                            handleInnerIndex( ( index ) * 3 )
                            handleProjectListClick()

                        }
                        submittedOrders.push( tempOrder )
                    }

                }
            } )
        } );
    }

    if ( props.program.company != null ) {
        props.program.company.projects.map( ( project, index ) => {
            return items.push(
                <ProjectListItem
                    key={ "company-project-list-item-key-" + index }
                    onClickHandler={ () => {
                        handleDrawerButtonActions( index + 3 )
                        handleInnerIndex( index * 3 )
                    } }
                    itemIndex={ index + 3 }
                    index={ currentIndex }
                    onClickInnerIndexHandler={ ( value ) => {
                        handleInnerIndex( index * 3 + value )
                    } }
                    innerIndex={ innerIndex } project={ project }/>
            );
        } );
    }

    userProjects.map( ( project, index ) => {
        return items.push(
            <ProjectListItem
                key={ "user-project-list-item-key-" + index }
                onClickHandler={ () => {
                    handleDrawerButtonActions( index + num + 3 )
                    handleInnerIndex( ( index + num ) * 3 )

                } }
                itemIndex={ index + num + 3 }
                index={ currentIndex }
                onClickInnerIndexHandler={ ( value ) => {
                    handleInnerIndex( ( index + num ) * 3 + value )
                } }
                innerIndex={ innerIndex } project={ project }
            /> )
    } )

    const handleInnerIndex = ( index ) => {
        setInnerIndex( index )
    }


    const handleProjectListClick = () => {
        setProjectCollapse( !projectCollapse )
    }

    const handleDrawerOpen = () => {
        setOpen( true );
    };

    const handleDrawerClose = () => {
        setOpen( false );
    };

    const handleDrawerButtonActions = ( index ) => {
        if ( index === 0 || index === 2 ) {
            setProjectCollapse( false )
        }
        setCurrentIndex( index );
    }

    const handleGoToProjects = () => {
        setCurrentIndex( 1 );
    }

    const handleGoToCompany = () => {
        setCurrentIndex( 2 );
    }
    return (
        <div className={ classes.root }>
            <CssBaseline/>
            <AppBar
                position="fixed"
                className={ clsx( classes.appBar, {
                    [ classes.appBarShift ]: open,
                } ) }
                elevation={ 0 }
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={ handleDrawerOpen }
                        edge="start"
                        className={ clsx( classes.menuButton, {
                            [ classes.hide ]: open,
                        } ) }
                    >
                        <Menu color="inherit"/>
                    </IconButton>
                    <Typography variant="h5" noWrap className={ classes.dashboard }>
                        Dashboard
                    </Typography>
                    <div className={ classes.grow }/>
                    { submittedOrders.length > 0 ?
                        <div>
                            <Button
                                onClick={ ( event ) => {
                                    setOrderNotificationOpen( event.currentTarget )
                                } }
                                className={ classes.signOutButton }>
                                <NotificationImportant className={ classes.signOutIcon }/>
                            </Button>
                            <Popover
                                id={ "popover-1" }
                                open={ orderNotificationOpen !== null }
                                anchorEl={ orderNotificationOpen }
                                onClose={ () => setOrderNotificationOpen( null ) }
                                anchorOrigin={ {
                                    vertical: "bottom",
                                    horizontal: "center"
                                } }
                                transformOrigin={ {
                                    vertical: "top",
                                    horizontal: "center"
                                } }
                            >
                                <Paper elevation={ 20 }>
                                    <Grid container className={ classes.notificationContainer }>
                                        <Grid item xs={ 12 } className={ classes.titleGrid }>
                                            <Typography className={ classes.notificationTitleText }>
                                                { "Translation Orders" }
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={ 12 } className={ classes.divider }>

                                        </Grid>
                                        {
                                            /*
                                            <Grid container className={classes.notificationHead}>
                                            <Grid item xs>
                                                <Typography className={classes.notificationHeadText}>
                                                    {"Project"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography className={classes.notificationHeadText}>
                                                    {"Order Title"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography className={classes.notificationHeadText}>
                                                    {"Translator"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography className={classes.notificationHeadText}>
                                                    {"Lines"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography className={classes.notificationHeadText}>
                                                    {"Word Count"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography className={classes.notificationHeadText}>
                                                    {""}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                             */
                                        }
                                        <Grid container>
                                            { submittedOrders.map( ( order ) => {
                                                return <Grid container className={ classes.bodyGrid }>
                                                    <Grid item xs={ 12 }
                                                          className={ classes.notificationGrid }
                                                          onClick={ () => {
                                                              order.goToProject()
                                                              setOrderNotificationOpen( null )
                                                          } }
                                                    >
                                                        <Typography className={ classes.notificationBodyText }>
                                                            { order.user.name.toUpperCase() + " " +
                                                            order.user.surname.toUpperCase() +
                                                            " has submitted " + order.projectName + "'s " +
                                                            order.orderTitle.toUpperCase() + " order" }
                                                        </Typography>
                                                    </Grid>
                                                    {
                                                        /*
                                                         <Grid item xs>
                                                        <Typography className={classes.notificationBodyText}>
                                                            {order.projectName}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography className={classes.notificationBodyText}>
                                                            {order.orderTitle}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography className={classes.notificationBodyText}>
                                                            {order.user.name + " " + order.user.surname}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography className={classes.notificationBodyText}>
                                                            {order.texts.length}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography className={classes.notificationBodyText}>
                                                            {order.textCount}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs className={classes.notificationEnd}>
                                                        <Tooltip title={"Go to this Project"}>
                                                            <Button
                                                                onClick={() => {
                                                                    order.goToProject()
                                                                    setOrderNotificationOpen(null)
                                                                }}>
                                                                <ArrowForwardIosOutlined
                                                                    className={classes.notificationIcon}/>
                                                            </Button>
                                                        </Tooltip>
                                                    </Grid>
                                                         */
                                                    }
                                                </Grid>
                                            } ) }
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Popover>
                        </div>
                        : null
                    }
                    <Typography variant="h5" noWrap >
                        { props.program.user.name + " " + props.program.user.surname }
                    </Typography>
                    <Grid item style={{
                        height:50,
                        background:Colors.PRIMARY_WHITE,
                        width:3,
                        borderRadius:10,
                        marginRight: 10, marginLeft:20
                    }}>

                    </Grid>

                    <Tooltip title={ "Keyword Search" }>
                        <Button
                            onClick={ () => {
                                setTextSearchDialog( true )
                            } }
                            className={ classes.signOutButton }>
                            <TextFieldsRounded className={ classes.signOutIcon }/>
                        </Button>
                    </Tooltip>
                    {
                        /*
                        updated ?
                            <CircularProgress
                                size={ 24 }
                                className={ classes.circularProgress }/>
                            :
                            <Tooltip title={ "Refresh Page" }>
                                <Button
                                    onClick={ async () => {
                                        setUpdated( true )
                                        await props.functionList.getProgram()
                                        setSuccessOpen( true )
                                        setUpdated( false )

                                    } }
                                    className={ classes.signOutButton }>
                                    <Refresh className={ classes.signOutIcon }/>
                                </Button>
                            </Tooltip>
                         */
                    }
                    <Tooltip title={ "Logout" }>
                        <Button
                            onClick={ () => {
                                props.functionList.signOut()
                            } }
                            className={ classes.signOutButton }>
                            <ExitToApp className={ classes.signOutIcon }/>
                        </Button>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={ clsx( classes.drawer, {
                    [ classes.drawerOpen ]: open,
                    [ classes.drawerClose ]: !open,
                } ) }
                classes={ {
                    paper: clsx( {
                        [ classes.drawerOpen ]: open,
                        [ classes.drawerClose ]: !open,
                    } ),
                } }
            >
                <div className={ classes.toolbar }>
                    <IconButton onClick={ handleDrawerClose }>
                        { theme.direction === 'rtl' ?
                            <ChevronRight color={ 'inherit' }/> :
                            <ChevronLeft color={ 'inherit' }/> }
                    </IconButton>
                </div>
                <Divider/>
                <List>
                    { [ 'User', 'Projects', 'Company' ].map( ( text, index ) => (
                        <div>
                            { text === 'Projects' ?
                                <List>
                                    <ListItem
                                        onClick={ () => {
                                            handleDrawerButtonActions( index )
                                            handleProjectListClick()
                                        } }
                                        button
                                        key={ "list-item-text" + text }>
                                        <Tooltip title={ "Projects" }>
                                            <ListItemIcon
                                                className={ ( currentIndex !== 0 && currentIndex !== 2 ) ?
                                                    classes.iconActive :
                                                    classes.iconClosed }>{
                                                <AccountTreeIcon/> }
                                            </ListItemIcon>
                                        </Tooltip>
                                        <ListItemText primary={ text }/>
                                        { projectCollapse ? <ExpandLess className={ classes.iconActive }/> :
                                            <ExpandMore className={ classes.iconClosed }/> }
                                    </ListItem>
                                    <Collapse
                                        in={ projectCollapse }
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        { items }
                                    </Collapse>
                                </List> :
                                index === 2 && props.program.user.premium === "normal" ? null :
                                    <ListItem
                                        onClick={ () => handleDrawerButtonActions( index ) }
                                        button key={ "list-item-text" + text }>
                                        <ListItemIcon
                                            className={ index === currentIndex ? classes.iconActive :
                                                classes.iconClosed }>
                                            {
                                                index === 0
                                                    ?
                                                    <Tooltip title={ "User Dashboard" }>
                                                        <Person/>
                                                    </Tooltip>
                                                    :
                                                    index === 2
                                                        ?
                                                        <Tooltip title={ "Company Dashboard" }>
                                                            <LocationCityIcon/>
                                                        </Tooltip>
                                                        :
                                                        index === 3
                                                            ?
                                                            <People/>
                                                            :
                                                            <People/>
                                            }
                                        </ListItemIcon>
                                        <ListItemText primary={ text }/>
                                    </ListItem> }
                        </div>
                    ) ) }
                </List>
                <Divider/>
            </Drawer>
            <main className={ classes.content }>
                <div className={ classes.toolbar }/>
                { currentIndex === 0 ?
                    <UserShortCutsPage
                        program={ props.program }
                        languages={ props.program.languages }
                        goToCompanyPage={ handleGoToCompany }
                        goToProjectsPage={ handleGoToProjects }
                        company={ props.program.company }
                        functionList={ props.functionList }
                        user={ props.program.user }
                        goToSpecificProjectTextPage={
                            ( value ) => {
                                handleDrawerButtonActions(
                                    ( num + value ) + 3 )
                                handleInnerIndex( ( num + value ) * 3 + 1 )
                                handleProjectListClick()
                            }
                        }
                        goToSpecificProjectPage={
                            ( value ) => {
                                props.functionList.getProgram()
                                handleDrawerButtonActions(
                                    ( num + value ) + 3 )
                                handleInnerIndex( ( num + value ) * 3 )
                                handleProjectListClick()
                            }
                        }
                    />
                    : currentIndex === 1 ?
                        <ProjectsPage
                            goToProjectPage={ ( value ) => {
                                handleDrawerButtonActions( value + 3 )
                                handleInnerIndex( value * 3 )
                                handleProjectListClick()
                            } }
                            projects={ props.program.company != null ? props.program.company.projects : [] }
                            userProjects={ userProjects }
                            functionList={ props.functionList }

                        /> :
                        currentIndex === 2 ?
                            props.program.user.premium === "premium" ?
                                <CompanyShortCutsPage functionList={ props.functionList }
                                                      program={ props.program }
                                                      user={ props.program.user }
                                                      company={ props.program.company }
                                                      languages={ props.program.languages }
                                                      goToProjectsPage={ handleGoToProjects }
                                                      goToSpecificProjectPage={
                                                          ( value ) => {
                                                              props.functionList.getProgram()
                                                              handleDrawerButtonActions( value + 3 )
                                                              handleInnerIndex( value * 3 )
                                                              handleProjectListClick()
                                                          }
                                                      }
                                /> : null
                            : ( innerIndex / 3 >= num ) ? innerIndex % 3 === 0 ?
                                    <ProjectStatus
                                        isCompanyProject={ false }
                                        program={ props.program }
                                        user={ props.program.user }
                                        company={ props.program.company }
                                        functionList={ props.functionList }
                                        project={ userProjects[ Math.floor( innerIndex / 3 ) - num ] }/> :
                                    innerIndex % 3 === 1 ?
                                        <ProjectTexts
                                            open={ open }
                                            isCompanyProject={ false }
                                            user={ props.program.user }
                                            company={ props.program.company }
                                            functionList={ props.functionList }
                                            program={ props.program }
                                            project={ userProjects[ Math.floor( innerIndex / 3 ) -
                                            num ] }/> :

                                        <ProjectSettings
                                            isCompanyProject={ false }
                                            company={ props.program.company }
                                            program={ props.program }
                                            user={ props.program.user }
                                            functionList={ props.functionList }
                                            project={ userProjects[ Math.floor(
                                                innerIndex / 3 ) - num ] }/> :
                                innerIndex % 3 === 0 ?
                                    <ProjectStatus
                                        isCompanyProject={ true }
                                        program={ props.program }
                                        user={ props.program.user }
                                        company={ props.program.company }
                                        functionList={ props.functionList }
                                        project={ props.program.company.projects[ Math.floor( innerIndex / 3 ) ] }/> :
                                    innerIndex % 3 === 1 ?
                                        <ProjectTexts
                                            open={ open }
                                            isCompanyProject={ true }
                                            company={ props.program.company }
                                            user={ props.program.user }
                                            functionList={ props.functionList }
                                            program={ props.program }
                                            project={ props.program.company.projects[ Math.floor( innerIndex / 3 ) ] }/> :
                                        <ProjectSettings
                                            isCompanyProject={ true }
                                            company={ props.program.company }
                                            program={ props.program }
                                            user={ props.program.user }
                                            functionList={ props.functionList }
                                            project={ props.program.company.projects[ Math.floor(
                                                innerIndex / 3 ) ] }/>
                }
            </main>
            { textSearchDialog &&
            <TextSearchDialog open={ textSearchDialog } onClose={ () => setTextSearchDialog( false ) }/> }
            <Snackbar open={ errorOpen }
                      autoHideDuration={ 6000 }
                      onClose={ () => setErrorOpen( false ) }
            >
                <Alert onClose={ () => setErrorOpen( false ) }
                       severity={ "warning" }
                >
                    Something went wrong, try it later.
                </Alert>
            </Snackbar>
            <Snackbar open={ successOpen }
                      autoHideDuration={ 1000 }
                      onClose={ () => setSuccessOpen( false ) }
            >
                <Alert onClose={ () => setSuccessOpen( false ) }
                       severity={ "success" }
                >
                    Successfully refreshed
                </Alert>
            </Snackbar>
        </div>
    );
}

