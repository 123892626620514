import React from "react";
import { Paper, Typography, Divider, Badge, Grid, Button, Tooltip } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import NotificationsIcon from '@material-ui/icons/Notifications';
import Colors from "../../constants/Colors";
import { AddBoxOutlined, ArrowForwardIosOutlined } from "@material-ui/icons";
import ProjectCreationDialog from "../companyShortcutScreens/ProjectCreationDialog";

const useStyles = makeStyles( ( theme ) => ( {
    card: {
        padding: 20,
        display: 'flex',
        height: '100%',
        borderRadius: 10,
        alignItems: "flex-start",
        color: Colors.PRIMARY_PINK_ALT,
        backgroundColor: Colors.PRIMARY_WHITE,
        '&:hover': {
            color: Colors.PRIMARY_PINK_UP,
            opacity: 1,
        },
    },
    title: {
        paddingLeft: 12,
        display: 'flex',
        fontSize: 20,
        fontFamily: Colors.fontOfProgram,
        fontWeight: "bold"
    },
    divider: {
        height: 2,
        borderRadius: 50,
        minWidth: 200,
        marginTop: 4,
        marginBottom: 4,
        background: Colors.gradient,
        width: '100%'
    },
    text: {
        color: Colors.PRIMARY_BLUE,
        display: 'flex',
        fontSize: 16,
        fontWeight: "bold"
    },
    cardBody: {
        paddingLeft: 16,
        paddingRight: 16,
        justifyContent: 'space-between',
        display: 'flex',
        padding: 10,
        color: Colors.PRIMARY_BLUE,
    },
    cardBodyChild: {
        paddingRight: 40,
        justifyContent: 'flex-end',
        display: 'flex',
        color: Colors.PRIMARY_BLUE,
    },
    notificationIcon: {
        color: Colors.PRIMARY_GREEN_LIGHT,
        fontSize: 24,
    },
    notificationIconActive: {
        color: Colors.PRIMARY_PINK_UP,
        fontSize: 24,
    },
    forwardIcon: {
        color: Colors.PRIMARY_BLUE,
        fontSize: 24,
    },
    forwardIconActive: {
        color: "darkred",
        fontSize: 24,
    },
    topIcon: {
        color: Colors.PRIMARY_GREEN_LIGHT,
        fontSize: 32,
    },
    button: {
        padding: 0,
        margin: 0,
    }

} ) );


export default function ProjectShortcut( props ) {
    const [ open, setOpen ] = React.useState( false );
    const [ isDialogOpen, setDialogOpen ] = React.useState( false );
    const classes = useStyles();
    const confirmationNumbers = []

    props.projects.forEach( ( project ) => {
        let count = 0;
        props.user.roles.forEach( role => {
            project.categories.forEach( category => {
                category.texts.forEach( text => {
                    text.localizations.forEach( localization => {
                            localization.confirmations.forEach( confirmation => {
                                if ( confirmation.statusInfo === "CHANGE"
                                    && confirmation.status !== "APPROVED"
                                    && confirmation.projectRole.id === role.projectRole.id )
                                    count++;
                            } )
                        }
                    )
                } )
            } )
        } )
        confirmationNumbers.push( count );

    } )


    const handleClickDialogOpen = () => {
        setDialogOpen( true );
    };

    const handleClickDialogClose = () => {
        setDialogOpen( false );
    };


    return <Paper onMouseOver={ () => {
        setOpen( true )
    } } onMouseOut={ () => {
        setOpen( false )
    } } elevation={ open ? 2 : 0 } className={ classes.card }>
        <Grid container spacing={ 1 }>
            <Grid container>
                <Grid item xs={ 8 }>
                    <Typography className={ classes.title }>
                        Projects
                    </Typography>
                </Grid>

                <Grid item xs={ 4 }
                      className={ classes.cardBodyChild }>

                    { props.isCompanyPage ?
                        <Tooltip title={ "Create a new Project" }>
                            <Button
                                onClick={ handleClickDialogOpen }
                                className={ classes.button }>
                                <AddBoxOutlined
                                    className={ classes.topIcon }/>
                            </Button>
                        </Tooltip>
                        :
                        <Tooltip title={ "Go to Projects" }>
                            <Button
                                onClick={ props.goToProjectsPage }
                                className={ classes.button }>
                                <ArrowForwardIosOutlined
                                    className={ classes.topIcon }/>
                            </Button>
                        </Tooltip>
                    }


                </Grid>

            </Grid>

            <Divider className={ classes.divider }>
            </Divider>

            {
                props.projects.map( ( project, index ) =>
                    <Grid key={ project.id }
                          container
                          className={ classes.cardBody }>
                        <Grid item xs={ 9 }>
                            <Typography className={ classes.text }>
                                { project.name }
                            </Typography>
                        </Grid>
                        <Grid item xs={ 3 }
                              className={ classes.cardBodyChild }>
                            <Grid item xs={ 2 }>
                                <Tooltip
                                    title={ confirmationNumbers[ index ] > 0 ? "Notifications need to be confirmed." :
                                        "All Done" }>
                                    <Badge
                                        color="secondary"
                                        badgeContent={ confirmationNumbers[ index ] }>
                                        <NotificationsIcon
                                            className={ confirmationNumbers[ index ] > 0 ?
                                                classes.notificationIconActive :
                                                classes.notificationIcon }/>
                                    </Badge>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={ 2 }>
                                <Tooltip title={ "Go to " + project.name + " Dashboard" }>
                                    <Button onClick={ () => props.goToSpecificProjectPage( index ) }
                                            className={ classes.button }>
                                        <ArrowForwardIosOutlined
                                            className={ confirmationNumbers[ index ] ? classes.forwardIconActive :
                                                classes.forwardIcon }/>
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid> )
            }
        </Grid>
        { props.isCompanyPage && <ProjectCreationDialog
            handleProjectAddition={ ( val ) => props.handleProjectAddition( val ) }
            company={ props.company }
            languages={ props.languages }
            functionList={ props.functionList }
            isOpen={ isDialogOpen }
            handleClose={ handleClickDialogClose }/> }
    </Paper>
}