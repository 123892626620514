import React from 'react';
import ProjectPreviewStatusStepper from "./ProjectPreviewStatusStepper";
import ProjectPreviewStatusInfoCard from "./ProjectPreviewStatusInfoCard";
import {Grid} from '@material-ui/core';

import {makeStyles} from '@material-ui/core/styles';
import Colors from "../../../constants/Colors";


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: Colors.PRIMARY_BACKGROUND,
    },
    grid:{
       display:"flex",
        justifyContent: 'space-between',
        alignItems: 'center',
    }

}));

export default function ProjectPreviewStatus(props) {
    const classes = useStyles();
    return <Grid key={props.projectStatus.id} container className={classes.grid}>
        <Grid container >
            <Grid  xs={2}>
                <ProjectPreviewStatusInfoCard projectStatusInfo={props.projectStatus}/>
            </Grid>
            <Grid  xs={10}>
                <ProjectPreviewStatusStepper
                    functionList={props.functionList}
                    projectStatusInfo={props.projectStatus}/>
            </Grid>
        </Grid>
    </Grid>;

}