import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Tooltip,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Colors from "../../../../../constants/Colors";
import TranslationOrderCreationWithNewUser from "./TranslationOrderCreationWithNewUser";
import TranslationOrderCreationWithExistingUser from "./TranslationOrderCreationWithExistingUser";
import {ArrowBackIos, Cancel} from "@material-ui/icons";
import TranslationOrderTextAddition from "./translationOrderTextAdditionComponents/TranslationOrderTextAddition";

const useStyles = makeStyles(() => ({
    dialog: {
        background: Colors.gradient,
    },
    div: {
        paddingTop: 10,
        paddingBottom: 20,
    },
    title: {
        color: Colors.PRIMARY_BLUE,
        fontSize: 32,
        fontWeight: "bold",
        fontFamily: Colors.fontOfProgram,

    },
    titleSubChild: {
        color: Colors.PRIMARY_GREY,
        fontSize: 18,
        fontWeight: "bold",
        fontFamily: Colors.fontOfProgram,

    },
    button: {
        width: 320,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        '&:hover': {
            backgroundColor: Colors.PRIMARY_PINK_UP,

        }
    },
    buttonText: {
        paddingLeft: 12,
        paddingRight: 12,
        fontWeight: "bold",
        fontSize: 16,
        color: Colors.PRIMARY_WHITE,
        fontFamily: Colors.fontOfProgram,

    },
    buttonGroup: {
        display: "flex",
        justifyContent: "space-evenly",
    },
    textFieldCenter: {
        paddingLeft: 240,
        paddingRight: 240,
        display: "flex",
        justifyContent: "center",
    },
    textField: {
        width: 400,
    },
    titleButton: {
        padding: 0,
    },
    titleIcon: {
        color: Colors.PRIMARY_PINK_UP,
        fontSize: 32,

    },
    scrollableDiv: {
        maxHeight: 800,
        overflowY: "scroll",
    }
}));


export default function TranslationOrderCreationDialog(props) {
    const [page, setPage] = React.useState("")
    const [translator, setTranslator] = React.useState(null)
    const [date, setDate] = React.useState("")
    const [name, setName] = React.useState("")
    const [surname, setSurname] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [oldOrder, selectOldOrder] = React.useState(null)
    const [selectedTexts, setSelectedTexts] = React.useState([])
    const [orderTitle, setOrderTitle] = React.useState(
        new Date(Date.now()).toLocaleDateString() + " " + new Date(Date.now()).getHours() + ":" +
        new Date(Date.now()).getMinutes())


    const classes = useStyles()
    let translators = []

    function checkTranslatorExist(order) {
        let check = false
        translators.forEach(translator => {
            if (translator.id === order.user.id)
                check = true
        })
        return check
    }

    props.program.user.roles.forEach(role => {
        role.projectRole.project.translationOrders.forEach(
            translationOrder => {
                if (!checkTranslatorExist(translationOrder))
                    translators.push(translationOrder.user)
            }
        )
    })

    if (props.program.company != null) props.program.company.projects.forEach(project => {
        project.translationOrders.forEach(
            translationOrder => {
                if (!checkTranslatorExist(translationOrder))
                    translators.push(translationOrder.user)
            }
        )
    })

    return <Dialog fullWidth={true}
                   maxWidth={page === "" || page === "existing" || page === "new" ? 'md' : 'lg'}
                   open={props.isOpen}
                   onClose={props.handleClose}
                   className={classes.dialog}
    >
        <div className={classes.div}>
            <DialogTitle>
                <Grid container>
                    <Grid item xs>
                        <Typography className={classes.title}>
                            {page === "" ? "Add a Job" :
                                page !== "texts" ? "Add a Job" : "Add a Job"}
                        </Typography>

                    </Grid>
                    {
                        page !== "" ?
                            <Grid>
                                <Tooltip title={"Back"}>
                                    <Button onClick={() => {
                                        if (page === "existing" || page === "new") {
                                            setTranslator(null)
                                            setName("")
                                            setSurname("")
                                            setDate("")
                                            setEmail("")
                                            setPage("")
                                        } else if (page === "texts") {
                                            if (translator === null)
                                                setPage("new")
                                            else
                                                setPage("existing")
                                        } else if (page === "copy_previous"
                                            || page === "create_custom") {
                                            setPage("texts")
                                        } else if (page === "old_order") {
                                            setPage("copy_previous")
                                        } else if (page === "view_as_list_older") {
                                            setPage("old_order")
                                        } else if (page === "view_as_detail_older") {
                                            setPage("old_order")
                                        }
                                    }
                                    } className={classes.titleButton}>
                                        <ArrowBackIos className={classes.titleIcon}/>
                                    </Button>
                                </Tooltip>
                            </Grid> :
                            null
                    }
                    <Grid>
                        <Tooltip title={"Close the panel."}>
                            <Button onClick={() => {
                                props.handleClose()
                                setPage("")
                                setTranslator(null)
                                setName("")
                                setSurname("")
                                setDate("")
                                setOrderTitle(
                                    new Date(Date.now()).toLocaleDateString() + " " + new Date(Date.now()).getHours() +
                                    ":" + new Date(Date.now()).getMinutes())
                                setEmail("")
                            }}
                                    className={classes.titleButton}>
                                <Cancel className={classes.titleIcon}/>
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs>
                        <Typography className={classes.titleSubChild}>
                            {
                                page === "" ? "Choose a translator." :
                                    page === "existing" ? "Choose a translator for the job." :
                                        page === "new" ? "Invite a translator for the job." :
                                            page !== "" ? "Choose the texts to be translated" : ""
                            }
                        </Typography>

                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent className={classes.textFieldCenter}>
                <TextField
                    className={classes.textField}
                    value={orderTitle}
                    onChange={(val) => {
                        setOrderTitle(val.target.value)
                    }}
                />
            </DialogContent>
            <div className={classes.scrollableDiv}>
                {page === "" ?
                    <DialogActions className={classes.buttonGroup}>
                        <Tooltip title={"Create a new job by using existing account."}>
                            <Button
                                disabled={orderTitle.length === 0}
                                onClick={() => setPage("existing")}
                                className={classes.button}>
                                <Typography className={classes.buttonText}>
                                    Choose Existing
                                </Typography>
                            </Button>
                        </Tooltip>
                        <Tooltip title={"Create a new job by creating new translator account."}>
                            <Button
                                disabled={orderTitle.length === 0}
                                onClick={() => setPage("new")}
                                className={classes.button}>
                                <Typography className={classes.buttonText}>
                                    Add New
                                </Typography>
                            </Button>
                        </Tooltip>
                    </DialogActions>
                    :
                    page === "new" ?
                        <TranslationOrderCreationWithNewUser
                            name={name}
                            surname={surname}
                            email={email}
                            date={date}
                            orderTitle={orderTitle}
                            onNameChange={(value) => {
                                setName(value)
                            }}
                            onSurnameChange={(value) => {
                                setSurname(value)
                            }}
                            onEmailChange={(value) => {
                                setEmail(value)
                            }}
                            onDateChange={(value) => {
                                setDate(value)
                            }}
                            onContinue={() => {
                                setPage("texts")
                            }}
                        />
                        :
                        page === "existing" ?
                            <TranslationOrderCreationWithExistingUser
                                date={date}
                                onDateChange={(value) => {
                                    setDate(value)
                                }}
                                onContinue={() => {
                                    setPage("texts")
                                }}
                                translators={translators}
                                value={translator}
                                orderTitle={orderTitle}
                                onSelect={(value) => setTranslator(value)
                                }/>
                            :
                            <TranslationOrderTextAddition
                                handleClose={() => props.handleClose()}
                                selectedTexts={selectedTexts}
                                setSelectedTexts={(value) => setSelectedTexts(value)}
                                selectOldOrder={(value) => selectOldOrder(value)}
                                oldOrder={oldOrder}
                                program={props.program}
                                orderTitle={orderTitle}
                                functionList={props.functionList}
                                page={page}
                                setPage={(value) => {
                                    setPage(value)
                                }}
                                name={name}
                                surname={surname}
                                email={email}
                                date={date}
                                translator={translator}
                                project={props.project}
                            />}
            </div>
        </div>
    </Dialog>
}