import React from "react";
import OuterTabs from "./productTextScreensComponents/OuterTabs";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";

const useStyles = makeStyles({

    div: {
        flexGrow: 1,
    }
});

export default function ProjectTexts(props) {
    const classes = useStyles()
    let auth = false

    if ((props.isCompanyProject && props.project.company.user.userName === props.user.userName) || checkUserRoleAuth())
        auth = true;

    function checkUserRoleAuth() {
        let check = false
        props.user.roles.forEach(roleDesp => {
            if (props.project.id === roleDesp.projectRole.project.id) {
                roleDesp.projectRole.project.roles.forEach(role => {
                    if (role.admin) {
                        role.roleList.forEach(p => {
                            if (p.user.userName === props.user.userName)
                                check = true
                        })
                    }
                })
            }

        })

        return check
    }
    console.log()
    return <div className={classes.div} style={{width: (props.open ? "82vw" : "93vw")}}>
        {props.project.categories.length > 0 ? <OuterTabs
                open={props.open}
                auth={auth}
                project={props.project}
                program={props.program}
                functionList={props.functionList}
                categories={props.project.categories}/>
            :
            <Grid container>
                <Typography>
                    Please add the translation subjects first!
                </Typography>
            </Grid>
        }

    </div>
}