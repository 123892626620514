import React from "react";
import {Button, DialogActions, DialogContent, Grid, makeStyles, TextField, Typography} from "@material-ui/core";
import Colors from "../../../../../constants/Colors";


const useStyles = makeStyles((theme) => ({
    dialogAction: {
        display: "flex",
        justifyContent: "center",
    },
    button: {
        backgroundColor: Colors.PRIMARY_BLUE,
        padding: 0,
        '&:hover': {
            backgroundColor: Colors.PRIMARY_PINK_UP,
        }
    },
    buttonText: {
        paddingTop: 8,
        paddingBottom: 8,
        fontWeight: "bold",
        paddingLeft: 40,
        paddingRight: 40,
        color: Colors.PRIMARY_WHITE,

    },
    grid: {
        display: "flex",
        justifyContent: "center",
    },
    textField: {
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20,

    }
}))

export default function TranslationOrderCreationWithNewUser(props) {
    const classes = useStyles()
    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    return <div>
        <DialogContent>
            <Grid container className={classes.grid}>
                <Grid className={classes.textField} item xs={3}>
                    <TextField
                        required={true}
                        fullWidth
                        id={"name"}
                        label={"Name"}
                        value={props.name}
                        onChange={(value) => {
                            props.onNameChange(value.target.value)
                        }}
                    >

                    </TextField>
                </Grid>
                <Grid className={classes.textField}
                      item xs={3}>
                    <TextField
                        fullWidth
                        id={"surname"}
                        label={"Surname"}
                        value={props.surname}
                        onChange={(value) => {
                            props.onSurnameChange(value.target.value)
                        }}
                    >

                    </TextField>
                </Grid>
                <Grid className={classes.textField}
                      item xs={3}>
                    <TextField
                        required={true}
                        error={ !validateEmail(props.email) && props.email.length>0}
                        fullWidth
                        id={"email"}
                        label={"Email"}
                        type={"email"}

                        value={props.email}
                        onChange={(value) => {
                            props.onEmailChange(value.target.value)
                        }}
                    >
                    </TextField>
                </Grid>
                <Grid className={classes.textField} item xs={3}>
                    <TextField
                        required={true}
                        id="datetime-local"
                        label="Deadline Date-Time"
                        fullWidth
                        type="datetime-local"
                        value={props.date}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(value) => {
                            props.onDateChange(value.target.value.toString())
                        }}
                    />


                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
            <Button
                disabled={props.name.length === 0
                || props.email.length === 0
                || props.date === ""
                || !validateEmail(props.email)
                }
                onClick={() => {
                    props.onContinue()
                }}
                className={classes.button}>
                <Typography className={classes.buttonText}>
                    Continue
                </Typography>
            </Button>
        </DialogActions>

    </div>
}