import React from "react";
import { Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import Colors from "../../../../../constants/Colors";

export default function OrderCreationRow( props ) {
    const [ translator, setTranslator ] = React.useState( "" )
    const [ jobType, setJobType ] = React.useState( "Write" )
    const [ deadline, setDeadline ] = React.useState(
        new Date( Date.now() + 1000 * 60 * 60 * 24 * 3 ).toLocaleString() )

    return <Grid container style={ { paddingTop: 20, } }>
        <Grid item xs style={ { display: "flex", justifyContent: "center" } }>
            <Checkbox checked={ props.row.selected } onChange={ () => {
                props.handleCheckChange()
            } }/>
        </Grid>
        <Grid item xs style={ { display: "flex", justifyContent: "center" } }>
            <TextField value={ props.row.orderTitle }
                       onChange={ ( e ) => props.handleTitleChange( e.target.value ) }/>
        </Grid>
        <Grid item xs style={ { display: "flex", justifyContent: "center" } }>
            <Typography style={ { color: Colors.PRIMARY_BLACK } }>
                { props.row.language.language.language }
            </Typography>
        </Grid>
        <Grid item xs style={ { display: "flex", justifyContent: "center" } }>
            <Select
                labelId="demo-simple-select-label"
                fullWidth
                id="demo-simple-select"
                value={ translator }
                label="Age"
                onChange={ ( val ) => {
                    let value = val.target.value
                    setTranslator( value )
                    console.log( value )
                    props.selectTranslator( value.email, value.name, value.surname, value.id )
                } }
            >
                <MenuItem value={ "" }>Choose</MenuItem>
                { props.translators.map( translator => {
                    return <MenuItem value={ translator }>{ translator.name + " " +
                    translator.surname }</MenuItem>
                } ) }
            </Select>
        </Grid>
        <Grid item xs style={ { display: "flex", justifyContent: "center" } }>
            <Typography style={ { color: Colors.PRIMARY_BLACK } }>
                <Select
                    labelId="demo-simple-select-label"
                    fullWidth
                    id="demo-simple-select"
                    value={ props.row.type === "WRITE" ? "Write" : "Read" }
                    label="Age"
                    onChange={ ( val ) => props.handleTypeChange( val.target.value.toUpperCase() ) }
                >
                    <MenuItem value={ "Read" }>Read</MenuItem>
                    <MenuItem value={ "Write" }>Write</MenuItem>
                </Select>

            </Typography>
        </Grid>
        <Grid item xs style={ { display: "flex", justifyContent: "center" } }>
            <TextField type={ "datetime-local" } value={ props.row.userExpire }
                       onChange={ e => props.handleExpirationChange( e.target.value ) }/>
        </Grid>
    </Grid>
}