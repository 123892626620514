import React from "react";
import { Paper, Typography, Divider, Grid, Button, Snackbar, Tooltip } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../constants/Colors";
import InvitationResponseDialog from "../userShortcutScreens/InvitationResponseDialog";
import { Cancel } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import Env from "../../constants/Env";

const useStyles = makeStyles( ( theme ) => ( {
    card: {
        padding: 20,
        display: 'flex',
        height: '100%',
        borderRadius: 10,
        alignItems: "flex-start",
        color: Colors.PRIMARY_PINK_ALT,
        backgroundColor: Colors.PRIMARY_WHITE,
        '&:hover': {
            color: Colors.PRIMARY_PINK_UP,
            opacity: 1,
        },
    },
    title: {
        paddingLeft: 12,
        display: 'flex',
        fontSize: 20,
        fontWeight: "bold"
    },
    divider: {
        height: 2,
        borderRadius: 50,
        minWidth: 200,
        marginTop: 4,
        marginBottom: 4,
        background: Colors.gradient,
        width: '100%'
    },
    text: {
        color: Colors.PRIMARY_BLUE,
        display: 'flex',
        fontSize: 16,
        fontWeight: "bold"
    },
    textPassive: {
        color: Colors.PRIMARY_PINK_ALT,
        display: 'flex',
        fontSize: 16,
        fontWeight: "bold"
    },
    cardBody: {
        paddingLeft: 16,
        paddingRight: 16,
        justifyContent: 'space-between',
        display: 'flex',
        padding: 10,
        color: Colors.PRIMARY_BLUE,
    },
    lineContainer: {
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        paddingTop: 12,
        color: Colors.PRIMARY_BLUE,
    },
    cardBodyChild: {
        paddingRight: 40,
        justifyContent: 'flex-end',
        display: 'flex',
        color: Colors.PRIMARY_BLUE,
    },
    notificationIcon: {
        color: Colors.PRIMARY_GREEN_LIGHT,
        fontSize: 24,
    },
    notificationIconActive: {
        color: Colors.PRIMARY_PINK_UP,
        fontSize: 24,
    },
    button: {
        justifyContent: 'center',
        padding: 0,
    },
    buttonWithText: {
        color: Colors.PRIMARY_WHITE,
        background: Colors.PRIMARY_BLUE,
        borderRadius: 10,
        width: 250,
        border: "solid",
        borderColor: Colors.PRIMARY_BLUE,
        "&:hover": {
            color: Colors.PRIMARY_BLUE,
            background: Colors.PRIMARY_WHITE,
            border: "solid",
        }
    },
    invitationIcon: {
        color: Colors.PRIMARY_BLUE,
        fontSize: 32,
    },
    iconPassive: {
        color: Colors.PRIMARY_PINK_ALT,
    },
    icon: {
        color: Colors.PRIMARY_BLUE,
        fontSize: 32,
    },

    textName: {
        marginLeft: 24,
        display: "flex",
        fontSize: 20,
        fontWeight: "bold",
    },
    titleText: {
        color: Colors.PRIMARY_WHITE,
        display: 'flex',
        fontSize: 16,
        fontWeight: "bold"
    },
    titleCard: {
        background: Colors.gradientSecond,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: Colors.PRIMARY_BLUE,
        alignContent: 'center',
        alignItems: 'center',
        paddingLeft: 16,
        paddingRight: 16,
        justifyContent: 'space-between',
        display: 'flex',
        height: 60,
        color: Colors.PRIMARY_WHITE,
    },
    endGrid: {
        display: "flex",
        justifyContent: "flex-end",
    }

} ) );

export default function InvitationShortCut( props ) {
    const classes = useStyles();
    const [ open, setOpen ] = React.useState( false );
    const [ errorOpen, setErrorOpen ] = React.useState( false )
    const [ successOpen, setSuccessOpen ] = React.useState( false )
    const [ isDialogOpen, setDialogOpen ] = React.useState( null )
    const [ invitationList, setInvitationList ] = React.useState( [] )

    if ( invitationList.length !== props.invitations.length ) {
        setInvitationList( props.invitations )
    }

    function handleInvitationDeletion( invitationId ) {
        let temp = []
        invitationList.forEach( invitation => {
            if ( invitation.id !== invitationId ) {
                temp.push( invitation )
            }
        } )

        props.setInvitations( temp )
    }

    const handleErrorOpen = () => {
        setErrorOpen( true )
    }

    const handleErrorClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }
        setErrorOpen( false );
    };

    const handleSuccessOpen = () => {
        setSuccessOpen( true )
    }

    const handleSuccessClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }
        setSuccessOpen( false );
    };


    const handleClickDialogOpen = ( invitation ) => {
        setDialogOpen( invitation );
    };

    const handleClickDialogClose = () => {
        setDialogOpen( null );
    };

    /*
    {props.isProject ? <Grid item xs={1}>
                        <Button className={classes.button}>
                            <AddToQueueIcon className={classes.invitationIcon}/>
                        </Button>
                    </Grid> : <div/>}
     */

    return <Paper
        onMouseOver={ () => {
            setOpen( true )
        } } onMouseOut={ () => {
        setOpen( false )
    } } elevation={ open ? 2 : 0 } className={ classes.card }>
        <Grid container spacing={ 1 }>
            <Grid container>
                <Grid item xs={ 11 }>
                    <Typography className={ classes.title }>
                        Invitations
                    </Typography>
                </Grid>

            </Grid>
            <Grid item xs={ 12 }>
                <Divider className={ classes.divider }>
                </Divider>
            </Grid>
            <Grid container className={ classes.titleCard }>
                <Grid item xs>
                    <Typography className={ classes.titleText }>
                        Project Name
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography className={ classes.titleText }>
                        From
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography className={ classes.titleText }>
                        To
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography className={ classes.titleText }>
                        Title
                    </Typography>
                </Grid>
                { props.isProject ? <Grid item xs>
                    <Typography className={ classes.titleText }>
                        { "" }
                    </Typography>
                </Grid> : <Grid item xs>
                    <Typography className={ classes.titleText }>
                        { }
                    </Typography>
                </Grid> }

            </Grid>
            <Grid container className={ classes.cardBody }>
                { invitationList.map( invitation => {
                    return <Grid
                        key={ Math.random()
                                  .toString() }
                        className={ classes.lineContainer }
                        container>
                        <Grid item xs>
                            <Typography className={ classes.text }>
                                { invitation.projectRole.project.name }
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={ classes.text }>
                                { invitation.sender.userName }
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={ classes.text }>
                                { invitation.receiver.userName }
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={ classes.text }>
                                { invitation.projectRole.role.charAt( 0 )
                                            .toUpperCase() +
                                invitation.projectRole.role.slice( 1 )
                                          .toLowerCase() }
                            </Typography>
                        </Grid>
                        { props.isProject ?
                            <Grid item xs className={ classes.endGrid }>
                                <Tooltip title={ "Cancel an Invitation" }>
                                    <Button
                                        onClick={ () => {

                                            const headers = {
                                                "Content-Type": "application/json",
                                                'Authorization': 'Bearer ' + Env.TOKEN
                                            }
                                            fetch(
                                                process.env.REACT_APP_URL + 'projects/invitations?invitationId=' +
                                                invitation.id,
                                                {
                                                    method: 'DELETE', headers: headers
                                                }, )
                                                .then( response => {
                                                    if ( response.ok ) return response.json()
                                                    else throw new Error();
                                                } )
                                                .then( data => {
                                                    handleInvitationDeletion( invitation.id )
                                                    handleSuccessOpen();
                                                } )
                                                .catch( e => {
                                                    console.log( e )
                                                    handleErrorOpen()
                                                } )
                                        } }
                                        className={ classes.button }>
                                        <Cancel
                                            className={ classes.icon }/>
                                    </Button>
                                </Tooltip>
                            </Grid>
                            :
                            <Grid item xs className={ classes.endGrid }>
                                <Tooltip title={ "Respond the Invitation" }>
                                    <Button onClick={ () => handleClickDialogOpen( invitation ) }
                                            className={ classes.buttonWithText }>
                                        <Typography>
                                            Respond Invitation
                                        </Typography>
                                    </Button>
                                </Tooltip>
                            </Grid> }
                    </Grid>
                } ) }
            </Grid>
        </Grid>
        { isDialogOpen != null ?
            <InvitationResponseDialog isOpen={ isDialogOpen != null }
                                      invitation={ isDialogOpen }
                                      functionList={ props.functionList }
                                      handleClose={ handleClickDialogClose }/>
            :
            <div/>
        }
        <Snackbar open={ errorOpen }
                  autoHideDuration={ 6000 }
                  onClose={ handleErrorClose }
        >
            <Alert onClose={ handleErrorClose }
                   severity={ "warning" }
            >
                Something went wrong, try it later.
            </Alert>
        </Snackbar>
        <Snackbar open={ successOpen }
                  autoHideDuration={ 1000 }
                  onClose={ handleSuccessClose }
        >
            <Alert onClose={ handleSuccessClose }
                   severity={ "success" }
            >
                Successfully deleted
            </Alert>
        </Snackbar>
    </Paper>;

}
