import React from "react";
import { Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import ProjectShortcut from "../shortcutComponents/ProjectShortcut";
import CompanyShortCut from "../shortcutComponents/CompanyShortCut";
import TranslationOrderShortCut from "../shortcutComponents/TranslationOrderShortCut";
import PermissionShortCut from "../shortcutComponents/PermissionShortCut";


const useStyles = makeStyles( ( theme ) => ( {
    grid: {
        justifyContent: "space-between",
        borderRadius: 10,
    },
    gridChild: {
        borderWidth: 0,
        borderRadius: 10,
        margin: 4,
    }
} ) );

export default function CompanyShortCutsPage( props ) {
    const classes = useStyles();
    const [ projectList, setProjectList ] = React.useState( null )
    let auth = true;

    if ( projectList === null ) {
        setProjectList( props.company != null ? props.company.projects : [] )
    }

    function handleProjectAddition( projectResponse ) {
        let tempList = [ ...projectList, projectResponse ]
        props.company.projects = tempList
        setProjectList( tempList )
    }

    return <div>
        <Grid className={ classes.grid } container spacing={ 0 }>
            { props.program.user.premium === "premium" ? <Grid item className={ classes.gridChild } xs>
                <CompanyShortCut
                    program={ props.program }
                    functionList={ props.functionList }
                    company={ props.company }/>
            </Grid> : null }
            { props.program.company === null ? null : <Grid item
                                                            className={ classes.gridChild }
                                                            xs={ 8 }>
                <ProjectShortcut
                    handleProjectAddition={ ( val ) => handleProjectAddition( val ) }
                    user={ props.program.user }
                    goToSpecificProjectPage={ props.goToSpecificProjectPage }
                    company={ props.company }
                    languages={ props.languages }
                    functionList={ props.functionList }
                    goToProjectsPage={ props.goToProjectsPage }
                    isCompanyPage={ true }
                    projects={ projectList }/>
            </Grid> }
            { props.program.company === null ? null : <Grid
                item
                className={ classes.gridChild }
                xs={ 12 }>
                <TranslationOrderShortCut
                    user={ props.program.user }
                    functionList={ props.functionList }
                    isProjects={ false }
                    isCompanyPage={ true }
                    projects={ props.company != null ? props.company.projects : [] }
                />
            </Grid> }
            { props.program.company === null ? null : <Grid
                item
                className={ classes.gridChild }
                xs={ 12 }>
                <PermissionShortCut
                    functionList={ props.functionList }
                    auth={ auth }
                    user={ props.program.user }
                    isProjectPage={ false }
                    isCompanyPage={ true }
                    projects={ props.company != null ? props.company.projects : [] }/>
            </Grid> }
        </Grid>
    </div>

}