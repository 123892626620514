import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Colors from "../../constants/Colors";
import { Button, Grid, Paper, Tooltip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CreateIcon from '@material-ui/icons/Create';
import { Lock, LockOpen, Visibility } from "@material-ui/icons";

const useStyles = makeStyles( ( theme ) => ( {
    paper: {
        background: Colors.PRIMARY_PINK_ALT,
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 0,
    },
    text: {
        color: Colors.PRIMARY_WHITE,
        fontWeight: "bold",
    },
    button: {
        padding: 0,
        color: Colors.PRIMARY_WHITE,
        alignItems: "center",
        justifyContent: "center",
    }, grid: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    }
} ) );


export default function TranslationTaskCard( props ) {
    const classes = useStyles()
    const [ open, setOpen ] = React.useState( false )
    let waitingCount = 0
    if ( props.order.texts.length > 0 ) {
        props.order.texts.forEach( text => {
                if ( text.equivalent === null || text.equivalent.length === 0 )
                    waitingCount++;
            }
        )
    }

    return <Paper
        elevation={ open ? 5 : 0 }
        onMouseOver={ () => setOpen( true ) }
        onMouseOut={ () => setOpen( false ) }
        className={ classes.paper }>
        <Grid container className={ classes.grid }>
            <Grid item xs={ 1 }>
                <Typography className={ classes.text }>
                    { props.order.type === "WRITE" ? "Editor" : "Viewer" }
                </Typography>
            </Grid>
            <Grid item xs={ 1 }>
                <Tooltip title={
                    props.order.status === "SAVED" ? "NOT SUBMITTED YET" : ""
                }>
                    <Typography className={ classes.text }>
                        { props.order.type !== "READ" ? props.order.status === "NOT_STARTED" ? "Not Started Yet" :
                            props.order.status === "SAVED" ? "Saved" :
                                props.order.status.replace( '_', " " )
                                     .charAt( 0 )
                                     .toUpperCase() +
                                props.order.status.replace( '_', " " )
                                     .slice( 1 )
                                     .toLowerCase() : "-------" }
                    </Typography>
                </Tooltip>
            </Grid>

            <Grid item xs={ 2 }>
                <Typography
                    className={ classes.text }>
                    { props.order.language }
                </Typography>
            </Grid>
            <Grid item xs={ 1 }>
                <Tooltip
                    title={ props.order.type !== "WRITE" ?
                        "You can NOT edit this job."
                        :
                        props.order.status === "COMPLETED" ||
                        props.order.status === "CHECKED" ||
                        props.order.status === "PENDING" ||
                        props.order.status === "PAID" ?
                            "You can NOT edit this job."
                            :
                            "You can edit this job."
                    }>
                    <Grid item className={ classes.button }>
                        { props.order.type !== "WRITE" ?
                            <Lock/>
                            :
                            props.order.status === "COMPLETED" ||
                            props.order.status === "CHECKED" ||
                            props.order.status === "PENDING" ||
                            props.order.status === "PAID" ?
                                <Lock/>
                                :
                                <LockOpen/>
                        }
                    </Grid>
                </Tooltip>
            </Grid>
            <Grid item xs={ 1 }>
                <Tooltip
                    title={
                        props.order.type !== "WRITE" ?
                            "You can NOT edit this job but you can view."
                            :
                            props.order.status === "COMPLETED" ||
                            props.order.status === "CHECKED" ||
                            props.order.status === "PENDING" ||
                            props.order.status === "PAID" ?
                                "You can NOT edit this job but you can view."
                                :
                                "You can  this job." }>
                    <Grid item>
                        <Button disabled={ new Date( props.account.userExpiration ) < new Date( Date.now() ) }
                                onClick={ () => props.goToTask( props.order ) }
                                className={ classes.button }>
                            { props.order.type !== "WRITE" ?
                                <Visibility/>
                                :
                                props.order.status === "COMPLETED" ||
                                props.order.status === "CHECKED" ||
                                props.order.status === "PENDING" ||
                                props.order.status === "PAID" ?
                                    <Visibility/>
                                    :
                                    <CreateIcon/>
                            }
                        </Button>
                    </Grid>
                </Tooltip>
            </Grid>
        </Grid>
    </Paper>
}