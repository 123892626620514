import React from "react";
import {Button, DialogActions, DialogContent, Grid, makeStyles, TextField, Typography} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import Colors from "../../../../../constants/Colors";

const useStyles = makeStyles((theme) => ({
    dialogAction: {
        display: "flex",
        justifyContent: "center",
    },
    button: {
        backgroundColor: Colors.PRIMARY_BLUE,
        padding: 0,
        '&:hover': {
            backgroundColor: Colors.PRIMARY_PINK_UP,
        }
    },
    buttonText: {
        paddingTop: 8,
        paddingBottom: 8,
        fontWeight: "bold",
        paddingLeft: 40,
        paddingRight: 40,
        color: Colors.PRIMARY_WHITE,

    },
    grid: {
        display: "flex",
        justifyContent: "center",
    },

    textField: {
        marginLeft: 40,
        marginRight: 40,
        width: 400,
    }

}))

export default function TranslationOrderCreationWithExistingUser(props) {
    const [searchOpen, setSearchOpen] = React.useState(false);
    const [selected, setSelected] = React.useState(null);
    const classes = useStyles()

    return <div>
        <DialogContent>
            <Grid className={classes.grid} container spacing={1}>
                <Grid item>
                    <Autocomplete
                        className={classes.textField}
                        open={searchOpen}
                        onOpen={() => {
                            setSearchOpen(true);
                        }}
                        onClose={() => {
                            setSearchOpen(false);
                        }}
                        id="Translator"
                        onChange={(event, value, reason, details) => {
                            props.onSelect(null)

                            if (value.name.length > 0) {
                                props.translators.forEach(option => {
                                    if (option.name + " " + option.surname === value.name + " " + value.surname) {
                                        let dt = new Date(option.userExpirationTime);
                                        props.onDateChange(dt.toISOString().split('.')[0])
                                    }
                                })
                            } else {
                                props.onDateChange("")
                            }
                            setSelected(value)
                        }}
                        getOptionSelected={(option, value) => {
                            return option.userName === value.userName;
                        }}

                        getOptionLabel={(option) => option.name + " " + option.surname}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Choose a Translator"
                                fullWidth
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                        options={props.translators}>

                    </Autocomplete>
                </Grid>
                <Grid item>
                    <TextField
                        className={classes.textField}
                        id="datetime-local"
                        label="Deadline"

                        type="datetime-local"
                        value={props.date}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(value) => {
                            props.onDateChange(value.target.value.toString())
                            //console.log(value.target.value.toString())
                            setSelected(selected)
                        }}
                    />
                </Grid>
            </Grid>


        </DialogContent>
        <DialogActions className={classes.dialogAction}>
            <Button
                disabled={selected == null}
                onClick={() => {
                    if(props.date.length.length>16){
                        selected.userExpirationTime = props.date + ".000+00:00"
                    }else{
                        selected.userExpirationTime = props.date + ".000+00:00"
                    }
                    props.onSelect(selected)
                    props.onContinue()
                }}
                className={classes.button}>
                <Typography className={classes.buttonText}>
                    Continue
                </Typography>
            </Button>
        </DialogActions>
    </div>
}