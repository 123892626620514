import { Button, ButtonGroup, CircularProgress, Dialog, Grid, Tooltip, Typography } from "@material-ui/core";
import Env from "../../../../constants/Env";
import Colors from "../../../../constants/Colors";
import React from "react";


let tracer = 0
let total = 0


export default function ImageUploadDialog( props ) {
    const [ submitted, setSubmitted ] = React.useState( false )
    return <Dialog fullWidth={ true }
                   maxWidth={ "lg" }
                   open={ props.dialogOpen > -1 }
                   onClose={ () => props.setDialogOpen( -1 ) }>
        <Grid container spacing={ 1 } className={ props.classes.gridAlternative }>
            <Grid container spacing={ 1 } className={ props.classes.dialogButtonGroup }>
                <Grid item>
                    <input
                        disabled={ !props.auth }
                        className={ props.classes.input }
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={ async ( event ) => {
                            setSubmitted( true )

                            tracer = 0
                            total = event.target.files.length
                            for ( const file of Array.from( event.target.files ) ) {
                                let tempListAlt = [];
                                const formDataAlt = new FormData();
                                formDataAlt.append( "files", file, file.name )
                                tempListAlt.push( {
                                    "listOfLong": []
                                } )
                                formDataAlt.append( "projectId", props.project.id )
                                const headers = {
                                    'Authorization': 'Bearer ' + Env.TOKEN
                                }
                                await fetch( process.env.REACT_APP_URL + 'projects/files', {
                                    method: 'POST', headers: headers, body: formDataAlt
                                }, )
                                    .then( response => response.json() )
                                    .then( ( data ) => {
                                        props.project.images = [ ...props.project.images, ...data ]
                                        props.handleImageAddition( data )

                                    } )
                                    .then( () => {
                                        tracer = tracer + 1

                                        if ( tracer === total ) {
                                            setSubmitted( false )
                                        }

                                    } )
                                    .catch( () => {
                                        tracer = tracer + 1

                                        if ( tracer === total ) {
                                            setSubmitted( false )
                                        }

                                    } )
                            }

                            /*
                             setSubmitted( true )
                             let tempList = [];
                             const formData = new FormData();

                             Array.from( event.target.files )
                                  .forEach( ( file ) => {
                                      formData.append( "files", file, file.name )
                                      tempList.push( {
                                          "listOfLong": []
                                      } )
                                  } )

                             formData.append( "projectId", props.project.id )

                             //console.log(returnObj)
                             const headers = {
                                 'Authorization': 'Bearer ' + Env.TOKEN
                             }


                             await fetch( process.env.REACT_APP_URL + 'projects/files', {
                                 method: 'POST', headers: headers, body: formData
                             }, )
                                 .then( response => response.ok ? response.json() : new Error() )
                                 .then( ( data ) => {
                                     props.handleImageAddition( data )
                                     setSubmitted( false )

                                 } )
                                 .catch( e => {
                                     setSubmitted( false )
                                 } )

                             */
                        } }
                    />
                    <ButtonGroup>
                        <label className={ props.classes.buttonLabelAlternative }>
                            <Tooltip title={ "Make guide image empty." }>
                                <Button
                                    disabled={ !props.auth }
                                    onClick={
                                        () => {
                                            props.handleAllTableImageChange( props.selectedElement.id, null )
                                            props.setDialogOpen( -1 )
                                        }
                                    }
                                    className={ props.classes.dialogButton }>
                                    <Typography className={ props.classes.dialogButtonText }>
                                        Empty
                                    </Typography>
                                </Button>
                            </Tooltip>
                        </label>
                        <label htmlFor="contained-button-file" className={ props.classes.buttonLabelAlternative }>
                            <Tooltip title={ "Upload new images to project." }>
                                <Button component="span" className={ props.classes.dialogButton }>
                                    <Typography className={ props.classes.dialogButtonText }>
                                        UPLOAD
                                    </Typography>
                                </Button>
                            </Tooltip>
                        </label>
                    </ButtonGroup>
                </Grid>
                <Grid item>
                    <ButtonGroup>
                        <label className={ props.classes.buttonLabelAlternative }>
                            <Tooltip title={ "Cancel" }>
                                <Button onClick={ () => {
                                    props.setDialogOpen( -1 )
                                } }
                                        className={ props.classes.dialogButton }>
                                    <Typography className={ props.classes.dialogButtonText }>
                                        Cancel
                                    </Typography>
                                </Button>
                            </Tooltip>
                        </label>
                    </ButtonGroup>

                </Grid>
            </Grid>

            { submitted ?
                <Typography
                    style={ { color: Colors.PRIMARY_BLUE, fontSize: 72, paddingTop: 28, paddingBottom: 28 } }>
                    { tracer + "/" + total }
                </Typography> :
                <Grid container className={ props.classes.gridAlternative }>
                    {
                        props.imageList.length > 0 ?
                            props.imageList.map( image => {
                                return <Grid item xs className={ props.classes.gridBody }>
                                    <Tooltip title={ "Click to Select" }>
                                        <img className={ props.dialogOpen === image.id ? props.classes.divSelected :
                                            props.classes.divNotSelected }
                                             alt={ "text's img" }
                                             onClick={ () => {
                                                 if ( props.auth ) {
                                                     props.setDialogOpen( -1 )
                                                     props.handleAllTableImageChange( props.selectedElement.id, {
                                                         id: image.id,
                                                         url: image.url
                                                     } )
                                                 }
                                             } }
                                             src={ process.env.REACT_APP_IMAGE_URL + image.url }
                                             height={ 400 }
                                        />
                                    </Tooltip>
                                </Grid>
                            } )
                            :
                            <Grid container style={ { display: "flex", justifyContent: "center", } }>
                                <Grid item>
                                    <Typography style={ {
                                        color: Colors.PRIMARY_PINK_ALT,
                                        fontSize: 24,
                                        marginTop: 20,
                                        marginBottom: 20
                                    } }>
                                        There is no image uploaded before in this project!
                                    </Typography>
                                </Grid>
                            </Grid>
                    }
                </Grid> }

        </Grid>

    </Dialog>
}