/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
    CircularProgress,
    Dialog,
    Grid,
    LinearProgress,
    makeStyles,
    Snackbar,
    TextField,
    Typography
} from "@material-ui/core";
import Colors from "../../../../constants/Colors";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { Close } from "@material-ui/icons";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Alert, Autocomplete } from "@material-ui/lab";
import Env from "../../../../constants/Env";

const useStyles = makeStyles( () => ( {
    dialog: {
        background: Colors.gradient,
    },
    title: {
        fontSize: 32,
        fontWeight: "bold",
        fontFamily: Colors.fontOfProgram,
        color: Colors.PRIMARY_BLUE,
    },
    grid: {
        alignItems: 'center',
        display: "flex",
        justifyContent: "space-between",
    },
    bodyText: {
        fontWeight: "bold",
        color: Colors.PRIMARY_BLUE,
        fontSize: 16,
        fontFamily: Colors.fontOfProgram,
    },
    bodyBottomText: {
        color: Colors.PRIMARY_PINK,
        fontWeight: "bold",
        fontSize: 20,
        fontFamily: Colors.fontOfProgram,
    },
    dialogContent: {
        paddingLeft: 28,
        paddingRight: 28,
    },
    dialogAction: {
        justifyContent: "center",
        display: "flex",
        paddingTop: 20,
        paddingBottom: 20,
    },
    buttonCreate: {
        width: 200,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_GREEN_LIGHT,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_YELLOW_LIGHT,
        }
    },
    icon: {
        fontSize: 32,
        color: Colors.PRIMARY_PINK_UP,
        fontFamily: Colors.fontOfProgram,
    },
    buttonCancel: {
        width: 200,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_PINK,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_BLUE,
        }
    },
    buttonText: {
        fontWeight: "bold",
        color: Colors.PRIMARY_WHITE,
    },
    progressBar: {
        background: Colors.gradient,
        borderRadius: 10,
        height: 5,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
    },
} ) )

export default function PersonAdditionDialog( props ) {
    const classes = useStyles();
    const [ searchOpen, setSearchOpen ] = React.useState( false );
    const [ options, setOptions ] = React.useState( [] );
    const loading = searchOpen && options.length === 0;
    const [ value, setValue ] = React.useState( null );
    const [ successOpen, setSuccessOpen ] = React.useState( false )
    const [ errorOpen, setErrorOpen ] = React.useState( false )
    const [ submitted, setSubmitted ] = React.useState( false )


    const handleErrorOpen = () => {
        setErrorOpen( true )
    }

    const handleErrorClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }

        setErrorOpen( false );
    };

    const handleSuccessClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }
        props.handleClose();
        setSuccessOpen( false );
    };

    const handleSuccessOpen = () => {
        setSuccessOpen( true )
    }

    const checkUserHasRole = ( projectRoleId, userId ) => {
        let check = false
        // this removes user if user already has this role
        props.project.roles.forEach( role => {
            if ( role.id === projectRoleId ) {
                role.roleList.forEach( r => {
                    if ( r.user.id === userId )
                        check = true
                } )
            }
        } )

        return check
    }

    const checkUserHasInvitation = ( projectRoleId, userName ) => {
        let check = false
        console.log( props.project.invitations )


        props.project.invitations.forEach( invitation => {
            if ( invitation.projectRole.id === projectRoleId ) {
                if ( invitation.receiver.userName === userName )
                    check = true
            }
        } )


        return check
    }

    React.useEffect( () => {
        let active = true;
        const users = []
        if ( !loading ) {
            return undefined;
        }

        ( async () => {
            const response = await fetch( process.env.REACT_APP_URL + 'users/search?username=', {
                method: 'GET', headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + Env.TOKEN
                }
            }, )

            const temp = await response.json();
            temp.forEach( ( user ) => {
                    if ( user.id !== props.user.id && !checkUserHasRole( props.role.id, user.id ) &&
                        !checkUserHasInvitation( props.role.id, user.userName ) )
                        users.push( user )
                }
            );

            if ( active ) {
                setOptions( users.map( ( user ) => {


                    return user
                } ) );
            }
        } )();

        return () => {
            active = false;
        };
    }, [ loading ] );

    React.useEffect( () => {
        if ( !searchOpen ) {
            setOptions( [] );
        }
    }, [ searchOpen ] );

    return <Dialog fullWidth={ true }
                   className={ classes.dialog }
                   open={ props.isOpen }
                   onClose={ () => {
                       props.handleClose()
                       setValue( null )
                   } }
    >
        <DialogTitle id="form-dialog-title">
            <Grid className={ classes.grid } container>
                <Grid item xs={ 5 }>
                    <Typography className={ classes.title }>
                        Invite Users
                    </Typography>
                </Grid>
                <Grid item xs={ 1 }>
                    <Button onClick={ () => {
                        props.handleClose()
                        setValue( null )
                    }
                    }>
                        <Close className={ classes.icon }/>
                    </Button>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent className={ classes.dialogContent }>
            <Autocomplete
                id="User"
                fullWidth
                open={ searchOpen }
                onOpen={ () => {
                    setSearchOpen( true );
                } }
                onClose={ () => {
                    setSearchOpen( false );
                } }
                onChange={ ( event, value, reason, details ) => {
                    setValue( value )
                }
                }
                getOptionSelected={ ( option, value ) => {
                    return option.userName === value.userName;
                } }
                getOptionLabel={ ( option ) => option.userName }
                options={ options }
                loading={ loading }
                renderInput={ ( params ) => (

                    <TextField
                        { ...params }
                        label="User"
                        fullWidth
                        InputProps={ {
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    { loading ? <CircularProgress color="inherit" size={ 20 }/> : null }
                                    { params.InputProps.endAdornment }
                                </React.Fragment>
                            ),
                        } }
                    />
                ) }
            />

        </DialogContent>
        <DialogActions className={ classes.dialogAction }>
            <Button disabled={ value === null }
                    onClick={ () => {
                        if ( value !== null ) {
                            setSubmitted( true )
                            const headers = {
                                "Content-Type": "application/json",
                                'Authorization': 'Bearer ' + Env.TOKEN
                            }
                            fetch( process.env.REACT_APP_URL + 'projects/invitations', {
                                method: 'POST', headers: headers, body: JSON.stringify( {
                                    "senderId": props.user.id,
                                    "receiverId": value.id,
                                    "projectId": props.project.id,
                                    "projectRoleId": props.role.id
                                } )
                            }, )
                                .then( response => {
                                    if ( response.ok ) {
                                        return response.json()
                                    } else throw new Error();
                                } )
                                .then( data => {
                                    props.handlePersonAddition( data )
                                    setSubmitted( false );
                                    handleSuccessOpen();
                                    setValue( null )
                                } )
                                .catch( e => {
                                    console.log(e)
                                    setSubmitted( false );
                                    handleErrorOpen()
                                    setValue( null )
                                } )
                        }
                    } } className={ classes.buttonCreate } color="primary">
                <Typography
                    className={ classes.buttonText }>
                    Invite
                </Typography>
            </Button>

        </DialogActions>
        <Snackbar open={ errorOpen }
                  autoHideDuration={ 6000 }
                  onClose={ handleErrorClose }
        >
            <Alert onClose={ handleErrorClose }
                   severity={ "warning" }
            >
                Something went wrong, try it later.
            </Alert>
        </Snackbar>
        <Snackbar open={ successOpen }
                  autoHideDuration={ 1000 }
                  onClose={ handleSuccessClose }
        >
            <Alert onClose={ handleSuccessClose }
                   severity={ "success" }
            >
                Successfully invited
            </Alert>
        </Snackbar>

        { submitted ? <LinearProgress className={ classes.progressBar } color={ 'secondary' }/> : <div/> }


    </Dialog>
}