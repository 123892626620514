import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, Paper} from '@material-ui/core';
import Colors from "../../../constants/Colors";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'transparent',
    },
    grid: {
        marginTop: 20,
        marginLeft: 24,
        paddingRight: 20,
        paddingTop: 20,
        color: Colors.PRIMARY_BLUE,
    },
    textChild: {
        display: 'flex',
        fontFamily: Colors.fontOfProgram,
        justifyContent: 'start',
        fontSize: 16,
        fontWeight: "bold",
    },
    textChildPassive: {
        display: 'flex',
        fontFamily: Colors.fontOfProgram,
        justifyContent: 'start',
        color: Colors.PRIMARY_GREY,
        fontSize: 16,
        fontWeight: "bold",
    }
}));

export default function ProjectPreviewStatusInfoCard(props) {
    const classes = useStyles();
    return (<Paper className={classes.root} elevation={0}>
            <Grid container className={classes.grid}>
                <Grid item xs={7}>
                    <Typography
                        className={classes.textChild}>{props.projectStatusInfo.language.language.language}</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography
                        className={props.projectStatusInfo.translator != null ? classes.textChild :
                            classes.textChildPassive}>{props.projectStatusInfo.translator != null ?
                        props.projectStatusInfo.translator.name : "No name"}</Typography>
                </Grid>

            </Grid>
        </Paper>

    );
}
