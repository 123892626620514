import React from "react";
import {Container, Grid, Snackbar, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Colors from "../../../../constants/Colors";
import {TrendingUp} from "@material-ui/icons";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles({
    buttonLabel: {
        backgroundColor: Colors.PRIMARY_WHITE,
        borderRadius: 10,
    },
    topButtonAlternative: {
        borderRadius: 10,
        margin: 4,
        border: "solid",
        display: "flex",
        cursor: "pointer",
        justifyContent: "center",
        borderColor: "transparent",
        width: 150,
        backgroundColor: Colors.PRIMARY_BLUE,
        color: Colors.PRIMARY_WHITE,
        '&:hover': {
            border: "solid",
            borderColor: Colors.PRIMARY_BLUE,
            backgroundColor: Colors.PRIMARY_WHITE,
            color: Colors.PRIMARY_BLUE,
        }
    },
    topButtonText: {
        fontSize: 12,
        fontWeight: "bold",
    },
    textField: {
        width: 200,
        paddingRight: 10,
        paddingLeft: 10,
    },
    exchangeIcon: {
        color: Colors.PRIMARY_PINK_ALT,
    }
});


export default function AllLanguagePanelReplaceWidget(props) {
    const classes = useStyles()
    const [findText, setFindText] = React.useState("")
    const [targetText, setTargetText] = React.useState("")
    const [infoOpen, setInfoOpen] = React.useState("")
    return <Grid container>


        <Grid item className={classes.buttonLabel}>
            <TextField
                value={findText}
                type={"text"}
                placeholder={"Find"}
                onChange={(val) => {
                    setFindText(val.target.value)
                }}
                className={classes.textField}
            />
        </Grid>
        <Grid>
            <TrendingUp className={classes.exchangeIcon}/>
        </Grid>
        <Grid item className={classes.buttonLabel}>
            <TextField
                type={"text"}
                placeholder={"Replace With"}
                value={targetText}
                onChange={(val) => {
                    setTargetText(val.target.value)
                }}
                className={classes.textField}
            />
        </Grid>
        <Grid item className={classes.buttonLabel}>
            <Container
                onClick={() => {
                    if (!props.auth) {
                        setInfoOpen("You do not have permission to perform this operation!")
                    } else if (findText.length === 0) {
                        setInfoOpen("Find can not be empty!")
                    } else {
                        props.allTableReplaceText(findText, targetText)
                    }
                }}
                className={classes.topButtonAlternative}>
                <Typography className={classes.topButtonText}>
                    {"Replace"}
                </Typography>
            </Container>
        </Grid>
        <Snackbar open={infoOpen.length > 0}
                  autoHideDuration={3000}
                  onClose={() => setInfoOpen("")}
        >
            <Alert onClose={() => setInfoOpen("")}
                   severity={"info"}
            >
                {infoOpen}
            </Alert>
        </Snackbar>
    </Grid>

}