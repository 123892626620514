import { Button, CircularProgress, Dialog, Grid, makeStyles, TextField } from "@material-ui/core"
import React from 'react'
import Colors from "../../../../../constants/Colors";

const useStyles = makeStyles( ( theme ) => ( {
    dialogAction: {
        display: "flex",
        justifyContent: "center",
    },
    button: {
        backgroundColor: Colors.PRIMARY_BLUE,
        padding: 0,
        '&:hover': {
            backgroundColor: Colors.PRIMARY_PINK_UP,
        }
    },
    buttonText: {
        paddingTop: 8,
        paddingBottom: 8,
        fontWeight: "bold",
        paddingLeft: 40,
        paddingRight: 40,
        color: Colors.PRIMARY_WHITE,

    },
    grid: {
        display: "flex",
        justifyContent: "center",
    },
    textField: {
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20,

    }
} ) )

export default function TranslatorAdditionDialog( props ) {
    const classes = useStyles()
    const [ submitted, setSubmitted ] = React.useState( false )

    function validateEmail( email ) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test( String( email )
            .toLowerCase() );
    }

    return <Dialog fullWidth maxWidth={ "md" } open={ props.open } onClose={ () => props.onClose() }>
        <Grid container className={ classes.grid } style={ { padding: "20px", fontSize: 24, fontWeight: "bold" } }>
            Add a new Translator
        </Grid>

        <Grid container className={ classes.grid }>
            <Grid className={ classes.textField } item xs={ 3 }>
                <TextField
                    required={ true }
                    fullWidth
                    id={ "name" }
                    label={ "Name" }
                    value={ props.name }
                    onChange={ ( value ) => {
                        props.setName( value.target.value )
                    } }
                >

                </TextField>
            </Grid>
            <Grid className={ classes.textField }
                  item xs={ 3 }>
                <TextField
                    required={ true }
                    fullWidth
                    id={ "surname" }
                    label={ "Surname" }
                    value={ props.surname }
                    onChange={ ( value ) => {
                        props.setSurname( value.target.value )
                    } }
                >

                </TextField>
            </Grid>
            <Grid className={ classes.textField }
                  item xs={ 3 }>
                <TextField
                    required={ true }
                    error={ !validateEmail( props.email ) && props.email.length > 0 }
                    fullWidth
                    id={ "email" }
                    label={ "Email" }
                    type={ "email" }

                    value={ props.email }
                    onChange={ ( value ) => {
                        props.setEmail( value.target.value )
                    } }
                >
                </TextField>
            </Grid>
            <Grid className={ classes.textField } item xs={ 3 }>
                <TextField
                    required={ true }
                    id="datetime-local"
                    label="Deadline Date-Time"
                    fullWidth
                    type="datetime-local"
                    value={ props.date }
                    InputLabelProps={ {
                        shrink: true,
                    } }
                    onChange={ ( value ) => {
                        props.setExpire( value.target.value.toString() )
                    } }
                />
            </Grid>
        </Grid>
        <Grid container className={ classes.grid } style={ { padding: "20px" } }>
            { submitted ? <CircularProgress/> : <Button style={ {
                borderRadius: "10px",
                border: "solid",
                padding: "5px 24px",
                borderColor: Colors.PRIMARY_BLUE
            } } onClick={ () => {
                setSubmitted( true )
                props.createTranslator( props.name, props.surname, props.expire, props.email )
                     .then( e => {
                         setSubmitted( false )
                         props.setName( "" )
                         props.setSurname( "" )
                         props.setExpire( "" )
                         props.setEmail( "" )
                         props.onClose()
                     } )
            } }>
                Create
            </Button> }
        </Grid>
    </Dialog>
}