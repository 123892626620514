
class Program {
    static checkList = []
    static paginationSize = 10
    static getCheckList(){
        return this.checkList
    }
}

export default {
    getCheckList(){
        return Program.checkList
    },
    setCheckList(val){
        Program.checkList = [...val]
    },

    getPaginationSize(){
        return Program.paginationSize
    },
    setPaginationSize(paginationSize){
        Program.paginationSize = paginationSize
    }
}