import React from "react";
import {Button, DialogActions, DialogContent, Grid, makeStyles, Paper, Tooltip, Typography} from "@material-ui/core";
import Colors from "../../../../../../constants/Colors";
import TranslationOrderTextCopier from "./TranslationOrderTextCopier";
import TranslationOrderTextCreator from "./TranslationOrderTextCreator";

const useStyles = makeStyles((theme) => ({
    dialogAction: {
        display: "flex",
        justifyContent: "center",
    },
    button: {
        width: 400,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        '&:hover': {
            backgroundColor: Colors.PRIMARY_PINK_UP,

        }
    },
    buttonText: {
        fontWeight: "bold",
        fontSize: 16,
        fontFamily: Colors.fontOfProgram,
        color: Colors.PRIMARY_WHITE,
    },
    buttonGroup: {
        display: "flex",
        justifyContent: "space-evenly",
    },
    grid: {
        justifyContent: "space-between",
        display: "flex",
    },
    subTitle: {
        color: Colors.PRIMARY_PINK_ALT,
        fontSize: 12,
        fontFamily: Colors.fontOfProgram,
        fontWeight: "bold",
    },
    subTitleChild: {
        color: Colors.PRIMARY_BLUE,
        fontWeight: "bold",
        fontFamily: Colors.fontOfProgram,
        fontSize: 16,
    },
    hr: {
        borderColor: Colors.PRIMARY_PINK_UP,
    }, scrollableDiv: {
        maxHeight: 800,
        overflowY: "scroll",
    }
}))

export default function TranslationOrderTextAddition(props) {
    const classes = useStyles()


    return <div>
        <DialogContent>
            <Paper elevation={0}>
                <Grid className={classes.grid} container>
                    <Grid item>
                        <Typography className={classes.subTitle}>
                            Translator's Name Surname
                        </Typography>
                        <Typography className={classes.subTitleChild}>
                            {props.translator === null
                                ? " " + props.name + " " + props.surname :
                                " " + props.translator.name + " " + props.translator.surname
                            }
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.subTitle}>
                            Email
                        </Typography>
                        <Typography className={classes.subTitleChild}>
                            {props.translator === null
                                ? props.email
                                :
                                props.translator.email
                            }
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.subTitle}>
                           Deadline
                        </Typography>
                        <Typography className={classes.subTitleChild}>
                            {props.translator === null
                                ? new Date((props.date)).toLocaleDateString() + " " +
                                new Date((props.date)).toLocaleTimeString()
                                : new Date((props.translator.userExpirationTime
                                )).toLocaleDateString() + " " +
                                new Date((props.translator.userExpirationTime
                                )).toLocaleTimeString()
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
            <hr className={classes.hr}/>
            <div className={classes.scrollableDiv}>
                {props.page === "copy_previous" || props.page === "old_order" || props.page === "view_as_list_older" ||
                props.page === "view_as_detail_older"
                    ? <TranslationOrderTextCopier
                        functionList={props.functionList}
                        selectedTexts={props.selectedTexts}
                        setSelectedTexts={props.setSelectedTexts}
                        selectOrder={props.selectOldOrder}
                        oldOrder={props.oldOrder}
                        page={props.page}
                        name={props.name}
                        orderTitle={props.orderTitle}
                        surname={props.surname}
                        email={props.email}
                        date={props.date}
                        translator={props.translator}
                        setPage={props.setPage}
                        project={props.project}
                        program={props.program}
                        handleClose={() => props.handleClose()}
                    />
                    : props.page === "create_custom"
                        ? <TranslationOrderTextCreator
                            handleClose={() => props.handleClose()}
                            functionList={props.functionList}
                            selectedTexts={props.selectedTexts}
                            setSelectedTexts={props.setSelectedTexts}
                            selectOrder={props.selectOldOrder}
                            oldOrder={props.oldOrder}
                            page={props.page}
                            orderTitle={props.orderTitle}
                            name={props.name}
                            surname={props.surname}
                            email={props.email}
                            date={props.date}
                            translator={props.translator}
                            setPage={props.setPage}
                            project={props.project}
                            program={props.program}
                        />
                        : null
                }
            </div>
        </DialogContent>
        {props.page === "texts"
            ? <DialogActions className={classes.buttonGroup}>
                <Tooltip title={"Create the job by customizing previous jobs."}>
                    <Button
                        onClick={() => {
                            props.setPage("copy_previous")
                        }}
                        className={classes.button}>
                        <Typography className={classes.buttonText}>
                            Choose Existing
                        </Typography>
                    </Button>
                </Tooltip>
                <Tooltip title={"Create the job by selecting texts manually."}>
                    <Button
                        onClick={() => {
                            props.setPage("create_custom")
                        }}
                        className={classes.button}>
                        <Typography className={classes.buttonText}>
                            Create New
                        </Typography>
                    </Button>
                </Tooltip>
            </DialogActions>
            :
            <div/>}
    </div>
}