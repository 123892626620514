import React from "react";
import {
    AppBar,
    Button,
    ButtonGroup, Checkbox, CircularProgress,
    Dialog,
    DialogContent,
    Grid, InputBase,
    makeStyles, TextField,
    Typography
} from "@material-ui/core";
import Colors from "../../../../constants/Colors";
import { Label, TrendingUp } from "@material-ui/icons";
import Env from "../../../../constants/Env";

const useStyles = makeStyles( () => ( {
    title: {
        fontSize: 32,
        fontFamily: Colors.fontOfProgram,
        fontWeight: "bold",
        color: Colors.PRIMARY_PINK_ALT,
    },
    dialog: {
        paddingTop: 80,
        background: Colors.gradient,
    },
    grid: {
        alignItems: 'center',
        display: "flex",
        justifyContent: "space-between",
    },
    bodyText: {
        paddingLeft: 12,
        paddingRight: 12,
        color: Colors.PRIMARY_BLACK,
        fontSize: 14,
        fontFamily: Colors.fontOfProgram,
    },
    bodyBottomText: {
        color: Colors.PRIMARY_PINK,
        fontWeight: "bold",
        fontSize: 20,
        fontFamily: Colors.fontOfProgram,
    },
    dialogContent: {
        padding: 0,
        margin: 0,
        backgroundColor: "transparent",
    },
    dialogAction: {
        justifyContent: "center",
        display: "flex",
        paddingTop: 20,
        paddingBottom: 20,
    },
    buttonCreate: {
        width: 140,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_PINK_UP,
        }
    },
    icon: {
        fontSize: 32,
        color: Colors.PRIMARY_PINK_UP,
        fontFamily: Colors.fontOfProgram,
    },
    buttonCancel: {
        width: 140,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_BLUE,
        }
    },
    buttonText: {
        fontWeight: "bold",
        color: Colors.PRIMARY_WHITE,
    },
    progressBar: {
        background: Colors.gradient,
        borderRadius: 10,
        height: 5,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
    },
    appBar: {
        backgroundColor: Colors.PRIMARY_WHITE,
        padding: 10,
        paddingLeft: 40,
        paddingRight: 40,

    },
    tableHead: {
        display: "flex",
        justifyContent: "space-between",

        background: Colors.gradient,
        color: Colors.PRIMARY_WHITE,
        padding: 20,
        alignItems: "center",
    },
    titleText: {
        paddingLeft: 12,
        paddingRight: 12,
        fontWeight: "bold",
        fontSize: 16,
        fontFamily: Colors.fontOfProgram,
    },
    tableBody: {
        display: "flex",
        justifyContent: "space-between",
        padding: 20,
        alignItems: "center",
        border: "dashed",
        borderTopWidth: 0,
        borderWidth: 1,
        borderColor: Colors.PRIMARY_PINK_ALT,
    },
    dialogBody: {
        maxHeight: 700,
        overflowY: "scroll",
    },
    textField: {
        width: 200,
        paddingRight: 10,
        paddingLeft: 10,
    },
    exchangeIcon: {
        color: Colors.PRIMARY_PINK_ALT,
    }

} ) )


export default function OrderControlDialog( props ) {
    const classes = useStyles()
    const [ sourceText, setSourceText ] = React.useState( '' )
    const [ targetText, setTargetText ] = React.useState( '' )
    const [ exportWarning, setExportWarning ] = React.useState( false )
    const [ loading, setLoading ] = React.useState( props.order.texts.length === 0 )
    const [ isStarted, setStarted ] = React.useState( props.order.texts.length !== 0 )
    const [ error, setError ] = React.useState( false )

    async function getTranslationOrder() {
        setLoading( true )
        let headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }

        fetch( process.env.REACT_APP_URL + 'projects/translationOrders?id=' + props.order.id, {
            method: 'get', headers: headers,
        }, )
            .then( response => {
                console.log(response)
                if ( response.ok ) {
                    return response.json()
                } else {
                    response.json()
                            .then( e => {
                                //console.log(e)
                            } )
                    throw new Error( 'Something went wrong' );
                }
            } )
            .then( data => {

                props.handleLoadingTexts( data.texts )
                setError( false )
                setLoading( false )
            } )
            .catch( ( error ) => {
                console.log( error )
                setLoading( false )
                setError( true )
            } )
    }

    if ( !isStarted ) {
        setStarted( true )
        getTranslationOrder()
    }

    function checkAllChecked() {
        let check = true

        props.order.texts.forEach( text => {
            if ( !text.checked ) {
                check = false
            }
        } )
        return check
    }


    return loading ? <Dialog fullWidth
                             maxWidth={ "xl" }
                             className={ classes.dialog }
                             open={ props.isOpen }
                             onClose={
                                 () => {
                                     props.checkWillBeOverride()
                                     props.onClose()
                                 }

                             }>
            <Grid container style={ { alignItems: "center", display: "flex", justifyContent: "center", padding: 200 } }>
                <CircularProgress/> </Grid>
        </Dialog> :
        error ? <Dialog fullWidth
                        maxWidth={ "xl" }
                        className={ classes.dialog }
                        open={ props.isOpen }
                        onClose={
                            () => {
                                props.checkWillBeOverride()
                                props.onClose()
                            }

                        }><Grid container
                                style={ {
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: 200
                                } }>
            <Grid item xs={ 12 } style={ { alignItems: "center", display: "flex", justifyContent: "center", } }>
                <Typography>Something went wrong! Data can not be downloaded!</Typography>
            </Grid>
            <Grid item xs={ 12 } style={ { alignItems: "center", display: "flex", justifyContent: "center", } }>
                <Button onClick={ () => getTranslationOrder() }>
                    <Typography>Retry</Typography>
                </Button>
            </Grid>
        </Grid> </Dialog> : <Dialog
            fullWidth
            maxWidth={ "xl" }
            className={ classes.dialog }
            open={ props.isOpen }
            onClose={
                () => {
                    props.checkWillBeOverride()
                    props.onClose()
                }

            }>
            <AppBar className={ classes.appBar }>
                <Grid container className={ classes.grid }>
                    <Grid item>
                        <Typography className={ classes.title }>
                            Check Submission
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            value={ sourceText }
                            type={ "text" }
                            placeholder={ "Find" }
                            onChange={ ( val ) => {
                                setSourceText( val.target.value )
                            } }
                            className={ classes.textField }
                        />
                        <TrendingUp className={ classes.exchangeIcon }/>
                        <TextField
                            type={ "text" }
                            placeholder={ "Replace With" }
                            value={ targetText }
                            onChange={ ( val ) => {
                                setTargetText( val.target.value )
                            } }
                            className={ classes.textField }
                        />
                        <ButtonGroup>
                            <Button
                                disabled={ sourceText.length === 0 }
                                onClick={ () => {
                                    props.order.texts.forEach( text => {
                                        if ( text.transferredText !== null ) {
                                            let str = text.transferredText
                                            props.handleTransferredText( text.id,
                                                str.replace( sourceText, targetText ) )
                                        }
                                    } )

                                } }
                                className={ classes.buttonCancel }>
                                <Typography className={ classes.buttonText }>
                                    Replace
                                </Typography>
                            </Button>
                            <Button
                                onClick={ () => {
                                    props.order.texts.forEach( text => {
                                        props.handleTransferredText( text.id, text.equivalent )
                                    } )
                                } }
                                className={ classes.buttonCancel }>
                                <Typography className={ classes.buttonText }>
                                    Reset
                                </Typography>
                            </Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item>
                        <ButtonGroup>
                            <Button
                                onClick={ () => props.handleOnSave() }
                                className={ classes.buttonCancel }>
                                <Typography className={ classes.buttonText }>
                                    Save
                                </Typography>
                            </Button>
                            <Button
                                onClick={ () => {
                                    if ( props.checkWillBeOverride() ) {
                                        setExportWarning( true )
                                    } else {
                                        props.handleOnComplete()
                                    }
                                } }
                                className={ classes.buttonCreate }>
                                <Typography className={ classes.buttonText }>
                                    Export
                                </Typography>
                            </Button>
                            <Button
                                onClick={ () => props.onClose() }
                                className={ classes.buttonCancel }>
                                <Typography className={ classes.buttonText }>
                                    Back
                                </Typography>
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </AppBar>
            <DialogContent className={ classes.dialogContent }>
                <Grid container className={ classes.tableHead }>
                    <Grid item xs>
                        <Typography className={ classes.titleText } style={ { textAlign: "center" } }>
                            Guide Image
                        </Typography>
                    </Grid>
                    <Grid item xs={ 2 }>
                        <Typography className={ classes.titleText } style={ { textAlign: "center" } }>
                            Description
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography className={ classes.titleText } style={ { textAlign: "center" } }>
                            Localization Text (SOURCE)
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography className={ classes.titleText } style={ { textAlign: "center" } }>
                            Covered Text
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography className={ classes.titleText } style={ { textAlign: "center" } }>
                            Submitted by Translator (TARGET)
                        </Typography>
                    </Grid>
                    <Grid item xs={ 2 }>
                        <Typography className={ classes.titleText } style={ { textAlign: "center" } }>
                            Proofread by Google Translate
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography className={ classes.titleText } style={ { textAlign: "center" } }>
                            { "Final Text" }
                        </Typography>
                    </Grid>
                    <Grid item xs={ 1 }>
                        <Checkbox
                            style={ { color: Colors.PRIMARY_WHITE } }
                            checked={ checkAllChecked() }
                            onChange={ () => {
                                if ( checkAllChecked() ) {
                                    props.handleAllCheck( false )
                                } else {
                                    props.handleAllCheck( true )
                                }
                            } }
                        />
                    </Grid>
                </Grid>
                <Grid container className={ classes.dialogBody }>
                    { props.order.texts.map( text => {
                        return <Grid
                            key={ "translation-order-check-page-" + text.id }
                            container className={ classes.tableBody }>
                            <Grid item xs style={ { textAlign: "center" } }>
                                { text.image != null ?
                                    <img alt={ "text's img" }
                                         onClick={
                                             () => {
                                             }
                                         }
                                         src={ process.env.REACT_APP_IMAGE_URL + text.image.url }
                                         height={ 50 }
                                    />
                                    :
                                    null
                                }
                            </Grid>
                            <Grid item xs={ 2 }>
                                <Typography className={ classes.bodyText }>
                                    { text.explanation != null ? text.explanation.content : "" }
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography className={ classes.bodyText }>
                                    { text.text.baseText.englishWord }
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography className={ classes.bodyText }>
                                    { text.englishShownWord }
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography className={ classes.bodyText }>
                                    { text.equivalent }
                                </Typography>
                            </Grid>
                            <Grid item xs={ 2 }>
                                <Typography className={ classes.bodyText }>
                                    { text.translationData }
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <InputBase
                                    multiline
                                    onChange={ ( val ) => {
                                        props.handleTransferredText( text.id, val.target.value )
                                    } }
                                    value={ text.transferredText === null ? "" : text.transferredText }
                                />
                            </Grid>
                            <Grid item xs={ 1 }>
                                <Checkbox
                                    checked={ text.checked }
                                    onChange={ ( event, checked ) => {
                                        props.handleCheckChange( text.id, checked )
                                    } }
                                />
                            </Grid>
                        </Grid>
                    } ) }
                </Grid>
            </DialogContent>
            <Dialog fullWidth
                    maxWidth={ "md" }
                    open={ exportWarning }
                    onClose={ () => {
                        setExportWarning( false )
                    } }
            >
                <Grid container style={ {
                    display: "flex",
                    justifyContent: "center",
                    padding: 20,
                } }>
                    <Grid container style={ {
                        display: "flex",
                        justifyContent: "center",
                        padding: 10
                    } }>
                        <Grid item>
                            <Typography style={ {
                                fontSize: 32,
                                color: Colors.PRIMARY_PINK_ALT,

                            } }>
                                Warning
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container style={ {
                        display: "flex",
                        justifyContent: "center",
                        padding: 10
                    } }>
                        <Grid item>
                            <Typography>
                                Some of the localizations will be overrode. Are you sure to continue?
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container style={ {
                        display: "flex",
                        justifyContent: "center",
                        padding: 10
                    } }>
                        <Grid item>
                            <ButtonGroup>
                                <Button
                                    style={ {
                                        width: 200,
                                        background: Colors.PRIMARY_GREEN_LIGHT,
                                        color: Colors.PRIMARY_WHITE,
                                        "&:hover": {
                                            background: Colors.PRIMARY_WHITE,
                                            color: Colors.PRIMARY_GREEN_LIGHT,
                                        }
                                    } }
                                    onClick={ () => {
                                        setExportWarning( false )
                                        props.handleOnComplete()

                                    } }
                                >
                                    <Typography>
                                        Export
                                    </Typography>
                                </Button>
                                <Button
                                    style={ {
                                        width: 200,
                                        background: Colors.PRIMARY_PINK_ALT,
                                        color: Colors.PRIMARY_WHITE,
                                        "&:hover": {
                                            background: Colors.PRIMARY_WHITE,
                                            color: Colors.PRIMARY_PINK_ALT,
                                        }
                                    } }
                                    onClick={ () => {
                                        setExportWarning( false )
                                    } }
                                >
                                    <Typography>
                                        Cancel
                                    </Typography>
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        </Dialog>
}