import React from "react";
import {
    Link,
    useParams
} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Colors from "../constants/Colors";
import {CircularProgress, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
    div: {
        background: Colors.PRIMARY_WHITE,
        display: "flex",
        minHeight: '100%',
        minWidth: '100%',
        position: "fixed",
        justifyContent: "center",
    },
    circularProgress: {
        padding: 200,
    },
    gridCenter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    text: {
        fontWeight: "bold",
        color: Colors.PRIMARY_BLUE,
        fontSize: 32,
        fontFamily: Colors.fontOfProgram,
    }
});

export default function ConfirmationPage() {
    const classes = useStyles()
    const [confirmed, setConfirmed] = React.useState(false)
    const [waiting, setWaiting] = React.useState(null)
    let {token} = useParams()

    if (waiting === null) {
        setWaiting(true)
        const headers = {
            "Content-Type": "application/json"
        }


        fetch(process.env.REACT_APP_URL + "auth/emailVerification?token=" + token,
            {method: "POST", headers: headers})
            .then(response => {
                if (response.ok && response.status === 200) {
                    response.json().then((data) => {
                        if (data.operationResult !== "ERROR") {
                            setConfirmed(true)
                            setWaiting(false)
                        } else {
                            setConfirmed(false)
                            setWaiting(false)
                        }
                    })
                } else {
                    setConfirmed(false)
                }

            }).catch(e => {
            //console.log(e)
        })
    }

    return <div
        className={classes.div}>
        <center>
            {waiting ?
                <Grid container className={classes.gridCenter}>
                    <Grid container className={classes.gridCenter}>
                        <Grid item>
                            <CircularProgress
                                className={classes.circularProgress}
                                size={100} color={"secondary"}/>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.gridCenter}>
                        <Grid item>
                            <Typography className={classes.text}>
                                Please wait, while we are confirming your account
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                :
                confirmed ?
                    <Grid container className={classes.circularProgress}>
                        <Grid container className={classes.gridCenter}>
                            <Grid item className={classes.gridCenter}>
                                <Typography className={classes.text}>
                                    Your account confirmation is successful.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.gridCenter}>
                            <Grid item className={classes.gridCenter}>
                                <Button>
                                    <Link to={"/"}>Go To Login</Link>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    <Grid container className={classes.circularProgress}>
                        <Grid container className={classes.gridCenter}>
                            <Grid item className={classes.gridCenter}>
                                <Typography className={classes.text}>
                                    Your account confirmation is unsuccessful.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.gridCenter}>
                            <Grid item className={classes.gridCenter}>
                                <Button>
                                    <Link to={"/"}>Go To Home</Link>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
            }
        </center>
    </div>
}