import React from "react";
import {
    Button,
    ButtonGroup, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl,
    Grid,
    IconButton, Input, InputLabel,
    makeStyles, MenuItem,
    Paper, Select, Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, Tooltip,
    Typography
} from "@material-ui/core";
import Colors from "../../../../constants/Colors";
import Env from "../../../../constants/Env";
import { Alert } from "@material-ui/lab";
import { CancelOutlined } from "@material-ui/icons";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null,
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const useStyles = makeStyles( () => ( {
    paper: {
        width: "100%",
        height: "100%",
        borderRadius: 10,
        padding: 20,
    },
    titleText: {
        color: Colors.PRIMARY_BLUE,
        fontSize: 28,
        paddingBottom: 20,
        fontWeight: "bold",
    },
    img: {
        paddingRight: 10,
        width: 160,
        maxHeight: 328,
        cursor: "pointer",
        display: "inline",
    },
    imgTemp: {
        paddingRight: 10,
        width: 160,
        minHeight: 328,
    },
    imageGrid: {
        padding: 4,
        position: "relative",
        maxWidth: 1400,
        overflowX: "auto",
        whiteSpace: "nowrap",
    },
    button: {
        width: 200,
        padding: 10,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_BLUE,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_BLUE,
            color: Colors.PRIMARY_WHITE,
        }
    },
    deleteButton: {
        width: 200,
        padding: 10,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: 'darkred',
        "&:hover": {
            backgroundColor: 'darkred',
            color: Colors.PRIMARY_WHITE,
        }
    },
    buttonText: {
        fontWeight: "bold",
    },
    buttonGrid: {
        display: "flex",
        justifyContent: "center",
        borderRadius: 10,
        paddingTop: 1,
        margin: 0,
    },
    buttonGridTop: {
        display: "flex",
        justifyContent: "center",
        borderRadius: 10,
        paddingTop: 30,
        margin: 0,
    },
    dialogImage: {
        maxHeight: 900,
        maxWidth: 1200
    },
    seeAllGrid: {
        padding: 20,
        display: "flex",
        justifyContent: "center",
    },
    buttonLabel: {
        backgroundColor: Colors.PRIMARY_WHITE,
        borderRadius: 10,
        padding: 4,
    },
    table: {
        minHeight: 300,
        maxHeight: 300,
        overflowY: "scroll",
    },
    tableHead: {
        background: Colors.PRIMARY_BLUE,
        color: Colors.PRIMARY_WHITE,
    },
    tableHeadText: {
        color: Colors.PRIMARY_WHITE,
        fontWeight: "bold",
    },
    tableBodyText: {
        colors: Colors.PRIMARY_BLACK,
        fontWeight: "bold",
    },
    tableBodyIcon: {
        color: Colors.PRIMARY_BLUE,
        '&:hover': {
            color: 'red'
        }
    },
    dialogTitleText: {
        fontWeight: "bold",
        paddingLeft: 20,
        paddingRight: 20,
        fontSize: 28,
        color: Colors.PRIMARY_BLUE,
    },
    dialogBodyText: {
        fontWeight: "bold",
        paddingLeft: 20,
        paddingRight: 20,
        fontSize: 16,
        color: Colors.PRIMARY_GREY,
    },
    selection: {
        paddingRight: 20,
        marginLeft: 20,
    },
    chip: {
        background: Colors.PRIMARY_BLUE,
        color: Colors.PRIMARY_WHITE
    }


} ) )


export default function ProjectLanguageSettings( props ) {
    const classes = useStyles()
    const [ successOpen, setSuccessOpen ] = React.useState( false )
    const [ errorOpen, setErrorOpen ] = React.useState( false )
    const [ addNew, setAddNew ] = React.useState( false )
    const [ deleteConfirmation, setDeleteConfirmation ] = React.useState( null )
    const [ selectedNewLanguages, setSelectedNewLanguage ] = React.useState( [] )
    const [ languageList, setLanguageList ] = React.useState( [] )

    if ( languageList.length !== props.project.projectLanguages.length ) {
        setLanguageList( [ ...props.project.projectLanguages ] )
    }

    function handleLanguageAddition( languageResponse ) {
        let tempList = [ ...languageList, ...languageResponse ]
        props.project.projectLanguages = tempList
        setLanguageList( tempList )
    }

    function handleLanguageDeletion( deletedId ) {
        let tempList = []

        languageList.forEach( lan => {
            if ( lan.id !== deletedId )
                tempList.push( lan )
        } )

        props.project.projectLanguages = tempList
        setLanguageList( tempList )
    }

    const submitted = false

    const handleChange = ( event ) => {
        setSelectedNewLanguage( event.target.value );
    };

    return <Paper
        elevation={ 20 }
        className={ classes.paper }>
        <Grid container>
            <Grid item xs={ 12 }>
                <Typography className={ classes.titleText }>
                    Project Languages
                </Typography>
            </Grid>
            <Grid item xs={ 12 } className={ classes.table }>
                <Table>
                    <TableHead className={ classes.tableHead }>
                        <TableRow>
                            <TableCell>
                                <Typography className={ classes.tableHeadText }>
                                    Language Name
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography className={ classes.tableHeadText }>
                                    Delete Language
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { props.project.projectLanguages.map( language => {
                            return <TableRow>
                                <TableCell>
                                    <Typography className={ classes.tableBodyText }>
                                        { language.language.language }
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title={ "Remove " + language.language.language +
                                    " language from the project." }>
                                        <IconButton
                                            disabled={ !props.auth }
                                            onClick={ () => setDeleteConfirmation( language ) }
                                            className={ classes.tableBodyIcon }
                                        >
                                            <CancelOutlined/>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        } ) }
                    </TableBody>
                </Table>

            </Grid>
            <Grid container className={ classes.buttonGridTop }>
                <Paper elevation={ 10 } className={ classes.buttonGrid }>
                    <ButtonGroup>
                        <label className={ classes.buttonLabel }>
                            <Tooltip title={ "Add more Languages to Project." }>
                                <Button
                                    disabled={ !props.auth }
                                    onClick={ () => setAddNew( true ) }
                                    className={ classes.button }>
                                    <Typography className={ classes.buttonText }>
                                        Add
                                    </Typography>
                                </Button>
                            </Tooltip>
                        </label>
                    </ButtonGroup>
                </Paper>
            </Grid>
        </Grid>
        <Dialog open={ addNew }
                fullWidth={ true }
                maxWidth={ "md" }
                onClose={ () => setAddNew( false ) }>
            <DialogTitle>
                <Typography className={ classes.dialogTitleText }>
                    Add Languages
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography className={ classes.dialogBodyText }>
                    You can choose one or more languages.
                </Typography>
                <FormControl fullWidth={ true } className={ classes.selection }>
                    <InputLabel id="language-chip-label">
                        Languages
                    </InputLabel>
                    <Select
                        disabled={ submitted }
                        labelId="language-chip-label"
                        id="language-chip"
                        multiple
                        value={ selectedNewLanguages }
                        onChange={ handleChange }
                        input={ <Input id="select-multiple-chip"/> }
                        renderValue={ ( selected ) => {
                            return (
                                <div className={ classes.chips }>
                                    { selected.map( ( value ) => (
                                        <Chip disabled={ submitted } key={ value } label={ value }
                                              className={ classes.chip }/>
                                    ) ) }
                                </div>
                            )
                        } }
                        MenuProps={ MenuProps }
                    >
                        { props.program.languages.map( ( lan ) => {
                                let check = false
                                props.project.projectLanguages.forEach( lang => {
                                    if ( lang.language.language === lan.language )
                                        check = true
                                } )
                                if ( !check && lan.language !== props.project.base.language )
                                    return <MenuItem key={ lan.id } value={ lan.language }>
                                        { lan.language }
                                    </MenuItem>
                                else {
                                    return null
                                }
                            }
                        ) }
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions className={ classes.seeAllGrid }>
                <Paper elevation={ 10 } className={ classes.buttonGrid }>
                    <ButtonGroup>
                        <label className={ classes.buttonLabel }>
                            <Button
                                disabled={ !props.auth }
                                onClick={ () => {
                                    if ( selectedNewLanguages.length > 0 ) {
                                        let requestBody = []

                                        selectedNewLanguages.forEach( lan => {
                                            props.program.languages.forEach( lang => {
                                                if ( lang.language === lan ) {
                                                    requestBody.push( {
                                                        "projectId": props.project.id,
                                                        "languageId": lang.id,
                                                    } )
                                                }
                                            } )
                                        } )

                                        const headers = {
                                            "Content-Type": "application/json",
                                            'Authorization': 'Bearer ' + Env.TOKEN
                                        }

                                        fetch( process.env.REACT_APP_URL + 'projects/languages?userId=' +
                                            props.program.user.id, {
                                            method: 'POST', headers: headers, body: JSON.stringify( requestBody )
                                        }, )
                                            .then( response => {
                                                if ( response.ok ) return response.json()
                                                else throw new Error();
                                            } )
                                            .then( data => {
                                                console.log( data )
                                                handleLanguageAddition( data )
                                                return data
                                            } )
                                            .then( () => setSuccessOpen( true ) )
                                            .then( () => setAddNew( false ) )
                                            .then( () => setSelectedNewLanguage( [] ) )
                                            .catch( e => {
                                                //console.log(e)
                                                setErrorOpen( true )
                                            } )


                                    }
                                } }
                                className={ classes.button }>
                                <Typography className={ classes.buttonText }>
                                    Add
                                </Typography>
                            </Button>
                        </label>
                        <label className={ classes.buttonLabel }>
                            <Button onClick={ () => setAddNew( false ) } className={ classes.deleteButton }>
                                <Typography className={ classes.buttonText }>
                                    Cancel
                                </Typography>
                            </Button>
                        </label>
                    </ButtonGroup>
                </Paper>
            </DialogActions>

        </Dialog>
        { deleteConfirmation !== null ?
            <Dialog open={ true }
                    fullWidth={ true }
                    maxWidth={ "md" }
                    onClose={ () => setDeleteConfirmation( null ) }
            >
                <DialogTitle>
                    <Typography className={ classes.dialogTitleText }>
                        Language Deletion
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography className={ classes.dialogBodyText }>
                        Are you sure to delete { deleteConfirmation.language.language } from your project ?
                        Anything related to this language will be gone!
                    </Typography>
                </DialogContent>
                <DialogActions className={ classes.seeAllGrid }>
                    <Paper elevation={ 10 } className={ classes.buttonGrid }>
                        <ButtonGroup>
                            <label className={ classes.buttonLabel }>
                                <Button
                                    disabled={ !props.auth }
                                    onClick={
                                        () => {
                                            const headers = {
                                                "Content-Type": "application/json",
                                                'Authorization': 'Bearer ' + Env.TOKEN
                                            }

                                            fetch( process.env.REACT_APP_URL + 'projects/languages?userId=' +
                                                props.program.user.id +
                                                '&projectLanguageId=' + deleteConfirmation.id, {
                                                method: 'DELETE', headers: headers
                                            }, )
                                                .then( response => {
                                                    if ( response.ok ) {
                                                        return response.json()
                                                    } else throw new Error();
                                                } )
                                                .then( ( data ) => {
                                                    if ( data.operationResult === "ERROR" ) {
                                                        throw new Error();
                                                    } else {
                                                        handleLanguageDeletion( deleteConfirmation.id )
                                                    }
                                                } )
                                                .then( () => setSuccessOpen( true ) )
                                                .then( () => setDeleteConfirmation( null ) )
                                                .catch( e => {
                                                    //console.log(e)
                                                    setErrorOpen( true )
                                                } )

                                        }
                                    }
                                    className={ classes.deleteButton }>
                                    <Typography className={ classes.buttonText }>
                                        Delete
                                    </Typography>
                                </Button>
                            </label>
                            <label className={ classes.buttonLabel }>
                                <Button onClick={ () => setDeleteConfirmation( null ) } className={ classes.button }>
                                    <Typography className={ classes.buttonText }>
                                        Cancel
                                    </Typography>
                                </Button>
                            </label>
                        </ButtonGroup>
                    </Paper>
                </DialogActions>
            </Dialog> : null }
        <Snackbar open={ errorOpen }
                  autoHideDuration={ 6000 }
                  onClose={ () => setErrorOpen( false ) }
        >
            <Alert onClose={ () => setErrorOpen( false ) }
                   severity={ "warning" }
            >
                Something went wrong, try it later.
            </Alert>
        </Snackbar>
        <Snackbar open={ successOpen }
                  autoHideDuration={ 1000 }
                  onClose={ () => setSuccessOpen( false ) }
        >
            <Alert onClose={ () => setSuccessOpen( false ) }
                   severity={ "success" }
            >
                Operations are completed successfully.
            </Alert>
        </Snackbar>
    </Paper>
}