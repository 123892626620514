import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../constants/Colors";
import {
    Button,
    CircularProgress,
    Dialog, Grid, InputBase,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip,
    Typography
} from "@material-ui/core";
import Env from "../../constants/Env";

const useStyles = makeStyles( ( theme ) => ( {
    paper: {
        paddingTop: 50,
        paddingBottom: 50,
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
    },
    table: {
        borderTopLeftRadiusRadius: 20,
        borderTopRightRadiusRadius: 20,
    },
    tableRow: {
        "&.MuiTableRow-root": {
            '&:nth-of-type(odd)': {
                background: Colors.gradient,
            },
        },
    },
    tableCell: {
        '&.MuiTableCell-body': {
            borderLeftWidth: 1,
            borderLeft: "solid",
            borderLeftColor: Colors.PRIMARY_PINK_ALT,
            fontSize: 14,
            fontFamily: Colors.fontOfProgram,
            borderBottomWidth: 1,
            borderBottomLeft: "solid",
            borderBottomColor: Colors.PRIMARY_PINK_ALT,
        },
        minWidth: 300,
        maxWidth: 300,
        '&.MuiTableCell-root': {},

        '&.MuiTableCell-head': {
            backgroundColor: 'transparent',
            color: theme.palette.common.white,
        },
    },
    tableCellSecond: {
        '&.MuiTableCell-body': {
            borderLeftWidth: 1,
            borderLeft: "solid",
            borderLeftColor: Colors.PRIMARY_PINK_ALT,
            borderBottomWidth: 1,
            borderBottomLeft: "solid",
            borderBottomColor: Colors.PRIMARY_PINK_ALT,
        },
        '&.MuiTableCell-head': {
            backgroundColor: 'transparent',
            color: theme.palette.common.white,
        },

        minWidth: 300,
        maxWidth: 300,
    },
    tableCellThird: {

        '&.MuiTableCell-head': {
            backgroundColor: 'transparent',
            color: theme.palette.common.white,
        },
        '&.MuiTableCell-body': {
            borderWidth: 1,
            border: "solid",
            borderTopWidth: 0,

            borderColor: Colors.PRIMARY_PINK_ALT,
        },
        minWidth: 300,
        maxWidth: 300,
    },
    tableBody: {
        width: '100%'
    },
    button: {
        padding: 0
    },

    text: {
        maxLines: 5,
        fontWeight: "bold",
        fontSize: 14,
        fontFamily: Colors.fontOfProgram,
    },
    images: {
        justifyContent: "center",
        display: "flex",
    },
    clickableImage: {
        cursor: "pointer",
    },
    cellComplete: {
        '&.MuiTableCell-body': {
            borderLeftWidth: 1,
            borderLeft: "solid",
            borderLeftColor: Colors.PRIMARY_PINK_ALT,
            borderBottomWidth: 1,
            borderBottomLeft: "solid",
            borderBottomColor: Colors.PRIMARY_PINK_ALT,
            borderRightWidth: 1,
            borderRight: "solid",
            borderRightColor: Colors.PRIMARY_PINK_ALT,
        },
        minWidth: 300,
        maxWidth: 300,
        backgroundColor: "#f2fff6",
        fontFamily: Colors.fontOfProgram,
        fontWeight: "bold",
        fontSize: 14,
    },
    cellUnComplete: {
        '&.MuiTableCell-body': {
            borderLeftWidth: 1,
            borderLeft: "solid",
            borderLeftColor: Colors.PRIMARY_PINK_ALT,
            borderBottomWidth: 1,
            borderBottomLeft: "solid",
            borderBottomColor: Colors.PRIMARY_PINK_ALT,
            borderRightWidth: 1,
            borderRight: "solid",
            borderRightColor: Colors.PRIMARY_PINK_ALT,
        },
        minWidth: 300,
        maxWidth: 300,
        backgroundColor: "#fff2f2",
        color: Colors.PRIMARY_BLACK,
        fontWeight: "bold",
        fontSize: 14,
        fontFamily: Colors.fontOfProgram,
    },
    input: {
        fontWeight: "bold",
        fontSize: 14,
        fontFamily: Colors.fontOfProgram,
        minWidth: 300,
        maxWidth: 300,
    },
    titleText: {
        fontSize: 24,
        fontFamily: Colors.fontOfProgram,
        fontWeight: "bold",
        color: Colors.PRIMARY_PINK_UP,
    },
    scrollable: {
        overflowY: "scroll",
    }
} ) );


export default function TranslationTaskPage( props ) {
    const classes = useStyles();
    let images = new Map()
    const [ localizations, setLocalizations ] = React.useState( props.order.texts )
    const [ dialogOpen, setDialogOpen ] = React.useState( null )
    const [ loading, setLoading ] = React.useState( true )
    const [ isStarted, setStarted ] = React.useState( false )
    const [ error, setError ] = React.useState( false )
    const [ texts, setTexts ] = React.useState( [] )

    const rows = []
    let waitingCount = 0

    function textAreaAdjust( element ) {
        element.style.height = "1px";
        element.style.height = ( 25 + element.scrollHeight ) + "px";
    }

    if ( texts.length > 0 ) {
        texts.forEach( text => {
                if ( text.equivalent === null || text.equivalent.length === 0 )
                    waitingCount++;
            }
        )
    }


    async function getTranslationOrder() {
        setLoading( true )
        let headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }
        fetch( Env.URL + 'projects/translationOrders?id=' + props.order.id, {
            method: 'get', headers: headers,
        }, )
            .then( response => {
                if ( response.ok ) {
                    return response.json()
                } else {
                    response.json()
                            .then( e => {
                                //console.log(e)
                            } )
                    throw new Error( 'Something went wrong' );
                }
            } )
            .then( data => {
                setLocalizations( [ ...data.texts ] )
                setTexts( [ ...data.texts ] )
                setError( false )
                setLoading( false )
            } )
            .catch( ( error ) => {
                setLoading( false )
                setError( true )
            } )
    }

    if ( !isStarted ) {
        getTranslationOrder()
        setStarted( true )
    }

    function handleChanges( id, text ) {
        if ( text.length === 0 )
            text = null
        props.onChange( id, text )
        setLocalizations( localizations.map( t => {
            if ( t.id === id ) {
                t.equivalent = text
            }
            return t
        } ) )
    }

    function adjustList( explanationList, text ) {
        let check = false
        explanationList.forEach( explanation => {
            if ( explanation.explanation === null ) {
                if ( text.explanation === null ) {
                    explanation.textList.push( text )
                    check = true
                }
            } else {
                if ( text.explanation === null || text.explanation.id !== explanation.explanation.id ) {
                } else if ( text.explanation.id === explanation.explanation.id ) {
                    explanation.textList.push( text )
                    check = true
                }
            }
        } )

        if ( !check ) {
            let explanation = {
                "explanation": text.explanation,
                "textList": [ text ],
            }
            explanationList.push( explanation )
        }
        return explanationList;

    }

    const process = ( text ) => {
        let explanation = {
            "explanation": text.explanation,
            "textList": [ text ],
        }

        if ( text.image != null ) {
            if ( images.has( text.image.id ) ) {
                images.set( text.image.id, adjustList( images.get( text.image.id ), text ) )
            } else {
                images.set( text.image.id, [ explanation ] )
            }
        } else {
            if ( images.has( text.image ) ) {
                if ( images.get( text.image ) ) {
                    images.set( text.image, adjustList( images.get( text.image ), text ) )
                }
            } else {
                images.set( text.image, [ explanation ] )
            }
        }

    }

    localizations.forEach( text => {

        process( text )
    } )

    const saveChanges = async ( id, value ) => {
        const requestList = []

        requestList.push( {
            "id": id,
            "equivalent": value
        } )

        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }
        await fetch( "https://localifyserver.com/localization-app/v1/" + 'projects/translationTexts/update', {
            method: 'POST', headers: headers, body: JSON.stringify( requestList )
        }, )
            .then( response => {
                if ( response.ok ) return response.json()
                else throw new Error();
            } )
            .then( data => {
            } )
            .then( async () => {
            } )
            .catch( e => {
            } )

    }

    images.forEach( ( value, key ) => {
        let tempImage = null
        if ( key != null ) {
            tempImage = value[ 0 ].textList[ 0 ].image
            tempImage.length = 0
            value.forEach( explanation => {
                    explanation.textList.forEach( text => {
                        tempImage.length = tempImage.length + 20 + ( ( text.equivalent === null ) ? 0 :
                            ( text.equivalent.length - 110 > 0 ? ( text.equivalent.length - 110 ) / 35 * 10 : ( 0 ) ) )
                    } )
                }
            )
        }

        rows.push(
            <TableRow>
                <TableCell align={ "center" } className={ classes.tableCell }>
                    {
                        tempImage == null ?
                            <div/> :
                            <Tooltip title={ "Click on image to Zoom" }>
                                <img
                                    key={ "https://localization-bucket.s3.eu-central-1.amazonaws.com/" + tempImage.url }
                                    className={ classes.clickableImage }
                                    onClick={ () => setDialogOpen(
                                        "https://localization-bucket.s3.eu-central-1.amazonaws.com/" + tempImage.url ) }
                                    src={ "https://localization-bucket.s3.eu-central-1.amazonaws.com/" + tempImage.url }
                                    alt={ "explanation" }
                                    height={ tempImage.length > 330 ? 330 : tempImage.length }
                                />
                            </Tooltip>

                    }
                </TableCell>
                { value.map( explanation => {
                    return <TableRow>
                        <TableCell align={ "center" } className={ classes.tableCellSecond }>
                            <div>
                                <InputBase
                                    rowsMax={ explanation.textList.length * 4 }
                                    disabled={ true }
                                    className={ classes.input }
                                    multiline={ true }
                                    value={ explanation.explanation === null ? "" :
                                        explanation.explanation.content }
                                >
                                </InputBase>
                            </div>
                        </TableCell>
                        { explanation.textList.map( text => {
                            return <TableRow>
                                <TableCell height={ 50 } align={ "center" }
                                           className={ classes.tableCellThird }>
                                    <textarea readOnly={ true } className={ classes.text } style={ {
                                        borderRadius: 10,
                                        minWidth: "100%",
                                        maxWidth: "100%",
                                        height: text.englishShownWord.replaceAll( /\\n/g, "<br />" ).length / 1.6 + 80
                                    } }>
                                        { text.englishShownWord.replaceAll( /\\n/g, "<br />" ) }
                                    </textarea>
                                </TableCell>
                                { props.order.type !== "WRITE" ? null :
                                    <TableCell align={ "center" }
                                               aria-multiline={ true }
                                               height={ 50 }
                                               className={
                                                   text.equivalent === null
                                                       ? classes.cellUnComplete
                                                       :
                                                       classes.cellComplete }>
                                        <textarea
                                            style={ {
                                                borderRadius: 10,
                                                minWidth: "100%",
                                                maxWidth: "100%",
                                                height: text.englishShownWord.replaceAll( /\\n/g, "<br />" ).length /
                                                    1.6 + 80
                                            } }
                                            disabled={ props.order.type === "READ" ||
                                            ( props.order.status === "PENDING" || props.order.status ===
                                                "COMPLETED" || props.order.status === "CHECKED" ||
                                                props.order.status === "PAID" ) }
                                            onBlur={ () => {
                                                saveChanges( text.id, text.equivalent )
                                                    .then( () => {
                                                    } )
                                            } }
                                            className={ classes.input }
                                            multiline={ true }
                                            value={ ( text.equivalent === null ) ? "" : text.equivalent }
                                            onChange={ ( value ) => {
                                                if ( !props.isPreview )
                                                    handleChanges( text.id, value.target.value )
                                            } }
                                        >
                                        </textarea>

                                    </TableCell> }
                            </TableRow>
                        } ) }
                    </TableRow>
                } ) }
            </TableRow>
        )

    } )


    return loading ? <Grid container style={ { alignItems: "center", display: "flex", justifyContent: "center", } }>
            <CircularProgress/> </Grid> :
        error ? <Grid container style={ { alignItems: "center", display: "flex", justifyContent: "center", } }>
                <Grid item xs={ 12 } style={ { alignItems: "center", display: "flex", justifyContent: "center", } }>
                    <Typography>Something went wrong! Data can not be downloaded!</Typography>
                </Grid>
                <Grid item xs={ 12 } style={ { alignItems: "center", display: "flex", justifyContent: "center", } }>
                    <Button onClick={ () => getTranslationOrder() }>
                        <Typography>Retry</Typography>
                    </Button>
                </Grid>
            </Grid> :
            <div>
                <center>
                    <Grid container justify={ "center" }>
                        <Grid item xs={ 2 }>
                            <Grid container>
                                <Grid item xs={ 12 }>
                                    <Typography className={ classes.text }>
                                        Lines
                                    </Typography>
                                </Grid>
                                <Grid item xs={ 12 }>
                                    <Typography className={ classes.titleText }>
                                        { texts.length }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={ 2 }>
                            <Grid container>
                                <Grid item xs={ 12 }>
                                    <Typography className={ classes.text }>
                                        Translated
                                    </Typography>
                                </Grid>
                                <Grid item xs={ 12 }>
                                    <Typography className={ classes.titleText }>
                                        { ( texts.length - waitingCount ) }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={ 2 }>
                            <Grid container>
                                <Grid item xs={ 12 }>
                                    <Typography className={ classes.text }>
                                        To Be Translated
                                    </Typography>
                                </Grid>
                                <Grid item xs={ 12 }>
                                    <Typography className={ classes.titleText }>
                                        { waitingCount }
                                    </Typography>

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={ 2 }>
                            <Grid container>
                                <Grid item xs={ 12 }>
                                    <Typography className={ classes.text }>
                                        Word Count
                                    </Typography>
                                </Grid>
                                <Grid item xs={ 12 }>
                                    <Typography className={ classes.titleText }>
                                        { props.order.textCount }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </center>
                <TableContainer className={ classes.paper }>
                    <Table style={ props.order.style === "WRITE" ?
                        { maxWidth: 1200, minWidth: 1200 }
                        :
                        { maxWidth: 900, minWidth: 900 }
                    } stickyHeader
                           aria-label="sticky table"
                           className={ classes.table }>
                        <TableHead>
                            <TableRow className={ classes.tableRow }>
                                <TableCell align={ "center" } className={ classes.tableCell }><Typography
                                    className={ classes.text }>Guide Images</Typography>
                                </TableCell>
                                <TableRow>
                                    <TableCell align={ "center" } className={ classes.tableCellSecond }>
                                        <Typography
                                            className={ classes.text }>
                                            { "Description \n " +
                                            "(DO NOT TRANSLATE)" }
                                        </Typography>
                                    </TableCell>
                                    <TableRow>
                                        <TableCell align={ "center" } className={ classes.tableCellThird }>
                                            <Typography
                                                className={ classes.text }>
                                                Source Language
                                            </Typography>
                                        </TableCell>
                                        { props.order.type !== "WRITE" ? null : <TableRow>
                                            <TableCell align={ "center" } className={ classes.tableCellThird }>
                                                <Typography className={ classes.text }>
                                                    Target Language
                                                    { props.order.type === "READ" ? " (Read Only) " :
                                                        ( props.order.status === "NOT_STARTED" ||
                                                            props.order.status ===
                                                            "IN_PROGRESS" || props.order.status ===
                                                            "SAVED" ) ? " (Editable) " : " (Read Only)" }
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        }
                                    </TableRow>
                                </TableRow>
                            </TableRow>
                        </TableHead>
                        <TableBody className={ classes.tableBody }>
                            { rows }
                        </TableBody>
                    </Table>
                    <Dialog open={ dialogOpen != null } onClose={ () => setDialogOpen( null ) }>
                        <img
                            key={ dialogOpen }
                            src={ dialogOpen }
                            width={ 400 }
                            alt={ "explanation" }/>
                    </Dialog>
                </TableContainer>
            </div>
}

/*
   <Grid container xs={3}>
                   <Grid item xs={12} >
                       <Typography className={classes.text}>
                           Show Empty Values
                       </Typography>

                   </Grid>
                   <Grid item xs={12}>
                       <Switch
                           checked={showEmptyOnes}
                           onChange={() => setShowEmptyOnes(!showEmptyOnes)}
                           inputProps={{ 'aria-label': 'secondary checkbox' }}
                       />
                   </Grid>
               </Grid>
 */