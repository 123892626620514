import React from 'react';
import {Grid, Paper, Typography, Divider, Tooltip} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import Colors from "../../../constants/Colors";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ProjectPreviewStatus from "./ProjectPreviewStatus";

const useStyles = makeStyles(() => ({
    paper: {
        borderRadius: 10,
        paddingTop:28,
        paddingRight:24,
        paddingLeft:24,
        height: 90,
        color: Colors.PRIMARY_PINK_ALT,
        backgroundColor: 'white',
        '&:hover': {
            color: Colors.PRIMARY_PINK_UP,
            backgroundColor: 'white',
            opacity: 1,
        },
    },
    paperExtended: {
        borderRadius: 10,
        paddingTop:28,
        paddingRight:24,
        paddingLeft:24,
        color: Colors.PRIMARY_PINK_UP,
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
            opacity: 1,
        },
    },
    grid: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    gridEnd: {
        flexGrow: 1,
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    textName: {
        marginLeft: 24,
        display: "flex",
        fontFamily: Colors.fontOfProgram,
        fontSize: 20,
        fontWeight: "bold",
    },
    textVersion: {
        marginRight: 28,
        display: "flex",
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingTop: 8,
        fontFamily: Colors.fontOfProgram,
        fontSize: 20,
        fontWeight: "bold",
    },
    divider: {
        margin: 20,
    },
    previewBody: {
        backgroundColor: Colors.PRIMARY_BACKGROUND,
    },
    textTitle: {
        fontSize: 17,
        fontFamily: Colors.fontOfProgram,
        fontWeight: "bold",
        color: Colors.PRIMARY_WHITE,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center"
    },
    gridTranslator: {
        paddingLeft: 20,
    },
    titleCard: {
        background: Colors.gradientSecond,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: Colors.PRIMARY_BLUE,
        display: "flex",
        margin: 4,
        marginBottom: 12,
        paddingTop: 20,
        paddingBottom: 20,
    },
    clickable: {
        cursor: "pointer",
    },
    clickableIcon: {

        fontSize: 32,
    },
    clickableDiv: {
        border: "solid",
        backgroundColor:Colors.PRIMARY_PINK_ALT,
        color:Colors.PRIMARY_WHITE,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        borderRadius: 10,
        '&:hover':{
            backgroundColor:Colors.PRIMARY_WHITE,
            color:Colors.PRIMARY_PINK_ALT,
        }
    },
    itemCenter: {
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center"
    },
    endGrid: {
        display: "flex",
        justifyContent: "end",
    }
}));

export default function ProjectPreviewCard(props) {
    const classes = useStyles();
    const [elevationValue, setElevationValue] = React.useState(0);
    const [extended, setExtended] = React.useState(false);

    return <Paper
        onMouseOver={() => setElevationValue(15)}
        onMouseOut={() => setElevationValue(0)}
        elevation={elevationValue}
        className={extended ? classes.paperExtended : classes.paper}>

        <Grid spacing={1} container className={classes.grid}>
            <Grid item xs={4}>
                <Typography className={classes.textName}>
                    {props.projectInfo.name}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography className={classes.textName}>
                    {props.projectInfo.projectLanguages != null ? props.projectInfo.projectLanguages.length +
                        (props.projectInfo.projectLanguages.length === 1 ? " Language" : " Languages") : "0 Language"}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography className={classes.textName}>
                    {props.projectInfo.categories != null ? props.projectInfo.categories.length +
                        (props.projectInfo.categories.length === 1 ? " Translation Subject" : " Translation Subjects") :
                        "0 Translation Subject"}
                </Typography>
            </Grid>
            <Grid item xs={1} className={classes.gridEnd}>
                <Grid container>
                    <Grid
                        item xs
                        className={classes.clickable} onClick={() => {
                        setExtended(!extended)
                    }
                    }>
                        <div className={classes.clickableDiv}>
                            {extended ?
                                <Tooltip title={"Hide the extended body."}>
                                    <KeyboardArrowUpIcon className={classes.clickableIcon}/>
                                </Tooltip>
                                :
                                <Tooltip title={"Extend the card to see progress."}>
                                    <KeyboardArrowDownIcon className={classes.clickableIcon}/>
                                </Tooltip>
                            }
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        {extended ?
            <div>
                <Divider className={classes.divider}/>
                <Paper className={classes.titleCard} elevation={1}>
                    <Grid container className={classes.grid}>
                        <Grid item xs={3}>
                            <Grid container>
                                <Grid item xs={4} className={classes.itemCenter}>
                                    <Typography className={classes.textTitle}>
                                        {"Target Language"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} className={classes.itemCenter}>
                                    <Typography className={classes.textTitle}>
                                        {"Translator Name"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography className={classes.textTitle}>
                                {"Progress"}
                            </Typography>
                        </Grid>

                    </Grid>
                </Paper>
                {
                    props.projectInfo.translationStatus.map((translations, index) => {

                        return <ProjectPreviewStatus
                            functionList={props.functionList}
                            projectStatus={translations}/>
                    })
                }
            </div> : <div/>}

    </Paper>;
}
