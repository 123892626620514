import React from 'react'
import { Button, CircularProgress, Dialog, Grid, Snackbar, TextField } from "@material-ui/core";
import Colors from "../constants/Colors";
import Env from "../constants/Env";
import { Alert } from "@material-ui/lab";

export default function TextSearchDialog( props ) {
    const [ result, setResult ] = React.useState( [] )
    const [ text, setText ] = React.useState( "" )
    const [ loading, setLoading ] = React.useState( false )
    const [ error, setError ] = React.useState( "" )
    const [ unSelectedTabs, setUnSelectedTabs ] = React.useState( [] )
    const [ tabs, setTabs ] = React.useState( [] )
    const [ selectedItem, setSelectedItem ] = React.useState( null )


    function searchText( text ) {
        setLoading( true )
        setSelectedItem(null)
        setUnSelectedTabs( [] )
        setResult([])

        let headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }

        fetch( process.env.REACT_APP_URL + 'projects/texts/searchText?text=' + text, {
            method: 'GET', headers: headers,
        }, )
            .then( response => {
                if ( response.ok ) {
                    return response.json()
                }
            } )
            .then( data => {
                setResult( [ ...data ] )

                let tempTabs = []
                console.log( data )
                data.forEach( row => {
                    if ( !tempTabs.includes( row.project.name ) ) {
                        tempTabs.push( row.project.name )
                    }
                } )

                setTabs( tempTabs )

                setError( "" )
                setLoading( false )
            } )
            .catch( ( error ) => {
                console.log( error )
                setLoading( false )
                setError( error.message )
            } )
    }

    return <Dialog fullWidth maxWidth={ "lg" } open={ props.open } onClose={ () => props.onClose() }>
        <Grid container style={ {
            minHeight: "80vh"
        } }>
            <Grid container style={ { padding: 20 } }>
                <Grid item xs={ 12 } style={ {
                    display: "flex", justifyContent: "center",
                    fontWeight: "bold",
                    padding: 16,
                    fontSize: 32
                } }>
                    Search Text
                </Grid>
                <Grid item xs={ 4 }>

                </Grid>
                <Grid item xs={ 2 } style={ {
                    display: "flex", justifyContent: "center"
                } }>
                    <TextField
                        value={ text }
                        onChange={ ( e ) => setText( e.target.value ) }
                        style={ {
                            width: 220
                        } }/>
                </Grid>
                <Grid item xs={ 2 } style={ {
                    display: "flex", justifyContent: "center"
                } }>
                    {
                        loading ? <CircularProgress/> : <Button
                            onClick={ () => {
                                if ( text.trim().length > 2 ) {
                                    searchText( text )
                                } else {
                                    setError( "Text has to be longer than 2 characters!" )
                                }
                            } }
                            style={ {
                                border: "solid",
                                borderWidth: 2,
                                borderRadius: 10,
                                width: 160
                            } }>
                            Search
                        </Button>
                    }
                </Grid>
                <Grid item xs={ 4 }>

                </Grid>
            </Grid>
            <Grid container style={ {
                minHeight: "6vh",
                display: "flex",
                justifyContent: "center",
                paddingBottom: 20
            } }>
                { tabs.map( tab => {
                    return <Button onClick={ () => {
                        if ( unSelectedTabs.includes( tab ) ) {
                            let temp = []
                            unSelectedTabs.forEach( el => {
                                if ( el !== tab ) {
                                    temp.push( el )
                                }
                            } )
                            setUnSelectedTabs( temp )
                        } else {
                            let temp = [ ...unSelectedTabs ]
                            temp.push( tab )
                            setUnSelectedTabs( temp )
                        }
                    } } style={ {
                        border: "solid", borderWidth: 2, borderRadius: 10,
                        background: unSelectedTabs.includes( tab ) ? Colors.PRIMARY_GREY : Colors.PRIMARY_GREEN_LIGHT,
                        color: unSelectedTabs.includes( tab ) ? Colors.PRIMARY_WHITE : Colors.PRIMARY_WHITE

                    } }>
                        { tab }
                    </Button>
                } ) }
            </Grid>
            <Grid container style={ {
                minHeight: "50vh",
            } }>
                <Grid item xs={ 6 }>
                    <Grid container style={ {

                        maxHeight: "60vh",
                        overflowX: "scroll",
                        borderRight: "solid",
                        borderRightWidth: 1,
                        paddingLeft: 20,
                        paddingRight: 20
                    } }>
                        { result.map( row => {
                            if ( !unSelectedTabs.includes( row.project.name ) ) {
                                return <Grid container
                                             onClick={ () => setSelectedItem( row ) }
                                             style={ {
                                                 padding: 4,
                                                 alignItems: "center",
                                                 borderBottom: "solid",
                                                 background: selectedItem === row ? Colors.PRIMARY_GREEN_THIRD :
                                                     Colors.PRIMARY_WHITE,
                                                 cursor: "pointer",
                                             } }>
                                    <Grid
                                        item xs={ 9 } style={ {
                                    } }>
                                        { row.englishWord }
                                    </Grid>
                                    <Grid item xs={ 1 }>

                                    </Grid>
                                    <Grid item xs={ 2 }
                                          style={ { color: Colors.PRIMARY_GREEN_LIGHT, fontWeight: "bold" } }>
                                        { row.project.name }
                                    </Grid>
                                </Grid>
                            }
                        } ) }
                    </Grid>
                </Grid>
                <Grid item xs={ 6 }>
                    <Grid container style={ {
                        minHeight: "60vh",
                        paddingLeft: 20,
                        paddingRight: 20,
                        maxHeight: "60vh",
                        overflowX: "scroll",
                        borderLeft: "solid",
                        borderLeftWidth: 1,
                    } }>
                        { selectedItem && selectedItem.localizations.map( ( localization ) => {
                            if ( !localization.base ) {
                                return <Grid container
                                             style={ { padding: 4, alignItems: "center", borderBottom: "solid" } }>
                                    <Grid

                                        item xs={ 9 }>
                                        { localization.text }
                                    </Grid>
                                    <Grid item xs={ 1 }>

                                    </Grid>
                                    <Grid item xs={ 2 }
                                          style={ { color: Colors.PRIMARY_GREEN_LIGHT, fontWeight: "bold" } }>
                                        { localization.projectLanguage.language.language }
                                    </Grid>
                                </Grid>
                            }
                        } ) }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Snackbar open={ error !== "" }
                  autoHideDuration={ 6000 }
                  onClose={ () => setError( "" ) }
        >
            <Alert onClose={ () => setError( "" ) }
                   severity={ "warning" }
            >
                { error }
            </Alert>
        </Snackbar>
    </Dialog>
}