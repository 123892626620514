import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../../../constants/Colors";
import {
    ButtonGroup,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    InputBase,
    Radio,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tabs,
    Tooltip,
    Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { CloseOutlined, History } from "@material-ui/icons";
import HistoryDialog from "./HistoryDialog";
import Program from "../../../../constants/Program";

const useStyles = makeStyles( {
    grid: {
        display: "flex",
        justifyContent: "center",
        maxHeight: '80',
    },
    navigationGrid: {
        display: "flex",
        justifyContent: "center",
        padding: 20,
        paddingBottom: 4,
        maxHeight: 200,
    },
    topText: {
        fontSize: 20,
        color: Colors.PRIMARY_PINK_ALT,
        fontWeight: "bold",
        textAlign: "center",
    },
    subText: {
        fontSize: 24,
        color: Colors.PRIMARY_BLUE,
        fontWeight: "bold",
        textAlign: "center",
    },
    tableGrid: {
        display: "flex",
        justifyContent: "center",
        paddingLeft: 80,
        paddingRight: 80,
        height: 600,
        maxHeight: 600,
    },
    buttonGroup: {
        borderRadius: 10,
    },
    button: {
        paddingLeft: 40,
        paddingRight: 40,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_BLUE,
        '&:hover': {
            backgroundColor: Colors.PRIMARY_BLUE,
            color: Colors.PRIMARY_WHITE,
        }
    },
    buttonText: {
        fontWeight: "bold",
        fontSize: 16
    },

    scrollableDiv: {
        maxHeight: 540,
        overflowY: "scroll",
    },
    gridTableHead: {
        marginLeft: 80,
        paddingBottom: 10,
        paddingTop: 10,
        marginRight: 80,
        alignItems: "center",
        color: Colors.PRIMARY_WHITE,
        background: Colors.gradient,
        display: "flex",
        justifyContent: "space-between",
    },
    gridTableBody: {
        border: "solid",
        borderWidth: 1,
        borderColor: Colors.PRIMARY_PINK_ALT,
        maxHeight: 600,
        alignItems: "center",
        marginLeft: 80,
        overflowY: "scroll",
        marginRight: 80,
        display: "flex",
        justifyContent: "space-between",
    },
    gridBodyRow: {
        display: "flex",
        justifyContent: "space-between",
    },
    gridHeadCell: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
    },
    gridTableCell: {
        width: 50,
        border: "groove",
        borderWidth: 1,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
    },

    gridTableCellActive: {
        background: Colors.PRIMARY_GREEN_THIRD,
        width: 50,
        border: "groove",
        borderWidth: 1,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
    },
    gridText: {
        fontWeight: "bold",
        fontSize: 14,
    },
    seeAllGrid: {
        padding: 20,
        display: "flex",
        justifyContent: "center",
    },
    seeAllImg: {
        padding: 4,
        width: 200,
        cursor: "pointer",
        display: "inline",
    },
    seeAllImgActive: {
        border: "solid",
        borderColor: Colors.PRIMARY_BLUE,
        padding: 4,
        width: 200,
        cursor: "pointer",
        display: "inline",
    },
    seeAllExp: {
        margin: 4,
        padding: 8,
        width: 300,
        cursor: "pointer",
        display: "inline",
    },
    seeAllExpActive: {
        border: "solid",
        borderColor: Colors.PRIMARY_BLUE,
        margin: 4,
        padding: 8,
        width: 300,
        cursor: "pointer",
        display: "inline",
    },
    textField: {
        height: "inherit",
        paddingLeft: 8,
        fontSize: 14,
        paddingRight: 8
    },
    dialogTitle: {
        color: Colors.PRIMARY_BLUE,
        fontWeight: "bold",
        fontSize: 40,
        paddingLeft: 90,
        paddingRight: 90,
        paddingTop: 20,
    },
    dialogTop: {
        padding: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    exportGridBody: {
        maxHeight: 800,
        overflowY: "scroll",
    },
    exportDialog: {
        background: Colors.gradient
    },
    innerTab: {
        color: Colors.PRIMARY_WHITE,

        "&.Mui-selected": {}
    },
    indicator: {
        background: Colors.PRIMARY_BLUE,
        width: '100%',
        display: "flex",
        justifyContent: 'center',
    },
    tab: {
        color: Colors.PRIMARY_BLUE,
        "&.Mui-selected": {}
    },
    loaderGrid: {
        paddingTop: 300,
        height: 800,
        maxHeight: 800,
    },
    exportPanel: {
        minWidth: 200,
        fontSize: 16,
        color: Colors.PRIMARY_BLACK
    },
    historyButton: {
        height: 50,
        width: 50,
        margin: 8,
        backgroundColor: Colors.PRIMARY_GREEN_LIGHT,
        color: Colors.PRIMARY_WHITE,
        '&:hover': {
            color: Colors.PRIMARY_GREEN_LIGHT,
            backgroundColor: 'transparent',
        }
    },
    centerGrid: {
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
    },
    centerGridStats: {
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        paddingBottom: 16,
    },
    historyIcon: {
        fontSize: 30,
        paddingTop: 4,
        margin: 0
    },
    img: {
        cursor: "pointer",
    },
    warningGrid: {
        display: "flex",
        justifyContent: "center",
        padding: 20,
    },
    warningTopText: {
        textAlign: "center",
        fontSize: 40,
        color: Colors.PRIMARY_PINK_UP,
        fontWeight: "bold",
    },
    warningSubText: {
        textAlign: "center",
        fontSize: 20,
        color: Colors.PRIMARY_GREY,
        fontWeight: "bold",
    },
    warningButtonGroup: {
        display: "flex",
        justifyContent: "center",
    },
    warningButtonProcess: {
        background: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_BLUE,
        width: 240,
        '&:hover': {
            background: Colors.PRIMARY_BLUE,
            color: Colors.PRIMARY_WHITE,
        }
    },
    warningButtonCancel: {
        background: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_PINK_UP,
        width: 240,
        '&:hover': {
            background: Colors.PRIMARY_PINK_UP,
            color: Colors.PRIMARY_WHITE,
        }
    },
    warningButtonText: {
        fontWeight: "bold",
    },
    cell: {
        padding: 0,

    },
    headCell: {
        padding: 4,

    },
    tableHead: {
        border: "solid",
        borderWidth: 1,
        borderColor: Colors.PRIMARY_BLUE
    }
} );

export default function TabsPanel( props ) {
    const classes = useStyles()
    const [ showExplanation, setShowExplanation ] = React.useState( false )
    const [ showImage, setShowImage ] = React.useState( false )
    const [ imageSelection, setImageSelection ] = React.useState( -1 )
    const [ explanationSelection, setExplanationSelection ] = React.useState( -1 )
    const [ selectedElement, setSelectedElement ] = React.useState( null )
    const [ history, setHistory ] = React.useState( null )
    const [ exportDialog, setExportDialog ] = React.useState( null )
    const [ exportPage, setExportPage ] = React.useState( "initial" )
    const [ multipleExportTab, setMultipleExportTab ] = React.useState( 0 )
    const [ exportWarning, setExportWarning ] = React.useState( null )
    const [ page, setPage ] = React.useState( 0 )
    const [ rowsPerPage, setRowsPerPage ] = React.useState( 5 )
    let languages = [ ...props.project.projectLanguages, props.project.base ]
    let textArea = null
    let hasAllConfirmed = true
    let permission = false
    let userRole = null

    if ( Program.getPaginationSize() !== rowsPerPage ) {
        setRowsPerPage( Program.getPaginationSize() )
    }

    function handleChangePage( event, newPage ) {
        setPage( newPage );
    }

    function handleChangeRowsPerPage( event ) {
        Program.setPaginationSize( parseInt( event.target.value, 10 ) )

        setRowsPerPage( parseInt( event.target.value, 10 ) );
        setPage( 0 );
    }

    props.table.forEach( row => {
        if ( row.confirmationAvailable )
            hasAllConfirmed = false
    } )

    props.project.roles.forEach( role => {
        let categoryCheck = false
        role.roleRestrictionList.forEach( roleRest => {
            if ( roleRest.category.id === props.project.categories[ props.categoryIndex ].id )
                categoryCheck = true
        } )

        if ( categoryCheck ) {
            role.roleList.forEach( el => {
                if ( el.user.id === props.program.user.id ) {
                    userRole = role
                }
            } )
        }
    } )

    props.project.categories[ props.categoryIndex ].permissionList.forEach( per => {
        if ( per.language.language === languages[ props.languageIndex ].language &&
            per.user.id === props.program.user.id &&
            new Date( per.expiration ) > new Date( Date.now() ) ) {
            permission = true
        }
    } )

    function checkAllIsConfirmed() {
        let check = true
        props.table.forEach( row => {
            if ( row.confirmationAvailable )
                check = false
        } )
        return check
    }

    function generateExportedText( language ) {
        if(language.language !== undefined){
            language = language.language
        }

        let tempArray = []

        if ( exportDialog === "android" ) {
            props.project.categories[ props.categoryIndex ].texts.forEach( text => {
                text.localizations.forEach( localization => {
                    let check = false;

                    props.selectedLocalizations.forEach( loc => {
                        if ( localization.id === loc )
                            check = true
                    } )


                    if ( check ) {
                        text.localizations.forEach( localization => {

                            if ( ( localization.projectLanguage !== null && check &&
                                    localization.projectLanguage.language.language === language ) ||
                                ( check && localization.base && language === props.project.base.language ) ) {
                                let temp = localization.text !== null ? localization.text : ' '

                                temp = temp.replaceAll( "%", "%%" )
                                temp = temp.replaceAll( "&", "&amp;" )
                                temp = temp.replaceAll( ":STR:", "%s" )
                                temp = temp.replaceAll( ":INT:", "%d" )
                                temp = temp.replaceAll( "'", "\\'" )
                                temp = temp.trim()

                                tempArray.push( "<string name=\"" + text.androidKey + "\">" + temp + "</string>" )
                            }
                        } )
                    }


                } )
            } )
        } else {
            props.project.categories[ props.categoryIndex ].texts.forEach( text => {
                text.localizations.forEach( localization => {
                    let check = false;
                    props.selectedLocalizations.forEach( loc => {
                        if ( localization.id === loc )
                            check = true
                    } )

                    if ( check ) {
                        text.localizations.forEach( localization => {
                            if ( ( check && localization.projectLanguage !== null &&
                                    localization.projectLanguage.language.language === language ) ||
                                ( check && localization.base && props.project.base.language === language ) ) {
                                let temp = localization.text !== null ? localization.text : ' '
                                let tempEnglish = text.englishWord
                                temp = temp.replaceAll( "%", "%%" )
                                temp = temp.replaceAll( ":STR:", "%@" )
                                temp = temp.replaceAll( ":INT:", "%d" )
                                temp = temp.replaceAll( "\"", "\\\"" )
                                temp = temp.trim()

                                tempEnglish = tempEnglish.replaceAll( "%", "%%" )
                                tempEnglish = tempEnglish.replaceAll( ":STR:", "%@" )
                                tempEnglish = tempEnglish.replaceAll( ":INT:", "%d" )
                                tempEnglish = tempEnglish.replaceAll( "\"", "\\\"" )
                                tempEnglish = tempEnglish.trim()

                                tempArray.push( '"' + tempEnglish + '" = "' + temp + '";' )
                            }
                        } )
                    }


                } )
            } )
        }
        return tempArray.join( '\n' )
    }

    function calculateTranslatedNumber() {
        let count = 0;
        props.table.forEach( row => {
            if ( row.localization.text !== null && row.localization.text.length > 0 ) {
                count++
            }
        } )

        return count
    }

    function calculateEnteredAndroidNumber() {
        let count = 0;
        props.table.forEach( row => {
            if ( row.androidKey !== null && row.androidKey.length > 0 ) {
                count++
            }
        } )

        return count
    }

    return <Grid key={ 'tabPanel-wider' } container className={ classes.grid }>
        <Grid key={ 'tabPanel-button-group' } container className={ classes.navigationGrid }>
            <ButtonGroup className={ classes.buttonGroup }>
                <Tooltip style={{width:"10vw"}} title={ showImage ? "Hide Guide Image Column" : "Show Guide Image" +
                    " Column" }>
                    <Button
                        onClick={ () => setShowImage( !showImage ) }
                        className={ classes.button }>
                        <Typography className={ classes.buttonText }>
                            { showImage ? "Hide Images" : "Show Images" }
                        </Typography>
                    </Button>
                </Tooltip>
                <Tooltip  style={{width:"10vw"}} title={ showExplanation ? "Hide Description Column" : "Show Description Column" }>
                    <Button
                        onClick={ () => setShowExplanation( !showExplanation ) }
                        className={ classes.button }>
                        <Typography className={ classes.buttonText }>
                            { showExplanation ? "Hide Descriptions" : "Show Descriptions" }
                        </Typography>
                    </Button>
                </Tooltip>
                {
                    /*
                    <Button
                    disabled={!(userRole !== null && userRole.authAndroid) ||
                    !props.project.categories[props.categoryIndex].hasAndroidKey}
                    className={classes.button}>
                    <Typography className={classes.buttonText}>
                        Enter Android Keys
                    </Typography>
                </Button>
                     */
                }
                <Tooltip  style={{width:"10vw"}}
                    title={ "Click to Export Localization Texts for Android Devices." +
                    " It is highly recommended that before the export process, enter all the android keys." }>
                    <Button
                        onClick={ () => {
                            if ( calculateTranslatedNumber() === props.table.length &&
                                calculateEnteredAndroidNumber() === props.table.length ) {
                                setExportDialog( "android" )
                            } else if ( calculateTranslatedNumber() !== props.table.length &&
                                calculateEnteredAndroidNumber() !== props.table.length ) {
                                setExportWarning( {
                                    message: "There are missing translations and android keys",
                                    platform: 'android'
                                } )
                            } else if ( calculateTranslatedNumber() !== props.table.length ) {
                                setExportWarning( {
                                    message: "There are missing translations",
                                    platform: 'android'
                                } )
                            } else if ( calculateEnteredAndroidNumber() !== props.table.length ) {
                                setExportWarning( {
                                    message: "There are android keys",
                                    platform: 'android'
                                } )
                            }
                        } }
                        disabled={ !( props.project.categories[ props.categoryIndex ].hasAndroidKey &&
                            ( userRole !== null && userRole.authAndroid ) ) }
                        className={ classes.button }>
                        <Typography className={ classes.buttonText }>
                            Export To Android
                        </Typography>
                    </Button>
                </Tooltip>
                <Tooltip  style={{width:"10vw"}} title={ "Click to Export Localization Texts for IOS Devices." }>
                    <Button
                        onClick={ () => {
                            if ( calculateTranslatedNumber() === props.table.length ) {
                                setExportDialog( "ios" )
                            } else if ( calculateTranslatedNumber() !== props.table.length ) {
                                setExportWarning( {
                                    "message": "There are missing translations",
                                    "platform": 'ios'
                                } )
                            }
                        } }
                        disabled={ !( props.project.categories[ props.categoryIndex ].hasAndroidKey &&
                            ( userRole !== null && userRole.authAndroid ) ) }
                        className={ classes.button }>
                        <Typography className={ classes.buttonText }>
                            Export To Ios
                        </Typography>
                    </Button>
                </Tooltip>
                <Tooltip  style={{width:"10vw"}} title={ "Confirm that You are informed about all changes." }>
                    <Button
                        onClick={ () => {
                            props.confirmChanges()
                        }
                        }
                        disabled={ userRole === null || hasAllConfirmed } className={ classes.button }>
                        <Typography className={ classes.buttonText }>
                            Confirm Changes
                        </Typography>
                    </Button>
                </Tooltip>
                <Tooltip  style={{width:"10vw"}} title={ "Save Changes You Made" }>
                    <Button
                        onClick={ () => props.handleSaveChanges() }
                        disabled={ ( !( props.auth || permission || ( userRole !== null && userRole.authAndroid ) ) ) ||
                        ( props.textChanges.length === 0 && props.localizationChanges.length === 0 ) }
                        className={ classes.button }>
                        <Typography className={ classes.buttonText }>
                            Save Changes
                        </Typography>
                    </Button>
                </Tooltip>
            </ButtonGroup>
        </Grid>
        <Grid container spacing={ 2 } className={ classes.centerGridStats }>
            <Grid item>
                <Grid container>
                    <Grid item xs={ 12 }>
                        <Typography className={ classes.topText }>
                            Lines
                        </Typography>
                    </Grid>
                    <Grid item xs={ 12 }>
                        <Typography className={ classes.subText }>
                            { calculateTranslatedNumber() + "/" + props.table.length }
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {
                props.project.categories[ props.categoryIndex ].hasAndroidKey ?
                    <Grid item>
                        <Grid container>
                            <Grid item xs={ 12 }>
                                <Typography className={ classes.topText }>
                                    Android Keys
                                </Typography>
                            </Grid>
                            <Grid item xs={ 12 }>
                                <Typography className={ classes.subText }>
                                    { calculateEnteredAndroidNumber() + "/" + props.table.length }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    null
            }
        </Grid>
        <TableContainer style={ { maxHeight: '60vh', paddingLeft: 40, paddingRight: 40 } } size={ 'small' }>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        { showImage &&
                        <TableCell className={ classes.headCell }>
                            Guide Image
                        </TableCell> }
                        { showExplanation &&
                        <TableCell className={ classes.headCell }>
                            Description
                        </TableCell> }
                        <TableCell className={ classes.headCell }>
                            Localization Text
                        </TableCell>
                        { props.project.categories[ props.categoryIndex ].hasAndroidKey &&
                        <TableCell className={ classes.headCell }>
                            Android Key
                        </TableCell>
                        }
                        <TableCell className={ classes.headCell }>
                            { "Translation in " +
                            ( languages[ props.languageIndex ].language.language !== undefined ?
                                languages[ props.languageIndex ].language.language :
                                languages[ props.languageIndex ].language ) }
                        </TableCell>
                        <TableCell className={ classes.headCell }>
                            History
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { props.table.slice( page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage )
                           .map( element => {
                               return <TableRow>
                                   { showImage && <TableCell className={ classes.cell }>
                                       { element.image != null ?
                                           <Tooltip title={ "Click to Zoom or Change" }>
                                               <img
                                                   onClick={ () => {
                                                       setSelectedElement( element )
                                                       setImageSelection( element.image.id )
                                                   } }
                                                   src={ process.env.REACT_APP_IMAGE_URL + element.image.url }
                                                   alt="project's uploaded img"
                                                   height={ 50 }
                                                   className={ classes.img }
                                               />
                                           </Tooltip>
                                           :
                                           <Tooltip title={ "Click to Select" }>
                                               <Button
                                                   disabled={ true }
                                                   onClick={ () => {
                                                       setSelectedElement( element )
                                                       setImageSelection( 0 )
                                                   } }>
                                                   <Typography className={ classes.gridText }>

                                                   </Typography>
                                               </Button>
                                           </Tooltip>
                                       }
                                   </TableCell> }
                                   { showExplanation && <TableCell className={ classes.cell }>
                                       { element.explanation != null ?
                                           <Typography onDoubleClick={ () => {
                                               setSelectedElement( element )
                                               setExplanationSelection( element.explanation.id )
                                           } } className={ classes.gridText }>
                                               { element.explanation.content }
                                           </Typography> :
                                           <Tooltip title={ "Click to Select" }>
                                               <Button
                                                   disabled={ true }
                                                   onClick={
                                                       () => {
                                                           setSelectedElement( element )
                                                           setExplanationSelection( 0 )
                                                       }
                                                   }
                                               >
                                                   <Typography className={ classes.gridText }>

                                                   </Typography>
                                               </Button>
                                           </Tooltip>
                                       }
                                   </TableCell> }
                                   <TableCell className={ classes.cell }>
                                       <InputBase
                                           className={ classes.textField }
                                           fullWidth
                                           disabled={ !( props.auth ) }
                                           multiline
                                           onChange={ ( val ) => {
                                               //props.handleTextChange(element, val.target.value)
                                           } }
                                           value={ element.englishWord }
                                       />
                                   </TableCell>

                                   { props.project.categories[ props.categoryIndex ].hasAndroidKey &&
                                   <TableCell className={ classes.cell }>
                                       <InputBase
                                           className={ classes.textField }
                                           fullWidth
                                           disabled={ !( ( userRole !== null && userRole.authAndroid ) || props.auth ) }
                                           multiline
                                           onChange={ ( val ) => {
                                               if ( userRole !== null && userRole.authAndroid )
                                                   props.handleAndroidKeyChange( element, val.target.value )
                                           } }
                                           value={ element.androidKey != null ?
                                               element.androidKey : "" }
                                       />
                                   </TableCell>
                                   }
                                   <TableCell className={ classes.cell }>
                                       <InputBase
                                           className={ classes.textField }
                                           fullWidth
                                           inputProps={ { 'aria-label': 'naked' } }
                                           disabled={ !( permission || props.auth ) || props.languageIndex ===
                                           languages.length - 1 }
                                           multiline
                                           onChange={ ( val ) => {
                                               //props.handleLocalizationChange(element.localization, val.target.value)
                                           } }
                                           value={ element.localization.text != null ?
                                               element.localization.text : "" }
                                       />
                                   </TableCell>
                                   <TableCell className={ classes.cell }>
                                       <Tooltip title={ "Click to See History/Log" }>
                                           <Button
                                               className={ classes.historyButton }
                                               onClick={ () => {
                                                   setHistory( element.localization )
                                               } }>
                                               <Grid container className={ classes.centerGrid }>
                                                   <Grid item xs>
                                                       <Typography>
                                                           { element.localization.histories.length }
                                                       </Typography>
                                                   </Grid>
                                                   <Grid item xs>
                                                       <History className={ classes.historyIcon }/>
                                                   </Grid>
                                               </Grid>
                                           </Button>
                                       </Tooltip>
                                   </TableCell>
                               </TableRow>
                           } ) }
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination rowsPerPageOptions={ [ 5, 10, 20, 50, 100 ,150 ] }
                         component="div"
                         count={ props.table.length }
                         rowsPerPage={ rowsPerPage }
                         page={ page }
                         onChangeRowsPerPage={ ( event ) => handleChangeRowsPerPage(
                             event ) }
                         onChangePage={ ( event, newPage ) => handleChangePage( event,
                             newPage ) }/>
        { history !== null &&
        <HistoryDialog history={ history } languages={ languages } languageIndex={ props.languageIndex }
                       isOpen={ true } classes={ classes } setOpen={ ( val ) => setHistory( val ) }/> }
        { exportWarning !== null ?
            <Dialog
                fullWidth
                maxWidth={ "md" }
                open={ true }
                onClose={ () => setExportWarning( null ) }>
                <Grid container className={ classes.warningGrid }>
                    <Grid item xs={ 12 } className={ classes.warningTopText }>
                        { "Warning!" }
                    </Grid>
                    <Grid item xs={ 12 } className={ classes.warningSubText }>
                        { exportWarning.message }
                    </Grid>
                    <Grid container xs={ 12 } className={ classes.warningGrid }>
                        <ButtonGroup className={ classes.warningButtonGroup }>
                            <Tooltip title={ "Process to export" }>
                                <Button
                                    onClick={ () => {
                                        setExportDialog( exportWarning.platform )
                                        setExportWarning( null )
                                    } }
                                    className={ classes.warningButtonProcess }>
                                    <Typography className={ classes.warningButtonText }>
                                        { "Continue" }
                                    </Typography>
                                </Button>
                            </Tooltip>
                            <Tooltip title={ "Cancel" }>
                                <Button
                                    onClick={ () => {
                                        setExportWarning( null )
                                    } }
                                    className={ classes.warningButtonCancel }>
                                    <Typography className={ classes.warningButtonText }>
                                        { "Cancel" }
                                    </Typography>
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Dialog>
            :
            null
        }
        <Dialog fullWidth={ true } maxWidth={ "lg" } open={ imageSelection !== -1 }
                onClose={ () => setImageSelection( -1 ) }>
            <Grid container className={ classes.seeAllGrid }>
                { props.project.images.map( image => {
                    return <img
                        key={ "tabpanel-image-" + image.id }
                        onClick={
                            () => {
                                if ( props.auth ) {
                                    props.handleImageChange( selectedElement, image )
                                    setImageSelection( -1 )
                                }
                            }
                        }
                        src={ process.env.REACT_APP_IMAGE_URL + image.url }
                        alt="project's uploaded img"
                        className={ image.id === imageSelection ? classes.seeAllImgActive : classes.seeAllImg }/>
                } ) }
            </Grid>
        </Dialog>
        <Dialog fullWidth={ true } maxWidth={ "lg" } open={ explanationSelection !== -1 }
                onClose={ () => setExplanationSelection( -1 ) }>
            <Grid container className={ classes.seeAllGrid }>
                { props.project.explanations.map( explanation => {
                    return <Card
                        onClick={ () => {
                            if ( props.auth ) {
                                props.handleExplanationChange( selectedElement, explanation )
                                setExplanationSelection( -1 )
                            }
                        } }

                        className={ explanation.id === explanationSelection ? classes.seeAllExpActive :
                            classes.seeAllExp }>
                        { explanation.content }
                    </Card>
                } ) }
            </Grid>
        </Dialog>
        { exportDialog === null ? null : exportPage === 'initial'
            ?
            <Dialog
                className={ classes.exportDialog }
                fullWidth
                maxWidth={ "xl" }
                onClose={ () => {
                    setExportDialog( null )
                    props.setSelectedLocalizationsEmpty()
                } }
                open={ true }>
                <Grid item xs className={ classes.seeAllGrid }>
                    <Typography className={ classes.dialogTitle }>
                        Export Selection
                    </Typography>
                </Grid>
                <DialogContent>
                    <Grid container>
                        <Grid container className={ classes.dialogTop }>
                            <ButtonGroup className={ classes.buttonGroup }>
                                <Button
                                    className={ classes.button }
                                    onClick={ () => props.selectAll( true, exportDialog === "android" ) }>
                                    <Typography
                                        className={ classes.buttonText }>
                                        Select All
                                    </Typography>
                                </Button>
                                <Button
                                    className={ classes.button }
                                    onClick={ () => props.selectAll( false, exportDialog === "android" ) }>
                                    <Typography
                                        className={ classes.buttonText }>
                                        Clear All Selection
                                    </Typography>
                                </Button>
                                <Button
                                    disabled={
                                        checkAllIsConfirmed()
                                    }
                                    className={ classes.button }
                                    onClick={ () => props.selectUnconfirmed( exportDialog === "android" ) }>
                                    <Typography
                                        className={ classes.buttonText }>
                                        Select Unconfirmed Texts
                                    </Typography>
                                </Button>
                                <Button
                                    disabled={ props.selectedLocalizations.length === 0 }
                                    onClick={ () => {
                                        setExportPage( 'single' )
                                    }
                                    }
                                    className={ classes.button }>
                                    <Typography
                                        className={ classes.buttonText }>
                                        Export
                                    </Typography>
                                </Button>
                                <Button
                                    disabled={ true || props.selectedLocalizations.length === 0 }
                                    onClick={ () => {
                                        setExportPage( 'multiple' )
                                    }
                                    }
                                    className={ classes.button }>
                                    <Typography
                                        className={ classes.buttonText }>
                                        Export For All Languages
                                    </Typography>
                                </Button>
                                <Button
                                    disabled={ props.selectedLocalizations.length === 0 }
                                    className={ classes.button }
                                    onClick={ () => {
                                        setExportPage( 'single' )
                                        props.confirmChangesWithIdList()
                                    }
                                    }>
                                    <Typography
                                        className={ classes.buttonText }>
                                        Export And Confirm
                                    </Typography>
                                </Button>
                                {
                                    /*
                                     <Button
                                    onClick={() => {
                                        setExportPage('multiple')
                                    }
                                    }
                                    className={classes.button}
                                >
                                    <Typography
                                        className={classes.buttonText}
                                    >
                                        Export And Confirm For All Languages
                                    </Typography>
                                </Button>
                                     */
                                }
                                <Button
                                    className={ classes.button }
                                    onClick={ () => {
                                        setExportDialog( null )
                                        props.setSelectedLocalizationsEmpty()

                                    } }
                                >
                                    <Typography
                                        className={ classes.buttonText }
                                    >
                                        Close
                                    </Typography>
                                </Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid container className={ classes.exportGridBody }>
                            <Grid container className={ classes.gridTableHead }>
                                <Grid item xs>
                                </Grid>
                                <Grid item xs>
                                    Base Text
                                </Grid>
                                <Grid item xs>
                                    Equivalent Text { " in " +
                                languages[ props.languageIndex ].language.language }
                                </Grid>
                                {
                                    exportDialog === "android"
                                        ? <Grid item xs>
                                            Android Key
                                        </Grid>
                                        : null
                                }
                            </Grid>
                            { props.table.map( ( row ) => {
                                return <Grid container className={ classes.gridTableBody }>
                                    <Grid item xs>
                                        <Tooltip
                                            title={ props.checkSelected( row.localization.id ) ? "Remove from export" :
                                                "Add to export" }>
                                            <Radio
                                                disabled={
                                                    row.localization.text === null
                                                    ||
                                                    row.localization.text.length === 0
                                                    ||
                                                    ( exportDialog === "android" && ( row.androidKey === null ||
                                                        row.androidKey.length === 0 ) )
                                                }
                                                checked={ props.checkSelected( row.localization.id ) }
                                                onClick={ () => {
                                                    props.handleCheck( row.localization.id )
                                                } }/>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs>
                                        { row.englishWord }
                                    </Grid>
                                    <Grid item xs>
                                        { row.localization.text !== null ? row.localization.text : "" }
                                    </Grid>
                                    {
                                        exportDialog === "android"
                                            ? <Grid item xs>
                                                { row.androidKey !== null ? row.androidKey : "" }
                                            </Grid>
                                            : null
                                    }
                                </Grid>
                            } ) }
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            : exportPage === 'single'
                ? <Dialog className={ classes.exportDialog }
                          fullWidth
                          maxWidth={ "xl" }
                          onClose={ () => {
                              setExportPage( 'initial' )
                              setExportDialog( null )
                              props.setSelectedLocalizationsEmpty()
                          } }
                          open={ true }>
                    <Grid item xs className={ classes.seeAllGrid }>
                        <Typography className={ classes.dialogTitle }>
                            Single Export
                        </Typography>
                    </Grid>
                    <DialogContent>
                        <Grid container className={ classes.gridHeadCell }>
                            <Grid container className={ classes.gridHeadCell }>
                                <textarea
                                    rows="35"
                                    cols="120"
                                    contentEditable={ false }
                                    onClick={ () => {
                                        textArea.select()
                                        document.execCommand( "copy" )

                                    } }
                                    ref={ ( textarea ) => textArea = textarea }
                                    className={ classes.exportPanel }
                                    value={ generateExportedText(
                                        languages[ props.languageIndex ].language )
                                        .trim() }
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                : <Dialog className={ classes.exportDialog }
                          fullWidth
                          maxWidth={ "xl" }
                          onClose={ () => {
                              setExportPage( 'initial' )
                              setExportDialog( null )
                              props.setSelectedLocalizationsEmpty()
                          } }
                          open={ true }>
                    <Grid item xs className={ classes.seeAllGrid }>
                        <Typography className={ classes.dialogTitle }>
                            Multiple Export
                        </Typography>
                    </Grid>
                    <DialogContent className={ classes.gridHeadCell }>
                        <Grid container className={ classes.gridHeadCell }>
                            <Grid container className={ classes.gridHeadCell }>
                                <Tabs
                                    id={ 'export' + multipleExportTab }
                                    key={ 'export' + multipleExportTab }
                                    classes={ {
                                        indicator: classes.indicator
                                    } }
                                    className={ classes.innerTab }
                                    value={ multipleExportTab }
                                    onChange={ ( event, index ) => setMultipleExportTab( index ) }
                                    indicatorColor="secondary"
                                    variant={ "scrollable" }
                                    scrollButtons={ "auto" }
                                    aria-label={ "scrollable auto outer tabs" }
                                >
                                    { languages.map( ( language, index ) => {
                                        return <Tab
                                            key={ "export-tab-" + index }
                                            className={ classes.tab }
                                            label={ language.language }
                                            aria-controls={ `scrollable-auto-tabpanel-${ index }` }
                                            id={ `scrollable-auto-tab-${ index }` }/>
                                    } ) }
                                </Tabs>
                            </Grid>
                            <Grid container className={ classes.gridHeadCell }>
                                <textarea
                                    rows="40"
                                    cols="50"
                                    contentEditable={ false }
                                    onClick={ () => {
                                        textArea.select()
                                        document.execCommand( "copy" )

                                    } }
                                    ref={ ( textarea ) => textArea = textarea }
                                    className={ classes.exportPanel }
                                    value={ generateExportedText(
                                        languages[ multipleExportTab ].language )
                                        .trim() }
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
        }
    </Grid>
}