import React from "react";
import {
    Button,
    ButtonGroup, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid, Input, MenuItem,
    Paper, Select, Snackbar, TextField,
    Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../../../constants/Colors";
import Env from "../../../../constants/Env";
import { Alert } from "@material-ui/lab";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null,
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useStyles = makeStyles( () => ( {
    contentCenter: {
        display: "flex",
        justifyContent: "center",
        paddingBottom: 80,
    },
    titleText: {
        paddingLeft: 10,
        paddingTop: 20,
        justifyContent: 'start',
        color: Colors.PRIMARY_BLUE,
        display: 'flex',
        fontSize: 32,
        fontFamily: Colors.fontOfProgram,
        fontWeight: "bold"
    },
    contentBody: {
        padding: 40,

    },
    buttonDialog: {
        width: 200,
        padding: 10,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_BLUE,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_BLUE,
            color: Colors.PRIMARY_WHITE,
        }
    },
    buttonText: {
        fontWeight: "bold",
    },
    buttonGrid: {
        display: "flex",
        justifyContent: "center",
        borderRadius: 10,
        paddingTop: 1,
        margin: 0,
    },
    deleteButton: {
        width: 200,
        padding: 10,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: 'darkred',
        "&:hover": {
            backgroundColor: 'darkred',
            color: Colors.PRIMARY_WHITE,
        }
    },
    buttonLabel: {
        backgroundColor: Colors.PRIMARY_WHITE,
        borderRadius: 10,
        padding: 4,
    },
    grid: {
        display: "flex",
        justifyContent: "space-between",
    },
    topText: {
        color: Colors.PRIMARY_PINK_ALT,
        fontWeight: "bold",
    }
} ) );

export default function PermissionAdditionDialog( props ) {
    const classes = useStyles();
    const [ person, setPerson ] = React.useState( '' )
    const [ selectedNewLanguages, setSelectedNewLanguage ] = React.useState( [] )
    const [ date, setDate ] = React.useState( '' )
    const submitted = false
    const [ successOpen, setSuccessOpen ] = React.useState( false )
    const [ errorOpen, setErrorOpen ] = React.useState( false )
    let users = []

    function checkUserRoleAuth() {
        let check = false
        props.user.roles.forEach( roleDesp => {
            if ( props.project.id === roleDesp.projectRole.project.id ) {
                roleDesp.projectRole.project.roles.forEach( role => {
                    if ( role.admin ) {
                        role.roleList.forEach( p => {
                            if ( p.user.userName === props.user.userName )
                                check = true
                        } )
                    }
                } )
            }

        } )

        return check
    }

    function checkUserAlreadyInList( userId, userName ) {
        let check = false;
        users.forEach( user => {
            if ( user.id === userId )
                check = true
        } )

        props.user.permissions.forEach( permission => {
            if ( permission.project.company.user.userName === userName )
                check = true
        } )

        props.user.roles.forEach( role => {
            if ( role.projectRole.project.company.user.userName === userName )
                check = true
        } )

        if ( userName === props.user.userName && checkUserRoleAuth() )
            check = true

        return check
    }


    props.project.roles.forEach( role => {
        if ( !role.admin ) {
            role.roleList.forEach( rolD => {
                if ( !checkUserAlreadyInList( rolD.user.id, rolD.user.userName ) )
                    users.push( rolD.user )
            } )
        }
    } )
    return <Dialog
        onClose={ () => props.handleClose() }
        fullWidth
        maxWidth={ "lg" }
        open={ true }>
        <DialogTitle>
            <Typography className={ classes.titleText }>
                Give Permission
            </Typography>
        </DialogTitle>
        <DialogContent className={ classes.contentBody }>
            <Grid container className={ classes.grid }>
                <Grid item xs={ 3 }>
                    <Grid item>
                        <Typography className={ classes.topText }>
                            User
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Select
                            fullWidth
                            labelId="simple-select-label"
                            id="simple-select"
                            key={ "simple-select" }
                            value={ person }
                            onChange={ ( val ) => setPerson( val.target.value ) }
                        >
                            {
                                users.map( user => {
                                    return <MenuItem
                                        key={ "productDetail-dialog-user-" + user.id + user.name }


                                        value={ user } id={ user.id + user.name }>{ user.name + "   (" +
                                    user.userName + ")" }</MenuItem>
                                } )
                            }
                        </Select>
                    </Grid>
                </Grid>
                <Grid item xs={ 3 }>
                    <Grid item>
                        <Typography className={ classes.topText }>
                            Languages
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Select
                            fullWidth
                            disabled={ submitted }
                            labelId="language-chip-label"
                            id="language-chip"
                            multiple
                            value={ selectedNewLanguages }
                            onChange={ ( val ) => setSelectedNewLanguage( val.target.value ) }
                            input={ <Input id="select-multiple-chip"/> }
                            renderValue={ ( selected ) => {
                                return (
                                    <div className={ classes.chips }>
                                        { selected.map( ( value ) => (
                                            <Chip
                                                key={ "productDetail-dialog-user-select-multiple" + value }
                                                disabled={ submitted }
                                                label={ value }
                                            />
                                        ) ) }
                                    </div>
                                )
                            } }
                            MenuProps={ MenuProps }
                        >
                            { props.project.projectLanguages.map( ( lan ) => {

                                    return <MenuItem
                                        key={ "productDetail-dialog-user-language-" + lan.language.language }
                                        value={ lan.language.language }>
                                        { lan.language.language }
                                    </MenuItem>
                                }
                            ) }
                        </Select>
                    </Grid>
                </Grid>
                <Grid item xs={ 3 }>
                    <Grid item>
                        <Typography className={ classes.topText }>
                            Deadline
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            fullWidth={ true }
                            id="datetime-local2"
                            type="datetime-local"
                            value={ date }
                            InputLabelProps={ {
                                shrink: true,
                            } }
                            onChange={ ( value ) => {
                                setDate( value.target.value.toString() )
                            } }
                        />
                    </Grid>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions className={ classes.contentCenter }>
            <Paper elevation={ 10 } className={ classes.buttonGrid }>
                <ButtonGroup>
                    <label className={ classes.buttonLabel }>
                        <Button
                            disabled={ !props.auth }
                            onClick={
                                () => {
                                    if ( date !== '' && person !== "" && selectedNewLanguages.length > 0 ) {
                                        let returnLanguages = []
                                        selectedNewLanguages.forEach( lang => {
                                            props.project.projectLanguages.forEach( lan => {
                                                if ( lang === lan.language.language ) {
                                                    returnLanguages.push( lan.id )
                                                }                                            } )
                                        } )



                                        const returnObject = {
                                            "projectId": props.project.id,
                                            "userId": person.id,
                                            "categoryId": props.category.id,
                                            "languageList": returnLanguages,
                                            "expiration": date.toString() + ":00.000+00:00"
                                        }

                                        const headers = {
                                            "Content-Type": "application/json",
                                            'Authorization': 'Bearer ' + Env.TOKEN
                                        }

                                        fetch( process.env.REACT_APP_URL + 'projects/permissions', {
                                            method: 'POST', headers: headers, body: JSON.stringify( returnObject )
                                        }, )
                                            .then( response => {

                                                if ( response.ok ) return response.json()
                                                else throw new Error()
                                            } )
                                            .then( data => {
                                                console.log(data)
                                                return data
                                            } )
                                            .then( () => props.functionList.getProgram() )
                                            .then( () => setSuccessOpen( true ) )
                                            .then( () => {
                                                setDate( "" )
                                                setPerson( "" )
                                                setSelectedNewLanguage( [] )
                                                props.handleClose()
                                            } )
                                            .catch( () => {
                                                setErrorOpen( true )
                                            } )
                                    }
                                }
                            }
                            className={ classes.buttonDialog }>
                            <Typography className={ classes.buttonText }>
                                Give Permission
                            </Typography>
                        </Button>
                    </label>
                    <label className={ classes.buttonLabel }>
                        <Button onClick={ () => props.handleClose() }
                                className={ classes.buttonDialog }>
                            <Typography className={ classes.buttonText }>
                                Back
                            </Typography>
                        </Button>
                    </label>
                </ButtonGroup>
            </Paper>
        </DialogActions>
        <Snackbar open={ errorOpen }
                  autoHideDuration={ 6000 }
                  onClose={ () => setErrorOpen( false ) }
        >
            <Alert onClose={ () => setErrorOpen( false ) }
                   severity={ "warning" }
            >
                Something went wrong, try it later.
            </Alert>
        </Snackbar>
        <Snackbar open={ successOpen }
                  autoHideDuration={ 1000 }
                  onClose={ () => setSuccessOpen( false ) }
        >
            <Alert onClose={ () => setSuccessOpen( false ) }
                   severity={ "success" }
            >
                Operations are completed successfully.
            </Alert>
        </Snackbar>
    </Dialog>
}