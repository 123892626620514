import React from "react";
import {Button, ButtonGroup, Checkbox, Grid, makeStyles, Tooltip} from "@material-ui/core";
import Colors from "../../../../../../constants/Colors";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
    button: {
        height: 35,
        width: 400,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        '&:hover': {
            backgroundColor: Colors.PRIMARY_PINK_UP,
        },

    },
    grid: {
        borderRadius: 10,
        margin: 10,
        padding: 20,
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        color: Colors.PRIMARY_WHITE,
        background: Colors.gradient,
        cursor: "pointer",
    },
    gridAlternative: {
        paddingTop: 20,
        paddingBottom: 20,
        marginLeft: 20,
        marginRight: 20,
        marginTop: 20,
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20,

        paddingLeft: 20,
        justifyContent: "flex-start",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        color: Colors.PRIMARY_WHITE,
        background: Colors.PRIMARY_BLUE,
    },
    gridUncolored: {
        borderRadius: 10,
        margin: 10,
        padding: 20,
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
    },
    icon: {
        fontSize: 30,
        color: Colors.PRIMARY_WHITE,
        fontFamily: Colors.fontOfProgram
    },
    iconButton: {
        height: 35,
        width: 50,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        '&:hover': {
            backgroundColor: Colors.PRIMARY_PINK_UP,

        }
    },
    buttonText: {
        fontWeight: "bold",
        fontSize: 16,
        fontFamily: Colors.fontOfProgram,
        color: Colors.PRIMARY_WHITE,
    },
    buttonGroup: {
        padding: 10,
        display: "flex",
        justifyContent: "space-evenly",
    },
    subGrid: {
        borderColor: Colors.PRIMARY_PINK_ALT,
        borderWidth: 1,
        borderTopWidth: 0,
        border: "solid",
        padding: 20,
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
    },
    title: {
        color: Colors.PRIMARY_PINK_UP,
        fontWeight: "bold",
        fontSize: 24,
        fontFamily: Colors.fontOfProgram,
        paddingBottom: 10,
        paddingTop: 10,

    },
    subTitleChild: {
        color: Colors.PRIMARY_WHITE,
        fontWeight: "bold",
        fontSize: 16,
        fontFamily: Colors.fontOfProgram,
    },
    paper: {
        background: Colors.gradient,
        justifyContent: "space-between",
        alignItems: "center",
        justifyItems: "center",
        padding: 20,
    },
    scrollableDiv: {
        maxHeight: 800,
        overflowY: "scroll",
    },
    text: {
        color: Colors.PRIMARY_WHITE,
        fontSize: 20,
        fontFamily: Colors.fontOfProgram,
        fontWeight: "bold",
    },
    divSelected: {
        justifyContent: "center", display: "flex", alignItems: "center", alignContent: "center",
        backgroundColor: Colors.PRIMARY_PINK_UP,
        fontWeight: "bold",
        borderRadius: 10,
        padding: 5,
        cursor: "pointer",
        color: Colors.PRIMARY_WHITE,
        maxHeight: 500,
        overflowY: "scroll",
        maxWidth: 400,
    },
    divNotSelected: {
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        borderRadius: 10,
        alignContent: "center",
        padding: 5,
        cursor: "pointer",
        fontWeight: "bold",
        color: Colors.PRIMARY_PINK_ALT,
        maxHeight: 500,
        overflowY: "scroll",
        maxWidth: 400,
    },
    subButtonText: {
        color: Colors.PRIMARY_WHITE,
        fontWeight: "bold",
        fontSize: 12,
        fontFamily: Colors.fontOfProgram,
    },
    explanationGrid: {
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
    },
    bodyGrid: {
        paddingBottom: 20,
    },
    dialog: {
        paddingTop: 20,
        paddingBottom: 20,
    },
    line: {
        border: "solid",
        borderColor: Colors.PRIMARY_BLUE,
        borderTopWidth: 0,
        marginLeft: 20,
        marginRight: 20,
        paddingLeft: 40,
        justifyContent: "flex-start",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
    },
    groupButtonLabel: {
        backgroundColor: Colors.PRIMARY_WHITE,
        borderRadius: 10,
        padding: 2,
    },
    groupButtonText: {
        fontWeight: "bold",
        fontSize: 12,
        fontFamily: Colors.fontOfProgram,
    },
    groupButton: {
        width: 320,
        height: 28,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_PINK_ALT,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_PINK_ALT,
            color: Colors.PRIMARY_WHITE,
        }
    },
    buttonGroupButtons: {
        borderRadius: 10,
        background: Colors.PRIMARY_PINK_ALT,
        padding: 2,
    },
    center: {
        display: "flex",
        justifyContent: "center",
    }
}))

export default function TranslationOrderCategoryCard(props) {

    const classes = useStyles()

    function checkLocalizationChecked(id) {
        let check = false

        props.selectedLocalizations.forEach(loc => {
            if (loc.id === id)
                check = true
        })

        return check;
    }

    function localizationFinder(text) {
        let localizationTemp = null;
        text.localizations.forEach(localization => {
            if ((localization.projectLanguage !== null && localization.projectLanguage.language.language
                ===
                props.language) || (localization.base && props.project.base.language === props.language))
                localizationTemp = localization
        })
        return localizationTemp
    }

    function checkAllSelected() {
        let check = true;

        props.category.texts.forEach(text => {
            text.localizations.forEach(loc => {
                if (!checkLocalizationChecked(loc.id))
                    check = false
            })
        })
        return check
    }

    function calculateTextCount() {
        let count = 0
        const regexpEmojiPresentation = /\p{Emoji_Presentation}/gu;

        props.category.texts.forEach(textEl => {
            if (checkLocalizationChecked(localizationFinder(textEl).id)) {
                textEl.englishWord.split(" ").forEach(el => {

                    if (el.match(regexpEmojiPresentation) === null && el.length > 1) {
                        if (!checkTextInBlackList(el)) {
                            count++
                        }
                    } else {
                        el.split(regexpEmojiPresentation).forEach(elChild => {
                            if (elChild.length > 1 && !checkTextInBlackList(elChild)) {
                                count++
                            }
                        })
                    }
                })
            }
        })
        return count
    }

    function checkTextInBlackList(text) {
        let check = false
        props.project.blackListTexts.forEach(blackListText => {
            if (blackListText.text === text)
                check = true
        })
        return check
    }


    return <Grid container className={classes.center}>
        <Grid container className={classes.buttonGroup}>
            <Grid item className={classes.buttonGroupButtons}>
                <ButtonGroup>
                    <label className={classes.groupButtonLabel}>
                        <Tooltip
                            title={"Select Untranslated Lines From " + props.category.categoryName + " ."}>
                            <Button onClick={() => props.handleEmptiesAddition(props.category.texts)}
                                    className={classes.groupButton}>
                                <Typography className={classes.groupButtonText}>
                                    {"Select Untranslated From \"" + props.category.categoryName + "\""}
                                </Typography>
                            </Button>
                        </Tooltip>
                    </label>
                    <label className={classes.groupButtonLabel}>
                        <Tooltip
                            title={checkAllSelected() ?
                                "Unselect All From " + props.category.categoryName + " ." :
                                "Select All From " + props.category.categoryName + " ."}>
                            <Button
                                onClick={() => {
                                    if (checkAllSelected()) {
                                        props.handleDeleteAll(props.category.texts)
                                    } else {
                                        props.handleAllAddition(props.category.texts)
                                    }
                                }}
                                className={classes.groupButton}>
                                <Typography className={classes.groupButtonText}>
                                    {checkAllSelected() ?
                                        "Unselect All From \"" + props.category.categoryName + "\"" :
                                        "Select All From \"" + props.category.categoryName + "\""}
                                </Typography>
                            </Button>
                        </Tooltip>
                    </label>
                </ButtonGroup>
            </Grid>
            <Grid container style={{
                display: "flex",
                paddingTop: 8,
                justifyContent: "center",
            }}>
                <Grid item xs={1}>
                    <Grid container style={{
                        alignItems: "center",
                        alignContent: "center",
                        display: "flex",
                        justifyContent: "center",
                        fontSize: 20
                    }}>
                        Text Count
                    </Grid>
                    <Grid container style={{
                        alignItems: "center",
                        alignContent: "center",
                        display: "flex",
                        justifyContent: "center",
                        fontSize: 16
                    }}>
                        {calculateTextCount()}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.gridAlternative}>
                <Grid item xs={1}>
                    <Typography className={classes.buttonText}>

                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography className={classes.buttonText}>
                        Source Text
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography className={classes.buttonText}>
                        Currently In App
                    </Typography>
                </Grid>
            </Grid>
            {props.category.texts.map(text => {
                return <Grid
                    key={"translation-order-text-addition" + text.id}
                    container
                    className={classes.line}>
                    <Grid item xs={1}>
                        {localizationFinder(text) !== null ?
                            <Tooltip
                                title={checkLocalizationChecked(localizationFinder(text).id) ? "Click to Unselect" :
                                    "Click to Select"}>
                                <Checkbox
                                    checked={checkLocalizationChecked(localizationFinder(text).id)}
                                    onClick={() => {
                                        if (checkLocalizationChecked(localizationFinder(text).id)) {
                                            props.handleDeletion(localizationFinder(text).id)
                                        } else {
                                            props.handleAddition(localizationFinder(text))
                                        }
                                    }
                                    }
                                >
                                </Checkbox>
                            </Tooltip>
                            :
                            null}
                    </Grid>
                    <Grid item xs={4}>
                        {text.englishWord}
                    </Grid>
                    <Grid item xs={4}>
                        {localizationFinder(text) === null || localizationFinder(text).text === null ? "" :
                            localizationFinder(text).text}
                    </Grid>
                </Grid>
            })}
        </Grid>
    </Grid>
}