import React, { useRef } from "react";
import {
    Button,
    ButtonGroup, Checkbox, CircularProgress, Container,
    Dialog,
    Grid, LinearProgress, MenuItem, Paper, Select, Snackbar, Table, TableBody,
    TableCell, TableContainer, TableHead, TablePagination,
    TableRow, TextField,
    Tooltip,
    Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../../../constants/Colors";
import {
    ArrowBackIosTwoTone,
    Backspace,
    Backup, CancelOutlined, CancelPresentationOutlined,
    CloudDone,
    Error, FileCopy,
    FlipToBack, FormatColorReset,
    Refresh,
    RefreshOutlined
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import Env from "../../../../constants/Env";
import AllLanguagesPanelRow from "./AllLanguagesPanelRow";
import NewTextTextField from "./NewTextTextField";
import AutoSaveComponent from "./AutoSaveComponent";
import OrderCreationDialog from "./orderCreationScreen/OrderCreationDialog";
import Program from "../../../../constants/Program";
import AllLanguagePanelReplaceWidget from "./AllLanguagePanelReplaceWidget";
import ImageUploadDialog from "./ImageUploadDialog";
import FormattedExportDialog from "./textScreenDialogs/FormattedExportDialog";

const useStyles = makeStyles( {
    grid: {
        maxHeight: 800,
        display: "flex",
        justifyContent: "center",
    },
    buttonGrid: {
        display: "flex",
        justifyContent: "center",
        borderRadius: 10,
        paddingTop: 0,
        margin: 0,
    },
    buttonGridTop: {
        display: "flex",
        justifyContent: "center",
        borderRadius: 10,
        padding: 16,
        margin: 0,
    },
    generalGrid: {
        padding: 32,
        display: "flex",
        justifyContent: "start",
    },
    tableCellHead: {
        width: 200,
        minWidth: 200,
        maxWidth: 200,
    },
    tableCellHeadAlternative: {
        width: 354,
        minWidth: 354,
        maxWidth: 354,
    },
    progressBar: {
        background: Colors.gradient,
        borderRadius: 10,
        height: 5,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
    },
    input: {
        display: "none",
    },
    tableCellDescription: {
        width: 200,
        minWidth: 200,
        maxWidth: 200,
    },
    tableCellImages: {
        width: 100,
        minWidth: 100,
        maxWidth: 100,
    },
    tableCellHeadOpen: {
        width: 200,
        minWidth: 200,
        maxWidth: 200,
    },
    tableCellHeadOpenAlternative: {
        width: 300,
        minWidth: 300,
        maxWidth: 300,
    },
    tableCellDescriptionOpen: {
        width: 236,
        minWidth: 236,
        maxWidth: 236,
    },
    tableCellImagesOpen: {
        width: 118,
        minWidth: 118,
        maxWidth: 118,
    },
    seeAllGrid: {
        padding: 20,
        display: "flex",
        justifyContent: "center",
    },
    addButtonText: {
        minWidth: 160,
        padding: 16,
        marginLeft: 8,
        border: "groove",
        borderColor: Colors.PRIMARY_GREEN_SECONDARY,
        color: Colors.PRIMARY_WHITE,
        backgroundColor: Colors.PRIMARY_GREEN_LIGHT,
        borderRadius: 10,
        "&:hover": {
            color: Colors.PRIMARY_GREEN_LIGHT,
            borderColor: "transparent",
            backgroundColor: Colors.PRIMARY_WHITE,
        }
    },
    addButton: {
        width: 160,
        padding: 16,
        border: "groove",
        borderColor: Colors.PRIMARY_GREEN_SECONDARY,
        color: Colors.PRIMARY_WHITE,
        backgroundColor: Colors.PRIMARY_GREEN_LIGHT,
        borderRadius: 10,
        "&:hover": {
            color: Colors.PRIMARY_GREEN_LIGHT,
            borderColor: "transparent",
            backgroundColor: Colors.PRIMARY_WHITE,
        }
    },
    buttonLabel: {
        backgroundColor: Colors.PRIMARY_WHITE,
        borderRadius: 10,
    },
    bodyGrid: {
        alignContent: "center",
        alignItems: "center",
        paddingBottom: 20,
    },
    topButton: {
        borderRadius: 10,
        margin: 4,
        border: "solid",
        borderColor: "transparent",
        width: 160,
        backgroundColor: Colors.PRIMARY_BLUE,
        color: Colors.PRIMARY_WHITE,
        '&:hover': {
            border: "solid",
            borderColor: Colors.PRIMARY_BLUE,
            backgroundColor: Colors.PRIMARY_WHITE,
            color: Colors.PRIMARY_BLUE,
        }
    },
    topButtonAlt: {
        borderRadius: 10,
        margin: 4,
        border: "solid",
        borderColor: "transparent",
        width: 120,
        backgroundColor: Colors.PRIMARY_BLUE,
        color: Colors.PRIMARY_WHITE,
        '&:hover': {
            border: "solid",
            borderColor: Colors.PRIMARY_BLUE,
            backgroundColor: Colors.PRIMARY_WHITE,
            color: Colors.PRIMARY_BLUE,
        }
    },
    topButtonCancel: {
        borderRadius: 10,
        margin: 4,
        border: "solid",
        borderColor: "transparent",
        width: 160,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        color: Colors.PRIMARY_WHITE,
        '&:hover': {
            border: "solid",
            borderColor: Colors.PRIMARY_PINK_ALT,
            backgroundColor: Colors.PRIMARY_WHITE,
            color: Colors.PRIMARY_PINK_ALT,
        }
    },
    topButtonAlternative: {
        borderRadius: 10,
        margin: 4,
        border: "solid",
        display: "flex",
        cursor: "pointer",
        padding: 0,
        justifyContent: "center",
        borderColor: "transparent",
        width: 68,
        backgroundColor: Colors.PRIMARY_BLUE,
        color: Colors.PRIMARY_WHITE,
        '&:hover': {
            border: "solid",
            borderColor: Colors.PRIMARY_BLUE,
            backgroundColor: Colors.PRIMARY_WHITE,
            color: Colors.PRIMARY_BLUE,
        }
    },
    seeAllImg: {
        padding: 4,
        width: 200,
        cursor: "pointer",
        display: "inline",
    },
    seeAllImgActive: {
        border: "solid",
        borderColor: Colors.PRIMARY_BLUE,
        padding: 4,
        width: 200,
        cursor: "pointer",
        display: "inline",
    },
    seeAllExp: {
        margin: 4,
        padding: 8,
        width: 300,
        cursor: "pointer",
        display: "inline",
    },
    seeAllExpActive: {
        border: "solid",
        borderColor: Colors.PRIMARY_BLUE,
        margin: 4,
        padding: 8,
        width: 300,
        cursor: "pointer",
        display: "inline",
    },
    topButtonText: {
        fontSize: 12,
        fontWeight: "bold",
    },
    gridText: {
        fontWeight: "bold",
        fontSize: 12,
        cursor: "pointer",
    },
    buttonGroup: {
        marginTop: 8,
        borderRadius: 10,
        display: "flex",
        justifyContent: "center",
    },
    img: {
        cursor: "pointer",
    },
    innerButtonGroup: {
        padding: 4,
        backgroundColor: Colors.PRIMARY_WHITE,
        borderColor: Colors.PRIMARY_BLUE,
        borderRadius: 10,
    },
    gridBody: {
        padding: 10,
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
    },
    gridAlternative: {
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        color: Colors.PRIMARY_WHITE,
        background: Colors.PRIMARY_WHITE,
    },
    divSelected: {
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        backgroundColor: Colors.PRIMARY_PINK_UP,
        fontWeight: "bold",
        borderRadius: 10,
        padding: 5,
        cursor: "pointer",
        color: Colors.PRIMARY_WHITE,
        maxHeight: 500,
        overflowY: "scroll",
        maxWidth: 400,
    },
    dialogTitleText: {
        textAlign: "start",
        justifyContent: "start",
        alignContent: 'start',
        color: Colors.PRIMARY_BLUE,
        alignItems: 'start',
        display: 'flex',
        fontFamily: Colors.fontOfProgram,
        fontSize: 32,
        fontWeight: "bold"
    },

    divNotSelected: {
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        borderRadius: 10,
        alignContent: "center",
        padding: 5,
        cursor: "pointer",
        fontWeight: "bold",
        color: Colors.PRIMARY_PINK_ALT,
        maxHeight: 500,
        overflowY: "scroll",
        maxWidth: 400,
    },
    scrollable: {
        overflowX: "scroll",
        scrollbarGutter: "stable",
    },
    explanationGrid: {
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
    },

    buttonLabelAlternative: {
        border: "solid",
        backgroundColor: Colors.PRIMARY_WHITE,
        borderRadius: 10,
    },
    buttonCancel: {
        width: 140,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_BLUE,
        }
    },
    buttonDialog: {
        width: 200,
        padding: 10,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_BLUE,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_BLUE,
            color: Colors.PRIMARY_WHITE,
        }
    },
    buttonText: {
        fontWeight: "bold",
    },
    dialogButton: {
        borderRadius: 10,
        height: 35,
        width: 200,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_BLUE,
        '&:hover': {
            color: Colors.PRIMARY_WHITE,
            backgroundColor: Colors.PRIMARY_PINK_UP,
        }
    },
    dialogButtonText: {
        fontWeight: "bold",
        fontSize: 16,
    },
    dialogButtonGroup: {
        backgroundColor: Colors.PRIMARY_BLUE,
        padding: 20,
        display: "flex",
        justifyContent: "space-between",
    },
    topText: {
        color: Colors.PRIMARY_GREY,
        fontWeight: "bold",
        fontFamily: Colors.fontOfProgram,
        fontSize: 16,
    },
    textField: {
        fontSize: 12,
    },
    buttonDialogDelete: {
        width: 200,
        padding: 10,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_PINK_ALT,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_PINK_ALT,
            color: Colors.PRIMARY_WHITE,
        }
    },
} );


export default function AllLanguagesPanel( props ) {
    const classes = useStyles()
    const [ showExplanation, setShowExplanation ] = React.useState( false )
    const [ showImage, setShowImage ] = React.useState( false )
    const [ selectedElement, setSelectedElement ] = React.useState( null )
    const [ dialogOpen, setDialogOpen ] = React.useState( -1 )
    const [ errorOpen, setErrorOpen ] = React.useState( "" )
    const [ successOpen, setSuccessOpen ] = React.useState( "" )
    const [ submitted, setSubmitted ] = React.useState( false )
    const [ page, setPage ] = React.useState( 0 )
    const [ rowsPerPage, setRowsPerPage ] = React.useState( 5 )
    const [ deletionWarning, setDeletionWarning ] = React.useState( false )
    const [ autoFillPage, setAutoFillPage ] = React.useState( false )
    const [ autoFillSubmitted, setAutoFillSubmitted ] = React.useState( false )
    const [ allSelected, setAllSelected ] = React.useState( false )
    const [ showUnconfirmed, setShowUnconfirmed ] = React.useState( false )
    const [ showAndroidKey, setShowAndroidKey ] = React.useState( false )
    const [ confirmPage, setConfirmPage ] = React.useState( false )
    const [ orderCreationPage, setOrderCreationPage ] = React.useState( false )
    const [ filterText, setFilterText ] = React.useState( "" )
    const [ filterKeyText, setFilterKeyText ] = React.useState( "" )
    const [ tempFilterText, setTempFilterText ] = React.useState( "" )
    const [ tempFilterKeyText, setTempFilterKeyText ] = React.useState( "" )
    const [ formattedExportOpen, setFormattedExportOpen ] = React.useState( false )
    const [ wordListFormatted, setWordListFormatted ] = React.useState( [] )
    const [ searchActive, setSearchActive ] = React.useState( false )
    const [ searchKeyActive, setSearchKeyActive ] = React.useState( false )
    const [ showWOKeys, setShowWOKeys ] = React.useState( false )
    const [ allTranslatorsInfo, setAllTranslatorsInfo ] = React.useState( null )

    let userRole = null

    const [ imageList, setImageList ] = React.useState( [] )
    const myRef = useRef( null )

    async function loadAllTranslatorsInfo() {

        let headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }
        await fetch( process.env.REACT_APP_URL + 'users/getAllTranslators', {
            method: 'get', headers: headers,
        }, )
            .then( response => {
                if ( response.ok ) {
                    return response.json()
                } else {
                    setErrorOpen( 'Something went wrong' )
                }
            } )
            .then( data => {
                setAllTranslatorsInfo( [ ...data ] )
            } )
            .catch( ( error ) => {
                setErrorOpen( error )
            } )

    }

    async function createTranslator( name, surname, userExpirationTime, email ) {
        let returnObject = {
            name: name,
            surname: surname,
            userExpirationTime: userExpirationTime,
            email: email
        }


        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }
        await fetch( process.env.REACT_APP_URL + 'users/createTranslator', {
            method: 'POST', headers: headers, body: JSON.stringify( returnObject )
        }, )
            .then( response => {
                if ( response.ok ) return response.json()
                else throw new Error();
            } )
            .then( ( data ) => {
                addTranslator( data.name, data.surname, data.id, data.userName )
            } )
            .catch( e => {
                setErrorOpen( e )
            } )
    }

    function addTranslator( name, surname, id, userName ) {
        let temp = [ ...allTranslatorsInfo ]
        temp.push( {
            id: id,
            name: name,
            surname: surname,
            userName: userName
        } )
        setAllTranslatorsInfo( temp )
    }

    if ( props.project.images.length !== imageList.length ) {
        setImageList( props.project.images )
    }

    if ( Program.getPaginationSize() !== rowsPerPage ) {
        setRowsPerPage( Program.getPaginationSize() )
    }

    function handleChangePage( event, newPage ) {
        setPage( newPage );
    }

    function handleChangeRowsPerPage( event ) {
        Program.setPaginationSize( parseInt( event.target.value, 10 ) )

        setRowsPerPage( parseInt( event.target.value, 10 ) );
        setPage( 0 );
    }

    props.project.roles.forEach( role => {
        let categoryCheck = false
        role.roleRestrictionList.forEach( roleRest => {
            if ( roleRest.category.id === props.project.categories[ props.categoryIndex ].id )
                categoryCheck = true
        } )

        if ( categoryCheck ) {
            role.roleList.forEach( el => {
                if ( el.user.id === props.program.user.id ) {
                    userRole = role
                }
            } )
        }
    } )

    const emptyRows =
        page > 0 ? Math.max( 0, ( 1 + page ) * rowsPerPage - props.allTable.length ) : 0;

    function handleImageAddition( imageResponse ) {
        let tempList = [ ...imageList, ...imageResponse ]
        props.project.images = tempList
        setImageList( tempList )
    }

    function makeUpperCaseAll() {

        Program.getCheckList()
               .forEach( checkedId => {
                   props.allTable.forEach( row => {
                       if ( row.id === checkedId ) {
                           if ( row.englishWord !== null && row.englishWord.length > 0 ) {
                               props.handleAllTableBaseChange( row.id, row.englishWord.trim()
                                                                          .toUpperCase() )
                               props.handleAllTableBaseSave( row )
                           }
                           row.localizations.forEach( loc => {
                               if ( loc.text !== null && loc.text.length > 0 ) {
                                   props.handleAllTableLocalizationChange( loc.id, loc.text.trim()
                                                                                      .toUpperCase() )
                                   props.handleAllTableLocalizationSave( loc )
                               }
                           } )
                       }
                   } )
               } )
    }

    function makeLowerCaseAll() {
        Program.getCheckList()
               .forEach( checkedId => {
                   props.allTable.forEach( row => {
                       if ( row.id === checkedId ) {
                           if ( row.englishWord !== null && row.englishWord.length > 0 ) {
                               props.handleAllTableBaseChange( row.id, row.englishWord.trim()
                                                                          .toLowerCase() )
                               props.handleAllTableBaseSave( row )
                           }
                           row.localizations.forEach( loc => {
                               if ( loc.text !== null && loc.text.length > 0 ) {
                                   props.handleAllTableLocalizationChange( loc.id, loc.text.trim()
                                                                                      .toLowerCase() )
                                   props.handleAllTableLocalizationSave( loc )
                               }
                           } )
                       }
                   } )
               } )
    }

    function makeFirstUpperCase() {
        Program.getCheckList()
               .forEach( checkedId => {
                   props.allTable.forEach( row => {
                       if ( row.id === checkedId ) {
                           if ( row.englishWord !== null && row.englishWord.length > 0 ) {
                               props.handleAllTableBaseChange( row.id,
                                   row.englishWord.toLowerCase()
                                      .trim()
                                      .charAt( 0 )
                                      .toUpperCase() + row.englishWord.toLowerCase()
                                                          .trim()
                                                          .slice( 1 ) )
                               props.handleAllTableBaseSave( row )
                           }
                           row.localizations.forEach( loc => {
                               if ( loc.text !== null && loc.text.length > 0 ) {
                                   props.handleAllTableLocalizationChange( loc.id,
                                       loc.text.toLowerCase()
                                          .charAt( 0 )
                                          .trim()
                                          .toUpperCase() + loc.text.toLowerCase()
                                                              .trim()
                                                              .slice( 1 ) )
                                   props.handleAllTableLocalizationSave( loc )
                               }
                           } )
                       }
                   } )
               } )
    }

    function makeAllFirstUpperCase() {
        Program.getCheckList()
               .forEach( checkedId => {
                   props.allTable.forEach( row => {
                       if ( row.id === checkedId ) {
                           if ( row.englishWord !== null && row.englishWord.length > 0 ) {

                               props.handleAllTableBaseChange( row.id,
                                   row.englishWord.toLowerCase()
                                      .split( " " )
                                      .map( k => k.trim()
                                                  .charAt( 0 )
                                                  .toUpperCase() + k.trim()
                                                                    .slice( 1 ) )
                                      .join( " " ) )
                               props.handleAllTableBaseSave( row )
                           }
                           row.localizations.forEach( loc => {
                               if ( loc.text !== null && loc.text.length > 0 ) {

                                   props.handleAllTableLocalizationChange( loc.id,
                                       loc.text.toLowerCase()
                                          .split( " " )
                                          .map( k => k.trim()
                                                      .charAt( 0 )
                                                      .toUpperCase() + k.trim()
                                                                        .slice( 1 ) )
                                          .join( " " ) )
                                   props.handleAllTableLocalizationSave( loc )
                               }
                           } )
                       }
                   } )
               } )
    }

    function checkConfirmed( row ) {
        return !showUnconfirmed || ( showUnconfirmed && row.confirmNeed )
    }

    function checkWOKey( row ) {
        return !showWOKeys || ( showWOKeys && ( row.androidKey === null || row.androidKey.trim().length === 0 ) )
    }

    function checkChecked( id ) {
        let check = false
        Program.getCheckList()
               .forEach( el => {
                   if ( el === id )
                       check = true
               } )
        return check
    }

    function selectAll( status ) {
        if ( status ) {
            let temp = []
            props.allTable.forEach( el => {
                temp.push( el.id )
            } )
            Program.setCheckList( temp )
            setAllSelected( true )
        } else {
            Program.setCheckList( [] )
            setAllSelected( false )
        }
    }

    function checkAllSelected() {
        return props.allTable.length === Program.getCheckList().length
    }

    return <Grid
        container className={ classes.grid }>
        <Grid item xs={ 12 } style={ {
            justifyContent: "space-between", display: "flex",
        } }>
            <ButtonGroup className={ classes.buttonGroup }>
                <div className={ classes.innerButtonGroup }>
                    <label className={ classes.buttonLabel }>
                        <Button

                            onClick={ () => {
                                setShowExplanation( !showExplanation )
                            } }
                            className={ classes.topButton }>
                            <Typography className={ classes.topButtonText }>
                                { showExplanation ? "Hide Descriptions" : "Show Descriptions" }
                            </Typography>
                        </Button>
                    </label>
                    <label className={ classes.buttonLabel }>
                        <Button
                            onClick={ () => {
                                setShowImage( !showImage )
                            } }
                            className={ classes.topButton }>
                            <Typography className={ classes.topButtonText }>
                                { showImage ? "Hide Guide Images" : "Show Guide Images" }
                            </Typography>
                        </Button>
                    </label>
                    <label className={ classes.buttonLabel }>
                        <Button
                            onClick={ () => {
                                setShowUnconfirmed( !showUnconfirmed )
                                setPage( 0 )
                            } }
                            className={ classes.topButton }>
                            <Typography className={ classes.topButtonText }>
                                { showUnconfirmed ? "Show All" : "Show Unconfirmed" }
                            </Typography>
                        </Button>
                    </label>

                    <Grid container>
                        <Grid item>
                            <label className={ classes.buttonLabel }>
                                <Button
                                    onClick={ () => {
                                        let wordList = []
                                        props.allTable.forEach( row => {
                                            if ( checkChecked( row.id ) ) {
                                                wordList.push( row )
                                            }
                                        } )

                                        if ( wordList.length === 0 ) {
                                            setErrorOpen( "No text is selected!" )
                                        } else {
                                            setWordListFormatted( wordList )
                                            setFormattedExportOpen( true )
                                        }

                                    } }
                                    className={ classes.topButtonAlt }>
                                    <Typography className={ classes.topButtonText }>
                                        { "FORMATTED" }
                                    </Typography>
                                </Button>
                            </label>
                        </Grid>
                        <Grid item>
                            <label className={ classes.buttonLabel }>
                                <Button
                                    onClick={ () => {
                                        setErrorOpen( "Not implemented!" )
                                    } }
                                    className={ classes.topButtonAlt }>
                                    <Typography className={ classes.topButtonText }>
                                        { "IOS" }
                                    </Typography>
                                </Button>
                            </label>
                        </Grid>
                        <Grid item>
                            <label className={ classes.buttonLabel }>
                                <Button
                                    onClick={ () => {
                                        setErrorOpen( "Not implemented!" )
                                    } }
                                    className={ classes.topButtonAlt }>
                                    <Typography className={ classes.topButtonText }>
                                        { "ANDROID" }
                                    </Typography>
                                </Button>
                            </label>
                        </Grid>
                        <Grid item>
                            <label className={ classes.buttonLabel }>
                                <Button
                                    disabled={ !( props.project.categories[ props.categoryIndex ].hasAndroidKey ) }
                                    onClick={ () => {
                                        setShowAndroidKey( !showAndroidKey )
                                    } }
                                    className={ classes.topButtonAlt }>
                                    <Typography className={ classes.topButtonText }>
                                        { showAndroidKey ? "HIDE KEYS" : "SHOW KEYS" }
                                    </Typography>
                                </Button>
                            </label>
                        </Grid>
                    </Grid>
                    <label className={ classes.buttonLabel }>
                        <Button
                            onClick={ () => {
                                setShowWOKeys( !showWOKeys )
                                setPage( 0 )
                            } }
                            className={ classes.topButton }>
                            <Typography className={ classes.topButtonText }>
                                { showWOKeys ? "Show All" : "Show WO Keys" }
                            </Typography>
                        </Button>
                    </label>
                    <label className={ classes.buttonLabel }>
                        <Button
                            onClick={ () => {
                                props.setProjectTrace( null )
                            } }
                            className={ classes.topButton }>
                            <Typography className={ classes.topButtonText }>
                                { "Refresh" }
                            </Typography>
                        </Button>
                    </label>
                    <label className={ classes.buttonLabel }>
                        <Button
                            onClick={ () => {

                            } }
                            className={ classes.topButton }>
                            <Typography className={ classes.topButtonText }>
                                { "Translate" }
                            </Typography>
                        </Button>
                    </label>
                </div>
            </ButtonGroup>
            <AutoSaveComponent autoSave={ props.autoSave }/>
            <ButtonGroup className={ classes.buttonGroup }>
                <div className={ classes.innerButtonGroup }>
                    <label className={ classes.buttonLabel }>
                        <Button
                            disabled={ !props.auth }
                            onClick={ () => {
                                if ( Program.getCheckList().length > 0 ) {
                                    setOrderCreationPage( true )
                                } else {
                                    setErrorOpen( "Should check texts!" )
                                }
                            } }
                            className={ classes.topButton }>
                            <Typography className={ classes.topButtonText }>
                                { "Job Creator" }
                            </Typography>
                        </Button>
                    </label>
                    <label className={ classes.buttonLabel }>
                        <Button
                            onClick={ () => {
                                if ( Program.getCheckList().length > 0 ) {
                                    let check = false
                                    Program.getCheckList()
                                           .forEach( id => {
                                               props.allTable.forEach( row => {
                                                   if ( row.id === id && row.confirmNeed ) {
                                                       check = true
                                                   }
                                               } )
                                           } )
                                    if ( check )
                                        setConfirmPage( true )
                                    else
                                        setErrorOpen( "Selected texts do not need confirmations!" )

                                } else {
                                    setErrorOpen( "Should check texts!" )
                                }
                            } }
                            className={ classes.topButton }>
                            <Typography className={ classes.topButtonText }>
                                { "Confirm" }
                            </Typography>
                        </Button>
                    </label>
                    <label className={ classes.buttonLabel }>

                        <Button
                            onClick={
                                () => {
                                    if ( Program.getCheckList().length > 0 ) {
                                        setAutoFillPage( true )
                                    } else {
                                        setErrorOpen( "Should check texts!" )
                                    }
                                }
                            }
                            className={ classes.topButton }>
                            <Typography className={ classes.topButtonText }>
                                { "AutoFill" }
                            </Typography>
                        </Button>
                    </label>
                    <label className={ classes.buttonLabel }>
                        <Button
                            onClick={
                                () => {
                                    if ( Program.getCheckList().length > 0 ) {
                                        setDeletionWarning( true )
                                    } else {
                                        setErrorOpen( "Should check texts!" )
                                    }
                                }
                            }
                            className={ classes.topButton }>
                            <Typography className={ classes.topButtonText }>
                                { "Remove" }
                            </Typography>
                        </Button>
                    </label>
                    <AllLanguagePanelReplaceWidget auth={ props.auth }
                                                   allTableReplaceText={ props.allTableReplaceText }/>
                    <Grid container style={ { alignItems: "center" } }>
                        <Grid item className={ classes.buttonLabel }>
                            <Container
                                onClick={ () => {
                                    if ( props.auth ) {
                                        makeUpperCaseAll()
                                    }
                                } }
                                className={ classes.topButtonAlternative }>
                                <Typography className={ classes.topButtonText }>
                                    { "AA" }
                                </Typography>
                            </Container>
                        </Grid>
                        <Grid item className={ classes.buttonLabel }>
                            <Container
                                onClick={ () => {
                                    if ( props.auth ) {
                                        makeFirstUpperCase()
                                    }
                                } }
                                className={ classes.topButtonAlternative }>
                                <Typography className={ classes.topButtonText }>
                                    { "Aa aa" }
                                </Typography>
                            </Container>
                        </Grid>
                        <Grid item className={ classes.buttonLabel }>
                            <Container
                                onClick={ () => {
                                    if ( props.auth ) {
                                        makeAllFirstUpperCase()
                                    }
                                } }
                                className={ classes.topButtonAlternative }>
                                <Typography className={ classes.topButtonText }>
                                    { "Aa Aa" }
                                </Typography>
                            </Container>
                        </Grid>
                        <Grid item className={ classes.buttonLabel }>
                            <Container
                                onClick={ () => {
                                    if ( props.auth ) {
                                        makeLowerCaseAll()
                                    }
                                } }
                                className={ classes.topButtonAlternative }>
                                <Typography className={ classes.topButtonText }>
                                    { "aa" }
                                </Typography>
                            </Container>
                        </Grid>
                        <Grid item style={ { paddingLeft: 10 } }>
                            <TextField
                                label={ "Word" }
                                style={ { width: 150 } }
                                value={ tempFilterText }
                                onKeyDown={ ( e ) => {
                                    if ( e.key === 'Enter' ) {
                                        setSearchActive( true )
                                        setSearchKeyActive( false )
                                        setFilterText( tempFilterText )
                                    }

                                } }
                                onChange={ ( e ) => {
                                    setTempFilterText( e.target.value )
                                } }
                            />
                        </Grid>
                        <Grid item style={ { paddingLeft: 10 } }>
                            <TextField
                                style={ { width: 150, padding: 0, margin: 0 } }
                                label={ "Android Key" }
                                value={ tempFilterKeyText }
                                onKeyDown={ ( e ) => {
                                    if ( e.key === 'Enter' ) {
                                        setSearchKeyActive( true )
                                        setSearchActive( false )
                                        setFilterKeyText( tempFilterKeyText )
                                    }
                                } }
                                onChange={ ( e ) => {
                                    setTempFilterKeyText( e.target.value )
                                } }
                            />
                        </Grid>
                        <Grid item>
                            <Button onClick={ () => {
                                setSearchKeyActive( false )
                                setSearchActive( false )
                                setFilterText( "" )
                                setFilterKeyText( "" )
                                setTempFilterText( "" )
                                setTempFilterKeyText( "" )
                            }
                            }>
                                <CancelOutlined/>
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </ButtonGroup>
        </Grid>
        {
            props.allTableAddedTexts.length > 0
                ?
                <Grid container
                      style={ {
                          display: "flex",
                          justifyContent: "center",

                          alignItems: "center",
                          borderRadius: 10,
                          background: Colors.PRIMARY_YELLOW_LIGHT,
                          padding: 12,
                          margin: 8,
                      } }>
                    <Grid item xs
                          style={ {
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",

                              color: Colors.PRIMARY_BLACK,
                          } }>
                        <Error style={ { fontSize: 36, } }/>
                    </Grid>
                    <Grid item xs={ 11 }>
                        <Typography style={ {
                            fontWeight: "bold",
                            fontSize: 16,
                            color: Colors.PRIMARY_BLACK,
                        } }>
                            You have unsaved changes. Do not forget to save them!
                        </Typography>
                    </Grid>

                </Grid>
                :
                null
        }
        <TableContainer style={ {
            maxHeight: 500
        } }>
            <Table stickyHeader size={ "small" }>
                { props.allTable.length === 0 ? null :
                    <TableHead>
                        <TableRow>
                            <TableCell style={ { width: 30, maxWidth: 30 } }>
                            </TableCell>
                            <TableCell style={ { width: 50, maxWidth: 50 } }>
                                <FileCopy
                                    onClick={ () => {
                                        let returnStr = ""
                                        props.allTable.forEach( row => {
                                            let tempEnglish =  row.englishWord
                                            tempEnglish = tempEnglish.replaceAll("\t"," ")
                                            tempEnglish = tempEnglish.replaceAll("\n"," ")
                                            returnStr += tempEnglish
                                            row.localizations.forEach( ( rowLoc, index ) => {
                                                if ( !rowLoc.base ) {
                                                    let temp = ""
                                                    if(rowLoc.text !== null){
                                                        temp = rowLoc.text
                                                    }

                                                    temp = temp.replaceAll("\t"," ")
                                                    temp = temp.replaceAll("\n"," ")

                                                    returnStr += "\t"
                                                    returnStr += temp
                                                }
                                            } )
                                            returnStr += "\n"
                                        } )


                                        navigator.clipboard.writeText( returnStr )
                                                 .then( r => {
                                                     setSuccessOpen( "Copied!" )
                                                 } )
                                    } }
                                    style={ { fontSize: 16, color: Colors.PRIMARY_BLUE, cursor: "pointer", } }/>
                            </TableCell>
                            <TableCell style={ { minWidth: 40, maxWidth: 40 } }>
                                <Checkbox
                                    checked={ allSelected }
                                    onChange={ () => {
                                        if ( allSelected ) {
                                            selectAll( false )
                                        } else {
                                            selectAll( true )
                                        }
                                    } }
                                    style={ { padding: 0, margin: 0 } }/>
                            </TableCell>
                            {
                                showImage ? <TableCell style={ {
                                    padding: 4,
                                    width: 100,
                                    minWidth: 100,
                                    alignItems: "center",
                                    textAlign: "center",
                                } }>
                                    Image
                                </TableCell> : null
                            }
                            {
                                showExplanation ? <TableCell style={ {
                                    padding: 4,
                                    width: 200,
                                    minWidth: 200,
                                    alignItems: "center",
                                    textAlign: "center",
                                } }>
                                    Description
                                </TableCell> : null
                            }
                            {
                                showAndroidKey ? <TableCell style={ {
                                    padding: 4,
                                    width: 200,
                                    minWidth: 200,
                                    alignItems: "center",
                                    textAlign: "center",
                                } }>
                                    Android Key
                                </TableCell> : null
                            }
                            <TableCell style={ {
                                padding: 4,
                                width: 200,
                                minWidth: 200,
                                alignItems: "center",
                                textAlign: "center",
                            } }>
                                { "Base (" + props.project.base.language + ")" }
                            </TableCell>
                            { props.allTable.length !== 0 ?
                                props.allTable[ 0 ].localizations.map( rowEl => {
                                    if ( rowEl.projectLanguage !== null ) {
                                        return <TableCell
                                            style={ {
                                                padding: 4,
                                                width: 200,
                                                minWidth: 200,
                                                alignItems: "center",
                                                textAlign: "center",
                                            } }>
                                            <Typography style={ { textAlign: "center" } }>
                                                { rowEl.projectLanguage.language.language + " (" +
                                                rowEl.projectLanguage.language.shortName + ")" }
                                            </Typography>
                                        </TableCell>
                                    } else {
                                        return null
                                    }
                                } ) : null
                            }
                        </TableRow>
                    </TableHead> }
                <TableBody>

                    { searchActive ? props.allTable.filter( checkConfirmed )
                                          .filter( checkWOKey )
                                          .map( ( row, index ) => {
                                              if ( row.englishWord.toLowerCase()
                                                      .includes( filterText.toLowerCase() ) ) {
                                                  return <AllLanguagesPanelRow
                                                      formattedExportOpen={ formattedExportOpen }
                                                      setFormattedExportOpen={ ( val ) => setFormattedExportOpen(
                                                          val ) }
                                                      isNew={ false }
                                                      checkList={ Program.getCheckList() }
                                                      userRole={ userRole }
                                                      showUnconfirmed={ showUnconfirmed }
                                                      showAndroidKey={ showAndroidKey }
                                                      handleAllTableAndroidKeySave={ ( textId,
                                                                                       androidKey ) => props.handleAllTableAndroidKeySave(
                                                          textId,
                                                          androidKey ) }
                                                      showImage={ showImage }
                                                      project={ props.project }
                                                      showExplanation={ showExplanation }
                                                      setCheckList={ ( val ) => {
                                                          Program.setCheckList( val )
                                                      } }
                                                      auth={ props.auth }
                                                      allSelected={ allSelected }
                                                      allTableReplaceText={ props.allTableReplaceText }
                                                      handleAllTableExplanationSave={ props.handleAllTableExplanationSave }
                                                      setDialogOpen={ ( val ) => setDialogOpen( val ) }
                                                      setSelectedElement={ ( val ) => setSelectedElement(
                                                          val ) }
                                                      setAllSelected={ ( val ) => setAllSelected( val ) }
                                                      handleAllTableTextDeletion={ props.handleAllTableTextDeletion }
                                                      selectAll={ ( status ) => selectAll( status ) }
                                                      handleAllTableAddedTextChange={ props.handleAllTableAddedTextChange }
                                                      handleAllTableBaseSave={ props.handleAllTableBaseSave }
                                                      handleAllTableLocalizationSave={ props.handleAllTableLocalizationSave }
                                                      category={ props.project.categories[ props.categoryIndex ] }
                                                      handleAllTableSetProjectAllTable={ props.handleAllTableSetProjectAllTable }
                                                      allTable={ props.allTable }
                                                      row={ row }
                                                      index={ index }/>
                                              }
                                          } ) :
                        searchKeyActive ? props.allTable.filter( checkConfirmed )
                                               .filter( checkWOKey )
                                               .map( ( row, index ) => {
                                                   if ( row.androidKey !== null && row.androidKey.toLowerCase()
                                                                                      .includes(
                                                                                          filterKeyText.toLowerCase() ) ) {
                                                       return <AllLanguagesPanelRow
                                                           formattedExportOpen={ formattedExportOpen }
                                                           setFormattedExportOpen={ ( val ) => setFormattedExportOpen(
                                                               val ) }
                                                           isNew={ false }
                                                           checkList={ Program.getCheckList() }
                                                           userRole={ userRole }
                                                           showUnconfirmed={ showUnconfirmed }
                                                           showAndroidKey={ showAndroidKey }
                                                           handleAllTableAndroidKeySave={ ( textId,
                                                                                            androidKey ) => props.handleAllTableAndroidKeySave(
                                                               textId,
                                                               androidKey ) }
                                                           showImage={ showImage }
                                                           project={ props.project }
                                                           showExplanation={ showExplanation }
                                                           setCheckList={ ( val ) => {
                                                               Program.setCheckList( val )
                                                           } }
                                                           auth={ props.auth }
                                                           allSelected={ allSelected }
                                                           allTableReplaceText={ props.allTableReplaceText }
                                                           handleAllTableExplanationSave={ props.handleAllTableExplanationSave }
                                                           setDialogOpen={ ( val ) => setDialogOpen( val ) }
                                                           setSelectedElement={ ( val ) => setSelectedElement(
                                                               val ) }
                                                           setAllSelected={ ( val ) => setAllSelected( val ) }
                                                           handleAllTableTextDeletion={ props.handleAllTableTextDeletion }
                                                           selectAll={ ( status ) => selectAll( status ) }
                                                           handleAllTableAddedTextChange={ props.handleAllTableAddedTextChange }
                                                           handleAllTableBaseSave={ props.handleAllTableBaseSave }
                                                           handleAllTableLocalizationSave={ props.handleAllTableLocalizationSave }
                                                           category={ props.project.categories[ props.categoryIndex ] }
                                                           handleAllTableSetProjectAllTable={ props.handleAllTableSetProjectAllTable }
                                                           allTable={ props.allTable }
                                                           row={ row }
                                                           index={ index }/>
                                                   }
                                               } ) : props.allTable.filter( checkConfirmed )
                                                          .filter( checkWOKey )
                                                          .slice( page * rowsPerPage,
                                                              page * rowsPerPage + rowsPerPage )
                                                          .map( ( row, index ) => {

                                                              return <AllLanguagesPanelRow
                                                                  formattedExportOpen={ formattedExportOpen }
                                                                  setFormattedExportOpen={ ( val ) => setFormattedExportOpen(
                                                                      val ) }
                                                                  isNew={ false }
                                                                  checkList={ Program.getCheckList() }
                                                                  userRole={ userRole }
                                                                  showUnconfirmed={ showUnconfirmed }
                                                                  showAndroidKey={ showAndroidKey }
                                                                  showImage={ showImage }
                                                                  handleAllTableAndroidKeySave={ ( textId,
                                                                                                   androidKey ) => props.handleAllTableAndroidKeySave(
                                                                      textId,
                                                                      androidKey ) }
                                                                  project={ props.project }
                                                                  showExplanation={ showExplanation }
                                                                  setCheckList={ ( val ) => {
                                                                      Program.setCheckList( val )
                                                                  } }
                                                                  auth={ props.auth }
                                                                  allSelected={ allSelected }
                                                                  allTableReplaceText={ props.allTableReplaceText }
                                                                  handleAllTableExplanationSave={ props.handleAllTableExplanationSave }
                                                                  setDialogOpen={ ( val ) => setDialogOpen(
                                                                      val ) }
                                                                  setSelectedElement={ ( val ) => setSelectedElement(
                                                                      val ) }
                                                                  setAllSelected={ ( val ) => setAllSelected(
                                                                      val ) }
                                                                  handleAllTableTextDeletion={ props.handleAllTableTextDeletion }
                                                                  selectAll={ ( status ) => selectAll(
                                                                      status ) }
                                                                  handleAllTableAddedTextChange={ props.handleAllTableAddedTextChange }
                                                                  handleAllTableBaseSave={ props.handleAllTableBaseSave }
                                                                  handleAllTableLocalizationSave={ props.handleAllTableLocalizationSave }
                                                                  category={ props.project.categories[ props.categoryIndex ] }
                                                                  handleAllTableSetProjectAllTable={ props.handleAllTableSetProjectAllTable }
                                                                  allTable={ props.allTable }
                                                                  row={ row }
                                                                  index={ index + page * rowsPerPage }/>
                                                          } ) }

                    { props.allTableAddedTexts.map( ( row, index ) => {
                        return <AllLanguagesPanelRow
                            formattedExportOpen={ formattedExportOpen }
                            setFormattedExportOpen={ ( val ) => setFormattedExportOpen(
                                val ) }
                            isNew={ true }
                            allTableReplaceText={ props.allTableReplaceText }
                            checkList={ Program.getCheckList() }
                            userRole={ userRole }
                            showUnconfirmed={ showUnconfirmed }
                            showAndroidKey={ showAndroidKey }
                            showImage={ showImage }
                            handleAllTableAndroidKeySave={ ( textId, androidKey ) => props.handleAllTableAndroidKeySave(
                                textId,
                                androidKey ) }
                            project={ props.project }
                            handleAllTableExplanationSave={ props.handleAllTableExplanationSave }
                            auth={ props.auth }
                            showExplanation={ showExplanation }
                            allSelected={ allSelected }
                            setCheckList={ ( val ) => Program.setCheckList( val ) }
                            setAllSelected={ ( val ) => setAllSelected( val ) }
                            handleAllTableTextDeletion={ props.handleAllTableTextDeletion }
                            selectAll={ ( status ) => selectAll( status ) }
                            allTableAddedTexts={ props.allTableAddedTexts }
                            handleAllTableAddedTextChange={ props.handleAllTableAddedTextChange }
                            handleAllTableBaseSave={ props.handleAllTableBaseSave }
                            handleAllTableLocalizationSave={ props.handleAllTableLocalizationSave }
                            category={ props.project.categories[ props.categoryIndex ] }
                            handleAllTableSetProjectAllTable={ props.handleAllTableSetProjectAllTable }
                            allTable={ props.allTable }
                            row={ row }
                            index={ index }/>
                    } ) }
                    <Grid item xs={ 12 }>
                        <div ref={ myRef }/>
                    </Grid>
                </TableBody>
            </Table>
        </TableContainer>
        { !searchActive && !searchKeyActive
        &&
        <Grid container>
            <Grid item xs={ 4 } style={ { display: "flex", justifyContent: "flex-end", alignItems: "center" } }>
                <Button
                    disabled={ page === 0 }
                    onClick={ () => {
                        setPage( 0 )
                    }
                    }
                    style={ {
                        borderRadius: 10,
                        width: 200
                    } }>
                    <Typography style={ { fontSize: 12, fontWeight: "bold" } }>
                        First Page
                    </Typography>
                </Button>
            </Grid>
            <Grid item xs={ 4 } style={ { display: "flex", justifyContent: "center", alignItems: "center" } }>
                <TablePagination rowsPerPageOptions={ [ 5, 10, 20, 50, 100, 150 ] }
                                 component="div"
                                 count={ props.allTable.filter( checkConfirmed )
                                              .filter( checkWOKey ).length }
                                 rowsPerPage={ rowsPerPage }
                                 page={ page }
                                 onChangeRowsPerPage={ ( event ) => handleChangeRowsPerPage(
                                     event ) }
                                 onChangePage={ ( event, newPage ) => handleChangePage( event,
                                     newPage ) }/>
            </Grid>
            <Grid item xs={ 4 } style={ { display: "flex", justifyContent: "flex-start", alignItems: "center" } }>
                <Button
                    disabled={ page === Math.floor( props.allTable.filter( checkConfirmed ).length / rowsPerPage ) }
                    onClick={ () => {
                        setPage( Math.floor( props.allTable.filter( checkConfirmed ).length / rowsPerPage ) )
                    }
                    }
                    style={ {
                        borderRadius: 10,
                        width: 200
                    } }>
                    <Typography style={ { fontSize: 12, fontWeight: "bold" } }>
                        Last Page
                    </Typography>
                </Button>
            </Grid>
            <Grid container style={ { display: "flex", justifyContent: "center" } }>
                <Select
                    style={ { width: "60px" } }
                    labelId="page-simple-select-label"
                    id="page-simple-select"
                    value={ page + 1 }
                    label="Page"
                    onChange={ ( event ) => setPage( event.target.value - 1 ) }
                >
                    { Array( Math.ceil( props.allTable.filter( checkConfirmed )
                                             .filter( checkWOKey ).length / rowsPerPage ) )
                        .fill( 0 )
                        .map( ( e, index ) => {

                            return <MenuItem value={ index + 1 }>{ index + 1 }</MenuItem>

                        } ) }

                </Select>
            </Grid>
        </Grid>
        }
        {
            submitted ?
                <LinearProgress style={ { height: 10, minHeight: 10, width: 1000, minWidth: 100, marginTop: 16 } }
                                className={ classes.progressBar } color={ "secondary" }/>
                :
                <NewTextTextField
                    allTableAddedTexts={ props.allTableAddedTexts }
                    handleAllTableTextAddition={ props.handleAllTableTextAddition }
                    auth={ props.auth }
                    handleAllTableTextSave={ props.handleAllTableTextSave }
                    setErrorOpen={ ( val ) => setErrorOpen( val ) }
                    setSubmitted={ ( val ) => setSubmitted( val ) }
                    scrollStarter={ () => {
                        if ( myRef.current !== null ) {
                            myRef.current.scrollIntoView(
                                { behavior: 'smooth', block: 'start', top: myRef.current.offsetTop } )
                        }
                    } }

                />
        }
        { orderCreationPage && <OrderCreationDialog
            allTranslatorsInfo={ allTranslatorsInfo }
            loadAllTranslatorsInfo={ () => loadAllTranslatorsInfo() }
            createTranslator={ ( name, surname, userExpirationTime, email ) => createTranslator( name, surname,
                userExpirationTime, email ) }
            project={ props.project }
            program={ props.program }
            isOpen={ orderCreationPage }
            onClose={ () => {
                setOrderCreationPage( false )
            } }
            allTable={ props.allTable }
        /> }
        <Dialog open={ confirmPage }
                maxWidth={ "md" }
                fullWidth
                onClose={ () => {
                    setConfirmPage( false )
                } }>
            <Grid container style={ {
                display: "flex",
                justifyContent: "center",
                padding: 20,
            } }>
                <Grid container style={ {
                    display: "flex",
                    justifyContent: "center",
                } }>
                    <Grid item xs style={ {
                        display: "flex",
                        justifyContent: "center",
                    } }>
                        <Typography style={ {
                            textAlign: "center",
                            fontSize: 20,
                            color: Colors.PRIMARY_BLUE
                        } }>
                            Are you sure to confirm all localizations of selected rows?
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={ 3 } style={ {
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 20,
                } }>
                    <Grid item>
                        <Button className={ classes.topButton }
                                onClick={ () => {
                                    // props.handleAllTableConfirmation()
                                    let idList = []
                                    Program.getCheckList()
                                           .forEach( textId => {
                                               props.allTable.forEach( row => {
                                                   if ( textId === row.id ) {
                                                       row.localizations.forEach( loc => {
                                                           idList.push( loc.id )
                                                       } )
                                                   }
                                               } )
                                           } )
                                    props.handleAllTableConfirmation( idList )
                                    setConfirmPage( false )
                                } }>
                            Confirm
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button className={ classes.topButtonCancel }
                                onClick={ () => {
                                    setConfirmPage( false )
                                } }>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Grid>


        </Dialog>
        <Dialog open={ deletionWarning }
                maxWidth={ "md" }
                onClose={ () => setDeletionWarning( false ) }>
            <Grid container className={ classes.generalGrid }>
                <Grid item xs={ 12 }>
                    <Typography className={ classes.dialogTitleText }>
                        <Grid item xs={ 7 }>
                            { "Delete Lines " }
                        </Grid>
                        <Grid item xs={ 5 }>
                            { Program.getCheckList().length + " lines will be removed" }
                        </Grid>
                    </Typography>
                </Grid>
                <Grid item xs={ 12 }>
                    <Typography className={ classes.topText }>
                        { "Are you sure to delete selected lines?\n" }
                    </Typography>
                    <Typography className={ classes.topText }>
                        { "This step can not be taken back, you will not be able to recover data!" }
                    </Typography>
                </Grid>
                <Grid container className={ classes.buttonGridTop }>
                    <Paper elevation={ 10 } className={ classes.buttonGrid }>
                        <ButtonGroup>
                            <label className={ classes.buttonLabel }>
                                <Button
                                    disabled={ !props.auth }
                                    onClick={ () => {
                                        setDeletionWarning( false )
                                        props.handleAllTableTextListDeletion( Program.getCheckList() )
                                    } }
                                    className={ classes.buttonDialogDelete }>
                                    <Typography className={ classes.buttonText }>
                                        Delete
                                    </Typography>
                                </Button>
                            </label>
                            <label className={ classes.buttonLabel }>
                                <Button onClick={ () => {
                                    setDeletionWarning( false )
                                } }
                                        className={ classes.buttonDialog }>
                                    <Typography className={ classes.buttonText }>
                                        Cancel
                                    </Typography>
                                </Button>
                            </label>
                        </ButtonGroup>
                    </Paper>
                </Grid>

            </Grid>
        </Dialog>
        <Dialog open={ autoFillPage }
                maxWidth={ "md" }
                onClose={
                    () => {
                        if ( !autoFillSubmitted )
                            setAutoFillPage( false )
                    }
                }
        >
            <Grid container className={ classes.generalGrid }>
                <Grid item xs={ 12 }>
                    <Typography className={ classes.dialogTitleText }>
                        Autofill Lines
                    </Typography>
                </Grid>
                <Grid item xs={ 12 }>
                    <Typography className={ classes.topText }>
                        { autoFillSubmitted ? "Please wait!" : "Selected localizations will be searched in dataset.\n" }
                    </Typography>
                    <Typography className={ classes.topText }>
                        { autoFillSubmitted ? "This process can take time. Do not close website and internet!" :
                            "Filled localizations will not change!" }
                    </Typography>
                </Grid>
                <Grid container className={ classes.buttonGridTop }>
                    <Paper elevation={ 10 } className={ classes.buttonGrid }>
                        { autoFillSubmitted ? <LinearProgress style={ { width: 400, height: 5, borderRadius: 10, } }/> :
                            <ButtonGroup>
                                <label className={ classes.buttonLabel }>
                                    <Button
                                        disabled={ !props.auth }
                                        onClick={ async () => {
                                            setAutoFillSubmitted( true )
                                            await props.handleAllTableTextAutoFill( Program.getCheckList() )
                                            setAutoFillPage( false )
                                            setAutoFillSubmitted( false )
                                        } }
                                        className={ classes.buttonDialog }>
                                        <Typography className={ classes.buttonText }>
                                            AutoFill
                                        </Typography>
                                    </Button>
                                </label>
                                <label className={ classes.buttonLabel }>
                                    <Button onClick={ () => {
                                        setAutoFillPage( false )
                                    } }
                                            className={ classes.buttonDialogDelete }>
                                        <Typography className={ classes.buttonText }>
                                            Cancel
                                        </Typography>
                                    </Button>
                                </label>
                            </ButtonGroup> }
                    </Paper>
                </Grid>

            </Grid>
        </Dialog>
        <ImageUploadDialog
            dialogOpen={ dialogOpen }
            classes={ classes }
            handleAllTableImageChange={ ( tar, val ) => props.handleAllTableImageChange( tar, val ) }
            project={ props.project }
            imageList={ imageList }
            handleImageAddition={ ( data ) => handleImageAddition( data ) }
            selectedElement={ selectedElement }
            auth={ props.auth }
            setDialogOpen={ ( val ) => setDialogOpen( val ) }/>
        <Snackbar open={ errorOpen.length > 0 }
                  autoHideDuration={ 2000 }
                  onClose={ () => setErrorOpen( '' ) }
        >
            <Alert onClose={ () => setErrorOpen( '' ) }
                   severity={ "warning" }
            >
                { errorOpen }
            </Alert>
        </Snackbar>
        <Snackbar open={ successOpen.length > 0 }
                  autoHideDuration={ 2000 }
                  onClose={ () => setSuccessOpen( "" ) }
        >
            <Alert onClose={ () => setSuccessOpen( "" ) }
                   severity={ "success" }
            >
                { successOpen }
            </Alert>
        </Snackbar>
        { formattedExportOpen &&
        <FormattedExportDialog baseLanguage={ props.project.base } wordList={ wordListFormatted }
                               open={ formattedExportOpen } setOpen={ ( val ) => setFormattedExportOpen( val ) }/> }
    </Grid>
}