import React from "react";
import {
    Button, ButtonGroup, Dialog,
    Divider,
    Grid,
    Paper,
    Switch, Tooltip,
    Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../constants/Colors";
import CategoryAdditionDialog
    from "../projectScreens/projectDetailScreens/productDetailScreensDialogComponents/CategoryAdditionDialog";
import Env from "../../constants/Env";
import { AddBoxOutlined, LockOpen, RemoveCircleOutline } from "@material-ui/icons";
import PermissionAdditionDialog
    from "../projectScreens/projectDetailScreens/productDetailScreensDialogComponents/PermissionAdditionDialog";
import CategoryLine from "./CategoryLine";

const useStyles = makeStyles( () => ( {
    card: {
        padding: 20,
        display: 'flex',
        height: '100%',
        borderRadius: 10,
        alignItems: "flex-start",
        color: Colors.PRIMARY_PINK_ALT,
        backgroundColor: Colors.PRIMARY_WHITE,
        '&:hover': {
            color: Colors.PRIMARY_PINK_UP,
            opacity: 1,
        },
    },
    title: {
        paddingLeft: 12,
        display: 'flex',
        fontSize: 20,
        fontFamily: Colors.fontOfProgram,
        fontWeight: "bold"
    },
    divider: {
        height: 2,
        borderRadius: 50,
        minWidth: 201,
        marginTop: 4,
        marginBottom: 4,
        background: Colors.gradient,
        width: '100%'
    },
    text: {
        color: Colors.PRIMARY_BLUE,
        display: 'flex',
        fontSize: 16,
        justifyContent: 'start',
        fontWeight: "bold"
    },
    textSubmitted: {
        color: Colors.PRIMARY_GREEN_LIGHT,
        display: 'flex',
        fontSize: 16,
        fontWeight: "bold"
    },
    textPassive: {
        color: Colors.PRIMARY_PINK_ALT,
        display: 'flex',
        fontSize: 16,
        fontWeight: "bold"
    },
    cardBody: {
        paddingLeft: 16,
        paddingRight: 16,
        justifyContent: 'space-between',
        display: 'flex',
        padding: 10,
        color: Colors.PRIMARY_BLUE,
    },
    lineContainer: {
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        paddingLeft: 24,
        paddingTop: 4,
        color: Colors.PRIMARY_BLUE,
    },
    cardBodyChild: {
        paddingRight: 40,
        justifyContent: 'flex-end',
        display: 'flex',
        color: Colors.PRIMARY_BLUE,
    },
    roleAdditionIcon: {
        color: Colors.PRIMARY_GREEN_LIGHT,
        fontSize: 32,
    },
    button: {
        justifyContent: 'start',
        padding: 0,
    },
    removeButton: {
        width: 200,
        background: Colors.PRIMARY_PINK_ALT,
        color: Colors.PRIMARY_WHITE,
        '&:hover': {
            background: Colors.PRIMARY_WHITE,
            color: Colors.PRIMARY_PINK_ALT,
        }
    },
    cancelButton: {
        width: 200,
        background: Colors.PRIMARY_BLUE,
        color: Colors.PRIMARY_WHITE,
        '&:hover': {
            background: Colors.PRIMARY_WHITE,
            color: Colors.PRIMARY_BLUE,
        }
    },
    removeTitle: {
        color: Colors.PRIMARY_PINK_ALT,
        fontWeight: "bold",
        fontSize: 32
    },
    removeBody: {
        color: Colors.PRIMARY_BLUE,
        fontWeight: "bold",
        fontSize: 20
    },
    icon: {
        fontSize: 32,
        color: Colors.PRIMARY_GREEN_LIGHT,
    },
    iconRemove: {
        fontSize: 32,
        color: Colors.PRIMARY_PINK_ALT,
    },
    textName: {
        marginLeft: 24,
        display: "flex",
        fontSize: 20,
        fontWeight: "bold",
    },
    titleText: {
        justifyContent: 'start',

        color: Colors.PRIMARY_WHITE,
        display: 'flex',
        fontSize: 16,
        fontWeight: "bold"
    },
    titleCard: {
        background: Colors.gradientSecond,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: Colors.PRIMARY_BLUE,
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        paddingLeft: 32,
        paddingRight: 16,
        height: 60,
        color: Colors.PRIMARY_WHITE,
    },
    buttonDialog: {
        width: 200,
        padding: 10,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_BLUE,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_BLUE,
            color: Colors.PRIMARY_WHITE,
        }
    },
    buttonText: {
        fontWeight: "bold",
    },
    buttonGrid: {
        display: "flex",
        justifyContent: "center",
        borderRadius: 10,
        paddingTop: 1,
        margin: 0,
    },
    centerWithPadding: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 20,
        paddingBottom: 20,
        margin: 0,
    },
    deleteButton: {
        width: 200,
        padding: 10,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: 'darkred',
        "&:hover": {
            backgroundColor: 'darkred',
            color: Colors.PRIMARY_WHITE,
        }
    },
    buttonLabel: {
        backgroundColor: Colors.PRIMARY_WHITE,
        borderRadius: 10,
        padding: 4,
    },
} ) );

export default function CategoryShortCut( props ) {
    const classes = useStyles();
    const [ currentCategory, setCurrentCategory ] = React.useState( {} );
    const [ open, setOpen ] = React.useState( false );
    const [ categoryAdditionOpen, setCategoryAdditionOpen ] = React.useState( false )
    const [ textAdditionOpen, setTextAdditionOpen ] = React.useState( false )
    const [ permissionCreationDialog, setPermissionCreationDialog ] = React.useState( null )
    const [ removeSubjectDialog, setRemoveSubjectDialog ] = React.useState( null )
    const [ categories, setCategories ] = React.useState( [] )

    if ( categories.length !== props.project.categories.length ) {
        setCategories( [ ...props.project.categories ] )
    }

    function onHandleCategoryAdditionOpen() {
        setCategoryAdditionOpen( true )
    }

    function onHandleCategoryAdditionClose() {
        setCategoryAdditionOpen( false )
    }


    function onHandleTextAdditionClose() {
        setTextAdditionOpen( false )
    }

    function handleCategoryDeletion( categoryId ) {
        let tempList = []
        let tempCategory
        categories.forEach( cat => {
            if ( cat.id !== categoryId ) {
                tempList.push( cat )
            } else {
                tempCategory = cat
            }
        } )

        setCategories( tempList )
        props.project.categories = tempList

        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }

        fetch( process.env.REACT_APP_URL + 'projects/categories?categoryId=' + categoryId, {
            method: 'DELETE', headers: headers,
        }, )
            .then( response => {
                if ( response.ok ) {
                    return response.json()
                } else {
                    return response.json()
                                   .then( e => {
                                   } );
                }
            } )
            .catch( ( error ) => {
                setCategories( [ ...tempList, tempCategory ] )
                props.project.categories = [ ...tempList, tempCategory ]
            } )
    }

    function handleCategoryAddition( categoryResponseList ) {
        let tempList = [ ...categories, ...categoryResponseList ]
        setCategories( tempList )
        props.project.categories = tempList
    }

    return <Paper onMouseOver={ () => {
        setOpen( true )
    } } onMouseOut={ () => {
        setOpen( false )
    } } elevation={ open ? 2 : 0 } className={ classes.card }>
        <Grid container spacing={ 1 }>
            <Grid container>
                <Grid item xs={ 11 }>
                    <Typography className={ classes.title }>
                        Translation Subjects
                    </Typography>
                </Grid>
                {
                    props.auth ?
                        <Grid item xs={ 1 }>
                            <Button
                                onClick={
                                    () => onHandleCategoryAdditionOpen()
                                }
                                className={ classes.button }
                            >
                                <Tooltip title={ "Add new translation subjects to project." }>
                                    <AddBoxOutlined
                                        className={ classes.roleAdditionIcon }
                                    />
                                </Tooltip>
                            </Button>
                        </Grid>
                        :
                        null
                }
            </Grid>
            <Grid item xs={ 12 }>
                <Divider className={ classes.divider }>
                </Divider>
            </Grid>
            <Grid container className={ classes.titleCard }>
                <Grid item xs={ 5 }>
                    <Typography className={ classes.titleText }>
                        Subject
                    </Typography>
                </Grid>

                {/*
                props.auth ?
                    <Grid item xs={2}>
                        <Typography className={classes.titleText}>
                            Add Line
                        </Typography>
                    </Grid>
                    :
                    null
                */
                }
                { props.auth ?
                    <Grid item xs={ 4 }>
                        <Typography className={ classes.titleText }>
                            Android Key
                        </Typography>
                    </Grid>
                    :
                    null }

                { props.auth ?
                    <Grid item xs={ 3 }>
                        <Typography className={ classes.titleText }>
                            Remove Subject
                        </Typography>
                    </Grid>
                    : null
                }
            </Grid>
            { categories.map( ( category ) => {
                return <CategoryLine
                    auth={ props.auth }
                    category={ category }
                    setRemoveSubjectDialog={ ( val ) => setRemoveSubjectDialog( val ) }
                    setPermissionCreationDialog={ ( val ) => setPermissionCreationDialog( val ) }/>
            } ) }
        </Grid>
        <CategoryAdditionDialog
            handleCategoryAddition={ ( list ) => handleCategoryAddition( list ) }
            functionList={ props.functionList }
            project={ props.project }
            isOpen={ categoryAdditionOpen }
            handleClose={ () => onHandleCategoryAdditionClose() }
        />
        {
            /*
            <TextAdditionDialog user={ props.user }
                            category={ currentCategory }
                            functionList={ props.functionList }
                            project={ props.project }
                            isOpen={ textAdditionOpen }
                            handleClose={ () => onHandleTextAdditionClose() }
        />
             */
        }

        {
            permissionCreationDialog != null ?
                <PermissionAdditionDialog
                    category={ permissionCreationDialog }
                    auth={ props.auth }
                    program={ props.program }
                    user={ props.user }
                    functionList={ props.functionList }
                    project={ props.project }
                    handleClose={ () => setPermissionCreationDialog( null ) }/> : null
        }
        {
            removeSubjectDialog != null ?
                <Dialog open={ true } fullWidth maxWidth={ "sm" }>
                    <Grid container>
                        <Grid item xs={ 12 } className={ classes.centerWithPadding }>
                            <Typography className={ classes.removeTitle }>
                                REMOVE
                            </Typography>
                        </Grid>
                        <Grid item xs={ 12 } className={ classes.centerWithPadding }>
                            <Typography className={ classes.removeBody }>
                                Are you sure to remove translation subject?
                            </Typography>
                        </Grid>
                        <Grid item xs className={ classes.centerWithPadding }>
                            <ButtonGroup>
                                <label className={ classes.buttonLabel }>
                                    <Button
                                        onClick={ () => {
                                            handleCategoryDeletion( removeSubjectDialog.id )
                                            setRemoveSubjectDialog( null )
                                        } }
                                        className={ classes.removeButton }>
                                        <Typography className={ classes.buttonText }>
                                            Remove
                                        </Typography>
                                    </Button>
                                </label>
                                <label className={ classes.buttonLabel }>
                                    <Button onClick={ () => setRemoveSubjectDialog( null ) }
                                            className={ classes.cancelButton }>
                                        <Typography className={ classes.buttonText }>
                                            Cancel
                                        </Typography>
                                    </Button>
                                </label>
                            </ButtonGroup>
                        </Grid>

                    </Grid>
                </Dialog> : null
        }

    </Paper>
}