import React from "react";
import {
    List,
    Collapse,
    ListItem,
    ListItemIcon,
    ListItemText, Tooltip
} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import Colors from "../constants/Colors";
import SettingsIcon from '@material-ui/icons/Settings';
import {DashboardRounded, Description, Folder} from "@material-ui/icons";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
    drawer: {
        elevation: 0,
        width: drawerWidth,
        flexShrink: 0,
        paddingLeft: 8,
        whiteSpace: 'nowrap',
        color: Colors.PRIMARY_WHITE,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        color: Colors.PRIMARY_WHITE,
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: Colors.PRIMARY_WHITE,
    },
    toolbar: {
        color: Colors.PRIMARY_WHITE,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
    },
    iconActive: {
        color: Colors.PRIMARY_WHITE,
    },
    iconClosed: {
        color: Colors.PRIMARY_YELLOW,
    },
    collapse: {
        paddingLeft: 8,
    }
}));

export default function ProjectListItem(props) {
    const classes = useStyles();


    const handleClick = () => {
        props.onClickHandler(props.itemIndex);
    };

    return <List
        key={props.key}
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={clsx(classes.drawer, {
            [classes.drawerOpen]: props.itemIndex === props.index,
            [classes.drawerClose]: !props.itemIndex === props.index,
        })} items
    >
        <ListItem key={props.project.id} button onClick={handleClick}>
            <ListItemIcon>
                <Tooltip title={props.project.name}>
                    <Folder className={props.itemIndex === props.index ? classes.iconActive : classes.iconClosed}/>
                </Tooltip>
            </ListItemIcon>
            <ListItemText primary={props.project.name}/>
            {props.itemIndex === props.index ? <ExpandLess/> : <ExpandMore/>}
        </ListItem>
        <Collapse in={props.itemIndex === props.index} timeout="auto" unmountOnExit className={classes.collapse}>
            <List component="div" disablePadding>
                {['Dashboard', 'Texts', 'Project Settings'].map(
                    (text, index) => (
                        <ListItem onClick={() => props.onClickInnerIndexHandler(index)} button
                                  key={text + props.project.id}>
                            <ListItemIcon
                                className={
                                    (props.itemIndex - 3) * 3 + index === props.innerIndex ? classes.iconActive :
                                        classes.iconClosed}>{
                                index === 0 ?
                                    <Tooltip title={"Dashboard of " + props.project.name}>
                                        <DashboardRounded/>
                                    </Tooltip>
                                    :
                                    index === 1
                                        ?
                                        <Tooltip title={"Texts of " + props.project.name}>
                                            <Description/>
                                        </Tooltip> :
                                        <Tooltip title={"Settings of " + props.project.name}>
                                            <SettingsIcon/>
                                        </Tooltip>
                            }
                            </ListItemIcon>
                            <ListItemText primary={text}/>
                        </ListItem>

                    ))}
            </List>
        </Collapse>
    </List>
}
