import React from "react";
import { Dialog, Grid, LinearProgress, makeStyles, Snackbar, Typography } from "@material-ui/core";
import Colors from "../../../../constants/Colors";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { AddOutlined, Close, RemoveOutlined } from "@material-ui/icons";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import { Alert } from "@material-ui/lab";
import Env from "../../../../constants/Env";

const useStyles = makeStyles( () => ( {
    dialog: {
        background: Colors.gradient,
    },
    formControl: {
        width: "100%",
        color: Colors.PRIMARY_PINK_UP,
        paddingBottom: 8,
    },
    title: {
        fontSize: 32,
        fontFamily: Colors.fontOfProgram,
        fontWeight: "bold",
        color: Colors.PRIMARY_BLUE,
    },
    grid: {
        alignItems: 'center',
        display: "flex",
        justifyContent: "space-between",
    },
    bodyText: {
        fontWeight: "bold",
        color: Colors.PRIMARY_BLUE,
        fontSize: 16,
        fontFamily: Colors.fontOfProgram,
    },
    bodyBottomText: {
        color: Colors.PRIMARY_PINK,
        fontWeight: "bold",
        fontSize: 20,
        fontFamily: Colors.fontOfProgram,
    },
    dialogContent: {
        paddingTop: 28,
        paddingBottom: 28,
    },
    dialogAction: {
        display: "flex",
        justifyContent: "center",
        paddingBottom: 20,
    },
    buttonCreate: {
        width: 40,
        marginTop: 20,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_GREEN_LIGHT,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_GREEN_LIGHT,
            color: Colors.PRIMARY_WHITE,
        }
    },
    buttonDelete: {
        width: 40,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        color: Colors.PRIMARY_WHITE,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_WHITE,
            color: Colors.PRIMARY_PINK_ALT,
        }
    },
    gridRow: {
        alignItems: "center",
        background: Colors.PRIMARY_BACKGROUND,
        paddingLeft: 16,
        borderRadius: 10,
        marginBottom: 4,
    },
    buttonSave: {
        width: 200,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_GREEN_LIGHT,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_YELLOW_LIGHT,
        }
    },
    icon: {
        fontSize: 32,
        color: Colors.PRIMARY_PINK_UP,
    },
    buttonCancel: {
        width: 200,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_PINK,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_BLUE,
        }
    },
    buttonText: {
        fontWeight: "bold",
        color: Colors.PRIMARY_WHITE,
    },
    progressBar: {
        background: Colors.gradient,
        borderRadius: 10,
        height: 5,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
    }
} ) )

export default function CategoryAdditionDialog( props ) {
    const classes = useStyles();
    const [ categoryText, setCategoryText ] = React.useState( "" );
    const [ categories, setCategories ] = React.useState( [] );
    const [ submitted, setSubmitted ] = React.useState( false )
    const [ successOpen, setSuccessOpen ] = React.useState( false )
    const [ errorOpen, setErrorOpen ] = React.useState( false )
    const [ infoOpen, setInfoOpen ] = React.useState( false )


    const handleInfoOpen = () => {
        setInfoOpen( true )
    }

    const handleInfoClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }
        setInfoOpen( false );
    };

    const handleErrorOpen = () => {
        setErrorOpen( true )
    }

    const handleErrorClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }

        setErrorOpen( false );
    };

    const handleSuccessClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }
        props.handleClose();
        setSuccessOpen( false );
    };

    const handleSuccessOpen = () => {
        setSuccessOpen( true )
    }


    function checkAlreadyExist( value ) {
        let check = false;
        props.project.categories.forEach( category => {

            if ( category.categoryName.toLowerCase()
                         .trim() === value.toLowerCase()
                                          .trim() ) {
                check = true
            }
        } )
        return check
    }

    const handleCategoryAddition = ( value ) => {
        if ( categories.indexOf( value ) !== -1 || checkAlreadyExist( value ) || value.length < 1 ) {
            handleInfoOpen()
        } else {
            setCategories( [ ...categories, value ] )
        }

    }

    const handleCategoryDeletion = ( value ) => {
        let temp = []
        categories.forEach( category => {
            if ( category !== value )
                temp.push( category )
        } )
        setCategories( temp )
    }


    return <Dialog fullWidth={ true } className={ classes.dialog } maxWidth={ 'sm' } open={ props.isOpen }>
        <DialogTitle id="form-dialog-title">
            <Grid className={ classes.grid } container>
                <Grid item xs={ 5 }>
                    <Typography className={ classes.title }>
                        Add Category
                    </Typography>
                </Grid>
                <Grid item xs={ 1 }>
                    <Button onClick={ props.handleClose }>
                        <Close className={ classes.icon }/>
                    </Button>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent className={ classes.dialogContent }>
            <Grid container>

                <Grid container>
                    <Grid item xs={ 11 }>
                        <TextField
                            className={ classes.textField }
                            autoFocus
                            disabled={ submitted }
                            value={ categoryText }
                            onChange={ ( value ) => {
                                setCategoryText( value.target.value )
                            } }
                            onKeyDown={ ( e ) => {
                                if ( e.key === 'Enter' ) {
                                    if ( categoryText.trim().length > 200 ) handleInfoOpen()
                                    else {
                                        handleCategoryAddition( categoryText )
                                        setCategoryText( "" )
                                    }
                                }
                            } }
                            margin="dense"
                            id="name"
                            label="Translation Subjects (Press Enter to Add)"
                            type="text"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={ 1 }>
                        <Button onClick={ () => {
                            if ( categoryText.trim().length > 200 ) handleInfoOpen()
                            else {
                                handleCategoryAddition( categoryText )
                                setCategoryText( "" )
                            }
                        } } className={ classes.buttonCreate } color="primary">
                            <AddOutlined/>
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    { categories.map( category => {
                        return <Grid container className={ classes.gridRow }>
                            <Grid item xs={ 11 }>
                                <Typography>
                                    { category }
                                </Typography>
                            </Grid>
                            <Grid item xs={ 1 }>
                                <Button onClick={ () => {
                                    handleCategoryDeletion( category )
                                } } className={ classes.buttonDelete } color="primary">
                                    <RemoveOutlined/>
                                </Button>
                            </Grid>
                        </Grid>
                    } ) }
                </Grid>
            </Grid>

            {
                /*
                 <div>
                {categories.map((value) => (
                    <Chip
                        key={"productDetail-dialog-chip3-" + value}
                        disabled={submitted} onClick={() => handleCategoryDeletion(value)}
                        label={value}
                    />
                ))}
            </div>
                 */
            }

        </DialogContent>
        <DialogActions className={ classes.dialogAction }>
            <Button onClick={ () => {
                if ( categories.length > 0 ) {
                    let requestList = [];
                    categories.map( ( category ) => {
                        return requestList.push( {
                            "projectId": props.project.id,
                            "categoryName": category
                        } )
                    } )
                    setSubmitted( true )
                    const headers = {
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + Env.TOKEN
                    }
                    fetch( process.env.REACT_APP_URL + 'projects/categories', {
                        method: 'POST', headers: headers, body: JSON.stringify( requestList )
                    }, )
                        .then( response => {
                            if ( response.ok ) return response.json()
                            else throw new Error();
                        } )
                        .then( data => {
                            props.handleCategoryAddition( data )
                            setSubmitted( false );
                            setCategories( [] );
                            handleSuccessOpen();
                        } )
                        .catch( () => {
                            setSubmitted( false );
                            handleErrorOpen()
                        } )
                }
            } } className={ classes.buttonSave } color="primary">
                <Typography
                    className={ classes.buttonText }>
                    Save
                </Typography>
            </Button>

        </DialogActions>
        <Snackbar open={ errorOpen }
                  autoHideDuration={ 6000 }
                  onClose={ handleErrorClose }
        >
            <Alert onClose={ handleErrorClose }
                   severity={ "warning" }
            >
                Something went wrong, try it later.
            </Alert>
        </Snackbar>
        <Snackbar open={ successOpen }
                  autoHideDuration={ 1000 }
                  onClose={ handleSuccessClose }
        >
            <Alert onClose={ handleSuccessClose }
                   severity={ "success" }
            >
                Successfully added
            </Alert>
        </Snackbar>
        <Snackbar open={ infoOpen }
                  autoHideDuration={ 1000 }
                  elevation={ 6 }
                  onClose={ handleInfoClose }
        >
            <Alert onClose={ handleInfoClose }
                   severity={ "info" }
            >
                Duplicated category or length of it exceeds the bounds(200 characters)
            </Alert>
        </Snackbar>
        { submitted ? <LinearProgress className={ classes.progressBar } color={ 'secondary' }/> : <div/> }

    </Dialog>
}