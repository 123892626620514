import React from "react";
import {
    Paper,
    Typography,
    Divider,
    Grid,
    Button,
    Dialog, ButtonGroup, TextField, Select, Input, MenuItem, Snackbar, Tooltip, LinearProgress
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../constants/Colors";
import {
    AddBoxOutlined, ArchiveOutlined, AttachMoney,
    FileCopyOutlined,
    LinkOffOutlined,
    LinkOutlined, SendOutlined,
    Settings,
    Spellcheck, Visibility
} from "@material-ui/icons";
import TranslationOrderCreationDialog
    from "../projectScreens/projectDetailScreens/productDetailScreensDialogComponents/translationOrderCreationComponents/TranslationOrderCreationDialog";
import OrderControlDialog
    from "../projectScreens/projectDetailScreens/productDetailScreensDialogComponents/OrderControlDialog";
import Env from "../../constants/Env";
import { Alert } from "@material-ui/lab";
import TranslationOrderPreviewDialog from "./TranslationOrderPreviewDialog";

const useStyles = makeStyles( () => ( {
    card: {
        padding: 20,
        display: 'flex',
        height: '100%',
        borderRadius: 10,
        alignItems: "flex-start",
        color: Colors.PRIMARY_PINK_ALT,
        backgroundColor: Colors.PRIMARY_WHITE,
        '&:hover': {
            color: Colors.PRIMARY_PINK_UP,
            opacity: 1,
        },
    },
    title: {
        paddingLeft: 12,
        display: 'flex',
        fontSize: 20,
        fontFamily: Colors.fontOfProgram,
        fontWeight: "bold"
    },
    divider: {
        height: 2,
        borderRadius: 50,
        minWidth: 201,
        marginTop: 4,
        marginBottom: 4,
        background: Colors.gradient,
        width: '100%'
    },
    text: {
        textAlign: "center",
        color: Colors.PRIMARY_BLUE,
        display: 'flex',
        justifyContent: "center",
        fontSize: 16,
        fontFamily: Colors.fontOfProgram,
        fontWeight: "bold"
    },
    textSubmitted: {
        textAlign: "center",
        color: Colors.PRIMARY_GREEN_LIGHT,
        display: 'flex',
        fontSize: 16,
        fontFamily: Colors.fontOfProgram,
        justifyContent: "center",
        fontWeight: "bold"
    },
    textPassive: {
        textAlign: "center",
        color: Colors.PRIMARY_PINK_ALT,
        display: 'flex',
        justifyContent: "center",
        fontSize: 16,
        fontFamily: Colors.fontOfProgram,
        fontWeight: "bold"
    },
    cardBody: {
        paddingLeft: 16,
        paddingRight: 16,
        justifyContent: 'space-between',
        display: 'flex',
        padding: 10,
        color: Colors.PRIMARY_BLUE,
    },
    lineContainer: {
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        paddingTop: 12,
        color: Colors.PRIMARY_BLUE,
    },
    cardBodyChild: {
        paddingRight: 40,
        justifyContent: 'flex-end',
        display: 'flex',
        color: Colors.PRIMARY_BLUE,
    },
    notificationIcon: {
        color: Colors.PRIMARY_GREEN_LIGHT,
        fontSize: 24,
    },
    notificationIconActive: {
        color: Colors.PRIMARY_PINK_UP,
        fontSize: 24,
    },
    button: {
        padding: 16,
    },
    iconCircularButtonChecked: {
        padding: 4,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_BLUE,
        color: Colors.PRIMARY_WHITE,
        '&:hover': {
            color: Colors.PRIMARY_BLUE,
        }
    },
    iconCircularButtonRemove: {
        padding: 4,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        color: Colors.PRIMARY_WHITE,
        '&:hover': {
            color: Colors.PRIMARY_PINK_ALT,
        }
    },
    iconCircularButtonActive: {
        padding: 4,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_GREEN_LIGHT,
        color: Colors.PRIMARY_WHITE,
        '&:hover': {
            color: Colors.PRIMARY_GREEN_LIGHT,

        }
    },
    iconCircularButtonPassive: {
        padding: 4,
        borderRadius: 10,

    },
    iconPassive: {
        color: 'transparent',
    },
    icon: {},
    textName: {
        marginLeft: 24,
        display: "flex",
        fontFamily: Colors.fontOfProgram,
        fontSize: 20,
        fontWeight: "bold",
    },
    titleText: {
        textAlign: "center",
        fontFamily: Colors.fontOfProgram,
        justifyContent: "center",
        alignContent: 'center',
        color: Colors.PRIMARY_WHITE,
        alignItems: 'center',
        display: 'flex',
        fontSize: 16,
        fontWeight: "bold"
    },
    titleCard: {
        background: Colors.gradientSecond,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: Colors.PRIMARY_BLUE,
        alignContent: 'center',
        fontFamily: Colors.fontOfProgram,
        alignItems: 'center',
        paddingLeft: 16,
        paddingRight: 16,
        justifyContent: 'space-between',
        display: 'flex',
        height: 60,
        color: Colors.PRIMARY_WHITE,
    },
    grid: {
        display: "flex",
        justifyContent: "space-between",
    },
    gridButton: {
        color: Colors.PRIMARY_GREEN_LIGHT,
        backgroundColor: Colors.PRIMARY_WHITE,

        '&:hover': {
            color: Colors.PRIMARY_WHITE,
            backgroundColor: Colors.PRIMARY_GREEN_LIGHT,
        }
    },
    gridButtonIcon: {
        fontSize: 32,
    },
    gridEnd: {
        display: "flex",
        justifyContent: "flex-end"
    },
    buttonText: {
        fontWeight: "bold",
    },
    buttonGrid: {
        display: "flex",
        justifyContent: "center",
        borderRadius: 10,
        paddingTop: 0,
        margin: 0,
    },
    buttonGridTop: {
        display: "flex",
        justifyContent: "center",
        borderRadius: 10,
        padding: 16,
        margin: 0,
    },
    generalGrid: {
        padding: 32,
        display: "flex",
        justifyContent: "start",
    },
    buttonDialog: {
        width: 200,
        padding: 10,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_BLUE,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_BLUE,
            color: Colors.PRIMARY_WHITE,
        }
    },

    buttonDialogDelete: {
        width: 200,
        padding: 10,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_PINK_ALT,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_PINK_ALT,
            color: Colors.PRIMARY_WHITE,
        }
    },
    buttonLabel: {
        backgroundColor: Colors.PRIMARY_WHITE,
        borderRadius: 10,
        padding: 4,
    },
    dialogTitleText: {
        textAlign: "start",
        justifyContent: "start",
        alignContent: 'start',
        color: Colors.PRIMARY_BLUE,
        alignItems: 'start',
        display: 'flex',
        fontFamily: Colors.fontOfProgram,
        fontSize: 32,
        fontWeight: "bold"
    },
    centerGrid: {
        padding: 24,
        display: "flex",
        justifyContent: "center",
    },
    textField: {
        height: 32,
    },
    topText: {
        color: Colors.PRIMARY_GREY,
        fontWeight: "bold",
        fontFamily: Colors.fontOfProgram,
        fontSize: 16,
    },
    bottomText: {
        color: Colors.PRIMARY_PINK_ALT,
        fontWeight: "bold",
        fontSize: 20,
        fontFamily: Colors.fontOfProgram,
    },
    linkIcon: {
        fontSize: 32,
        fontFamily: Colors.fontOfProgram,
        color: Colors.PRIMARY_GREEN_LIGHT,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_GREEN_LIGHT,
            color: Colors.PRIMARY_WHITE,
        }
    },
    linkDeleteIcon: {
        fontFamily: Colors.fontOfProgram,
        fontSize: 32,
        color: Colors.PRIMARY_PINK_UP,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_PINK_UP,
            color: Colors.PRIMARY_WHITE,
        }
    },
    progressBar: {
        background: Colors.gradient,
        borderRadius: 10,
        height: 5,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
    },
} ) );

export default function TranslationOrderShortCut( props ) {
    const classes = useStyles();
    const [ open, setOpen ] = React.useState( false );
    const [ orderCreationDialogOpen, setOrderCreationDialogOpen ] = React.useState( false )
    const [ translationOrderSettings, setTranslationOrderSettings ] = React.useState( null )
    const [ successOpen, setSuccessOpen ] = React.useState( false )
    const [ errorOpen, setErrorOpen ] = React.useState( false )
    const [ submitted, setSubmitted ] = React.useState( false )
    const [ checkPage, setCheckPage ] = React.useState( null )
    const [ deletionCheck, setDeletionCheck ] = React.useState( false )
    const [ currentStatus, setCurrentStatus ] = React.useState( null )
    const [ archiveOrderPage, setArchiveOrderPage ] = React.useState( false )
    const [ date, setDate ] = React.useState( '' )
    const [ orderPreview, setOrderPreview ] = React.useState( null )
    const [ changedIds, setChangedIds ] = React.useState( [] )
    const [ constOrderList, setConstOrderList ] = React.useState( [] )
    const [ sendNotifyDialog, setSendNotifyDialog ] = React.useState( null )

    let orderList = [];

    if ( props.isCompanyPage ) {
        props.projects.forEach( project => {
            project.translationOrders.forEach( translationOrder => {
                if ( new Date( translationOrder.user.userExpirationTime ).getFullYear() > 2021 ) {
                    translationOrder.projectName = project.name;
                    if ( translationOrder.status === "COMPLETED" ) {
                        orderList = [ translationOrder, ...orderList ];
                    } else {
                        orderList.push( translationOrder );
                    }
                }

            } )
        } )
    } else {
        if ( props.isProjects ) {
            props.translationOrders.forEach( translationOrder => {
                translationOrder.projectName = props.project.name;
                if ( translationOrder.status === "COMPLETED" ) {
                    orderList = [ translationOrder, ...orderList ];
                } else {
                    orderList.push( translationOrder );
                }

            } )
        } else {
            orderList.push( ...props.translationOrders )
        }
    }

    if ( constOrderList.length !== orderList.length ) {
        setConstOrderList( [ ...orderList ] )
    }

    function checkWillBeOverride() {
        let check = false


        checkPage.texts.forEach( text => {
            if ( text.checked ) {
                props.project.categories.forEach( category => {
                    category.texts.forEach( categoryText => {
                        if ( categoryText.id === text.text.baseText.id ) {
                            categoryText.localizations.forEach( loc => {
                                if ( loc.projectLanguage !== null && loc.projectLanguage.language.language ===
                                    text.text.projectLanguage.language.language &&
                                    ( loc.text !== null && loc.text.length > 0 ) ) {
                                    check = true
                                }
                            } )
                        }
                    } )
                } )
            }
        } )


        return check
    }

    function handleChangeTrace( id ) {
        let check = false;

        changedIds.forEach( item => {
            if ( item === id ) {
                check = true
            }
        } )

        if ( !check ) {
            let temp = [ ...changedIds ]
            temp.push( id )
            setChangedIds( temp )
        }
    }

    function handleTransferredTextChange( textId, newText ) {
        let temp = []

        checkPage.texts.forEach( text => {
            if ( textId === text.id ) {
                text.transferredText = newText;
                handleChangeTrace( textId )
            }
            temp.push( text )

        } )
        let tempObj = { ...checkPage };
        tempObj.texts = temp
        setCheckPage( tempObj )
    }

    function handleLoadingTexts( texts ) {
        let tempObj = { ...checkPage }
        tempObj.texts = [ ...texts ]
        setCheckPage( tempObj )
    }

    function checkAll( status ) {
        let temp = []
        let idTemp = []
        checkPage.texts.forEach( text => {
            text.checked = status;
            idTemp.push( text.id )
            temp.push( text )
        } )
        setChangedIds( idTemp )
        let tempObj = { ...checkPage };
        tempObj.texts = temp
        setCheckPage( tempObj )
    }

    function handleCheckBoxChanges( textId, status ) {

        let temp = []
        checkPage.texts.forEach( text => {
            if ( textId === text.id ) {
                text.checked = status;
                handleChangeTrace( textId )
            }
            temp.push( text )
        } )

        let tempObj = { ...checkPage };
        tempObj.texts = temp
        setCheckPage( tempObj )
    }

    function handleOnSave() {
        const returnObject = []
        setSubmitted( true )

        changedIds.forEach( item => {
            checkPage.texts.forEach( text => {
                if ( text.id === item ) {
                    returnObject.push( {
                        "id": item,
                        "status": text.checked,
                        "transferredText": text.transferredText
                    } )
                }
            } )
        } )

        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }

        fetch( process.env.REACT_APP_URL + 'projects/translationTexts/check', {
            method: 'POST', headers: headers, body: JSON.stringify( returnObject )
        }, )
            .then( response => {
                if ( response.ok ) return response.json()
                else throw new Error();
            } )
            .then( () => {
                setSubmitted( false )
                setSuccessOpen( true )
            } )
            .catch( e => {
                setErrorOpen( true )
                setSubmitted( false )

                //console.log(e)
            } )

        setCheckPage( null )
    }

    function createLink( order ) {
        setSubmitted( true )

        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }
        fetch(
            process.env.REACT_APP_URL + 'projects/translationOrders/publicLink?orderId=' + order.id,
            {
                method: 'POST', headers: headers
            }, )
            .then( response => {
                if ( response.ok ) {
                    setSuccessOpen( true )
                    setSubmitted( false )
                    return response.text()
                } else throw new Error();
            } )
            .then( data => {
                order.publicLink = data
                setSubmitted( false )
            } )
            .catch( e => {
                console.log( e )
                setErrorOpen( true )
                setSubmitted( false )
                //console.log(e)
            } )
    }

    function deleteLink( order ) {
        setSubmitted( true )

        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }
        fetch(
            process.env.REACT_APP_URL + 'projects/translationOrders/publicLink?orderId=' + order.id,
            {
                method: 'DELETE', headers: headers
            }, )
            .then( response => {
                if ( response.ok ) {
                    setSuccessOpen( true )
                    setSubmitted( false )
                    return response.json()
                } else throw new Error();
            } )
            .then( data => {
                order.publicLink = null
                setSubmitted( false )
            } )
            .catch( e => {
                setErrorOpen( true )
                setSubmitted( false )
                //console.log(e)
            } )
    }

    function handleOnComplete() {
        setSubmitted( true )
        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }


        let tempList = []


        constOrderList.forEach( ord => {
            if ( ord.id !== checkPage.id ) {
                tempList.push( ord )
            } else {
                let tempObj = { ...ord }
                tempObj.status = "CHECKED"
                tempList.push( tempObj )
            }
        } )


        fetch( process.env.REACT_APP_URL + 'projects/translationTexts/complete?orderId=' + checkPage.id + "&userId=" +
            props.user.id, {
            method: 'POST', headers: headers,
        }, )
            .then( response => {
                if ( response.ok ) return response.json()
                else response.json()
                             .then( e => {
                                 //console.log(e.message)
                             } );
            } )
            .then( () => {
                setConstOrderList( tempList )
                setSuccessOpen( true )
                setSubmitted( false )
            } )
            .catch( e => {
                setErrorOpen( true )
                //console.log(e)
            } )

        setCheckPage( null )
    }

    const handleOrderCreationOpen = () => {
        setOrderCreationDialogOpen( true )
    }

    const handleOrderCreationClose = () => {
        setOrderCreationDialogOpen( false )
    }

    const updateStatus = ( orderId, status, date, userId ) => {
        setSubmitted( true )

        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }

        console.log(
            process.env.REACT_APP_URL + 'projects/translationOrders/update?id=' + orderId +
            "&status=" + status +
            "&userId=" + userId +
            "&date=" + date + ":00" )

        let tempList = []

        constOrderList.forEach( ord => {
            if ( ord.id !== orderId ) {
                tempList.push( ord )
            } else {
                let tempObj = { ...ord }
                tempObj.status = status
                tempObj.user.userExpirationTime = new Date( date ).getTime()
                tempList.push( tempObj )
            }
        } )

        setConstOrderList( tempList )

        fetch(
            process.env.REACT_APP_URL + 'projects/translationOrders/update?id=' + orderId +
            "&status=" + status +
            "&userId=" + userId +
            "&date=" + date + ":00",
            {
                method: 'POST', headers: headers
            }, )
            .then( response => {
                if ( response.ok ) return response.json()
                else throw new Error();
            } )
            .then( data => {
            } )
            .then( () => {
                setSuccessOpen( true )
                setSubmitted( false )

                setTranslationOrderSettings( null )
            } )
            .catch( e => {
                setErrorOpen( true )
                //console.log(e)
            } )

    }

    const deleteOrder = ( orderId ) => {
        setSubmitted( true )

        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Env.TOKEN
        }

        let tempList = []

        constOrderList.forEach( ord => {
            if ( ord.id !== orderId ) {
                tempList.push( ord )
            }
        } )


        fetch(
            process.env.REACT_APP_URL + 'projects/translationOrders?orderId=' + orderId,
            {
                method: 'DELETE', headers: headers
            }, )
            .then( response => {
                if ( response.ok ) return response.json()
                else throw new Error();
            } )
            .then( data => {
            } )
            .then( e => props.functionList.getProgram() )
            .then( () => {
                setConstOrderList( tempList )
                setSuccessOpen( true )
                setSubmitted( false )
                setDeletionCheck( false )
                setTranslationOrderSettings( null )
            } )
            .catch( e => {
                setErrorOpen( true )
                //console.log(e)
            } )

    }


    return <Paper
        onMouseOver={ () => {
            setOpen( true )
        } } onMouseOut={ () => {
        setOpen( false )
    } } elevation={ open ? 2 : 0 }
        className={ classes.card }
    >
        <Grid container spacing={ 1 }>
            <Grid container className={ classes.grid }>
                <Grid item xs={ 10 }>
                    <Typography className={ classes.title }>
                        Jobs Sent to Translators
                    </Typography>
                </Grid>
                <Grid item xs={ 2 } style={ { display: "flex", justifyContent: "flex-end" } }>
                    <Grid container style={ { display: "flex", justifyContent: "flex-end" } }>
                        <Grid item>
                            { props.isProjects
                                ?
                                <Tooltip title={ "Open Archive" }>
                                    <Button
                                        onClick={ () => {
                                            setArchiveOrderPage( true )
                                        } }
                                        className={ classes.gridButton }>
                                        <ArchiveOutlined className={ classes.gridButtonIcon }/>
                                    </Button>
                                </Tooltip>
                                :
                                <div/>
                            }
                        </Grid>
                        {
                            /*
                             <Grid item>
                            { props.isProjects
                                ?
                                <Tooltip title={ "Create a new job" }>
                                    <Button
                                        onClick={ () => {
                                            handleOrderCreationOpen()
                                        } }
                                        className={ classes.gridButton }>
                                        <AddBoxOutlined className={ classes.gridButtonIcon }/>
                                    </Button>
                                </Tooltip>
                                :
                                <div/>
                            }
                        </Grid>
                             */
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={ 12 }>
                <Divider className={ classes.divider }>
                </Divider>
            </Grid>
            <Grid container className={ classes.titleCard }>
                <Grid item xs>
                    <Typography className={ classes.titleText }>
                        Project
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography className={ classes.titleText }>
                        Name Surname
                    </Typography>
                </Grid>
                <Grid item xs={ 2 }>
                    <Typography className={ classes.titleText }>
                        Job
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography className={ classes.titleText }>
                        Target Language
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography className={ classes.titleText }>
                        Word Count
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography className={ classes.titleText }>
                        Permission
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography className={ classes.titleText }>
                        Deadline
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography className={ classes.titleText }>
                        Account Status
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography className={ classes.titleText }>
                        Job Status
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography className={ classes.titleText }>
                        { "" }
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography className={ classes.titleText }>
                        { "" }
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography className={ classes.titleText }>
                        { "" }
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography className={ classes.titleText }>
                        { "" }
                    </Typography>
                </Grid>

            </Grid>
            <Grid container className={ classes.cardBody }>
                { constOrderList.map( order => {
                    const isPassed = new Date( order.user.userExpirationTime ) < new Date( Date.now() );
                    const textClass = order.status === "COMPLETED" ? classes.textSubmitted :
                        isPassed ? classes.textPassive : classes.text;
                    if ( order.status === "PAID" || order.status === "PENDING" ) {
                        return null
                    } else {
                        return <Grid
                            key={ "translation-order-shortcut-order-" + order.id + "-" + props.isProjects +
                            props.isCompanyPage }
                            className={ classes.lineContainer } container>
                            <Grid item xs>
                                <Typography className={ textClass }>
                                    { order.projectName }
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography className={ textClass }>
                                    { ( order.user.name + " " + order.user.surname ).length > 10 ?
                                        order.user.name + " " + order.user.surname.charAt( 0 ) + "." :
                                        order.user.name + " " + order.user.surname }
                                </Typography>
                            </Grid>
                            <Grid item xs={ 2 }>
                                <Typography className={ textClass }>
                                    { order.orderTitle }
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography className={ textClass }>
                                    { order.language }
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography className={ textClass }>
                                    { order.textCount }
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography className={ textClass }>
                                    { order.type === "WRITE" ? "Editor" : "Viewer" }
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography className={ textClass }>
                                    { new Date( ( order.user.userExpirationTime ) ).toLocaleDateString() + " " }
                                    { new Date( ( order.user.userExpirationTime ) ).toLocaleTimeString() }
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography className={ textClass }>
                                    { isPassed ? "Not Active" : "Active" }
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography className={ textClass }>
                                    { order.status !== "PENDING" ?
                                        ( order.type === "WRITE" ? order.status.split( "_" )[ 0 ].charAt( 0 ) +
                                            order.status.split( "_" )[ 0 ].slice( 1 )
                                                                          .toLowerCase() + " " +
                                            ( ( order.status.split( "_" ).length > 1 ) ?
                                                order.status.split( "_" )[ 1 ].charAt( 0 ) +
                                                order.status.split( "_" )[ 1 ].slice( 1 )
                                                                              .toLowerCase() : "" ) :
                                            "-----------" ) : "Suspended" }
                                </Typography>
                            </Grid>
                            { order.type === "WRITE" && <Grid item xs className={ classes.gridEnd }>
                                <Tooltip title={ "Set Paid" }>
                                    <Button
                                        onClick={ () => {
                                            updateStatus( order.id, "PAID", order.user.userExpirationTime,
                                                order.user.id )
                                        } }
                                        className={ classes.iconCircularButtonActive }>
                                        <AttachMoney className={ classes.icon }/>
                                    </Button>
                                </Tooltip>
                            </Grid> }
                            <Grid item xs className={ classes.gridEnd }>
                                <Tooltip title={ "Job Settings" }>
                                    <Button
                                        onClick={ () => {
                                            let dt = new Date( order.user.userExpirationTime );
                                            //console.log(dt)
                                            setDate( dt.toISOString()
                                                       .split( ':' )[ 0 ] + ":" +
                                                dt.toISOString()
                                                  .split( ':' )[ 1 ]
                                            )

                                            if ( order.status === "IN_PROGRESS" ||
                                                order.status === "SAVED" ) {
                                                setCurrentStatus( "In Progress" )
                                            } else if ( order.status === "NOT_STARTED" ) {
                                                setCurrentStatus( "Not Started" )
                                            } else {
                                                setCurrentStatus( order.status !== "PENDING" ?
                                                    order.status.charAt( 0 ) + order.status.slice( 1 )
                                                                                    .toLowerCase() :
                                                    "Suspended" )
                                            }
                                            setTranslationOrderSettings( order )
                                        } }
                                        className={ classes.iconCircularButtonActive }>
                                        <Settings className={ classes.icon }/>
                                    </Button>
                                </Tooltip>
                            </Grid>
                            <Grid item xs className={ classes.gridEnd }>
                                { ( order.status === "COMPLETED" ) &&
                                order.type === "WRITE" ?
                                    <Tooltip
                                        title={ "This order was submitted and you haven't completed the checking it." }>
                                        <Button
                                            onClick={ () => setCheckPage( order ) }
                                            className={ classes.iconCircularButtonActive }>
                                            <Spellcheck className={ classes.icon }/>
                                        </Button>
                                    </Tooltip>
                                    :
                                    ( order.status === "CHECKED"
                                        || order.status === "PAID"
                                        || order.status === "PENDING"
                                    ) &&
                                    order.type === "WRITE" ?
                                        <Tooltip
                                            title={ "This order was checked or suspended. But you can still transfer texts." }>
                                            <Button
                                                onClick={ () => setCheckPage( order ) }
                                                className={ classes.iconCircularButtonChecked }>
                                                <Spellcheck className={ classes.icon }/>
                                            </Button>
                                        </Tooltip>
                                        :
                                        <Button

                                            onClick={ () => {
                                                setOrderPreview( order )
                                            } }
                                            className={ classes.iconCircularButtonActive }>
                                            <Visibility className={ classes.icon }/>
                                        </Button>
                                }
                            </Grid>
                            <Grid item xs>
                                <Typography className={ textClass }>
                                    <Tooltip
                                        title={ "Send email to remind job manuel!" }>
                                        <Button
                                            onClick={ () => {
                                                setSendNotifyDialog(order)
                                            } }
                                            className={ classes.iconCircularButtonActive }>
                                            <SendOutlined className={ classes.icon }/>
                                        </Button>
                                    </Tooltip>
                                </Typography>
                            </Grid>
                        </Grid>
                    }

                } ) }
            </Grid>
        </Grid>
        { props.isProjects ? <TranslationOrderCreationDialog
                project={ props.project }
                functionList={ props.functionList }
                program={ props.program }
                isOpen={ orderCreationDialogOpen }
                handleClose={ () => handleOrderCreationClose() }/>
            :
            <div></div>
        }
        {
            checkPage != null ?
                <OrderControlDialog
                    project={ props.project }
                    order={ checkPage }
                    handleLoadingTexts={ ( data ) => handleLoadingTexts( data ) }
                    checkWillBeOverride={ () => checkWillBeOverride() }
                    handleAllCheck={ ( status ) => checkAll( status ) }
                    handleOnComplete={ () => {
                        const returnObject = []

                        changedIds.forEach( item => {
                            checkPage.texts.forEach( text => {
                                if ( text.id === item ) {
                                    returnObject.push( {
                                        "id": item,
                                        "status": text.checked,
                                        "transferredText": text.transferredText
                                    } )
                                }
                            } )
                        } )

                        const headers = {
                            "Content-Type": "application/json",
                            'Authorization': 'Bearer ' + Env.TOKEN
                        }

                        fetch( process.env.REACT_APP_URL + 'projects/translationTexts/check', {
                            method: 'POST', headers: headers, body: JSON.stringify( returnObject )
                        }, )
                            .then( response => {
                                if ( response.ok ) return response.json()
                                else throw new Error();
                            } )
                            .then( data => {
                                //console.log(data)
                            } )
                            .then( () => {
                                handleOnComplete()
                                setSuccessOpen( true )
                            } )
                            .catch( e => {
                                setErrorOpen( true )
                            } )

                    } }
                    handleCheckChange={ ( textId, status ) => handleCheckBoxChanges( textId, status ) }
                    handleTransferredText={ ( textId, newText ) => handleTransferredTextChange( textId, newText ) }
                    handleOnSave={ () => handleOnSave() }
                    isOpen={ checkPage != null }
                    onClose={
                        () => {
                            checkAll( false )
                            setCheckPage( null )
                        }
                    }/>
                : null
        }
        {
            translationOrderSettings !== null ?
                <Dialog open={ true }
                        maxWidth={ "md" }
                        onClose={ () => {
                            setTranslationOrderSettings( null )
                            setDeletionCheck( false )
                        } }
                >
                    { deletionCheck ?
                        <Grid container className={ classes.generalGrid }>
                            <Grid item xs={ 12 }>
                                <Typography className={ classes.dialogTitleText }>
                                    Order Deletion
                                </Typography>
                            </Grid>
                            <Grid item xs={ 12 }>
                                <Typography className={ classes.topText }>
                                    { "Are you sure to delete this order?\n" }
                                </Typography>
                                <Typography className={ classes.topText }>
                                    { "After this step, you will not be able to recover data!" }
                                </Typography>
                            </Grid>
                            <Grid container className={ classes.buttonGridTop }>
                                <Paper elevation={ 10 } className={ classes.buttonGrid }>
                                    <ButtonGroup>
                                        <label className={ classes.buttonLabel }>
                                            <Button
                                                disabled={ !props.auth }
                                                onClick={ () => {
                                                    deleteOrder( translationOrderSettings.id )
                                                } }
                                                className={ classes.buttonDialogDelete }>
                                                <Typography className={ classes.buttonText }>
                                                    Delete
                                                </Typography>
                                            </Button>
                                        </label>
                                        <label className={ classes.buttonLabel }>
                                            <Button onClick={ () => {
                                                setDeletionCheck( false )
                                            } }
                                                    className={ classes.buttonDialog }>
                                                <Typography className={ classes.buttonText }>
                                                    Cancel
                                                </Typography>
                                            </Button>
                                        </label>
                                    </ButtonGroup>
                                </Paper>
                            </Grid>

                        </Grid>
                        :
                        <Grid container className={ classes.generalGrid }>
                            <Grid item xs={ 12 }>
                                <Typography className={ classes.dialogTitleText }>
                                    Order Settings
                                </Typography>
                            </Grid>
                            <Grid item xs={ 12 }>
                                <Typography className={ classes.topText }>
                                    { "You can change the order status and translation deadline.\n" }
                                </Typography>
                                <Typography className={ classes.topText }>
                                    { " Note that in order to make its status \"Editable\", you have to adjust the date accordingly." }
                                </Typography>
                            </Grid>
                            <Grid spacing={ 4 } container className={ classes.centerGrid }>
                                <Grid container className={ classes.centerGrid }>
                                    <Grid item xs={ 5 }>
                                        <Grid container>
                                            <Grid item xs={ 12 }>
                                                <Typography className={ classes.topText }>
                                                    Account Email
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={ 12 }>
                                                <Typography className={ classes.bottomText }>
                                                    { translationOrderSettings.user.email }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={ 1 }>
                                    </Grid>
                                    <Grid item xs={ 5 }>
                                        <Grid container>
                                            <Grid item xs={ 12 }>
                                                <Typography className={ classes.topText }>
                                                    Account Password
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={ 12 }>
                                                <Typography className={ classes.bottomText }>
                                                    { translationOrderSettings.user.emailConfirmationToken }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container className={ classes.centerGrid }>
                                    <Grid item xs={ 5 }>
                                        <Grid container>
                                            <Grid item xs={ 12 }>
                                                <Typography className={ classes.topText }>
                                                    Job Status
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={ 12 }>
                                                <Select
                                                    fullWidth
                                                    className={ classes.textField }
                                                    labelId="status-chip-label"
                                                    id="status-chip"
                                                    value={ currentStatus }
                                                    onChange={ ( val ) => {
                                                        setCurrentStatus( val.target.value )
                                                    } }
                                                    input={ <Input id="select-status-chip"/> }
                                                    MenuProps={ translationOrderSettings.type === "WRITE" ?
                                                        [ "Not Started", "In Progress", "Paid", "Checked", "Completed",
                                                            "Suspended" ] :
                                                        [ "Not Started", "In Progress", "Suspended" ] }
                                                    renderValue={ ( selected ) => {
                                                        return (
                                                            <div>
                                                                <Typography>
                                                                    { selected }
                                                                </Typography>
                                                            </div>
                                                        )
                                                    } }
                                                >
                                                    { ( translationOrderSettings.type === "WRITE" ?
                                                        [ "Not Started", "In Progress", "Paid", "Checked", "Completed",
                                                            "Suspended" ] :
                                                        [ "Not Started", "In Progress", "Suspended" ] ).map( ( el ) => {
                                                            if ( el === "In Progress" ) {
                                                                return <MenuItem key={ el } value={ el }>
                                                                    { el }
                                                                </MenuItem>
                                                            } else {
                                                                return <MenuItem key={ el } value={ el }>
                                                                    { el }
                                                                </MenuItem>
                                                            }
                                                        }
                                                    ) }
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={ 1 }>
                                    </Grid>
                                    <Grid item xs={ 5 }>
                                        <Grid container>
                                            <Grid item xs={ 12 }>
                                                <Typography className={ classes.topText }>
                                                    Deadline
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={ 12 }>
                                                <TextField
                                                    className={ classes.textField }
                                                    fullWidth
                                                    id="datetime-local-order"
                                                    label=""
                                                    type="datetime-local"
                                                    value={ date }
                                                    InputLabelProps={ {
                                                        shrink: true,
                                                    } }
                                                    onChange={ ( value ) => {
                                                        setDate( value.target.value.toString() )
                                                    } }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container className={ classes.centerGrid }>
                                    <Grid item xs={ 5 }>
                                        <Grid container>
                                            <Grid item xs={ 12 }>
                                                <Typography className={ classes.topText }>
                                                    Public Link
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={ 12 }>
                                                <Typography className={ classes.bottomText }>
                                                    { ( translationOrderSettings.publicLink !== null &&
                                                        translationOrderSettings.publicLink !== undefined ) ?
                                                        "https://localifyapp.com/order/" +
                                                        translationOrderSettings.publicLink :
                                                        "" }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={ 1 }>
                                    </Grid>
                                    <Grid item xs={ 5 }>
                                        <Grid container>
                                            <Grid item xs={ 12 }>
                                                { ( translationOrderSettings.publicLink !== null &&
                                                    translationOrderSettings.publicLink !== undefined ) ?
                                                    <Typography className={ classes.topText }>
                                                        &nbsp;&nbsp;&nbsp;Copy &nbsp;&nbsp;&nbsp;&nbsp; Delete
                                                    </Typography> :
                                                    <Typography className={ classes.topText }>
                                                        Create Link
                                                    </Typography> }
                                            </Grid>
                                            <Grid item xs={ 12 }>
                                                { ( translationOrderSettings.publicLink !== null &&
                                                    translationOrderSettings.publicLink !== undefined ) ?
                                                    <ButtonGroup>
                                                        <label className={ classes.buttonLabel }>
                                                            <Button
                                                                onClick={ () => {
                                                                    navigator.clipboard.writeText(
                                                                        "https://localifyapp.com/order/" +
                                                                        translationOrderSettings.publicLink )
                                                                } }
                                                                className={ classes.linkIcon }>
                                                                <FileCopyOutlined/>
                                                            </Button>
                                                        </label>
                                                        <label className={ classes.buttonLabel }>
                                                            <Button
                                                                onClick={ () => {
                                                                    translationOrderSettings.publicLink = null
                                                                    deleteLink( translationOrderSettings )
                                                                } }
                                                                className={ classes.linkDeleteIcon }>
                                                                <LinkOffOutlined/>
                                                            </Button>
                                                        </label>

                                                    </ButtonGroup>
                                                    :
                                                    <label className={ classes.buttonLabel }>
                                                        <Button
                                                            onClick={ () => {
                                                                createLink( translationOrderSettings )
                                                            } }
                                                            className={ classes.linkIcon }>
                                                            <LinkOutlined/>
                                                        </Button>
                                                    </label>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container className={ classes.buttonGridTop }>
                                <Paper elevation={ 10 } className={ classes.buttonGrid }>
                                    <ButtonGroup>

                                        <label className={ classes.buttonLabel }>
                                            <Button
                                                disabled={ !props.auth }
                                                onClick={ () => {
                                                    if ( currentStatus === "In Progress" ) {
                                                        updateStatus( translationOrderSettings.id, "IN_PROGRESS", date,
                                                            translationOrderSettings.user.id )
                                                    } else if ( currentStatus === "Paid" ) {
                                                        updateStatus( translationOrderSettings.id, "PAID", date,
                                                            translationOrderSettings.user.id )
                                                    } else if ( currentStatus === "Checked" ) {
                                                        updateStatus( translationOrderSettings.id, "CHECKED", date,
                                                            translationOrderSettings.user.id )
                                                    } else if ( currentStatus === "Completed" ) {
                                                        updateStatus( translationOrderSettings.id, "COMPLETED", date,
                                                            translationOrderSettings.user.id )
                                                    } else if ( currentStatus === "Suspended" ) {
                                                        updateStatus( translationOrderSettings.id, "PENDING", date,
                                                            translationOrderSettings.user.id )
                                                    } else if ( currentStatus === "Not Started" ) {
                                                        updateStatus( translationOrderSettings.id, "NOT_STARTED", date,
                                                            translationOrderSettings.user.id )
                                                    }
                                                } }
                                                className={ classes.buttonDialog }>
                                                <Typography className={ classes.buttonText }>
                                                    Save
                                                </Typography>
                                            </Button>
                                        </label>
                                        <label className={ classes.buttonLabel }>
                                            <Button
                                                disabled={ !props.auth }
                                                onClick={ () => {
                                                    setDeletionCheck( true )
                                                } }
                                                className={ classes.buttonDialogDelete }>
                                                <Typography className={ classes.buttonText }>
                                                    Delete
                                                </Typography>
                                            </Button>
                                        </label>
                                        <label className={ classes.buttonLabel }>
                                            <Button onClick={ () => {
                                                setTranslationOrderSettings( null )
                                            } }
                                                    className={ classes.buttonDialog }>
                                                <Typography className={ classes.buttonText }>
                                                    Cancel
                                                </Typography>
                                            </Button>
                                        </label>
                                    </ButtonGroup>
                                </Paper>
                            </Grid>
                        </Grid> }
                    { submitted ? <LinearProgress className={ classes.progressBar } color={ 'secondary' }/> : <div/> }
                </Dialog>
                : null
        }
        <Dialog open={ archiveOrderPage }
                maxWidth={ "lg" }
                onClose={ () => {
                    setArchiveOrderPage( false )
                } }
        >
            <Grid container className={ classes.generalGrid }>
                <Grid item xs={ 12 }>
                    <Typography className={ classes.dialogTitleText }>
                        Archive
                    </Typography>
                </Grid>
                <Grid item xs={ 12 }>
                    <Typography className={ classes.topText }>
                        { "You can view paid and suspended orders.\n" }
                    </Typography>
                </Grid>
                <Grid container className={ classes.titleCard }>
                    <Grid item xs>
                        <Typography className={ classes.titleText }>
                            Name Surname
                        </Typography>
                    </Grid>
                    <Grid item xs={ 2 }>
                        <Typography className={ classes.titleText }>
                            Job
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography className={ classes.titleText }>
                            Target Language
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography className={ classes.titleText }>
                            Word Count
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography className={ classes.titleText }>
                            Type
                        </Typography>
                    </Grid>
                    <Grid item xs={ 2 }>
                        <Typography className={ classes.titleText }>
                            Deadline
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography className={ classes.titleText }>
                            Account Status
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography className={ classes.titleText }>
                            Job Status
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography className={ classes.titleText }>
                            { "" }
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography className={ classes.titleText }>
                            { "" }
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography className={ classes.titleText }>
                            { "" }
                        </Typography>
                    </Grid>

                </Grid>
                <Grid container className={ classes.cardBody }>
                    { constOrderList.map( order => {
                        const isPassed = new Date( order.user.userExpirationTime ) < new Date( Date.now() );
                        const textClass = order.status === "COMPLETED" ? classes.textSubmitted :
                            isPassed ? classes.textPassive : classes.text;
                        if ( order.status === "PAID" || order.status === "PENDING" ) {
                            return <Grid
                                key={ "translation-order-shortcut-order-archive" + order.id + "-" + props.isProjects +
                                props.isCompanyPage }
                                className={ classes.lineContainer } container>
                                <Grid item xs>
                                    <Typography className={ textClass }>
                                        { ( order.user.name + " " + order.user.surname ).length > 10 ?
                                            order.user.name + " " + order.user.surname.charAt( 0 ) + "." :
                                            order.user.name + " " + order.user.surname }
                                    </Typography>
                                </Grid>
                                <Grid item xs={ 2 }>
                                    <Typography className={ textClass }>
                                        { order.orderTitle }
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography className={ textClass }>
                                        { order.language }
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography className={ textClass }>
                                        { order.textCount }
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography className={ textClass }>
                                        { order.type === "WRITE" ? "Editor" : "Viewer" }
                                    </Typography>
                                </Grid>
                                <Grid item xs={ 2 }>
                                    <Typography className={ textClass }>
                                        { new Date( ( order.user.userExpirationTime ) ).toLocaleDateString() + " " }
                                        { new Date( ( order.user.userExpirationTime ) ).toLocaleTimeString() }
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography className={ textClass }>
                                        { isPassed ? "Not Active" : "Active" }
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography className={ textClass }>
                                        { order.status !== "PENDING" ?
                                            ( order.type === "WRITE" ? order.status.split( "_" )[ 0 ].charAt( 0 ) +
                                                order.status.split( "_" )[ 0 ].slice( 1 )
                                                                              .toLowerCase() + " " +
                                                ( ( order.status.split( "_" ).length > 1 ) ?
                                                    order.status.split( "_" )[ 1 ].charAt( 0 ) +
                                                    order.status.split( "_" )[ 1 ].slice( 1 )
                                                                                  .toLowerCase() : "" ) :
                                                "-----------" ) : "Suspended" }
                                    </Typography>
                                </Grid>
                                <Grid item xs className={ classes.gridEnd }>
                                    <Tooltip title={ "Set Unpaid" }>
                                        <Button
                                            onClick={ () => {
                                                updateStatus( order.id, "CHECKED", order.user.userExpirationTime,
                                                    order.user.id )
                                            } }
                                            className={ classes.iconCircularButtonRemove }>
                                            <AttachMoney className={ classes.icon }/>
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs className={ classes.gridEnd }>
                                    <Tooltip title={ "Job Settings" }>
                                        <Button
                                            onClick={ () => {
                                                let dt = new Date( order.user.userExpirationTime );
                                                setDate( dt.toISOString()
                                                           .split( ':' )[ 0 ] + ":" +
                                                    dt.toISOString()
                                                      .split( ':' )[ 1 ]
                                                )

                                                if ( order.status === "NOT_STARTED" || order.status === "IN_PROGRESS" ||
                                                    order.status === "SAVED" ) {
                                                    setCurrentStatus( "In Progress" )
                                                } else {
                                                    setCurrentStatus( order.status !== "PENDING" ?
                                                        order.status.charAt( 0 ) + order.status.slice( 1 )
                                                                                        .toLowerCase() :
                                                        "Suspended" )
                                                }
                                                setTranslationOrderSettings( order )
                                            } }
                                            className={ classes.iconCircularButtonActive }>
                                            <Settings className={ classes.icon }/>
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs className={ classes.gridEnd }>
                                    { ( order.status === "COMPLETED" ) &&
                                    order.type === "WRITE" ?
                                        <Tooltip
                                            title={ "This order was submitted and you haven't completed the checking it." }>
                                            <Button
                                                onClick={ () => setCheckPage( order ) }
                                                className={ classes.iconCircularButtonActive }>
                                                <Spellcheck className={ classes.icon }/>
                                            </Button>
                                        </Tooltip>
                                        :
                                        ( order.status === "CHECKED"
                                            || order.status === "PAID"
                                            || order.status === "PENDING"
                                        ) &&
                                        order.type === "WRITE" ?
                                            <Tooltip
                                                title={ "This order was checked or pending. But you can still transfer texts." }>
                                                <Button
                                                    onClick={ () => setCheckPage( order ) }
                                                    className={ classes.iconCircularButtonChecked }>
                                                    <Spellcheck className={ classes.icon }/>
                                                </Button>
                                            </Tooltip>
                                            :
                                            <Button
                                                disabled={ true }
                                                className={ classes.iconCircularButtonPassive }>
                                                <Spellcheck className={ classes.iconPassive }/>
                                            </Button>
                                    }
                                </Grid>
                            </Grid>
                        } else {
                            return null
                        }

                    } ) }
                </Grid>
                <Grid container className={ classes.buttonGridTop }>
                    <Paper elevation={ 10 } className={ classes.buttonGrid }>
                        <ButtonGroup>
                            <label className={ classes.buttonLabel }>
                                <Button onClick={ () => {
                                    setArchiveOrderPage( false )
                                } }
                                        className={ classes.buttonDialog }>
                                    <Typography className={ classes.buttonText }>
                                        Close
                                    </Typography>
                                </Button>
                            </label>
                        </ButtonGroup>
                    </Paper>
                </Grid>
            </Grid>
            {
                submitted ?
                    <LinearProgress className={ classes.progressBar } color={ 'secondary' }/>
                    :
                    <div/>
            }
        </Dialog>
        {sendNotifyDialog && <Dialog fullWidth maxWidth={"sm"} open={ sendNotifyDialog !== null } onClose={ () => setSendNotifyDialog( null ) }>
            <Grid container className={ classes.grid } style={ { padding: "20px", fontSize: 24, fontWeight: "bold",display:"flex",justifyContent:"center" } }>
                Send Email
            </Grid>

            <Grid container className={ classes.grid } style={ {display:"flex",justifyContent:"center" } }>
                <Typography>
                    {"Email will be sent to : " + sendNotifyDialog.user.email}
                </Typography>
            </Grid>
            <Grid container className={ classes.grid } style={ { padding: "20px",display:"flex",justifyContent:"center" } }>
                <Grid item xs={4}  style={ {display:"flex",justifyContent:"center" } }>
                    <Button style={ {
                        borderRadius: "10px",
                        border: "solid",
                        width:"160px",
                        borderColor: Colors.PRIMARY_PINK_ALT,
                        background:Colors.PRIMARY_PINK_ALT,
                        color:Colors.PRIMARY_WHITE

                    } } onClick={ () => {
                        setSendNotifyDialog(null)
                    } }>
                        Cancel
                    </Button>
                </Grid>

                <Grid item xs={4} style={ {display:"flex",justifyContent:"center" } }>
                    <Button style={ {
                        borderRadius: "10px",
                        border: "solid",
                        width:"160px",

                        borderColor: Colors.PRIMARY_BLUE,
                        background:Colors.PRIMARY_BLUE,
                        color:Colors.PRIMARY_WHITE
                    } } onClick={ () => {
                        setErrorOpen( false )
                        setSuccessOpen( true )
                        let email =  sendNotifyDialog.user.email
                        let id =  sendNotifyDialog.id
                        console.log(sendNotifyDialog)
                        setSendNotifyDialog(null)
                        const headers = {
                            "Content-Type": "application/json",
                            'Authorization': 'Bearer ' + Env.TOKEN
                        }
                        console.log(sendNotifyDialog.user)
                        fetch( process.env.REACT_APP_URL +
                            "projects/translationOrders/notify?translatorEmail=" +
                            email + "&orderId=" + id + "&senderId=" +
                            props.program.user.id,
                            { method: "POST", headers: headers } )
                            .then( response => {
                                if ( response.ok && response.status === 200 ) {
                                    response.json()
                                            .then( ( data ) => {
                                                console.log( data )
                                                if ( data.operationResult !== "ERROR" ) {

                                                } else {
                                                    setErrorOpen( true )
                                                }
                                            } )
                                } else {
                                    setErrorOpen( true )
                                }

                            } )
                            .catch( e => {

                            } )
                    } }>
                        Send
                    </Button>
                </Grid>
            </Grid>
        </Dialog>}
        <TranslationOrderPreviewDialog isOpen={ orderPreview != null } order={ orderPreview }
                                       onClose={ () => setOrderPreview( null ) }/>

        <Snackbar open={ errorOpen }
                  autoHideDuration={ 6000 }
                  onClose={ () => setErrorOpen( false ) }
        >
            <Alert onClose={ () => setErrorOpen( false ) }
                   severity={ "warning" }
            >
                Something went wrong, try it later.
            </Alert>
        </Snackbar>
        <Snackbar open={ successOpen }
                  autoHideDuration={ 1000 }
                  onClose={ () => setSuccessOpen( false ) }
        >
            <Alert onClose={ () => setSuccessOpen( false ) }
                   severity={ "success" }
            >
                Operations are made successfully.
            </Alert>
        </Snackbar>

    </Paper>
}