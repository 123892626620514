import { CircularProgress, Grid, Tooltip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Env from "../../constants/Env";
import { CancelOutlined } from "@material-ui/icons";
import React from "react";

export default function RoleRestrictionLine( props ) {
    const [ deletionSubmitted, setDeletionSubmitted ] = React.useState( false )

    return <Grid
        key={ "productDetail-dialog-roleRestrictionList-role-" + props.rest.id }
        container className={ props.classes.gridSpaceBtw }>
        <Grid item>
            <Typography className={ props.classes.userText }>
                { props.rest.category.categoryName }
            </Typography>
        </Grid>
        <Grid item>
            { deletionSubmitted ? <CircularProgress/> : <Tooltip title={ "Remove translation subject under this work" +
            " title." }>
                <Button onClick={
                    () => {
                        setDeletionSubmitted( true )
                        const headers = {
                            "Content-Type": "application/json",
                            'Authorization': 'Bearer ' + Env.TOKEN
                        }

                        let returnObj = []
                        returnObj.push( props.rest.id )
                        fetch( process.env.REACT_APP_URL + 'projects/roleRestrictions', {
                            method: 'DELETE',
                            headers: headers,
                            body: JSON.stringify( returnObj )
                        }, )
                            .then( response => {
                                if ( response.ok ) {
                                    return response.json()
                                }
                                throw new Error()
                            } )
                            .then( data => {
                                setDeletionSubmitted( false )
                                props.handleRoleRestrictionDeletion( props.rest.id )
                            } )
                            .catch( e => {

                            } )
                    }
                }>
                    <CancelOutlined/>
                </Button>
            </Tooltip> }
        </Grid>
    </Grid>
}