import React from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import Colors from "../../../../constants/Colors";
import {CloudDone} from "@material-ui/icons";
import Program from "../../../../constants/Program";

export default function AutoSaveComponent(props) {

    if (props.autoSave) {
        return <CircularProgress style={{margin: 20, color: Colors.PRIMARY_GREEN_LIGHT}}
                                 size={20}/>
    } else {
        return <div>
            <CloudDone style={{
                paddingTop: 20,
                fontSize: 52,
                color: Colors.PRIMARY_GREEN_LIGHT
            }}/>
        </div>

    }
}