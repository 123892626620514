import {Button, Grid, Tooltip, Typography} from "@material-ui/core";
import {AddAlarm} from "@material-ui/icons";
import React from "react";

export default function PermissionLine(props) {
    const isPassed = new Date(props.permission.expiration) < new Date(Date.now());
    const textClass = isPassed ? props.classes.textPassive : props.classes.text;
    const iconClass = isPassed ? props.classes.iconPassive : props.classes.icon;

    return <Grid
        key={"permission-shortcut-order-" + props.permission.id + "-" + props.isProjectPage +
        props.isCompanyPage}
        className={props.classes.lineContainer} container>
        <Grid key={props.permission.user.name} item xs>
            <Typography className={textClass}>
                {props.permission.user.name}
            </Typography>
        </Grid>
        <Grid key={props.permission.projectName} item xs>
            <Typography className={textClass}>
                {props.permission.project.name}
            </Typography>
        </Grid>
        <Grid key={props.permission.permissionRole} item xs>
            <Typography className={textClass}>
                {props.permission.category.categoryName}
            </Typography>
        </Grid>
        <Grid key={props.permission.language.language} item xs>
            <Typography className={textClass}>
                {props.permission.language.language}
            </Typography>
        </Grid>
        <Grid key={props.permission.id + "1"} item xs={2}>
            <Typography className={textClass}>
                {new Date((props.permission.expiration)).toLocaleDateString() + " "}
                {new Date((props.permission.expiration)).toLocaleTimeString()}
            </Typography>
        </Grid>
        <Grid key={props.permission.expiration + isPassed} item xs>
            {isPassed ?
                <Typography className={textClass}>
                    Not Active
                </Typography> :
                <Typography className={textClass}>
                    Active
                </Typography>
            }
        </Grid>
        <Grid key={props.permission.expiration + isPassed + props.permission.expiration} item xs>
            {props.permission.user.id !== props.user.id ?
                <Tooltip title={"Extend/Shorten the Permission Expiration"}>
                    <Button
                        onClick={() => {
                            let dt = new Date(props.permission.expiration);

                            props.setDate(dt.toISOString().split(":")[0] + ":" +
                                dt.toISOString().split(":")[1])
                            props.setPermissionDateDialog(props.permission)
                        }}
                        className={props.classes.button}>
                        <AddAlarm className={iconClass}/>
                    </Button>
                </Tooltip>
                :
                null}
        </Grid>
    </Grid>
}