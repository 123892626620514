import React from "react";
import { AppBar, CircularProgress, Grid, Tab, Tabs } from "@material-ui/core";
import TabsPanel from "./TabsPanel";
import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../../../constants/Colors";
import AllLanguagesPanel from "./AllLanguagesPanel";
import Env from "../../../../constants/Env";

const useStyles = makeStyles( {
    appBar: {},
    innerTab: {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        background: Colors.gradient,
        color: Colors.PRIMARY_WHITE,
        "&.Mui-selected": {}
    },
    indicator: {
        background: "transparent",
        width: '100%',
        display: "flex",
        justifyContent: 'center',
    },
    tab: {
        paddingLeft: 30,
        paddingRight: 30,
        "&.Mui-selected": {
            background: Colors.PRIMARY_WHITE,
            color: Colors.PRIMARY_PINK_UP,
        }
    },
    loaderGrid: {
        paddingTop: 300,
        height: 800,
        maxHeight: 800,
    },
    div: {
        flexGrow: 1,
    }
} );

export default function InnerTabs( props ) {
    const category = props.categories[ props.categoryIndex ]
    const classes = useStyles()
    let languageList = [ ...props.project.projectLanguages, props.project.base ]
    let hashmap = []

    if(languageList !== null){
        languageList.forEach( lan => {
            hashmap[ lan.language.language === undefined ? lan.language : lan.language.language ] = 0
        } )
    }


    props.program.user.roles.forEach( role => {
        category.texts.forEach( text => {
            text.localizations.forEach( localization => {
                    if(localization.confirmations !== null){
                        localization.confirmations.forEach( confirmation => {
                            if ( confirmation.statusInfo === "CHANGE"
                                && confirmation.status !== "APPROVED"
                                && confirmation.projectRole.id === role.projectRole.id ) {
                                hashmap[ localization.projectLanguage !== null ?
                                    localization.projectLanguage.language.language.language :
                                    props.project.base.language.language ] =
                                    hashmap[ localization.projectLanguage !== null ?
                                        localization.projectLanguage.language.language.language :
                                        props.project.base.language.language ] + 1
                            }
                        } )
                    }
                }
            )
        } )

    } )

    return <div className={ classes.div } style={ { width: ( props.open ? '73vw' : '84vw' ) } }>
        <AppBar color={ 'transparent' } elevation={ 0 } position="static">
            <Tabs
                id={ 'inner' + props.innerTab }
                key={ 'inner' + props.innerTab }
                classes={ {
                    indicator: classes.indicator
                } }
                className={ classes.innerTab }
                value={ props.languageIndex }
                onChange={ ( event, index ) => props.handleLanguageIndex( index ) }
                indicatorColor="secondary"
                variant={ "scrollable" }
                scrollButtons={ "auto" }
                aria-label={ "scrollable auto outer tabs" }
            >
                <Tab
                    key={ "inner-tab-all" }
                    className={ classes.tab }
                    label={ "All" }
                    aria-controls={ `scrollable-auto-tabpanel-all` }
                    id={ `scrollable-auto-tab-all` }/>
                { languageList.map( ( language, index ) => {
                    return <Tab
                        key={ "inner-tab-" + index }
                        disabled={ props.allTableLoading }
                        className={ classes.tab }
                        label={
                            ( language.language.language === undefined ? language.language :
                                language.language.language )
                            + ( hashmap[ language.language.language === undefined ? language.language :
                                language.language.language ] === 0
                                ? "" :
                                " (" + hashmap[ language.language.language === undefined ? language.language :
                                    language.language.language ] + ") " )
                        }
                        aria-controls={ `scrollable-auto-tabpanel-${ index }` }
                        id={ `scrollable-auto-tab-${ index }` }/>
                } ) }
            </Tabs>
        </AppBar>
        { !props.submitted ? ( props.languageIndex !== 0
                ?
                <TabsPanel
                    setProjectTrace={ ( val ) => props.setProjectTrace( val ) }
                    table={ props.table }
                    auth={ props.auth }
                    categoryIndex={ props.categoryIndex }
                    languageIndex={ props.languageIndex - 1 }
                    program={ props.program }
                    functionList={ props.functionList }
                    project={ props.project }
                    handleLocalizationChange={ props.handleLocalizationChange }
                    handleExplanationChange={ props.handleExplanationChange }
                    handleImageChange={ props.handleImageChange }
                    handleAndroidKeyChange={ props.handleAndroidKeyChange }
                    handleTextChange={ props.handleTextChange }
                    textChanges={ props.textChanges }
                    localizationChanges={ props.localizationChanges }
                    handleSaveChanges={ props.handleSaveChanges }
                    confirmChanges={ props.confirmChanges }
                    handleCheck={ props.handleCheck }
                    checkSelected={ props.checkSelected }
                    selectUnconfirmed={ props.selectUnconfirmed }
                    selectAll={ props.selectAll }
                    confirmChangesWithIdList={ props.confirmChangesWithIdList }
                    selectedLocalizations={ props.selectedLocalizations }
                    setSelectedLocalizationsEmpty={ props.setSelectedLocalizationsEmpty }
                    allTableReplaceText={ props.allTableReplaceText }
                />
                :
                props.allTableLoading ? <Grid container style={ {
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    padding: 200,
                } }><CircularProgress/></Grid> : <AllLanguagesPanel
                    setProjectTrace={ ( val ) => props.setProjectTrace( val ) }
                    handleAllTableTextRemove={ props.handleAllTableTextRemove }
                    handleAllTableTextAddition={ props.handleAllTableTextAddition }
                    handleAllTableTextDeletion={ props.handleAllTableTextDeletion }
                    handleAllTableTextChange={ props.handleAllTableTextChange }
                    handleAllTableTextSave={ props.handleAllTableTextSave }
                    handleAllTableTextAutoFill={ props.handleAllTableTextAutoFill }
                    handleAllTableAndroidKeySave={ ( textId, androidKey ) => props.handleAllTableAndroidKeySave( textId,
                        androidKey ) }
                    handleAllTableImageChange={ props.handleAllTableImageChange }
                    handleAllTableTextListDeletion={ props.handleAllTableTextListDeletion }
                    autoSave={ props.autoSave }
                    open={ props.open }
                    table={ props.table }
                    allTableAddedTexts={ props.allTableAddedTexts }
                    auth={ props.auth }
                    handleAllTableSetProjectAllTable={ props.handleAllTableSetProjectAllTable }
                    handleAllTableExplanationChange={ props.handleAllTableExplanationChange }
                    handleAllTableExplanationSave={ props.handleAllTableExplanationSave }
                    handleAllTableConfirmation={ props.handleAllTableConfirmation }
                    categoryIndex={ props.categoryIndex }
                    languageIndex={ props.languageIndex - 1 }
                    program={ props.program }
                    handleAllTableLocalizationSave={ props.handleAllTableLocalizationSave }
                    functionList={ props.functionList }
                    handleAllTableLocalizationChange={ props.handleAllTableLocalizationChange }
                    project={ props.project }
                    handleAllTableAddedTextChange={ props.handleAllTableAddedTextChange }
                    handleAllTableBaseSave={ props.handleAllTableBaseSave }
                    handleAllTableBaseChange={ props.handleAllTableBaseChange }
                    handleLocalizationChange={ props.handleLocalizationChange }
                    handleExplanationChange={ props.handleExplanationChange }
                    handleImageChange={ props.handleImageChange }
                    handleAndroidKeyChange={ props.handleAndroidKeyChange }
                    handleTextChange={ props.handleTextChange }
                    textChanges={ props.textChanges }
                    allTable={ props.allTable }
                    localizationChanges={ props.localizationChanges }
                    handleSaveChanges={ props.handleSaveChanges }
                    confirmChanges={ props.confirmChanges }
                    handleCheck={ props.handleCheck }
                    checkSelected={ props.checkSelected }
                    selectUnconfirmed={ props.selectUnconfirmed }
                    selectAll={ props.selectAll }
                    allTableReplaceText={ props.allTableReplaceText }
                    confirmChangesWithIdList={ props.confirmChangesWithIdList }
                    selectedLocalizations={ props.selectedLocalizations }
                    setSelectedLocalizationsEmpty={ props.setSelectedLocalizationsEmpty }
                /> ) :
            <center container className={ classes.loaderGrid }>
                <CircularProgress size={ 100 }/>
            </center> }
    </div>

}