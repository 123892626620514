import React from "react";
import {
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid, LinearProgress,
    makeStyles,
    Switch, Tooltip
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Colors from "../../../../constants/Colors";
import {
    Add, CancelOutlined,
    PersonAdd,
} from "@material-ui/icons";
import PersonAdditionDialog from "./PersonAdditionDialog";
import Env from "../../../../constants/Env";
import RoleRestrictionAdditionDialog from "./RoleRestrictionAdditionDialog";
import RoleRestrictionLine from "../../../shortcutComponents/RoleRestrictionLine";

const useStyles = makeStyles( () => ( {
    button: {
        height: 35,
        width: 200,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        '&:hover': {
            backgroundColor: Colors.PRIMARY_PINK_UP,

        }
    },
    progressBar: {
        background: Colors.gradient,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        height: 10,
    },
    buttonDone: {
        height: 35,
        width: 200,
        backgroundColor: Colors.PRIMARY_BLUE,
        '&:hover': {
            backgroundColor: Colors.PRIMARY_GREEN_LIGHT,
        }
    },
    buttonText: {
        fontWeight: "bold",
        fontSize: 16,
        color: Colors.PRIMARY_WHITE,
    },
    buttonGroup: {
        padding: 20,
        display: "flex",
        justifyContent: "space-evenly",
    },
    title: {
        color: Colors.PRIMARY_PINK_UP,
        fontWeight: "bold",
        fontSize: 32,
        paddingBottom: 10,
        paddingTop: 10,

    },
    grid: {
        display: "flex",
        justifyContent: "center",
    },
    subTitle: {
        color: Colors.PRIMARY_BLUE,
        fontSize: 16,

    },
    subTitleChild: {
        color: Colors.PRIMARY_PINK_ALT,
        fontSize: 20,
        fontWeight: "bold",
    },
    gridSpaceBtw: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 4,
    },
    divider: {
        marginBottom: 20,
        marginTop: 20,
        borderColor: Colors.PRIMARY_GREEN,
        height: 1,
        width: '100%'
    },
    userText: {
        color: Colors.PRIMARY_BLACK,
        fontSize: 16,
        fontWeight: "bold",
    },
    tableHead: {
        paddingTop: 8,
        paddingBottom: 8,
        background: Colors.gradient,
        borderRadius: 10,
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: 20,
        paddingRight: 20,
    },
    tableHeadText: {
        color: Colors.PRIMARY_WHITE,
        fontSize: 16,
        fontWeight: "bold",
    },
    buttonWoPadding: {
        padding: 10,
        margin: 0,
        color: Colors.PRIMARY_PINK_ALT,
        "&:hover": {
            color: Colors.PRIMARY_PINK_UP,

        }
    },
    iconEnd: {
        display: "flex",
        justifyContent: "flex-end",
    }
} ) )

export default function RoleSettingDialog( props ) {
    const classes = useStyles()
    const [ personAdditionOpen, setPersonAdditionOpen ] = React.useState( false )
    const [ roleRestrictionDialog, setRoleRestrictionDialog ] = React.useState( false )
    const [ progress, setProgress ] = React.useState( false )
    const [ admin, setAdmin ] = React.useState( null )
    const [ androidAuth, setAndroidAuth ] = React.useState( null )
    const [ roleRestrictions, setRoleRestrictions ] = React.useState( [] )

    let role = {}


    props.project.roles.forEach( tmp => {
        if ( tmp.id === props.role ) {
            role = tmp

            if ( roleRestrictions.length !== role.roleRestrictionList.length ) {
                setRoleRestrictions( [ ...role.roleRestrictionList ] )
            }

            if ( admin === null )
                setAdmin( role.admin )
        }

    } )

    if ( androidAuth === null ) {
        setAndroidAuth( role.authAndroid )
    }

    function handleRoleRestrictionAddition( roleRestrictionResponse ) {
        let tempList = [ ...roleRestrictions, ...roleRestrictionResponse ]
        let tempProject = { ...props.project }
        tempProject.roles.forEach( tmp => {
            if ( tmp.id === props.role ) {
                tmp.roleRestrictionList = tempList
            }
        } )

        role.roleRestrictionList = tempList
        setRoleRestrictions( tempList )
    }

    function handleRoleRestrictionDeletion( roleRestrictionId ) {
        let tempList = []

        roleRestrictions.forEach( roleRest => {
            if ( roleRest.id !== roleRestrictionId ) {
                tempList.push( roleRest )
            }
        } )

        console.log( tempList )
        props.project.roles.forEach( tmp => {
            if ( tmp.id === props.role ) {
                tmp.roleRestrictionList = tempList
            }
        } )

        role.roleRestrictionList = tempList
        setRoleRestrictions( tempList )
    }

    return <Dialog fullWidth maxWidth={ "md" }
                   open={ props.isOpen }
                   onClose={ () => props.onClose()
                   }>
        <DialogTitle>
            <Typography className={ classes.title }>
                Title Settings
            </Typography>
        </DialogTitle>
        <DialogContent>
            <Grid container>
                <Grid container className={ classes.gridSpaceBtw }>
                    <Grid>
                        <Grid>
                            <Typography className={ classes.subTitle }>
                                Title Description
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography className={ classes.subTitleChild }>
                                { role.role }
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Grid>
                            <Typography className={ classes.subTitle }>
                                Author
                            </Typography>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Typography className={ classes.subTitleChild }>
                                    { admin ? "Admin" : "Not Admin" }
                                </Typography>
                            </Grid>
                            <Tooltip title={ "Give an admin role to this work title. Only Company owner can process." }>
                                <Grid item>
                                    <Switch
                                        disabled={ !( props.user.company !== null &&
                                            props.project.company.id === props.user.company.id ) }
                                        checked={ admin }
                                        size={ "small" }
                                        onChange={ ( event, change ) => {
                                            setAdmin( change )
                                            props.handleTitleAdminChange( change )

                                            fetch(
                                                process.env.REACT_APP_URL + 'projects/projectRoles/admin?roleId=' +
                                                role.id +
                                                "&status=" + change.toString(), {
                                                    method: 'POST', headers: {
                                                        "Content-Type": "application/json",
                                                        'Authorization': 'Bearer ' + Env.TOKEN
                                                    }
                                                }, )
                                                .then( response => {
                                                    if ( response.ok ) return response.json()
                                                    else throw new Error();
                                                } )
                                                .catch( e => {
                                                    props.handleTitleAdminChange( !change )
                                                    setAdmin( !change )
                                                } )
                                        } }
                                        className={ classes.buttonWoPadding }
                                    />
                                </Grid>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Grid>
                            <Typography className={ classes.subTitle }>
                                Developer
                            </Typography>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Typography className={ classes.subTitleChild }>
                                    { androidAuth ? "Allow" : "Not Allow" }
                                </Typography>
                            </Grid>
                            <Tooltip
                                title={ "Give an mobile authentication to this work title to let them enter android keys and export localized texts." }>
                                <Grid item>
                                    <Switch
                                        checked={ androidAuth }
                                        size={ "small" }
                                        onChange={ ( event, change ) => {
                                            setAndroidAuth( change )
                                            props.handleAndroidKeyChange( change )

                                            fetch(
                                                process.env.REACT_APP_URL +
                                                'projects/projectRoles/authAndroid?roleId=' +
                                                role.id +
                                                "&status=" + change.toString(), {
                                                    method: 'POST', headers: {
                                                        "Content-Type": "application/json",
                                                        'Authorization': 'Bearer ' + Env.TOKEN
                                                    }
                                                }, )
                                                .then( response => {
                                                    if ( response.ok ) return response.json()
                                                    else throw new Error()
                                                } )
                                                .catch( e => {
                                                    props.handleAndroidKeyChange( !change )
                                                    setAndroidAuth( !change )
                                                } )
                                        } }
                                        className={ classes.buttonWoPadding }
                                    />
                                </Grid>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Grid>
                            <Typography className={ classes.subTitle }>
                                Users
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography className={ classes.subTitleChild }>
                                { role.roleList.length.toString() }
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <hr className={ classes.divider }/>
                <Grid container>
                    <Grid container className={ classes.gridSpaceBtw }>
                        <Grid>
                            <Typography className={ classes.subTitle }>
                                User List
                            </Typography>
                        </Grid>
                        <Grid>
                            <Tooltip title={ "Add a new user under this work title." }>
                                <Button onClick={ () => setPersonAdditionOpen( true ) }
                                        className={ classes.subTitle }>
                                    <PersonAdd/>
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid container className={ classes.tableHead }>
                        <Grid item xs>
                            <Typography className={ classes.tableHeadText }>
                                Name
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={ classes.tableHeadText }>
                                Surname
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={ classes.tableHeadText }>
                                Username
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={ classes.tableHeadText }>

                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        { role.roleList.map( role => {
                            return <Grid
                                key={ "productDetail-dialog-roleList-role-" + role.id }
                                container className={ classes.gridSpaceBtw }>
                                <Grid item xs>
                                    <Typography className={ classes.userText }>
                                        { role.user.name }
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography className={ classes.userText }>
                                        { role.user.surname }
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography className={ classes.userText }>
                                        { role.user.userName }
                                    </Typography>
                                </Grid>
                                <Tooltip title={ "Remove the user under this work title." }>
                                    <Grid item xs className={ classes.iconEnd }>
                                        <Button onClick={
                                            () => {
                                                props.onDeletion( role.id )
                                            }
                                        }>
                                            <CancelOutlined/>
                                        </Button>
                                    </Grid>
                                </Tooltip>
                            </Grid>
                        } ) }
                    </Grid>
                </Grid>
                <hr className={ classes.divider }/>
                <Grid container>
                    <Grid container className={ classes.gridSpaceBtw }>
                        <Grid>
                            <Typography className={ classes.subTitle }>
                                Subjects To Notify
                            </Typography>
                        </Grid>
                        <Grid>
                            <Tooltip title={ "Add a new category that this work title wil be responsible for." }>
                                <Button onClick={ () => {
                                    setRoleRestrictionDialog( true )
                                } }
                                        className={ classes.subTitle }>
                                    <Add/>
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid container className={ classes.tableHead }>
                        <Grid item>
                            <Typography className={ classes.tableHeadText }>
                                Translation Subject
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        { roleRestrictions.map( rest => {
                            return <RoleRestrictionLine rest={ rest } classes={ classes }
                                                        handleRoleRestrictionDeletion={ ( val ) => handleRoleRestrictionDeletion(
                                                            val ) }/>
                        } ) }
                    </Grid>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions className={ classes.grid }>
            <ButtonGroup className={ classes.buttonGroup }>
                <Tooltip title={ "Close Title Settings." }>
                    <Button onClick={
                        () => props.onClose()
                    }
                            className={ classes.buttonDone }>
                        <Typography className={ classes.buttonText }>
                            Done
                        </Typography>
                    </Button>
                </Tooltip>
            </ButtonGroup>
            <ButtonGroup className={ classes.buttonGroup }>
                <Tooltip title={ "Delete title from project." }>
                    <Button onClick={
                        () => {
                            props.onTitleDeletion( props.role )
                            props.onClose()
                        }
                    }
                            className={ classes.button }>
                        <Typography className={ classes.buttonText }>
                            Delete Title
                        </Typography>
                    </Button>
                </Tooltip>
            </ButtonGroup>
        </DialogActions>
        <RoleRestrictionAdditionDialog
            handleRoleRestrictionAddition={ ( val ) => handleRoleRestrictionAddition( val ) }
            project={ props.project }
            setProgress={ ( val ) => setProgress( val ) }
            progress={ progress }
            functionList={ props.functionList }
            role={ role }
            isOpen={ roleRestrictionDialog }
            onClose={ () => setRoleRestrictionDialog( false ) }/>
        <PersonAdditionDialog
            handlePersonAddition={ ( val ) => props.handlePersonAddition( val ) }
            setProgress={ ( val ) => setProgress( val ) }
            user={ props.user }
            role={ role }
            functionList={ props.functionList }
            project={ props.project }
            isOpen={ personAdditionOpen }
            handleClose={ () => setPersonAdditionOpen( false ) }
        />
        {
            progress ? <LinearProgress className={ classes.progressBar }/> : null
        }
    </Dialog>
}