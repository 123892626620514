import { Button, Dialog, Grid, Snackbar, Tab, Tabs } from "@material-ui/core";
import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../../../../constants/Colors";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles( {
    appBar: {},
    innerTab: {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        background: Colors.gradient,
        color: Colors.PRIMARY_WHITE,
        "&.Mui-selected": {}
    },
    indicator: {
        background: "transparent",
        width: '100%',
        display: "flex",
        justifyContent: 'center',
    },
    tab: {
        paddingLeft: 30,
        paddingRight: 30,
        "&.Mui-selected": {
            background: Colors.PRIMARY_WHITE,
            color: Colors.PRIMARY_PINK_UP,
        }
    },
    loaderGrid: {
        paddingTop: 300,
        height: 800,
        maxHeight: 800,
    },
    div: {
        flexGrow: 1,
    }
} );

export default function FormattedExportDialog( props ) {
    const classes = useStyles()
    const [ languageIndex, setLanguageIndex ] = React.useState( 0 )
    const [ errorOpen, setErrorOpen ] = React.useState( "" )
    const [ successOpen, setSuccessOpen ] = React.useState( "" )

    let languages = []

    props.wordList[ 0 ].localizations.forEach( loc => {
        if ( !loc.base ) {
            languages.push( loc.projectLanguage.language.language )
        } else {
            languages.push( props.baseLanguage.language )
        }
    } )
    console.log( props.wordList )

    function getExportedText() {
        let outputText = "<string-array name=\"\">\n"
        props.wordList.forEach( word => {
            let temp = "    <item>" + word.localizations[ languageIndex ].text.trim() + "</item>\n"
            outputText = outputText + temp;
        } )
        outputText = outputText + "</string-array>"

        return outputText

    }

    return <Dialog maxWidth={ "md" } fullWidth={ true } open={ props.open } onClose={ () => props.setOpen( false ) }
                   style={ {
                       display: "flex",
                       justifyContent: "center",
                       alignItems: "center"
                   } }>
        <Grid container>
            <Tabs
                id={ 'inner' + props.innerTab }
                key={ 'inner' + props.innerTab }
                classes={ {
                    indicator: classes.indicator
                } }
                className={ classes.innerTab }
                value={ languageIndex }
                onChange={ ( event, index ) => setLanguageIndex( index ) }
                indicatorColor="secondary"
                variant={ "scrollable" }
                scrollButtons={ "auto" }
                aria-label={ "scrollable auto outer tabs" }
            >
                { languages.map( ( language, index ) => {
                    return <Tab
                        key={ "inner-tab-" + index }
                        disabled={ props.allTableLoading }
                        className={ classes.tab }
                        label={ language }
                        aria-controls={ `scrollable-auto-tabpanel-${ index }` }
                        id={ `scrollable-auto-tab-${ index }` }/>
                } ) }
            </Tabs>
        </Grid>
        <Grid container style={ {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 20,

        } }>
            <Grid item>
                <Button
                    style={ {
                        background: Colors.PRIMARY_BLUE,
                        color: Colors.PRIMARY_WHITE,
                        borderRadius: 10,
                        width: 300
                    } }
                    onClick={ () => {
                        let outputText = "<string-array name=\"\">\n"
                        props.wordList.forEach( word => {
                            let temp = "    <item>" + word.localizations[ languageIndex ].text.trim() + "</item>\n"
                            outputText = outputText + temp;
                        } )
                        outputText = outputText + "</string-array>"

                        navigator.clipboard.writeText( outputText )
                                 .then( r => {
                                     setSuccessOpen( "Copied!" )
                                 } )
                    }
                    }>
                    { "COPY for " + languages[ languageIndex ] }
                </Button>
            </Grid>
        </Grid>
        <Grid container style={ {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 10,
        } }>
            <Grid item xs style={ {
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            } }>
                  <textarea
                      rows="35"
                      cols="80"
                      contentEditable={ false }
                      onClick={ () => {
                          navigator.clipboard.writeText( getExportedText() )
                                   .then( r => {
                                       setSuccessOpen( "Copied!" )
                                   } )

                      } }
                      className={ classes.exportPanel }
                      value={ getExportedText() }
                  />

            </Grid>
        </Grid>
        <Snackbar open={ errorOpen.length > 0 }
                  autoHideDuration={ 2000 }
                  onClose={ () => setErrorOpen( '' ) }
        >
            <Alert onClose={ () => setErrorOpen( '' ) }
                   severity={ "warning" }
            >
                { errorOpen }
            </Alert>
        </Snackbar>
        <Snackbar open={ successOpen.length > 0 }
                  autoHideDuration={ 2000 }
                  onClose={ () => setSuccessOpen( "" ) }
        >
            <Alert onClose={ () => setSuccessOpen( "" ) }
                   severity={ "success" }
            >
                { successOpen }
            </Alert>
        </Snackbar>
    </Dialog>
}