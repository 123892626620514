import './App.css';
import React from "react";
import InitialPage from './initialScreens/InitialPage'
import MainLayoutPage from './dashboardScreens/MainLayoutPage.js'
import { LinearProgress, withStyles } from "@material-ui/core";
import Colors from "./constants/Colors";
import TranslationMainLayoutPage from "./dashboardScreens/TranslationMainLayoutPage";
import Env from "./constants/Env";
import { makeStyles } from "@material-ui/core/styles";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import ConfirmationPage from "./initialScreens/ConfirmationPage";
import ResetPasswordPage from "./initialScreens/ResetPasswordPage";
import TranslationTaskPublicMainLayoutPage
    from "./dashboardScreens/translationTaskScreens/translationTaskPublicLinkScreens/TranslationTaskPublicMainLayoutPage";

const BorderLinearProgress = withStyles( () => ( {
    root: {
        height: 40,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
    },
    colorPrimary: {
        background: Colors.gradientMainLayoutBlueReverse,
    },
    bar: {
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        background: Colors.gradient,
    }
} ) )( LinearProgress );

const useStyles = makeStyles( {
    div: {
        background: Colors.PRIMARY_WHITE,
        display: "flex",
        minHeight: '100%',
        minWidth: '100%',
        position: "fixed",
        justifyContent: "center",
    },
} );

export default function App() {
    const [ program, setProgram ] = React.useState( null )
    const [ isFailed, setFailed ] = React.useState( false )

    const classes = useStyles()

    const functionList = {
        'getProgram': async function getProgram() {
            let returnData = {};
            let headers = {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + Env.TOKEN
            }
            await fetch( process.env.REACT_APP_URL + 'programs?publicId=' + Env.PUBLIC_ID, {
                method: 'get', headers: headers,
            }, )
                .then( response => {
                    if ( response.ok ) {
                        return response.json()
                    } else {

                        throw new Error( 'Something went wrong' );
                    }
                } )
                .then( data => {
                    returnData = data
                    setFailed( false )
                    setProgram( data )

                } )
                .catch( ( error ) => {
                    returnData = error
                    setFailed( true )
                    console.log( error )
                } )
            return returnData
        },
        'handleSignIn': async function signInCompleted( token, publicId ) {
            Env.PUBLIC_ID = publicId
            Env.TOKEN = token
            localStorage.setItem( "token", token )
            localStorage.setItem( "publicId", publicId )
            await this.getProgram()
        },
        'handleSingUp': function singOutCompleted() {

        },
        'signOut': function signOut() {
            localStorage.clear()
            setFailed( true )
            Env.TOKEN = ''
            Env.PUBLIC_ID = ''
        },
        'setProgram': function setProgram( val ) {
            if ( val !== program ) {
                setProgram( val )
            }
        },
        'getProgramAsObject': function getProgramAsObject() {
            return program
        }
    }

    if ( program === null && !isFailed ) {
        Env.TOKEN = localStorage.getItem( "token" ) !== null ? localStorage.getItem( "token" ) : ''
        Env.PUBLIC_ID = localStorage.getItem( "publicId" ) !== null ? localStorage.getItem( "publicId" ) : ''
        functionList.getProgram();
    }

    return <Router>
        <Switch>
            <Route
                path={ "/order/:publicLink" }
                children={ <TranslationTaskPublicMainLayoutPage
                    functionList={ functionList }
                /> }
            />
            <Route
                path={ "/resetPassword/:token" }
                children={ <ResetPasswordPage/> }
            />
            <Route
                path={ "/confirmation/:token" }
                children={ <ConfirmationPage/> }
            />
            <Route
                path={ "/" }
                children={
                    isFailed ?
                        <div className={ classes.div }>
                            <center>
                                <InitialPage
                                    functionList={ functionList }
                                />
                            </center>
                        </div>
                        :
                        program === null ?
                            <BorderLinearProgress/>
                            :
                            program.user.userExpiration === null ?
                                <MainLayoutPage
                                    functionList={ functionList }
                                    program={ program }
                                />
                                :
                                <TranslationMainLayoutPage
                                    program={ program }
                                    functionList={ functionList }
                                />
                }/>
        </Switch>
    </Router>


}

