import React from "react";
import {
    Button,
    ButtonGroup,
    Container,
    Dialog,
    Grid,
    InputBase, LinearProgress,
    makeStyles, Snackbar, TextField, Tooltip,
    Typography
} from "@material-ui/core";
import Colors from "../../../../../../constants/Colors";
import Env from "../../../../../../constants/Env";
import { Alert } from "@material-ui/lab";
import ExplanationUploadDialog from "../../ExplanationUploadDialog";
import { TrendingUp } from "@material-ui/icons";
import ImageUploadDialogInTranslationCreation from "./ImageUploadDialogInTranslationCreation";

const useStyles = makeStyles( () => ( {
    button: {
        border: "salid",
        borderColor: Colors.PRIMARY_PINK_UP,
        height: 35,
        width: 400,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        '&:hover': {
            backgroundColor: Colors.PRIMARY_PINK_UP,
        }
    },
    image: {
        cursor: "pointer",
    },
    iconButton: {
        height: 35,
        width: 50,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        '&:hover': {
            backgroundColor: Colors.PRIMARY_PINK_UP,

        }
    },
    icon: {
        fontSize: 30,
        color: Colors.PRIMARY_WHITE
    },
    buttonText: {
        fontWeight: "bold",
        fontSize: 16,
        color: Colors.PRIMARY_WHITE,
    },
    buttonGroup: {
        padding: 20,
        display: "flex",
    },
    grid: {
        padding: 20,
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        color: Colors.PRIMARY_WHITE,
        background: Colors.gradient,
    },
    gridAlternative: {
        width: "100%",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        color: Colors.PRIMARY_WHITE,
        background: Colors.PRIMARY_WHITE,
    },
    subGrid: {
        borderColor: Colors.PRIMARY_PINK_ALT,
        borderWidth: 1,
        borderTopWidth: 0,
        border: "solid",
        padding: 20,
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
    },
    buttonGrid: {
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
    },
    title: {
        color: Colors.PRIMARY_PINK_UP,
        fontWeight: "bold",
        fontSize: 24,
        paddingBottom: 10,
        paddingTop: 10,

    },
    subTitleChild: {
        color: Colors.PRIMARY_WHITE,
        fontWeight: "bold",
        fontSize: 16,
    },
    paper: {
        background: Colors.gradient,
        justifyContent: "space-between",
        alignItems: "center",
        justifyItems: "center",
        padding: 20,
    },
    scrollableDiv: {
        maxHeight: 800,
        overflowY: "scroll",
    },
    text: {
        color: Colors.PRIMARY_WHITE,
        fontSize: 20,
        fontWeight: "bold",
    },
    divSelected: {
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        backgroundColor: Colors.PRIMARY_PINK_UP,
        fontWeight: "bold",
        borderRadius: 10,
        padding: 5,
        cursor: "pointer",
        color: Colors.PRIMARY_WHITE,
        maxHeight: 500,
        overflowY: "scroll",
        maxWidth: 400,
    },
    divNotSelected: {
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        borderRadius: 10,
        alignContent: "center",
        padding: 5,
        cursor: "pointer",
        fontWeight: "bold",
        color: Colors.PRIMARY_PINK_ALT,
        maxHeight: 500,
        overflowY: "scroll",
        maxWidth: 400,
    },
    subButtonText: {
        color: Colors.PRIMARY_WHITE,
        fontWeight: "bold",
        fontSize: 12,
    },
    explanationGrid: {
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
    },
    bodyGrid: {
        alignContent: "center",
        alignItems: "center",
        paddingBottom: 20,
    },
    progressBar: {
        background: Colors.gradient,
        borderRadius: 10,
        height: 5,
        width: '100%',
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
    },
    input: {
        display: "none",
    },
    dialogButton: {
        borderRadius: 10,
        height: 35,
        width: 200,
        backgroundColor: Colors.PRIMARY_WHITE,
        color: Colors.PRIMARY_BLUE,
        '&:hover': {
            color: Colors.PRIMARY_WHITE,
            backgroundColor: Colors.PRIMARY_PINK_UP,
        }
    },
    dialogButtonText: {
        fontWeight: "bold",
        fontSize: 16,
    },
    dialogButtonGroup: {
        backgroundColor: Colors.PRIMARY_BLUE,
        padding: 20,
        display: "flex",
        justifyContent: "space-between",
    },
    gridBody: {
        padding: 10,
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
    },
    buttonLabel: {
        border: "solid",
        backgroundColor: Colors.PRIMARY_WHITE,
        borderRadius: 10,
    },
    buttonCancel: {
        width: 140,
        borderRadius: 10,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        "&:hover": {
            backgroundColor: Colors.PRIMARY_BLUE,
        }
    },
    exchangeIcon: {
        color: Colors.PRIMARY_PINK_ALT,
    },
    textField: {
        width: 200,
        paddingRight: 10,
        paddingLeft: 10,
    },
    itemCenter: {
        display: "flex",
        alignItems: "center",
        justifyItems: "center",
        justifyContent: "center",
    }
} ) )

export default function TranslationOrderTextViewerAsList( props ) {
    const classes = useStyles()
    const [ dialogOpen, setDialogOpen ] = React.useState( -1 )
    const [ currentText, setCurrentText ] = React.useState( -1 )
    const [ successOpen, setSuccessOpen ] = React.useState( false )
    const [ sourceText, setSourceText ] = React.useState( '' )
    const [ targetText, setTargetText ] = React.useState( '' )
    const [ errorOpen, setErrorOpen ] = React.useState( false )
    const [ explanationDialog, setExplanationDialog ] = React.useState( -1 )
    const [ submitted, setSubmitted ] = React.useState( false )
    const [ explanationUploadDialog, setExplanationUploadDialog ] = React.useState( false )
    const [ images, setImages ] = React.useState( [] )
    let explanations = []

    props.project.explanations.forEach( explanation => explanations.push( explanation ) )

    if ( images.length !== props.project.images.length ) {
        setImages( props.project.images )
    }

    function handleImageAddition( responseList ) {
        let tempList = [ ...images, ...responseList ]
        setImages( tempList )
    }


    const handleErrorClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }
        setErrorOpen( false );
    };

    const handleErrorOpen = () => {
        setErrorOpen( true )
    }

    const handleSuccessClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }
        setSuccessOpen( false );
    };

    const handleSuccessOpen = () => {
        setSuccessOpen( true )
    }

    function checkAllShownTextAreNotEmpty() {
        let check = true
        props.texts.forEach( text => {
            if ( text.englishShownWord === null || text.englishShownWord.length === 0 ) {
                check = false
            }
        } )

        return check
    }

    function calculateTextCount( textModelList ) {
        let count = 0
        const regexpEmojiPresentation = /\p{Emoji_Presentation}/gu;

        textModelList.forEach( textModel => {
            textModel.englishShownWord.split( " " )
                     .forEach( el => {

                         if ( el.match( regexpEmojiPresentation ) === null && el.length > 1 ) {
                             if ( !checkTextInBlackList( el ) ) {
                                 count++
                             }
                         } else {
                             el.split( regexpEmojiPresentation )
                               .forEach( elChild => {
                                   if ( elChild.length > 1 && !checkTextInBlackList( elChild ) ) {
                                       count++
                                   }
                               } )
                         }
                     } )


        } )
        return count
    }

    function checkTextInBlackList( text ) {
        let check = false
        props.project.blackListTexts.forEach( blackListText => {
            if ( blackListText.text === text )
                check = true
        } )
        return check
    }

    return <Grid container>
        <Grid container>
            <Grid container className={ classes.bodyGrid }>
                <Grid item xs>
                    <Grid container style={ {
                        alignItems: "center",
                        alignContent: "center",
                        display: "flex",
                        justifyContent: "center",
                        color: Colors.PRIMARY_PINK_ALT,

                        fontSize: 12
                    } }>
                        Line Count
                    </Grid>
                    <Grid container style={ {
                        alignItems: "center",
                        alignContent: "center",
                        display: "flex",
                        justifyContent: "center",
                        color: Colors.PRIMARY_BLUE,
                        fontSize: 24
                    } }>
                        { props.texts.length }
                    </Grid>
                </Grid>
                <Grid item xs className={ classes.itemCenter }>
                    <TextField
                        value={ sourceText }
                        type={ "text" }
                        placeholder={ "Find" }
                        onChange={ ( val ) => {
                            setSourceText( val.target.value )
                        } }
                        className={ classes.textField }
                    />
                    <TrendingUp className={ classes.exchangeIcon }/>
                    <TextField
                        type={ "text" }
                        placeholder={ "Replace With" }
                        value={ targetText }
                        onChange={ ( val ) => {
                            setTargetText( val.target.value )
                        } }
                        className={ classes.textField }
                    />
                    <ButtonGroup>
                        <Tooltip title={ "Replace" }>
                            <Button
                                disabled={ sourceText.length === 0 }
                                onClick={ () => {
                                    props.texts.forEach( text => {
                                        if ( text.englishShownWord !== null ) {
                                            let str = text.englishShownWord
                                            props.handleEnglishShownWordChange( str.replace( sourceText, targetText ),
                                                text.id )
                                        }
                                    } )

                                } }
                                className={ classes.buttonCancel }>
                                <Typography className={ classes.buttonText }>
                                    Replace
                                </Typography>
                            </Button>
                        </Tooltip>
                        <Tooltip title={ "Reset All Changes" }>
                            <Button
                                onClick={ () => {
                                    props.texts.forEach( text => {
                                        props.handleEnglishShownWordChange( text.text.baseText.englishWord, text.id )
                                    } )
                                } }
                                className={ classes.buttonCancel }>
                                <Typography className={ classes.buttonText }>
                                    Reset
                                </Typography>
                            </Button>
                        </Tooltip>
                    </ButtonGroup>
                </Grid>
                <Grid item xs>
                    <Grid container style={ {
                        alignItems: "center",
                        alignContent: "center",
                        display: "flex",
                        justifyContent: "center",
                        color: Colors.PRIMARY_PINK_ALT,
                        fontSize: 12
                    } }>
                        Text Count
                    </Grid>
                    <Grid container style={ {
                        alignItems: "center",
                        alignContent: "center",
                        display: "flex",
                        justifyContent: "center",
                        color: Colors.PRIMARY_BLUE,
                        fontSize: 24
                    } }>
                        { calculateTextCount( props.texts ) }
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={ classes.grid }>
                <Grid item xs={ 3 }>
                    <Typography className={ classes.text }>
                        Source
                    </Typography>
                </Grid>
                <Grid item xs={ 3 }>
                    <Typography className={ classes.text }>
                        Shown To Translator
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography className={ classes.text }>
                        Guide Image
                    </Typography>
                </Grid>
                <Grid item xs={ 4 }>
                    <Typography className={ classes.text }>
                        Description
                    </Typography>
                </Grid>
            </Grid>
            <Grid container style={ { maxHeight: 400 } }>
                { props.texts.map( ( text, index ) => {
                    return <Grid
                        key={ "translation-order-text-addition-body-row" + text.id + index }
                        container className={ classes.subGrid }>
                        <Grid item xs={ 3 }>
                            <InputBase
                                style={ { padding: 0, margin: 0, fontSize: 14, } }
                                multiline={ true }
                                value={ text.text.baseText.englishWord }
                                onChange={ () => {
                                } }
                            />
                        </Grid>
                        <Grid item xs={ 3 }>
                            <InputBase
                                style={ { padding: 0, margin: 0, fontSize: 14, } }
                                multiline={ true }
                                value={ text.englishShownWord }
                                onChange={ ( value ) => {
                                    props.handleEnglishShownWordChange( value.target.value, text.id )
                                } }
                            />
                        </Grid>
                        <Grid item xs>
                            { text.image === null ? <Button onClick={ () => {
                                    setCurrentText( text.id )
                                    setDialogOpen( 0 )
                                } }>
                                    Empty
                                </Button> :
                                <Tooltip
                                    title={ "Click to Change" }>
                                    <img alt={ "text's img" }
                                         onClick={ () => {
                                             setDialogOpen( text.image.id )
                                             setCurrentText( text.id )
                                         } }
                                         className={ classes.image }
                                         src={ process.env.REACT_APP_IMAGE_URL + text.image.url }
                                         height={ 50 }
                                    />
                                </Tooltip>
                            }
                        </Grid>
                        <Grid item xs={ 4 } className={ classes.explanationGrid }>
                            <Grid container>
                                <Tooltip title={ "You can directly enter description." }>
                                    <Grid item xs={ 10 }>
                                        <InputBase
                                            multiline={ true }
                                            value={ text.explanation == null ? "" :
                                                text.explanation.content === null ? "" : text.explanation.content }
                                            onChange={ ( value ) => {
                                                if ( value.target.value === "" )
                                                    props.handleExplanationChange( null, text.id, 0 )
                                                else
                                                    props.handleExplanationChange( value.target.value, text.id, 0 )
                                            } }
                                        >
                                        </InputBase>
                                    </Grid>
                                </Tooltip>
                                <Tooltip title={ "You can select." }>
                                    <Grid item xs={ 2 }>
                                        <Button className={ classes.iconButton }
                                                onClick={ () => {
                                                    if ( text.explanation != null && text.explanation.id !==
                                                        undefined ) {
                                                        setExplanationDialog( text.explanation.id )
                                                    } else {
                                                        setExplanationDialog( 0 )

                                                    }
                                                    setCurrentText( text.id )
                                                } }>
                                            <Typography className={ classes.subButtonText }> Select</Typography>
                                        </Button>
                                    </Grid>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                } ) }
                <Grid container className={ classes.buttonGrid }>
                    <Grid item>
                        <ButtonGroup className={ classes.buttonGroup }>
                            <Tooltip title={ "Send to Translator to Edit." }>
                                <Button disabled={ props.texts.length === 0 || !checkAllShownTextAreNotEmpty() }
                                        className={ classes.button }
                                        onClick={ async () => {
                                            setSubmitted( true )

                                            let returnObject = {
                                                "projectId": props.project.id,
                                                "type": "WRITE",
                                                "userId": props.translator == null ? null : props.translator.id,
                                                "language": props.language,
                                                "userExpire": props.date,
                                                "name": props.translator == null ? props.name : null,
                                                "surname": props.translator == null ? props.surname : null,
                                                "orderTitle": props.orderTitle,
                                                "email": props.translator == null ? props.email : null,
                                                textCount: 0,
                                                translationTextModelList: []
                                            }

                                            props.texts.forEach( text => {
                                                returnObject.translationTextModelList.push( {
                                                    "textId": text.text.id,
                                                    "englishShownWord": text.englishShownWord,
                                                    "image": {
                                                        "id": text.image == null ? -1 : text.image.id,
                                                    },
                                                    "explanation": {
                                                        "id": text.explanation === null ? -1 : text.explanation.id,
                                                        "content": text.explanation === null ? null :
                                                            text.explanation.content,
                                                    }
                                                } )
                                            } )
                                            returnObject.textCount =
                                                calculateTextCount( returnObject.translationTextModelList )
                                            //console.log(returnObject.textCount)

                                            const headers = {
                                                "Content-Type": "application/json",
                                                'Authorization': 'Bearer ' + Env.TOKEN
                                            }
                                            await fetch( process.env.REACT_APP_URL + 'projects/translationOrders', {
                                                method: 'POST', headers: headers, body: JSON.stringify( returnObject )
                                            }, )
                                                .then( response => {
                                                    if ( response.ok ) return response.json()
                                                    else throw new Error();
                                                } )
                                                .then( ( data ) => {
                                                    console.log( data )
                                                    let tempProject = { ...props.project }
                                                    tempProject.translationOrders.push( data )
                                                    props.project = tempProject
                                                    handleSuccessOpen()
                                                } )
                                                .catch( e => {
                                                    handleErrorOpen()
                                                    //console.log(e)
                                                } )
                                            setSubmitted( false )
                                            props.handleClose()
                                            props.setPage( "" )

                                        } }>
                                    <Typography className={ classes.buttonText }>
                                        Send For Translation
                                    </Typography>
                                </Button>
                            </Tooltip>
                            <Tooltip title={ "Send to Translator to View." }>
                                <Button disabled={ props.texts.length === 0 || !checkAllShownTextAreNotEmpty() }
                                        className={ classes.button }
                                        onClick={ async () => {

                                            setSubmitted( true )
                                            let returnObject = {
                                                "projectId": props.project.id,
                                                "type": "READ",
                                                "userId": props.translator == null ? null : props.translator.id,
                                                "userExpire": props.date,
                                                "language": props.language,
                                                "name": props.translator == null ? props.name : null,
                                                "orderTitle": props.orderTitle,
                                                "surname": props.translator == null ? props.surname : null,
                                                "email": props.translator == null ? props.email : null,
                                                textCount: 0,
                                                translationTextModelList: []
                                            }
                                            props.texts.forEach( text => {
                                                returnObject.translationTextModelList.push( {
                                                    "textId": text.text.id,
                                                    "englishShownWord": text.englishShownWord,
                                                    "image": {
                                                        "id": text.image == null ? -1 : text.image.id,
                                                    },
                                                    "explanation": {
                                                        "id": text.explanation === null ? -1 : text.explanation.id,
                                                        "content": text.explanation === null ? null :
                                                            text.explanation.content,
                                                    }
                                                } )
                                            } )
                                            returnObject.textCount =
                                                calculateTextCount( returnObject.translationTextModelList )

                                            const headers = {
                                                "Content-Type": "application/json",
                                                'Authorization': 'Bearer ' + Env.TOKEN
                                            }

                                            await fetch( process.env.REACT_APP_URL + 'projects/translationOrders', {
                                                method: 'POST', headers: headers, body: JSON.stringify( returnObject )
                                            }, )
                                                .then( response => {
                                                    if ( response.ok ) return response.json()
                                                    else response.json()
                                                                 .then( data => console.log( data.message ) )
                                                } )
                                                .then( ( data ) => {
                                                    console.log( data )
                                                    let tempProject = { ...props.project }
                                                    tempProject.translationOrders.push( data )
                                                    props.project = tempProject
                                                    handleSuccessOpen()
                                                } )
                                                .catch( () => {
                                                    handleErrorOpen()
                                                } )
                                            props.setPage( "" )
                                            setSubmitted( false )

                                            props.handleClose()
                                        } }>
                                    <Typography className={ classes.buttonText }>
                                        Send a Preview
                                    </Typography>
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
        <ImageUploadDialogInTranslationCreation
            dialogOpen={ dialogOpen }
            setDialogOpen={ ( val ) => setDialogOpen( val ) }
            classes={ classes }
            project={ props.project }
            images={ images }
            handleImageAddition={ ( val ) => handleImageAddition( val ) }
            currentText={ currentText }
            handleImageChange={ ( val, tar ) => props.handleImageChange( val, tar ) }
        />
        <Dialog fullWidth={ true }
                maxWidth={ "lg" }
                open={ explanationDialog > -1 }
                onClose={ () => setExplanationDialog( -1 ) }>
            <Grid container spacing={ 1 } className={ classes.gridAlternative }>
                <Grid container spacing={ 1 } className={ classes.dialogButtonGroup }>
                    <Grid item>
                        <ButtonGroup>
                            <label className={ classes.buttonLabel }>
                                <Tooltip title={ "Make Description Empty." }>
                                    <Button onClick={ () => {
                                        props.handleExplanationChange( null, currentText, 0 )
                                        setExplanationDialog( -1 )
                                    } }
                                            className={ classes.dialogButton }>
                                        <Typography className={ classes.dialogButtonText }>
                                            Empty
                                        </Typography>
                                    </Button>
                                </Tooltip>
                            </label>

                            <label className={ classes.buttonLabel }>
                                <Tooltip title={ "Upload Descriptions." }>
                                    <Button
                                        onClick={ () => setExplanationUploadDialog( true ) }
                                        className={ classes.dialogButton }>
                                        <Typography className={ classes.dialogButtonText }>
                                            UPLOAD MORE
                                        </Typography>
                                    </Button>
                                </Tooltip>
                            </label>
                        </ButtonGroup>
                    </Grid>
                    <Grid item>
                        <ButtonGroup>
                            <label className={ classes.buttonLabel }>
                                <Tooltip title={ "Cancel" }>
                                    <Button onClick={ () => {
                                        setExplanationDialog( -1 )
                                    } }
                                            className={ classes.dialogButton }>
                                        <Typography className={ classes.dialogButtonText }>
                                            Cancel
                                        </Typography>
                                    </Button>
                                </Tooltip>
                            </label>
                        </ButtonGroup>

                    </Grid>
                </Grid>
                <hr/>
                <Grid container className={ classes.bodyGrid }>
                    {
                        explanations.length > 0 ?
                            explanations.map( explanation => {
                                return <Tooltip title={ "Click to Select Description." }>
                                    <Grid item xs>
                                        <Container

                                            className={ explanationDialog === explanation.id ? classes.divSelected :
                                                classes.divNotSelected }
                                            alt={ "text's img" }
                                            onClick={ () => {
                                                props.handleExplanationChange( explanation.content, currentText,
                                                    explanation.id )
                                                setExplanationDialog( -1 )
                                            } }
                                        >
                                            { explanation.content }
                                        </Container>
                                    </Grid>
                                </Tooltip>
                            } )
                            :
                            <Grid container style={ { display: "flex", justifyContent: "center", } }>
                                <Grid item>
                                    <Typography style={ {
                                        color: Colors.PRIMARY_PINK_ALT,
                                        fontSize: 24,
                                        marginTop: 20,
                                        marginBottom: 20
                                    } }>
                                        There is no description uploaded before in this project!
                                    </Typography>
                                </Grid>
                            </Grid>
                    }
                </Grid>
            </Grid>
        </Dialog>
        <ExplanationUploadDialog
            functionList={ props.functionList }
            project={ props.project }
            isOpen={ explanationUploadDialog }
            onClose={ () => setExplanationUploadDialog( false ) }/>
        <Snackbar open={ errorOpen }
                  autoHideDuration={ 6000 }
                  onClose={ handleErrorClose }
        >
            <Alert onClose={ handleErrorClose }
                   severity={ "warning" }
            >
                Something went wrong, try it later.
            </Alert>
        </Snackbar>
        <Snackbar open={ successOpen }
                  autoHideDuration={ 1000 }
                  onClose={ handleSuccessClose }
        >
            <Alert onClose={ handleSuccessClose }
                   severity={ "success" }
            >
                Order is successfully created.
            </Alert>
        </Snackbar>

        { submitted ? <LinearProgress className={ classes.progressBar } color={ 'primary' }/> : <div/> }

    </Grid>
}