class Colors {
    static PRIMARY_WHITE = '#ffffff';
    static PRIMARY_BLACK = '#000000';
    static PRIMARY_PINK = "#d53369";
    static PRIMARY_YELLOW = "#cbad6d";
    static PRIMARY_YELLOW_LIGHT = "#F5D26E";
    static PRIMARY_GREEN_LIGHT = "#7FCC9B";
    static PRIMARY_GREEN_SECONDARY = "#edffe9";

    static PRIMARY_GREEN_THIRD = "#e9fbff";

    static PRIMARY_PINK_ALT = "#CA7989";
    static PRIMARY_PINK_UP = "#C52D64";
    static PRIMARY_GREEN = "#DA9550";
    static PRIMARY_BLUE = "#4D597C";
    static PRIMARY_BACKGROUND = "#F0EBEC";
    static PRIMARY_GREY = "#7e7474"
    static gradient = 'linear-gradient(45deg, #ad5389 30%,  #C6426E 90%)';  // 'linear-gradient(45deg, #ad5389 30%,  #3c1053 90%)' second ;
    static gradientSecond = Colors.gradient; //'linear-gradient(45deg, #d53369 30%,  #4D597C 90%)' first;
    static gradientMainLayoutBlue = 'linear-gradient(45deg,  #C6426E 30%,  #ad5389 90%)';
    static gradientMainLayoutGreen = Colors.gradient;
    static gradientMainLayoutBlueReverse = Colors.gradientMainLayoutBlue;
    static fontOfProgram = {
        fontFamily: 'poppins',
    }
    //'linear-gradient(45deg, #496B66 30%,  #cbad6d 90%)';  green and yellow
}

export default Colors;