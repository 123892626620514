import React from 'react';
import {Grid, Paper, Typography, Divider} from '@material-ui/core';
import ProjectPreviewCard from "./ProjectPreviewCard";
import {makeStyles} from "@material-ui/core/styles";
import Colors from "../../../constants/Colors";

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: 'transparent',
        borderRadius: 10,
        padding: 20,
    },
    grid: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
    },
    title: {
        display: "flex",
        fontWeight: 'bold',
        fontSize: 32,
        color: Colors.PRIMARY_PINK_UP,
    },
    divider: {
        background: 'linear-gradient(45deg, #cbad6d 30%,  #4D597C 90%)',
        marginTop: 8,
        marginBottom: 20,
    }

}));

export default function ProjectPreviewConsole(props) {
    const classes = useStyles();

    return (<Paper elevation={0} className={classes.paper}>
        <Grid container className={classes.grid} spacing={1}>
            <Grid item xs={12}>
                <Typography className={classes.title}>PROJECTS</Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
            </Grid>
            {props.projects.map((item, index) => {
                return <Grid
                    key={"ProjectPreviewConsole-" + item.id}
                    onDoubleClick={() => props.goToProjectPage(index)} item xs={12}>
                    <ProjectPreviewCard
                        functionList={props.functionList}
                        projectInfo={item} index={index}/>
                </Grid>;
            })}
            {props.userProjects.map((item, index) => {
                return <Grid
                    key={"ProjectPreviewConsole-" + item.id}
                    onDoubleClick={() => props.goToProjectPage(index + props.projects.length)} item xs={12}>
                    <ProjectPreviewCard
                        functionList={props.functionList}
                        projectInfo={item} index={index + props.projects.length}/>
                </Grid>;
            })}
        </Grid>
    </Paper>);
}