import React from "react";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import Colors from "../../../../../../constants/Colors";
import TranslationOrderCard from "./TranslationOrderCard";
import TranslationOrderTextPreviewer from "./TranslationOrderTextPreviewer";
import TranslationOrderTextViewerAsList from "./TranslationOrderTextViewerAsList";

const useStyles = makeStyles( ( theme ) => ( {
    dialogAction: {
        display: "flex",
        justifyContent: "center",
    },
    button: {
        width: 400,
        backgroundColor: Colors.PRIMARY_PINK_ALT,
        '&:hover': {
            backgroundColor: Colors.PRIMARY_PINK_UP,

        }
    },
    buttonText: {
        fontWeight: "bold",
        fontSize: 16,
        fontFamily: Colors.fontOfProgram,
        color: Colors.PRIMARY_WHITE,
    },
    buttonGroup: {
        display: "flex",
        justifyContent: "space-evenly",
    },
    grid: {
        justifyContent: "space-between",
        display: "flex",
    },

    title: {
        color: Colors.PRIMARY_PINK_UP,
        fontWeight: "bold",
        fontSize: 24,
        paddingBottom: 10,
        fontFamily: Colors.fontOfProgram,
        paddingTop: 10,

    },
    subTitleChild: {
        color: Colors.PRIMARY_WHITE,
        fontWeight: "bold",
        fontSize: 16,
        fontFamily: Colors.fontOfProgram,
    },
    paper: {
        background: Colors.gradient,
        justifyContent: "space-between",
        alignItems: "center",
        justifyItems: "center",
        padding: 20,
    },
    scrollableDiv: {
        maxHeight: 800,
        overflowY: "scroll",
    }
} ) )


export default function TranslationOrderTextCopier( props ) {
    const classes = useStyles();
    const [ texts, setTexts ] = React.useState( [] )


    function handleEnglishShownWordChange( value, textId ) {
        let textTemp = [ ...texts ]
        textTemp.forEach( text => {
            if ( text.id === textId ) {
                text.englishShownWord = value
            }
        } )
        setTexts( textTemp )
    }

    function handleImageChange( img, textId ) {
        let textTemp = [ ...texts ]
        textTemp.forEach( text => {
            if ( text.id === textId ) {
                text.image = img
            }
        } )
        setTexts( textTemp )
    }

    function handleExplanationChange( value, textId, explanationId ) {
        let textTemp = [ ...texts ]
        textTemp.forEach( text => {
            if ( text.id === textId ) {
                text.explanation = {
                    "id": explanationId,
                    "content": value
                }
                //console.log(text.explanation)
            }
        } )
        setTexts( textTemp )
    }


    return <div>
        { props.page === "copy_previous" ?
            <div>
                <Typography className={ classes.title }>
                    Previous Orders
                </Typography>
                <Paper
                    elevation={ 0 }
                    className={ classes.paper }
                >
                    <Grid container className={ classes.grid }>
                        <Grid item xs>
                            <Typography className={ classes.subTitleChild }>
                                { "Permission" }
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={ classes.subTitleChild }>
                                { "Status" }
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={ classes.subTitleChild }>
                                { "Lines" }
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={ classes.subTitleChild }>
                                { "Translator Name" }

                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={ classes.subTitleChild }>
                                { "Email" }
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
                { props.project.translationOrders.map( ( order ) => {
                    return <TranslationOrderCard
                        selectOrder={ () => {
                            props.selectOrder( order )
                            props.setPage( "old_order" )
                        } }
                        order={ order }
                    />
                } ) }
            </div>
            : props.page === "old_order" ?
                <TranslationOrderTextPreviewer
                    setTextObjects={ ( val ) => setTexts( val ) }
                    texts={ props.selectedTexts }
                    orderTitle={ props.orderTitle }
                    setPage={ props.setPage }
                    completeSelection={ props.setSelectedTexts }
                    order={ props.oldOrder }/>
                : props.page === "view_as_list_older" ?
                    <TranslationOrderTextViewerAsList
                        language={ props.oldOrder.language }
                        orderTitle={ props.orderTitle }
                        handleClose={ () => props.handleClose() }
                        setPage={ props.setPage }
                        functionList={ props.functionList }
                        texts={ texts }
                        handleEnglishShownWordChange={ ( val, textId ) => handleEnglishShownWordChange( val, textId ) }
                        handleExplanationChange={ ( val, textId, explanationId ) => handleExplanationChange( val,
                            textId, explanationId ) }
                        handleImageChange={ ( val, textId ) => handleImageChange( val, textId ) }
                        textIdList={ props.selectedTexts }
                        project={ props.project }
                        order={ props.oldOrder }
                        program={ props.program }
                        name={ props.name }
                        surname={ props.surname }
                        email={ props.email }
                        date={ props.date }
                        translator={ props.translator }
                    />
                    : null
        }
    </div>
}